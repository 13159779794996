import web3 from "web3";
import BigNumber from 'bignumber.js'

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18)
    amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
  return amount
}

export const _approveBUSD = async (contractBUSD, address, amount) => {
  const amountTemp = web3.utils.toWei(amount.toString());
  const result = await contractBUSD.approve(address, amountTemp);
  return result;
};

export const _isRefundedRC = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase())
}

export const _claimTokens = async (claimContract, amount, tokenDecimals, signClaim) => {

  const amountTemp = convertTokenToWei(amount, tokenDecimals)
  console.log('amountTemp', amountTemp);
  console.log('signClaim', signClaim);
  const args = [amountTemp, signClaim]
  const gas = await claimContract.estimateGas.claimTokens(...args)
  return claimContract.claimTokens(...args, { gasLimit: gas })
}

export const _refund = async (claimContract, amountBusd, signatureRefund) => {
  const amount = web3.utils.toWei(amountBusd.toString(), 'ether')
  const args = [amount, signatureRefund]
  const estimatedGas = await claimContract.estimateGas.refund(...args)
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  })
}

export const _showClaimBtn = async (claimContract, account, amount, tokenDecimals) => {
  const amountTemp = convertTokenToWei(amount, tokenDecimals)
  const result = await claimContract.showClaimBtn(account, amountTemp)
  return result
}

export const _isClaimed = async (poolContract, account) => {
  return poolContract && poolContract.isClaimed(account)
}
export const _currentClaimRound = async (claimContract) => {
  return claimContract && claimContract.currentClaimRound()
}

export const _joinPoolNew = async (joinPoolContract, amount, projectId, roundId, signBusd, chainId) => {
  if (chainId === 1) {
    const amountTemp = web3.utils.toWei(amount.toString(), 'picoether')
    const args = [amountTemp, projectId, roundId, signBusd]
    const gas = await joinPoolContract.estimateGas.join(...args)
    return joinPoolContract.join(...args, { gasLimit: gas })
  }

  if (chainId === 137) {
    const amountTemp = web3.utils.toWei(amount.toString(), 'picoether')
    const args = [amountTemp, projectId, roundId, signBusd]
    const gas = await joinPoolContract.estimateGas.join(...args)
    return joinPoolContract.join(...args, { gasLimit: gas })
  }

  const amountTemp = web3.utils.toWei(amount.toString(), 'ether')
  const args = [amountTemp, projectId, roundId, signBusd]
  const gas = await joinPoolContract.estimateGas.join(...args)
  return joinPoolContract.join(...args)
}

export const _refundChange = async (claimContract, amountBusd, sign) => {

  const amount = web3.utils.toWei(amountBusd.toString(), 'ether');
  console.log('amountBusd', amount);
  console.log('sign', sign);
  const args = [amount, sign]
  const estimatedGas = await claimContract.estimateGas.refund(...args)
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  })
}

export const _isClaimChange = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase())
}

export const convertWeiToToken = (value, decimal) => {
  return value.toString() / (10 ** decimal)
}

export const _totalClaimed = async (poolContract, account, tokenDecimals) => {
  const res = poolContract && await poolContract.totalClaimed(account);
  return convertWeiToToken(res, tokenDecimals);
}
export const _getClaimAble = async (claimContract, account, amount, tokenDecimals) => {
  const amountTP = web3.utils.toWei(amount.toString(), 'ether');
  const res = claimContract && await claimContract.getClaimAble(amountTP, account);
  return convertWeiToToken(res, tokenDecimals);
}
export const _totalJoined = async (poolJoinContract) => {
  const res = poolJoinContract && await poolJoinContract.totalJoined();
  return res;
}

export const totalDailyClaimed = async (dailyClaimSC, account) => {
  return dailyClaimSC.userInfo(account)
}
export const _dailyClaimAble = async (dailyClaimSC, account) => {
  return dailyClaimSC.claimAble(account)
}
export const _totalTokenNeed = async (dailyClaimSC) => {
  return dailyClaimSC.totalTokenNeed()
}
export const _dailyClaimTokens = async (dailyClaimSC) => {
  return dailyClaimSC.claimTokens()
}

export const _dailyStartTime = async (dailyClaimSC) => {
  return dailyClaimSC.startTime();
}



// export const dailyWhitelisted = async (dailyClaimSC, account) => {
//   return dailyClaimSC.userInfo(account)
// }

export const _isJoined = async (poolJoinContract, account, projectId, roundId) => {
  try {
    return poolJoinContract.isJoined(account, projectId, roundId)
  } catch (error) {
    return false
  }
}


export const getProgressTime = (startTime) => {
  const now = new Date();
  const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
  const startDate = new Date(startTime);
  const startTS = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds(),
    0
  );
  let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const seconds = Math.floor(delta % 60);
  if (days >= 1) {
    return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  } else {
    return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  }
};

export const rendererCountDown = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  // Render a countdown
  if (completed) {
    window.location.reload();
    return;
  }
  return (
    <div className="process-tc">
      <div className="timer-count">
        {days} <span>:</span><small>day</small>
      </div>
      <div className="timer-count">
        {hours} <span>:</span><small>hours</small>
      </div>
      <div className="timer-count">
        {minutes} <span>:</span><small>mins</small>
      </div>
      <div className="timer-count">
        {seconds} <small>sec</small>
      </div>
    </div>
  );
};
