
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Form, Steps } from 'antd';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import VerifyToken from './verify-token';
import DefiLaunchpadInfo from './defi-launchpad-info';
import AdditonalInfo from './additional-info';
import Finish from './finish';
import { useWrap } from 'context/WrapperContext';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useActiveWeb3React } from 'hooks';
import { useDeployLaunchpadContract } from '../../../../hooks/useContract';
import { ethers } from 'ethers';
import { deployLaunchpad } from '../../../utils';
import web3 from 'web3';
import { currencies, currencyDecimals, ZOOM } from 'config/network/config';
import { getInformationByChain } from 'config/network/multichainAddresses';
import {
    INIT_LAUNCHPAD_FEE,
    LAUNCHPAD_AMOUNT_RAISED_FEE,
    LAUNCHPAD_TOKEN_RAISED_FEE,
    LAUNCHPAD_TOKEN_RAISED_FEE_ONLY,
    LIST_LAUNCHPAD_RAISED_FEE_ONLY,
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE,
    LIST_LAUNCHPAD_RAISED_FEE,
    LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1,
    LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2
} from 'components/keyConfig';
import { getChainListInfo } from 'config/network/chainlist';

const { Step } = Steps;
const { Content } = Layout;
declare const window: Window & typeof globalThis & { ethereum: any };

const IcoCreate = () => {
    const history = useHistory();
    const { showNoti } = useWrap();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // const [raisedFeeOnly, setRaisedFeeOnly] = useState<any>(0);
    // const [tokenRaisedFee, setTokenRaisedFee] = useState<any>(0);
    const [initLaunchpadFee, setInitLaunchpadFee] = useState(0);

    // const [amountRaisedFee, setAmountRaisedFee] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React();

    //INIT_LAUNCHPAD_FEE
    const [createPoolFee, setCreatePoolFee] = useState<any>(0);

    const [launchpadRaisedFeeOnly, setLaunchpadRaisedFeeOnly] = useState<any>(0);

    //BNB FEE PERCENT
    const [bnbFeePercent, setBNBFeePercent] = useState<any>(0);

    // LIST_LAUNCHPAD_TOKEN_RAISED_FEE
    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>(0);

    //LAUNCHPAD_RAISED_FEE
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>(0);

    //TOKEN_W86_FEE_OPTION_1
    const [tokeW86FeeOption1, setTokeW86FeeOption1] = useState<any>(0);

    //TOKEN_W86_FEE_OPTION_2
    const [tokeW86FeeOption2, setTokeW86FeeOption2] = useState<any>(0);

    let storageTokenInfo = window.localStorage.getItem('tokenInfo');
    let storageTokenApproval = window.localStorage.getItem('tokenApproval');
    let storageLaunchpadInfo = window.localStorage.getItem('launchpadInfo');
    let storageAdditionalInfo = window.localStorage.getItem('additionalInfo');

    const deployLaunchpadAddress = getInformationByChain(chainId, 'REACT_APP_DEPLOY_LAUNCHPAD');

    const deployLaunchpadContract = useDeployLaunchpadContract(deployLaunchpadAddress);
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const address_0 = process.env.REACT_APP_ADDRESS_0 ?? '0x0000000000000000000000000000000000000000';

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);



    let additionalInfo;
    let launchpadInfo: any;
    let tokenApproval;
    if (storageTokenApproval) {
        tokenApproval = JSON.parse(storageTokenApproval);
    }
    let tokenInfo;
    if (storageTokenInfo) {
        tokenInfo = JSON.parse(storageTokenInfo);
    }

    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }
    if (storageLaunchpadInfo) {
        launchpadInfo = JSON.parse(storageLaunchpadInfo);
    }
    const steps = [
        {
            title: 'Verify Token',
            content: <VerifyToken form={form} />,
            description: 'Enter your token address. If you haven’t had one, click “Create token”.'
        },
        {
            title: 'ICO Campaign Info',
            content: <DefiLaunchpadInfo form={form} />,
            description: 'Please fill out all the information of your ICO campaign.'
        },
        {
            title: 'Team & Project',
            content: <AdditonalInfo form={form} />,
            description: 'Let community know more about your project'
        },
        {
            title: 'Completion',
            content: <Finish />,
            description: 'Review everything. It is time for magic!'
        },
    ];
    let initCurrentStep = 0;
    if (storageTokenInfo && storageLaunchpadInfo) {
        initCurrentStep = 1
    }
    if (storageLaunchpadInfo) {
        initCurrentStep = 2
    }
    if (storageAdditionalInfo) {
        initCurrentStep = 3
    }

    const [current, setCurrent] = React.useState(initCurrentStep);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    let isManualListing: any = tokenInfo && tokenInfo.listing_option == 0 ? false : true;

    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0 && tokenInfo?.fee_options) {

                    JSON.parse(tokenInfo?.fee_options).forEach(element => {
                        if (element == INIT_LAUNCHPAD_FEE) {
                            let poolFee = res.data.filter((itemSetting) => INIT_LAUNCHPAD_FEE === itemSetting.settingKey);
                            if (poolFee && poolFee[0]) {
                                setCreatePoolFee(poolFee[0]);
                            }
                        }

                        if (element == LIST_LAUNCHPAD_RAISED_FEE_ONLY) {
                            let launchpadRaisedFeeOnlyObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE_ONLY === itemSetting.settingKey);
                            if (launchpadRaisedFeeOnlyObj && launchpadRaisedFeeOnlyObj[0]) {
                                setLaunchpadRaisedFeeOnly(launchpadRaisedFeeOnlyObj[0]);
                                setBNBFeePercent(launchpadRaisedFeeOnlyObj[0]);

                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_RAISED_FEE) {
                            let launchpadTokenRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                            if (launchpadTokenRaisedFeeObj && launchpadTokenRaisedFeeObj[0]) {
                                setLaunchpadTokenRaisedFee(launchpadTokenRaisedFeeObj[0]);
                            }
                        }

                        if (element == LIST_LAUNCHPAD_RAISED_FEE) {
                            let launchpadRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE === itemSetting.settingKey);
                            if (launchpadRaisedFeeObj && launchpadRaisedFeeObj[0]) {
                                setLaunchpadRaisedFee(launchpadRaisedFeeObj[0]);
                                setBNBFeePercent(launchpadRaisedFeeObj[0]);
                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1) {
                            let tokeW86FeeOption1Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1 === itemSetting.settingKey);
                            if (tokeW86FeeOption1Obj && tokeW86FeeOption1Obj[0]) {
                                setTokeW86FeeOption1(tokeW86FeeOption1Obj[0]);
                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2) {
                            let tokeW86FeeOption2Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2 === itemSetting.settingKey);
                            if (tokeW86FeeOption2Obj && tokeW86FeeOption2Obj[0]) {
                                setTokeW86FeeOption2(tokeW86FeeOption2Obj[0]);
                            }
                        }
                    })


                    // let initFee = res.data.filter((itemSetting) => INIT_LAUNCHPAD_FEE === itemSetting.settingKey);
                    // if (initFee.length > 0) {
                    //     setInitLaunchpadFee(parseFloat(initFee[0].settingValue))
                    // }
                    // if (tokenInfo !== undefined) {
                    //     let feeOptions = JSON.parse(tokenInfo.fee_options);

                    //     if (feeOptions.length == 1) { // BNB only
                    //         let raisedFeeOnlyAPI = res.data.filter((itemSetting) => LAUNCHPAD_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey);
                    //         setRaisedFeeOnly(parseFloat(raisedFeeOnlyAPI[0].settingValue));
                    //         setTokenRaisedFee(0);
                    //     } else if (feeOptions.length == 2) { // BNB + TOKEN fee

                    //         let tokenRaisedFee = res.data.filter((itemSetting) => LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                    //         let amountRaisedFee = res.data.filter((itemSetting) => LAUNCHPAD_AMOUNT_RAISED_FEE === itemSetting.settingKey);
                    //         if (tokenRaisedFee && tokenRaisedFee[0]) {
                    //             setTokenRaisedFee(parseFloat(tokenRaisedFee[0].settingValue));
                    //         }
                    //         if (amountRaisedFee && amountRaisedFee[0]) {
                    //             setRaisedFeeOnly(parseFloat(amountRaisedFee[0].settingValue));
                    //         }
                    //     }
                    // }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        if (chainId) {
            getSettings();
        }

    }, [chainId]);

    const confirmCreateIdo = async () => {
        let startTime = new Date(launchpadInfo.start_time);
        let endTime = new Date(launchpadInfo.end_time);
        const networkSymbol = getChainListInfo(chainId, 'SYMBOL');
        const networkChainId = getChainListInfo(chainId, 'CHAIN_ID');
        if (tokenInfo.chainId != chainId) {
            showNoti('warning', 'Invalid Network. Please Connect Correct Network!');
            return;
        }
        if (!createPoolFee) {
            showNoti('error', 'Invalid Fee Create!');
            return;
        }
        if (!networkChainId) {
            showNoti('error', 'Chain not found!');
            return;
        }

        const payload = {
            ownerAddress: account,
            name: tokenInfo.token_name,
            description: additionalInfo.description,
            logoUrl: additionalInfo.logo_url,
            website: additionalInfo.website,
            activated: true,
            campaignType: "ICO",
            softCap: launchpadInfo.softcap ? parseFloat(launchpadInfo.softcap) : 0,
            hardCap: launchpadInfo.hardCap ? parseFloat(launchpadInfo.hardCap) : 0,
            liquidityLockup: launchpadInfo.liquidity_lockup ? parseInt(launchpadInfo.liquidity_lockup) : 0,
            antiRug: launchpadInfo.team_vesting_system && launchpadInfo.team_vesting_system[0] ? launchpadInfo.team_vesting_system[0] : false,
            backgroundLink: additionalInfo.background_link ? additionalInfo.background_link : '',
            doVestingContributor: !!(launchpadInfo.vesting_contributor && launchpadInfo.vesting_contributor[0]),
            dexId: launchpadInfo.router ? parseInt(launchpadInfo.router) : 0,
            raisedFeeOnly: launchpadRaisedFeeOnly ? parseFloat(launchpadRaisedFeeOnly.settingValue) : 0, // no
            tokenFeeOption1: tokeW86FeeOption1 ? parseInt(tokeW86FeeOption1.settingValue) : 0,
            tokenFeeOption2: tokeW86FeeOption2 ? parseInt(tokeW86FeeOption2.settingValue) : 0,
            tokenInfo: {
                tokenAddress: tokenInfo.token_address,
                name: tokenInfo.token_name,
                symbol: tokenInfo.token_symbol,
                logoURL: additionalInfo.logo_url,
                decimals: tokenInfo.token_decimal ? parseInt(tokenInfo.token_decimal) : 0,
                totalSupply: tokenInfo.total_supply ? parseInt(tokenInfo.total_supply) : 0,
                networkChain: networkSymbol
            },
            socialNetwork: {
                facebook: additionalInfo.facebook ? additionalInfo.facebook : '',
                twitter: additionalInfo.twitter ? additionalInfo.twitter : '',
                medium: additionalInfo.medium ? additionalInfo.medium : '',
                discord: additionalInfo.discord ? additionalInfo.discord : '',
                github: additionalInfo.github ? additionalInfo.github : '',
                telegram: additionalInfo.telegram ? additionalInfo.telegram : '',
                instagram: additionalInfo.instagram ? additionalInfo.instagram : '',
                reddit: additionalInfo.reddit ? additionalInfo.reddit : ''
            },
            // vestingTeam: {
            //     totalTeamToken: launchpadInfo.total_vesting_tokens ? parseFloat(launchpadInfo.total_vesting_tokens) : 0,
            //     dayReleaseAfterList: launchpadInfo.first_token_release ? parseInt(launchpadInfo.first_token_release) : 0,
            //     percentTokenTGE: launchpadInfo.first_token_release ? parseFloat(launchpadInfo.first_token_release) : 0,
            //     vestingCycle: launchpadInfo.vesting_period_each_cycle ? parseInt(launchpadInfo.vesting_period_each_cycle) : 0,
            //     tokenReleaseCycle: launchpadInfo.first_token_release_amount ? parseFloat(launchpadInfo.first_token_release_amount) : 0
            // },
            vestingContributor: {
                firstRelease: launchpadInfo.vc_first_release ? parseFloat(launchpadInfo.vc_first_release) : 0,
                tgeLock: launchpadInfo.vc_tge_lock ? parseFloat(launchpadInfo.vc_tge_lock) : 0,
                vestingCycle: launchpadInfo.vc_vesting_cycle ? parseInt(launchpadInfo.vc_vesting_cycle) : 0,
                lockAfterTGE: launchpadInfo.vc_lock_after_tge ? parseInt(launchpadInfo.vc_lock_after_tge) : 0,
                tokenReleaseCycle: launchpadInfo.vc_token_release_cycle ? parseFloat(launchpadInfo.vc_token_release_cycle) : 0
            },
            rounds: [
                {
                    tokenAddress: tokenInfo.token_address,
                    listingRate: launchpadInfo.listing_rate ? parseFloat(launchpadInfo.listing_rate) : 0,
                    liquidityPercent: launchpadInfo.liquidity ? parseInt(launchpadInfo.liquidity) : 0,
                    initFee: createPoolFee ? parseFloat(createPoolFee.settingValue) : 0,
                    raisedFee: launchpadRaisedFee ? parseFloat(launchpadRaisedFee.settingValue) : 0,
                    raisedTokenFee: launchpadTokenRaisedFee ? parseFloat(launchpadTokenRaisedFee.settingValue) : 0,
                    swapRate: launchpadInfo.presale_rate ? parseFloat(launchpadInfo.presale_rate) : 0,
                    minBuy: launchpadInfo.minimum_buy ? parseFloat(launchpadInfo.minimum_buy) : 0,
                    maxBuy: launchpadInfo.maximum_buy ? parseFloat(launchpadInfo.maximum_buy) : 0,
                    refundType: launchpadInfo.refund_type ? launchpadInfo.refund_type.toUpperCase() : 'REFUND',
                    whiteListOnly: launchpadInfo.whitelist === 'enable' ? true : false,
                    startTime: launchpadInfo.start_time ? startTime.toISOString() : '',
                    endTime: launchpadInfo.end_time ? endTime.toISOString() : '',
                    roundAddress: tokenInfo.token_address,
                    name: launchpadInfo.title ? launchpadInfo.title : 'Guaranted',
                    currencyPay: tokenInfo.currency_pay ? tokenInfo.currency_pay : '',
                    chainId: networkChainId,
                    networkChain: networkSymbol,
                    virtualMachine: "EVM",
                    doManualListing: isManualListing,
                    currencyPayAddress: tokenInfo.currency_pay_address ? tokenInfo.currency_pay_address : ethers.constants.AddressZero,
                    currencyPayDecimals: tokenInfo.currency_pay_decimals ? tokenInfo.currency_pay_decimals : 18
                }
            ]
        };

        try {
            const feeDec = currencyDecimals[tokenInfo.chainId][tokenInfo.currency_pay]
            const icoToken = payload.tokenInfo.tokenAddress;
            const feeToken = currencies[tokenInfo.chainId][tokenInfo.currency_pay];
            const softCap = ethers.utils.parseUnits(`${launchpadInfo.softcap}`, feeDec);
            const hardCap = ethers.utils.parseUnits(`${launchpadInfo.hardCap}`, feeDec);
            const presaleRate = ethers.utils.parseUnits(`${launchpadInfo.presale_rate}`, parseInt(tokenInfo.token_decimal));
            const minInvest = ethers.utils.parseUnits(`${launchpadInfo.minimum_buy}`, feeDec);
            const maxInvest = ethers.utils.parseUnits(`${launchpadInfo.maximum_buy}`, feeDec);
            const startTimeSC = (new Date(launchpadInfo.start_time).getTime()) / 1000;
            const endTimeSC = (new Date(launchpadInfo.end_time).getTime()) / 1000;
            const whitelistPool = launchpadInfo.whitelist == 'enable' ? 1 : 0;
            const poolType = launchpadInfo.refund_type.toUpperCase() == 'REFUND' ? 1 : 0;
            const launchpadInfoSC = [icoToken, feeToken, softCap, hardCap, presaleRate, minInvest, maxInvest, startTimeSC, endTimeSC, whitelistPool, poolType];

            const useContributionVesting = parseFloat(launchpadInfo.vc_first_release) > 0;
            const firstReleasePercent = useContributionVesting ? parseInt(`${parseFloat(launchpadInfo.vc_first_release) * 100}`) : 100 * 100;
            const vestingPeriodEachCycle = useContributionVesting ? (parseInt(launchpadInfo.vc_vesting_cycle) * (24 * 60)) * 60 : 0;
            const tokenReleaseEachCycle = useContributionVesting ? parseInt(`${parseFloat(launchpadInfo.vc_token_release_cycle) * 100}`) : 0;
            const cliffVesting = useContributionVesting ? parseInt(`${parseFloat(launchpadInfo.vc_tge_lock) * 60}`) : 0;
            const lockAfterCliffVesting = useContributionVesting ? parseInt(`${(parseFloat(launchpadInfo.vc_lock_after_tge) * (24 * 60)) * 60}`) : 0;


            const claimInfo = [cliffVesting, lockAfterCliffVesting, firstReleasePercent, vestingPeriodEachCycle, tokenReleaseEachCycle];

            // const useTeamVesting = parseFloat(launchpadInfo.total_vesting_tokens) > 0;
            // const teamTotalVestingTokens = ethers.utils.parseUnits(`${useTeamVesting ? launchpadInfo.total_vesting_tokens : 0}`, parseInt(tokenInfo.token_decimal));

            // const teamCliffVesting = useTeamVesting ? parseInt(launchpadInfo.first_token_release) : 0;
            // const teamFirstReleasePercent = useTeamVesting ? parseInt(`${parseFloat(launchpadInfo.first_token_release_amount) * 100}`) : 0;
            // const teamVestingPeriodEachCycle = useTeamVesting ? parseInt(launchpadInfo.vesting_period_each_cycle) * 60 : 0; //TODO: PARSE TIMESTAMP * 60
            // const teamTokenReleaseEachCycle = useTeamVesting ? parseInt(`${parseFloat(launchpadInfo.token_period_each_cycle ?? 0) * 100}`) : 0;

            // const teamVestingInfo = [teamTotalVestingTokens, teamCliffVesting, teamFirstReleasePercent, teamVestingPeriodEachCycle, teamTokenReleaseEachCycle];

            const isManual = isManualListing;
            const routerAddress = launchpadInfo.routerAddress ? launchpadInfo.routerAddress : ethers.constants.AddressZero;
            const factoryAddress = launchpadInfo.factoryAddress ? launchpadInfo.factoryAddress : ethers.constants.AddressZero;
            const listingPrice = ethers.utils.parseUnits(`${launchpadInfo.listing_rate ? launchpadInfo.listing_rate : 0}`, parseInt(tokenInfo.token_decimal));
            const listingPercent = parseInt(`${parseFloat(launchpadInfo.liquidity ? launchpadInfo.liquidity : 0) * 100}`);
            const lpLockTime = launchpadInfo.liquidity_lockup ? (parseInt(launchpadInfo.liquidity_lockup) * (24 * 60)) * 60 : 0; // days swap to min


            const dexInfo = [isManual, routerAddress, factoryAddress, listingPrice, listingPercent, lpLockTime];

            // const raisedFeeOnlyPercent = (Math.floor(launchpadRaisedFeeOnly ? parseFloat(launchpadRaisedFeeOnly.settingValue) : 0) * 100);
            const raisedTokenFeePercent = (Math.floor(launchpadTokenRaisedFee ? parseFloat(launchpadTokenRaisedFee.settingValue) : 0) * 100);
            const raisedFeePercent = (Math.floor(bnbFeePercent ? parseFloat(bnbFeePercent.settingValue) : 0) * 100);
            const penaltyFee = Math.floor(10 * 100);

            const feeInfo = [ethers.utils.parseUnits(`${parseFloat(createPoolFee.settingValue)}`, 18), raisedFeePercent, raisedTokenFeePercent, penaltyFee]

            if (!routerAddress || !factoryAddress) {
                showNoti('warning', 'Something went wrong!');
                return;
            }

            if (!icoToken || icoToken.toLowerCase() == ethers.constants.AddressZero.toLocaleLowerCase()) {
                showNoti('warning', 'Invalid Token Address!');
                return;
            }

            if (softCap.gt(hardCap)) {
                showNoti('warning', 'Invalid Cap Setting!');
                return;
            }

            if (minInvest.gt(maxInvest)) {
                showNoti('warning', 'Invalid Investment Setting!');
                return;
            }

            if (startTimeSC > endTimeSC) {
                showNoti('warning', 'Invalid Time Setting!');
                return;
            }
            if (!isManualListing) {
                if (listingPercent < 0 || listingPercent > ZOOM) {
                    showNoti('warning', 'Invalid Listing Percent Setting!');
                    return;
                }
            }

            if (firstReleasePercent < 0 || tokenReleaseEachCycle < 0 || firstReleasePercent + tokenReleaseEachCycle > ZOOM) {
                showNoti('warning', 'Invalid User Claim Percent Setting!');
                return;
            }

            setLoading(true);
            await deployLaunchpad(deployLaunchpadContract, launchpadInfoSC, claimInfo, dexInfo, feeInfo, parseFloat(createPoolFee.settingValue)).then((txn) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        let newSCAddress;
                                        for (let i = 0; i < res.logs.length; i++) {
                                            if (res.logs[i].topics && res.logs[i].topics.length == 2 && res.logs[i].topics[0] === '0xc7e1810916b60e6291f5b59fede64609924cde531cfa0841516a230dc0ea0c04') {
                                                newSCAddress = res.logs[i].topics[1];
                                            }
                                        }
                                        if (newSCAddress) {
                                            console.log(w3.eth.abi.decodeParameter('address', newSCAddress));
                                            payload.rounds[0].roundAddress = w3.eth.abi.decodeParameter('address', newSCAddress);
                                            let res = await IdoLaunchpad.createIdoLaunchpad(payload);

                                            if (res.status === 201) {
                                                if (res.data) {
                                                    window.localStorage.removeItem('tokenApproval');
                                                    window.localStorage.removeItem('tokenInfo');
                                                    window.localStorage.removeItem('launchpadInfo');
                                                    window.localStorage.removeItem('additionalInfo');
                                                    showNoti('success', `Create Launchpad Successfully`);
                                                    setLoading(false);
                                                    setTimeout(() => {
                                                        history.push({
                                                            pathname: `/ico/detail/${res.data.id}/${res.data?.handle}`,
                                                            search: 'status=upcoming'
                                                        });
                                                    }, 500);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    showNoti('error', 'Create Launchpad Failed');
                                    setLoading(false);
                                }

                            }
                        })();
                    }, 1000);
                }
            })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (error) {
            console.log('Error: ', error);
            showNoti('error', 'Confirm Failed');
        }
    };

    const onConfirmSteps = (values: any) => {
        if (values.step_verify_token && values.step_verify_token == 1) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('tokenInfo', data);
            // window.location.reload();
        }
        if (tokenInfo && values.step_launchpad_info && values.step_launchpad_info == 2) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('launchpadInfo', data);
            window.location.reload();
        }
        if (tokenInfo && values.step_additional_info && values.step_additional_info == 3) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('additionalInfo', data);
            // window.location.reload();
        }
        setCurrent(current + 1);
    }

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="back-history">
                                <Link to={`/ido`}><LeftCircleOutlined /> Cancel create ICO</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Create ICO</div>
                                <div className="create-step">
                                    <>
                                        <Steps current={current} className="create-step-cus">
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} description={item.description} />
                                            ))}
                                        </Steps>
                                        <div className="create-step-block">
                                            <Form form={form} layout="vertical" autoComplete="off"
                                                onFinish={onConfirmSteps}
                                            >
                                                <div className="steps-content">{steps[current].content}</div>
                                                <div className="steps-action">
                                                    {current > 0 && (
                                                        <button className="btn-default" type="button" style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                            Previous
                                                        </button>
                                                    )}
                                                    {current < steps.length - 1 && (
                                                        <>
                                                            {current == 0 && tokenApproval?.token_approval && (
                                                                <button disabled={tokenApproval?.token_approval && account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                            {current == 1 && (
                                                                <button disabled={account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                            {current == 2 && (
                                                                <button disabled={account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {current === steps.length - 1 && (
                                                        <button type="button" disabled={storageLaunchpadInfo && storageTokenInfo && storageAdditionalInfo && account ? false : true} className="btn-all" onClick={confirmCreateIdo}>
                                                            {account ? 'Confirm' : 'Connect Wallet'}
                                                        </button>
                                                    )}
                                                </div>
                                            </Form>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
                {loading && (
                    <>
                        <LoadingFull />
                    </>
                )}
            </Content>
        </>
    )
}

export default IcoCreate
