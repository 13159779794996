import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Menu } from 'antd';
import { BrowserRouter as Router, Route, Link, Switch, useLocation } from 'react-router-dom';
import './style.css';
const ProjectMenu = () => {
    const location = useLocation();
    let defaultMenu;
    switch (location.pathname) {
        case '/launchpad/my-project':
            defaultMenu = 'my-project';
            break;
        default:
            defaultMenu = 'all-project';
    }
    const [current, setCurrent] = useState(defaultMenu);
    const activeMenu = (e: { key: React.SetStateAction<string> }) => {
        setCurrent(e.key);
    };

    useEffect(() => {
        setCurrent(defaultMenu)
    }, [defaultMenu])

    return (
        <div className="project-menu">
            <Menu mode="horizontal" onClick={activeMenu} selectedKeys={[current]} className="project-menu-list">
                <Menu.Item key="all-project"><Link to="/ico-launchpad">All Project</Link></Menu.Item>
                <Menu.Item key="my-project"><Link to="/launchpad/my-project">My Project</Link></Menu.Item>
            </Menu>
        </div>
    );
}

export default ProjectMenu