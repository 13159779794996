import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useParams, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import {
	Layout, Row, Col, Form, Pagination, Button, Input, Spin, Alert, Table, Progress, Card, Modal, Radio, Tag, InputNumber
} from 'antd';
import { GlobalOutlined, LeftCircleOutlined, CopyOutlined, FacebookFilled, GithubOutlined, InstagramOutlined, TwitterCircleFilled, YoutubeOutlined } from '@ant-design/icons';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import Countdown from 'react-countdown';
import LoadingFull from 'components/loading-full';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { RadioChangeEvent } from 'antd';
import web3 from 'web3';
import { useWrap } from '../../../context/WrapperContext';
import { getInformationByChain } from '../../../config/network/multichainAddresses';
import { ethers } from 'ethers';
import { privatesSaleApi } from '../../../config/api/privatesSaleApi';
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';
import { getProgressTime, rendererCountDown } from 'components/ido-launchpad/utilsIDO';
import {
	approveToken, getSignature, getTokenAllowance,
	getTokenInformation,
	privateSaleCancel,
	privateSaleClaimFund,
	privateSaleContribute,
	privateSaleEmWithdrawContribute,
	privateSaleFinalize, privateSaleGetClaimAble, privateSaleGetCurrency,
	privateSaleGetCycle,
	privateSaleGetCyclePercent, privateSaleGetEndTime, privateSaleGetHardCap,
	privateSaleGetHoldingAmount,
	privateSaleGetHoldingToken,
	privateSaleGetInvestInfo, privateSaleGetInvestors, privateSaleGetMaxInvest, privateSaleGetMinInvest,
	privateSaleGetOwner,
	privateSaleGetPrivateSaleType,
	privateSaleGetRaisedAmount,
	privateSaleGetSoftCap, privateSaleGetStartTime,
	privateSaleGetState,
	privateSaleGetTgeDate,
	privateSaleGetTgePercent,
	privateSaleGetUnlockedAmount,
	privateSaleSetWhitelistPool, privateSaleTotalInvestors,
	privateSaleWithdrawContribute
} from '../../utils';
import privateSaleAbi from '../../../config/abi/privateSaleAbi.json';

import { convertDateTime } from 'utils/formatDatetime';
import { currencyDecimals, getCurrencyDecimals } from 'config/network/config';
import pairAbi from '../../../config/abi/pairAbi.json';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Content } = Layout;
const { TextArea } = Input;
declare const window: Window & typeof globalThis & { ethereum: any };

const PrivateDetail = () => {
	const { chainId, account, library } = useActiveWeb3React();
	const { showNoti } = useWrap();

	const [form] = Form.useForm<{ name: string; age: number }>();
	let { handle, privateSaleAddress, privateSaleId }: { handle: string, privateSaleAddress: string, privateSaleId: string } = useParams();
	const [detail, setDetail] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [whitelistPool, setWhitelistPool] = useState<any>(-1);
	const [whitelists, setWhitelists] = useState<any>([]);
	const [loadingData, setLoadingData] = useState(false);
	const [addWLForm] = Form.useForm();
	const [rmWLForm] = Form.useForm();
	const [holdingTokenForm] = Form.useForm();


	const [isShowPublicAnti, setIsShowPublicAnti] = useState<boolean>(false);
	const [isFormAddWL, setIsFormAddWL] = useState<boolean>(false);
	const [isFormRmWL, setIsFormRmWL] = useState<boolean>(false);
	const [keyLoading, setKeyLoading] = useState(0);
	const [tgeBps, setTgeBps] = useState<any>(0);
	const [cycle, setCycle] = useState<any>(0);
	const [cycleBps, setCycleBps] = useState<any>(0);


	const [totalAllocations, setTotalAllocations] = useState<any>(0);
	const [isOwner, setIsOwner] = useState<any>(true);
	const [allowance, setAllowance] = useState<any>(0);
	const [progressPercent, setProgressPercent] = useState<any>(0);
	const [loadingBuy, setLoadingBuy] = useState(false);

	const [poolState, setPoolPoolState] = useState(0);

	const [holdingToken, setHoldingToken] = useState<any>('');
	const [holdingAmount, setHoldingAmount] = useState<any>('');
	const [holdingName, setHoldingName] = useState<any>('');
	const [holdingSymbol, setHoldingSymbol] = useState<any>('');
	const [holdingDecimals, setHoldingDecimals] = useState<any>(18);

	const [refund, setRefund] = useState<boolean>();

	const [tgeDate, setTgeDate] = useState<any>(0);
	const [raisedAmount, setRaisedAmount] = useState<any>(0);
	const [unlockedAmount, setUnlockedAmount] = useState<any>(0);
	const [investedAmount, setInvestedAmount] = useState<any>(0);
	const [softCap, setSoftCap] = useState<any>(0);
	const [hardCap, setHardCap] = useState<any>(0);
	const [minInvest, setMinInvest] = useState<any>(0);
	const [maxInvest, setMaxInvest] = useState<any>(0);
	const [startTime, setStartTime] = useState<any>(0);
	const [endTime, setEndTime] = useState<any>(0);
	const [claimAble, setClaimAble] = useState<any>(0);
	const [totalRow, setTotalRow] = useState(0);
	const [signature, setSignature] = useState<any>('0x00');
	const [disableBuyBtn, setDisableBuyBtn] = useState<boolean>(true);
	const [currency, setCurrency] = useState<any>(ethers.constants.AddressZero);


	let nowDate = new Date(Date.now());
	let startDate = new Date(detail?.startTime);
	let endDate = new Date(detail?.endTime);
	const [param, setParam] = useState(
		{
			page: 0,
			size: 20,
			sort: 'id,desc'
		}
	);

	const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

	const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

	const getPrivateSaleId = async (handle) => {
		try {
			let res = await privatesSaleApi.getPrivateSaleId(handle);
			if (res.status === 200) {
				if (res.data) {
					setDetail(res.data);
					console.log(detail);
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	const getWLByAcc = async (acc, id) => {
		try {
			let res = await privatesSaleApi.checkWLByAcc(acc, id);
			if (res.status === 200) {
				if (res?.data?.joinSign) {
					setSignature(res.data.joinSign);
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};
	const getWhitelistByPriId = async (projectId) => {

		try {
			let res = await privatesSaleApi.getWhitelistByRound(projectId, param);
			setTotalRow(parseInt(res.headers['x-total-count']));
			setTotalAllocations(parseInt(res.headers['x-total-count']));
			if (res.status === 200) {
				if (res.data.length > 0) {
					setWhitelists(res.data);

				} else {
					setWhitelists([]);
					setTotalAllocations(0)
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		getPrivateSaleId(handle);
	}, [handle]);

	useEffect(() => {

		let nowMil = Math.floor(Date.now() / 1000)
		if (startTime <= nowMil && endTime >= nowMil && poolState == 1 && account && raisedAmount < hardCap) {
			if (whitelistPool === 0 || whitelistPool == 2) {
				setDisableBuyBtn(false)
			} else if (whitelistPool === 1) {
				if (parseInt(signature) > 0) {
					setDisableBuyBtn(false);
				}
			} else {
				setDisableBuyBtn(false);
			}

		}

	}, [startTime, endTime, signature, whitelistPool, poolState, account, raisedAmount, hardCap, currency, allowance]);

	useEffect(() => {
		if (account) {
			getWLByAcc(account, privateSaleId);
		}

	}, [account, privateSaleId]);

	useEffect(() => {
		getWhitelistByPriId(handle);
	}, [handle, param]);

	const onChange_Pagi = (pageNumber: any) => {
		setParam({
			...param,
			page: pageNumber - 1
		});
	};

	const getSCInfo = async () => {

		try {
			setLoadingData(true)
			const tgeDateSC = await privateSaleGetTgeDate(privateSaleAbi, privateSaleAddress, library, account);
			const tgeBpsSC = await privateSaleGetTgePercent(privateSaleAbi, privateSaleAddress, library, account);
			const cycleSC = await privateSaleGetCycle(privateSaleAbi, privateSaleAddress, library, account);
			const cycleBpsSC = await privateSaleGetCyclePercent(privateSaleAbi, privateSaleAddress, library, account);
			const raisedAmountSC = await privateSaleGetRaisedAmount(privateSaleAbi, privateSaleAddress, library, account);
			const unlockedAmountSC = await privateSaleGetUnlockedAmount(privateSaleAbi, privateSaleAddress, library, account);
			const investInfosSC = await privateSaleGetInvestInfo(privateSaleAbi, privateSaleAddress, library, account);
			const privateSaleTypeSC = await privateSaleGetPrivateSaleType(privateSaleAbi, privateSaleAddress, library, account);
			const holdingTokenSC = await privateSaleGetHoldingToken(privateSaleAbi, privateSaleAddress, library, account);
			const holdingAmountSC = await privateSaleGetHoldingAmount(privateSaleAbi, privateSaleAddress, library, account);
			const softCapSC = await privateSaleGetSoftCap(privateSaleAbi, privateSaleAddress, library, account);
			const hardCapSC = await privateSaleGetHardCap(privateSaleAbi, privateSaleAddress, library, account);
			const minInvestSC = await privateSaleGetMinInvest(privateSaleAbi, privateSaleAddress, library, account);
			const maxInvestSC = await privateSaleGetMaxInvest(privateSaleAbi, privateSaleAddress, library, account);
			const startTimeSC = await privateSaleGetStartTime(privateSaleAbi, privateSaleAddress, library, account);
			const endTimeSC = await privateSaleGetEndTime(privateSaleAbi, privateSaleAddress, library, account);
			const stateSC = await privateSaleGetState(privateSaleAbi, privateSaleAddress, library, account);
			const owner = await privateSaleGetOwner(privateSaleAbi, privateSaleAddress, library, account);
			const claimableSC = await privateSaleGetClaimAble(privateSaleAbi, privateSaleAddress, library, account);
			const currencySC = await privateSaleGetCurrency(privateSaleAbi, privateSaleAddress, library, account);


			const decimal = detail?.currencyPayDecimals || getCurrencyDecimals(chainId, detail?.currencyApply) || 18;


			setRaisedAmount(parseFloat(ethers.utils.formatUnits(raisedAmountSC, decimal)));
			setUnlockedAmount(parseFloat(ethers.utils.formatUnits(unlockedAmountSC, decimal)));
			setInvestedAmount(parseFloat(ethers.utils.formatUnits(investInfosSC.totalInvestment, decimal)));
			setSoftCap(parseFloat(ethers.utils.formatUnits(softCapSC, decimal)));
			setHardCap(parseFloat(ethers.utils.formatUnits(hardCapSC, decimal)));
			setMinInvest(parseFloat(ethers.utils.formatUnits(minInvestSC, decimal)));
			setMaxInvest(parseFloat(ethers.utils.formatUnits(maxInvestSC, decimal)));
			setMaxInvest(parseFloat(ethers.utils.formatUnits(maxInvestSC, decimal)));
			setHoldingAmount(parseFloat(ethers.utils.formatUnits(holdingAmountSC, decimal)));
			setClaimAble(parseFloat(ethers.utils.formatUnits(claimableSC, decimal)));
			setHoldingToken(ethers.constants.AddressZero == holdingTokenSC ? '' : holdingTokenSC);
			setStartTime(parseInt(startTimeSC));
			setEndTime(parseInt(endTimeSC));
			setRefund(investInfosSC.refund);
			setWhitelistPool(parseInt(privateSaleTypeSC));
			setCurrency(currencySC);
			setTgeDate(tgeDateSC);
			setTgeBps(parseInt(tgeBpsSC) / 100);
			setCycle(parseInt(cycleSC) / 60);
			setCycleBps(parseInt(cycleBpsSC) / 100);
			setPoolPoolState(parseInt(stateSC));


			setIsOwner(account?.toLowerCase() == owner?.toLowerCase());
			setLoadingData(false)
		} catch (e) {
			console.log(e);
			setLoadingData(false)
		}
	}

	const getInvestorInfo = async () => {

		try {
			setLoadingData(true)
			const totalInvestor = await privateSaleTotalInvestors(privateSaleAbi, privateSaleAddress, library, account);
			console.log(parseInt(totalInvestor));
			const investorsSC = await privateSaleGetInvestors(privateSaleAbi, privateSaleAddress, library, account, 0, parseInt(totalInvestor));

			console.log(investorsSC);
			// console.log(ethers.utils.formatUnits(investorsSC[0].totalInvestment, 18));



			setLoadingData(false)
		} catch (e) {
			console.log(e);
			setLoadingData(false)
		}
	}

	const getTokenInfo = async (tokenAddressVal, spender) => {
		let allowanceSC = await getTokenAllowance(tokenInfoAbi, tokenAddressVal, account, spender)
		const tokenInfoSC = await getTokenInformation(pairAbi, tokenAddressVal);
		setAllowance(parseFloat(ethers.utils.formatUnits(allowanceSC, parseInt(tokenInfoSC.decimals))));
	}


	useEffect(() => {
		if (account && privateSaleAddress && detail && detail.chainId == chainId) {
			getSCInfo()
			getInvestorInfo()
		}
	}, [account, privateSaleAddress, chainId, detail]);

	useEffect(() => {
		if (currency && privateSaleAddress && account &&
			currency.toLowerCase() !== ethers.constants.AddressZero.toLowerCase() &&
			privateSaleAddress.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
			getTokenInfo(currency, privateSaleAddress)
		}
	}, [account, currency, privateSaleAddress]);

	useEffect(() => {
		setProgressPercent(Math.ceil(raisedAmount / hardCap * 100))
	}, [raisedAmount, hardCap])


	const handleApproveToken = async (e) => {
		try {
			e.preventDefault()
			setLoading(true);
			setKeyLoading(20);
			await approveToken(tokenInfoAbi, currency, library, account, privateSaleAddress)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										!countNoti && showNoti('success', `Approve ${detail?.currencyApply} Successfully`);
										countNoti++;
										setAllowance(Math.pow(2, 256));

									} else {
										showNoti('error', `Approve ${detail?.currencyApply} Failed`);
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (error: any) {
			console.log(error);
			setLoading(false);
		}
	};

	const onSubmitBuy = async (values: any) => {
		try {
			setLoadingBuy(true);
			const decimals = detail.currencyPayDecimals || getCurrencyDecimals(detail.chainId, detail.currencyPay)

			const amount = ethers.utils.parseUnits(`${values.amount}`, decimals)
			privateSaleContribute(privateSaleAbi, privateSaleAddress, library, account, amount, signature)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setRaisedAmount(raisedAmount + parseFloat(values.amount));
											setInvestedAmount(investedAmount + parseFloat(values.amount));

											const payload = {
												id: detail?.id,
												ownerAddress: account,
												amountToken: 0,
												amountCurrency: values.amount,
												joinHash: txn.hash,
												joinSign: signature,
												tokenSaleTimeId: detail?.id,
												tokenSaleTime: {
													id: detail?.id
												}
											};
											let res = await privatesSaleApi.joinPool(payload);
											console.log(res);
											if (res.status === 201) {
												showNoti('success', 'Buy Successfully');
												// window.location.reload()
											}

											setLoadingBuy(false);
										}
									} else {
										showNoti('warning', 'Contribute Failed');
									}
									setLoadingBuy(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoadingBuy(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoadingBuy(false);
		}
	};


	const onEmergencyWithdraw = () => {
		try {

			setLoading(true);
			setKeyLoading(1);
			privateSaleEmWithdrawContribute(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Emergency Withdraw Successfully');
											setRaisedAmount(raisedAmount - investedAmount);
											setInvestedAmount(0);
										}
									} else {
										showNoti('warning', 'Emergency Withdraw Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	}

	const onWithdrawContribute = () => {
		try {
			setLoading(true);
			setKeyLoading(1);
			privateSaleWithdrawContribute(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Withdraw Successfully');
											setRaisedAmount(raisedAmount - investedAmount);
											setInvestedAmount(0);
										}
									} else {
										showNoti('warning', 'Withdraw Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	}

	const showFormAddWL = () => {
		setIsFormAddWL(true)
		addWLForm.resetFields();
	};
	const showFormRemoveWL = () => {
		setIsFormRmWL(true);
		rmWLForm.resetFields();
	};

	const closeBox = (e) => {
		e.preventDefault()
		setIsFormAddWL(false);
		setIsFormRmWL(false);
		setIsShowPublicAnti(false)
	};


	const onFinalizePool = async () => {
		try {

			if (poolState == 2) {
				const payload = {
					id: detail?.id,
					roundAction: 'FINALIZE'
				};
				let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
				if (res.status === 200) {
					if (res.data) {
						setLoading(false);
						showNoti('success', 'Finalize Launchpad Successfully');
						window.location.reload()
					}
				}
				return;
			}
			setLoading(true);
			setKeyLoading(5)
			privateSaleFinalize(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setPoolPoolState(2);

											const payload = {
												id: detail?.id,
												roundAction: 'FINALIZE'
											};
											let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
											if (res.status === 200) {
												if (res.data) {
													setLoading(false);
													showNoti('success', 'Finalize Launchpad Successfully');
													getPrivateSaleId(handle);
												}
											}
										}
									} else {
										showNoti('warning', 'Finalize Launchpad Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	}

	const onClaimFund = async () => {
		try {
			setLoading(true);
			setKeyLoading(6);
			privateSaleClaimFund(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Claim Fund Successfully');
											setUnlockedAmount(unlockedAmount + claimAble)
											setClaimAble(0)
										}
									} else {
										showNoti('warning', 'Claim fund Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	}

	const onCancelPool = async (status) => {
		try {
			if (!privateSaleId || !privateSaleAddress) {
				showNoti('warming', 'Invalid Private Sale!');
				return;
			}
			if (poolState == 3) {
				const payload = {
					id: parseInt(privateSaleId),
					roundAction: 'CANCEL'
				};
				let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
				if (res.status === 200) {
					if (res.data) {
						setLoading(false);
						showNoti('success', 'Cancel Private Sale Successfully');
						getPrivateSaleId(handle);
					}
				}
				return;
			}
			setLoading(true);
			setKeyLoading(7);
			privateSaleCancel(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setPoolPoolState(3);
											const payload = {
												id: parseInt(privateSaleId),
												roundAction: 'CANCEL'
											};
											let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
											if (res.status === 200) {
												if (res.data) {
													setLoading(false);
													showNoti('success', 'Cancel Private Sale Successfully');
													getPrivateSaleId(handle);
												}
											}
										}
									} else {
										showNoti('warning', 'Cancel Private Sale Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	}

	const onSubmitAddWL = async (values: any) => {
		try {
			if (!privateSaleId || !privateSaleAddress || !account) {
				showNoti('warning', 'Invalid Private Sale');
				return
			}
			const addressList = JSON.parse(values.addressList);
			if (!addressList) {
				showNoti('warning', 'Invalid Addresses');
				return
			}
			setLoading(true);
			setKeyLoading(2);
			const signatureWeb3 = await getSignature(account, library, 'private-sale-white-lists/add');
			const payload = {
				addressList: addressList,
				saleTokenTimeId: parseInt(privateSaleId),
				message: 'private-sale-white-lists/add',
				signature: signatureWeb3
			};
			let res = await privatesSaleApi.addWhiteList(payload);

			if (res.status === 204) {
				setLoading(false);
				showNoti('success', 'Add Whitelist Successfully');
				setIsFormAddWL(false);
				addWLForm.resetFields()
				getPrivateSaleId(handle);
				getWhitelistByPriId(handle);
				getWLByAcc(account, privateSaleId)
			}
		} catch (error) {
			console.log('Error: ', error);
			showNoti('error', 'Add Whitelist Failed');
		} finally {
			setLoading(false);
		}
	};

	const onSubmitRemoveWL = async (values: any) => {
		console.log('payload', values);
		try {
			if (!privateSaleId || !privateSaleAddress || !account) {
				showNoti('warning', 'Invalid Private Sale');
			}

			const addressList = JSON.parse(values.addressList);
			if (!addressList) {
				showNoti('warning', 'Invalid Addresses');
				return
			}
			setLoading(true);
			setKeyLoading(3);
			const signatureWeb3 = await getSignature(account, library, 'private-sale-white-lists/remove');
			const payload = {
				addressList: addressList,
				saleTokenTimeId: parseInt(privateSaleId),
				message: 'private-sale-white-lists/remove',
				signature: signatureWeb3
			};
			let res = await privatesSaleApi.removeWhiteList(payload);

			if (res.status === 204) {
				setLoading(false);
				showNoti('success', 'Remove Successfully');
				setIsFormRmWL(false);
				rmWLForm.resetFields()
				getPrivateSaleId(handle);
				getWhitelistByPriId(handle);
				getWLByAcc(account, privateSaleId)
			}
		} catch (error) {
			console.log('Error: ', error);
			showNoti('error', 'Remove Failed');
		} finally {
			setLoading(false);
		}
	};

	const onchangeRadioBtn = async ({ target: { value } }: RadioChangeEvent) => {
		try {
			if (value == 2) {
				setIsShowPublicAnti(true);
				return;
			}
			console.log('radio checked', value); // PUBLIC, WHITE_LIST, ANTI_BOT
			if (value == whitelistPool || value < 0) {
				return;
			}

			setLoading(true);
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, value, ethers.constants.AddressZero, 0)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											const payload = {
												id: privateSaleId,
												saleType: value
											};
											let res = await privatesSaleApi.updateStatusWhitelist(privateSaleId, payload);
											if (res.status === 200) {
												setLoading(false);
												showNoti('success', 'Update Private Sale Successfully');
												setWhitelistPool(value);
												holdingTokenForm.resetFields();
												setHoldingToken('');
												setHoldingName('');
												setHoldingSymbol('');
												setHoldingDecimals('');
												setHoldingAmount(0);
												getPrivateSaleId(handle);
											}
										}
									} else {
										showNoti('warning', 'Update Private Sale Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
			// } else if (value == 2) {
			// 	setIsShowPublicAnti(true);
			// }

		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	const onEnableWhitelist = async (val: boolean) => {
		try {
			setLoading(true);
			setKeyLoading(4)
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, val ? 1 : 0, ethers.constants.AddressZero, 0)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', `${val ? 'Enable' : 'Disable'} Whitelist Successfully`);
											setWhitelistPool(val ? 1 : 0)
										}
									} else {
										showNoti('warning', `${val ? 'Enable' : 'Disable'} Whitelist Failed`);
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};



	const onSetHoldingToken = (values) => {
		try {

			if (!values.token_address || !ethers.utils.isAddress(values.token_address) || !values.amount || !holdingDecimals) {
				console.log('calll');
				return;
			}

			setLoading(true);
			setKeyLoading(11);
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, 2, values.token_address, ethers.utils.parseUnits(`${values.amount}`, holdingDecimals))
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Update Successfully');
											setWhitelistPool(2);
											setIsShowPublicAnti(false);
										}
									} else {
										showNoti('warning', 'Update Launchpad Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('warning', error.message);
						} else {
							if (error.data && error.data.message) {
								showNoti('warning', error.data.message);
							}
						}
					}
				});


		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	const onCloseSetting = async (e) => {
		try {
			e.preventDefault();
			setIsShowPublicAnti(false);
			holdingTokenForm.setFieldsValue({
				'amount': holdingAmount,
				'token_address': holdingToken
			});

		} catch (e: any) {
			console.log(e);
		}
	};

	const onChangeTokenAddress = async (values: any) => {
		const tokenAddressVal: any = values.target.value;
		if (!tokenAddressVal || !parseInt(tokenAddressVal)) {
			return;
		}

		try {
			const tokenInfo = await getTokenInformation(tokenInfoAbi, tokenAddressVal);
			setHoldingName(tokenInfo.name);
			setHoldingSymbol(tokenInfo.symbol);
			setHoldingDecimals(tokenInfo.decimals);

		} catch (e: any) {
			console.log(e);
		}
	};





	const options = [
		{ label: 'Public', value: 0 },
		{ label: 'Whitelist', value: 1 },
		{ label: 'Public Anti', value: 2 }
	];

	const columnsWhitelist = [
		{
			title: 'No.',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Address',
			dataIndex: 'userAddress',
			key: 'userAddress',
		}
	];

	const StatusRender = (nowDate, startDate, endDate) => {
		let status: any = '';
		let color: any = '';
		if (startDate > nowDate && endDate > nowDate) {
			status = 'upcoming';
			color = 'orange';
		}
		if (startDate < nowDate && endDate > nowDate) {
			status = 'opening';
			color = '#f5c112';
		}
		if (startDate < nowDate && endDate < nowDate) {
			status = 'closed';
			color = '#919191';
		}
		if (detail?.roundAction === "CANCEL") {
			status = 'canceled';
			color = 'red';
		}

		return <Tag color={color}>{status}</Tag>;
	}


	const onCopy = () => {
		showNoti('success', 'Copied');
	};

	return (
		<>
			<HeaderMobile />
			<Content className='page-container'>

				<div className='page-main'>
					<HeaderTop pageType={'color'} chainIdDetail={detail?.chainId} />
					<div className='page-content'>
						<div className='project-block-create container'>
							<div className='back-history'>
								<Link to={`/private-sale`}><LeftCircleOutlined /> Back to Private Sale List</Link>
							</div>
							<div className='create-form'>
								<div className='create-title font-mobile-24'>{detail?.title}</div>
								<div className='create-step'>
									<div className='private-block-detail'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={24} xl={16}>
												<Card title="Private Sale Info" extra={
												<>
														<div className='private-status'>
															{StatusRender(nowDate, startDate, endDate)}
														</div>
												</>
											}>
													<div className='airdrop-line pt-0'>
														<div className="ido-info">
															<Row gutter={{ xs: 0 }} className="">
																<Col className="gutter-row" xs={6} xl={3} md={3} sm={3}>
																	<div className="ido-info-image">
																		<img src={detail?.logoLink} />
																	</div>
																</Col>
																<Col className="gutter-row" xs={18} xl={21} md={21} sm={21}>
																	<Row gutter={{ xs: 0 }} className="">
																		<Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
																			<div className="ido-name private-detail-name" title={detail?.title}>{detail?.title} <span></span></div>
																			<div className="ido-social">
																				<div className="privates-social">
																					{detail?.website && (
																						<span className="detail-social"><a href={detail?.website}><GlobalOutlined className="icon" /></a></span>
																					)}
																					{detail?.facebook && (
																						<span className="detail-social"><a href={detail?.facebook}><FacebookFilled className="icon" /></a></span>
																					)}
																					{detail?.git && (
																						<span className="detail-social"><a href={detail?.git}><GithubOutlined className="icon" /></a></span>
																					)}
																					{detail?.instagram && (
																						<span className="detail-social"><a href={detail?.instagram}><InstagramOutlined className="icon" /></a></span>
																					)}
																					{detail?.telegram && (
																						<span className="detail-social"><a href={detail?.telegram}><FontAwesomeIcon icon={faPaperPlane as IconProp} /></a></span>
																					)}
																					{detail?.twitter && (
																						<span className="detail-social"><a href={detail?.twitter}><TwitterCircleFilled className="icon" /></a></span>
																					)}
																					{detail?.youtube && (
																						<span className="detail-social"><a href={detail?.youtube}><YoutubeOutlined className="icon" /></a></span>
																					)}
																				</div>
																			</div>
																		</Col>
																		
																	</Row>
																</Col>
															</Row>
														</div>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={9}><span>Private Sale Address</span></Col>
															<Col className='gutter-row text-right' xs={15}>
																<span className='text-danger text-dot'>
																	{privateSaleAddress}
																	<CopyToClipboard onCopy={onCopy} text={privateSaleAddress}>
																		<CopyOutlined className='text-info ml-5' />
																	</CopyToClipboard>
																</span>
																
																<p className='text-info mb-0'>(Do not send {detail?.currencyApply} to the private sale address. You will lose your funds.)</p>
															</Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Soft Cap</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{detail?.softCap} {detail?.currencyApply}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Hard Cap</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{detail?.hardCap} {detail?.currencyApply}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Private Sale Start Time</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{convertDateTime(startDate)}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Private Sale End Time</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{convertDateTime(endDate)}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>First Release For Project</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{detail?.firstReleasePercent}%</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Vesting For Project</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{detail?.releaseEachCyclePercent}%</span></Col> {/* each 5 minutes */}
														</Row>
													</div>

													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Cycle</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{cycle.toLocaleString()} Minutes</span></Col> {/* each 5 minutes */}
														</Row>
													</div>
												</Card>
												{whitelistPool == 1 && (
													<Card title={`Allocation [${totalAllocations}]`} style={{ marginTop: '15px' }}>
														{whitelists.length > 0 && (
															<>
																<hr className="hr-cus"></hr>
																<div className="detail-sub-title">User Whitelist</div>
																<div className="whitelist-list">
																	<Table pagination={false} dataSource={whitelists} columns={columnsWhitelist} />

																	{totalRow > param.size && (
																		<Pagination
																			current={param.page + 1}
																			defaultCurrent={1}
																			total={totalRow}
																			pageSize={param.size}
																			onChange={onChange_Pagi}
																			showSizeChanger={false}
																			className="pagiation-custom"
																		/>
																	)}
																</div>
															</>
														)}
													</Card>
												)}

											</Col>
											<Col className='gutter-row' xs={24} xl={8}>
												<Card title=''>
													<p className='airdrop-time'><Alert message="Make sure the website is WaveSignal." type='warning' /></p>
													{startDate > nowDate && (detail?.roundAction != 'CANCEL'  || poolState != 3) && (
														<>
															<div className="detail-time">
																<span className="text-time">Sale start in </span>
																<div className="time-coundown">
																	<Countdown
																		date={getProgressTime(startDate)}
																		renderer={rendererCountDown}
																	/>
																</div>
															</div>
														</>
													)}
													{startDate < nowDate && endDate > nowDate && (detail?.roundAction != 'CANCEL' || poolState != 3) && (
														<>
															<div className="detail-time">
																<span className="text-time">Sale end in </span>
																<div className="time-coundown">
																	<Countdown
																		date={getProgressTime(endDate)}
																		renderer={rendererCountDown}
																	/>
																</div>
															</div>
															<Progress
																strokeColor={{
																	'0%': '#0261a5',
																	'100%': '#89befe'
																}}
																percent={progressPercent}
																showInfo={false}
															/>
															<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
																<Col className='gutter-row' xs={12}><span className='text-info'>0 {detail?.currencyApply}</span></Col>
																<Col className='gutter-row text-right' xs={12}><span className='text-info'>{raisedAmount.toLocaleString()}/{hardCap.toLocaleString()} {detail?.currencyApply}</span></Col>
															</Row>

															<Form className='mt-15' form={form} layout="vertical" autoComplete="off" onFinish={onSubmitBuy}>
																<Form.Item name="amount"
																	initialValue={0}
																	label={`Amount (min: ${detail?.minBuy} ${detail?.currencyApply}, max: ${detail?.maxBuy} ${detail?.currencyApply})`}
																	// label={`Amount`}
																	rules={[
																		{
																			validator: (rule, value, cb: (msg?: string) => void) => {
																				!value || parseFloat(value) <= 0 || parseFloat(value) < detail?.minBuy || parseFloat(value) > detail?.maxBuy
																					? cb('Invalid value')
																					: cb();
																			}
																		}
																	]}
																>
																	<InputNumber className="amount-buy" />
																</Form.Item>
																{/*{allowance > maxInvest && (*/}
																{/*	<Form.Item>*/}
																{/*		<Button type="primary" className="btn-all" htmlType="submit" disabled={disableBuyBtn} >Buy With {detail?.currencyApply} {loadingBuy && <Spin className="ml-10" size="small" />}</Button>*/}
																{/*	</Form.Item>*/}
																{/*)}*/}

																{currency.toLowerCase() != ethers.constants.AddressZero.toLowerCase() && allowance === 0 ? (
																	<Form.Item>
																		<Button type="primary" className="btn-all" disabled={loading && keyLoading == 20} onClick={handleApproveToken} >Approve {detail?.currencyApply} {(loading && keyLoading == 20) && <Spin className="ml-10" size="small" />}</Button>
																	</Form.Item>
																): (
																	<Form.Item>
																		<Button type="primary" className="btn-all" htmlType="submit" disabled={disableBuyBtn} >Buy With {detail?.currencyApply} {loadingBuy && <Spin className="ml-10" size="small" />}</Button>
																	</Form.Item>
																)}

																{(investedAmount > 0 && (startDate <= nowDate && endDate >= nowDate) && poolState == 1) &&
																	<Form.Item>
																		<Button className="btn-buy" htmlType="button" onClick={onEmergencyWithdraw}
																			disabled={loading && keyLoading === 1}>Emergency Withdraw {(loading && keyLoading === 1) && <Spin className="ml-10" size="small" />}</Button>
																	</Form.Item>}
																{(investedAmount > 0 && (poolState == 3 || (endDate <= nowDate && raisedAmount < softCap))) &&
																	<div className="grp-btn-claim">
																		<Button className="btn-all" htmlType="button" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
																	</div>
																}

															</Form>
														</>
													)}
													{(endDate < nowDate || detail?.roundAction == 'CANCEL' || poolState == 3) && (
														<>
															{(investedAmount > 0 && (poolState == 3 || (endDate <= nowDate && raisedAmount < softCap))) &&
															<div className="grp-btn-claim">
																<Button className="btn-all" htmlType="button" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
															</div>
															}
															<div className="detail-time">
																<span className="text-time text-danger">Sale ended </span>
															</div>
														</>
													)}
												</Card>
												<Card style={{ marginTop: '15px' }}>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Status</span></Col>
															<Col className='gutter-row text-right text-danger' xs={12}><span>{StatusRender(nowDate, startDate, endDate)}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Your {detail?.currencyApply} balance</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>0 {detail?.currencyApply}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Minimum Buy</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{minInvest.toLocaleString()} {detail?.currencyApply}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Maximum Buy</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{maxInvest.toLocaleString()} {detail?.currencyApply}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Your purchased</span></Col>
															<Col className='gutter-row text-right text-info' xs={12}><span>{investedAmount.toLocaleString()} {detail?.currencyApply}</span></Col>
														</Row>
													</div>
												</Card>
												{isOwner && (
													<Card title={'Owner Zone'} style={{ marginTop: '15px' }}>
														{poolState == 1 && (
															<div className='airdrop-btn-grp'>
																<Radio.Group
																	className="m-10"
																	options={options}
																	onChange={onchangeRadioBtn}
																	value={whitelistPool}
																/>
																<div className='mt-15'>

																	{whitelistPool == 0 && (
																		<Button htmlType="button" className="btn-tab-detail" onClick={() => onEnableWhitelist(true)} disabled={loading && keyLoading === 4}>Enable Whitelist {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
																	)}

																	{whitelistPool === 1 && (
																		<>
																			<Button htmlType="button" className="airdrop-btn" onClick={showFormAddWL}>Add users to whitelist</Button>
																			<Button htmlType="button" className="airdrop-btn" onClick={showFormRemoveWL} disabled={!whitelists.length} >Remove users from whitelist</Button>
																			{/* <Button htmlType="button" className="airdrop-btn" onClick={showFormRemoveWL} >Remove users from whitelist</Button> */}
																			<Button htmlType="button" className="airdrop-btn" onClick={() => onEnableWhitelist(false)} disabled={loading && keyLoading === 4}>Disable whitelist {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
																		</>

																	)}

																	{whitelistPool == 2 && (
																		<Button htmlType="button" className="btn-tab-detail" onClick={() => setIsShowPublicAnti(true)} >Setting Token Holding</Button>
																	)}
																</div>
															</div>
														)}
														<p style={{ marginTop: '15px' }}><strong>Pool Actions</strong></p>
														<div className='airdrop-btn-grp'>

															{poolState == 1 && (
																<>
																	<Button htmlType="button" className="airdrop-btn" disabled={(loading && keyLoading === 5) || !((poolState === 1 && ((raisedAmount >= hardCap) || (endTime < Math.floor(Date.now() / 1000) && raisedAmount >= softCap))))} onClick={onFinalizePool}>Finalize Pool {(loading && keyLoading === 5) && <Spin className="ml-10" size="small" />}</Button>
																	<Button htmlType="button" className="airdrop-btn btn-cancel-pool" disabled={(loading && keyLoading === 7) || (poolState != 1)} onClick={onCancelPool}>Cancel Pool {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />} </Button>
																</>
															)}
															{poolState == 2 && (
																<Button htmlType="button" className="btn-all" disabled={(loading && keyLoading === 6) || claimAble == 0} onClick={onClaimFund}>{claimAble > 0 ? ` Claim (${claimAble} )` : ` Claimed (${unlockedAmount}/${raisedAmount})`} {detail?.currencyApply} {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}</Button>

															)}

														</div>
													</Card>
												)}
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</div>
						<Footer />
					</div>

					<Modal
						title={<div className="text-md custom-modal-title">Public with holding condition</div>}
						visible={isShowPublicAnti}
						className="custom-modal create-token-modal"
						footer={false}
					>
						<div className="transer-box-content">
							<div><Alert message="With this option you can control who can contribute to the pool. Only Users who hold a minimum amount of token you suggest would be able to contribute" type="warning" showIcon /></div>
							<Form
								form={holdingTokenForm}
								name="basic"
								autoComplete="off"
								onFinish={onSetHoldingToken}
								layout="vertical"
							>
								<Form.Item name="token_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]} initialValue={holdingToken}>
									<input className="amount-buy" placeholder="Enter address" onBlur={onChangeTokenAddress} />
								</Form.Item>

								<Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input Amount!' }]} initialValue={holdingAmount}>
									<input className="amount-buy" placeholder="Enter Amount" />
								</Form.Item>

								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className="gutter-row" xs={24}>
										{holdingName && holdingDecimals && holdingSymbol && (
											<div className="bg-token-info token-info-detail">
												<div className="detail-text">
													<div className="left">Name</div>
													<div className="right">{holdingName}</div>
												</div>
												<div className="detail-text">
													<div className="left">Symbol</div>
													<div className="right">{holdingSymbol}</div>
												</div>
												<div className="detail-text mb-15">
													<div className="left">Decimals</div>
													<div className="right">{holdingDecimals}</div>
												</div>
											</div>
										)}
									</Col>
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className="gutter-row" xs={24}>
										<Form.Item className="modal-btn-group">
											<button className="btn-all" disabled={loading && keyLoading === 11} type="submit">Confirm {loading && keyLoading === 11 && <Spin className="ml-10" size="small" />}</button>
											<button className="btn-default" disabled={loading && keyLoading === 11} onClick={e => onCloseSetting(e)}>Close</button>
										</Form.Item>
									</Col>
								</Row>
							</Form>

						</div>
					</Modal>

					<Modal
						title={<div className="text-md custom-modal-title">Remove Users Whitelist</div>}
						visible={isFormRmWL}
						className="custom-modal create-token-modal"
						footer={false}
					>
						<div className="transer-box-content">
							<Form
								form={rmWLForm}
								name="basic"
								autoComplete="off"
								onFinish={onSubmitRemoveWL}
								layout="vertical"
							>
								<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item name="account_address" label="Users" rules={[
									{
										validator: (rule, value, cb: (msg?: string) => void) => {
											if (!value) {
												return cb('Allocations can not be blank');
											}
											if (value) {
												const wallets = value.trim().split('\n');

												let addressList: any = [];

												for (let i = 0; i < wallets.length; i++) {
													const address = wallets[i].trim();
													addressList.push(address);


													if (!ethers.utils.isAddress(address)) {
														return cb(`Incorrect value at line ${i + 1}`);
													}
												}
												rmWLForm.setFieldsValue({
													'addressList': JSON.stringify(addressList)
												});
												cb();
											}

										}
									}]}>
									<TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
								</Form.Item>
								<Form.Item className="modal-btn-group">
									<button className="btn-all" disabled={loading && keyLoading === 3} type="submit">Remove User {(loading && keyLoading === 3) && <Spin size="small" />}</button>
									<button className="btn-default" disabled={loading && keyLoading === 3} onClick={closeBox}>Close</button>
								</Form.Item>
							</Form>

						</div>
					</Modal>

					<Modal
						title={<div className="text-md custom-modal-title">Add Users Whitelist</div>}
						visible={isFormAddWL}
						className="custom-modal create-token-modal"
						footer={false}
					>
						<div className="transer-box-content">
							<Form
								form={addWLForm}
								name="basic"
								autoComplete="off"
								onFinish={onSubmitAddWL}
								layout="vertical"
							>
								<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item name="account_address" label="Users" rules={[
									{
										validator: (rule, value, cb: (msg?: string) => void) => {
											if (!value) {
												return cb('Allocations can not be blank');
											}
											if (value) {
												const wallets = value.trim().split('\n');

												let addressList: any = [];

												for (let i = 0; i < wallets.length; i++) {
													const address = wallets[i].trim();
													addressList.push(address);


													if (!ethers.utils.isAddress(address)) {
														return cb(`Incorrect value at line ${i + 1}`);
													}
												}
												addWLForm.setFieldsValue({
													'addressList': JSON.stringify(addressList)
												});
												cb();
											}

										}
									}]}>
									<TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
								</Form.Item>
								<Form.Item className="modal-btn-group">
									<button className="btn-all" disabled={loading && keyLoading === 2} type="submit">Add User {(loading && keyLoading === 2) && <Spin size="small" />}</button>
									<button className="btn-default" disabled={loading && keyLoading === 2} onClick={closeBox}>Close</button>
								</Form.Item>
							</Form>

						</div>
					</Modal>


					{/*<Modal*/}
					{/*	title={<div className='text-md custom-modal-title'>Setting time to start</div>}*/}
					{/*	visible={isStartForm}*/}
					{/*	className='custom-modal create-token-modal'*/}
					{/*	footer={false}*/}
					{/*>*/}
					{/*	<div className='transer-box-content'>*/}
					{/*		<Form*/}
					{/*			form={startForm}*/}
					{/*			name='basic'*/}
					{/*			autoComplete='off'*/}
					{/*			onFinish={onStartPublic}*/}
					{/*			layout='vertical'*/}
					{/*		>*/}
					{/*			<Form.Item name="specType" label="Set time to start airdrop" initialValue={0}>*/}
					{/*				<Radio.Group className="radio-gr-create" onChange={e => setSpecificTime(!(!e.target.value))}>*/}
					{/*					<Radio value={0}>Start now</Radio>*/}
					{/*					<Radio value={1}>Start with specific time</Radio>*/}
					{/*				</Radio.Group>*/}
					{/*			</Form.Item>*/}
					{/*			{specificTime ? (*/}
					{/*				<Form.Item name="start_time" label="Start time"*/}
					{/*					rules={[*/}
					{/*						{ required: true, message: 'Please input Start time!' },*/}
					{/*					]}>*/}
					{/*					<Input className="amount-buy" type="datetime-local" />*/}
					{/*				</Form.Item>*/}
					{/*			) : (<></>)}*/}

					{/*			<div className="start-info">*/}
					{/*				<span className="start-info-detail"> You need at least {totalToStartTokens} {dataAirdrop?.tokenSymbol} to start airdrop</span>*/}
					{/*			</div>*/}


					{/*			<Form.Item className='modal-btn-group'>*/}

					{/*				{allowance < totalTokens ? (*/}
					{/*					<button className='btn-all' disabled={loading} onClick={handleApproveToken}>approve {loading &&*/}
					{/*						<Spin className='ml-10' size='small' />}</button>*/}
					{/*				) : (*/}
					{/*					<button className='btn-all' disabled={loading} type='submit'>Start Airdrop {loading &&*/}
					{/*						<Spin className='ml-10' size='small' />}</button>*/}
					{/*				)}*/}
					{/*				<button className='btn-default' disabled={loading} onClick={closeBox}>Close</button>*/}
					{/*			</Form.Item>*/}
					{/*		</Form>*/}

					{/*	</div>*/}
					{/*</Modal>*/}
				</div>
				{loadingData && (<LoadingFull />)}
			</Content>
		</>
	);
};

export default PrivateDetail;
