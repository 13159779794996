import React, { memo, useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import { DotChartOutlined, DribbbleOutlined, AccountBookOutlined, FileSearchOutlined, HomeFilled, CodepenOutlined, DeploymentUnitOutlined, DollarCircleFilled, MenuUnfoldOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams } from 'react-router-dom';
import {
	Wrapper,
	LinkLogo,
	Logo,
	Img,
	MenuContent,
	BackgroundNone
} from './styled';
import './style.css';

import { useHookShowMenuMobile } from './actionsSidebar';
import ConnectWallet from 'components/connect-wallet/ConnectWallet';

const { SubMenu } = Menu;
const SidebarApp = () => {
	const location: any = useLocation();
	const [state, actions] = useHookShowMenuMobile();
	const handleHideMenu = () => {
		actions.setShow(false)
	};
	let defaultMenu;
	switch (location.pathname) {

		case '/ico-launchpad':
			defaultMenu = 'launchpad';
			break;
		case '/ido-launchpad':
			defaultMenu = 'launchpad';
			break;
		case '/ido-launchpad/:handle':
			defaultMenu = 'launchpad';
			break;
		case '/ido/create':
			defaultMenu = 'launchpad';
			break;
		case '/ico/create':
			defaultMenu = 'launchpad';
			break;
		case '/pre-sale/create':
			defaultMenu = 'launchpad';
			break;
		case '/launchpad/create':
			defaultMenu = 'launchpad';
			break;
		case '/launchpad/anti-bot':
			defaultMenu = 'services';
			break;
		case '/token/create':
			defaultMenu = 'launchpad';
			break;
		case '/ido/anti-bot':
			defaultMenu = 'launchpad';
			break;
		case '/ino':
			defaultMenu = 'ino';
			break;
		case 'ino-launchpad/ino-detail':
			defaultMenu = 'ino-launchpad/ino-detail';
			break;
		case '/services':
			defaultMenu = 'services';
			break;
		case '/wave-lock':
			defaultMenu = 'services';
			break;
		case '/wave-lock/detail/:address':
			defaultMenu = 'services';
			break;
		case '/wave-lock/record/:id':
			defaultMenu = 'services';
			break;
		case '/wave-lock/create':
			defaultMenu = 'services';
			break;
		case '/wave-lock/token':
			defaultMenu = 'services';
			break;
		case '/wave-lock/liquidity':
			defaultMenu = 'services';
			break;
		case '/airdrop':
			defaultMenu = 'services';
			break;
		case '/airdrop/create':
			defaultMenu = 'services';
			break;
		case 'airdrop/detail':
			defaultMenu = 'services';
			break;
		case '/multi-sender':
			defaultMenu = 'services';
			break;
		case '/staking':
			defaultMenu = 'staking';
			break;
		case '/fixed-staking':
			defaultMenu = 'staking';
			break;
		case '/farm':
			defaultMenu = 'staking';
			break;
		case '/private-sale/create':
			defaultMenu = 'private-sale';
			break;
		case '/private-sale':
			defaultMenu = 'private-sale';
			break;
		default:
			defaultMenu = 'home';
	}
	const [current, setCurrent] = useState(defaultMenu);
	const activeMenu = (e: { key: React.SetStateAction<string> }) => {
		if (e.key === 'logo') {
			setCurrent('home');
			return;
		}
		setCurrent(e.key);
	};

	return (
		<>
			<Wrapper className="menu-marketplace" mobileShow={state.show}>
				<BackgroundNone onClick={() => actions.setShow(false)} mobileShow={state.show} />
				<LinkLogo to="/">
					<Logo>
						<Img src='../../images/w-logo.png' alt="logo" />
					</Logo>
				</LinkLogo>
				<MenuContent>
					<Menu
						selectedKeys={[current]}
						defaultOpenKeys={['sub1']}
						mode="inline"
						theme="dark"
						onClick={activeMenu}
						className={'menu-left'}
					>
						<Menu.Item key="home" icon={<HomeFilled style={{ fontSize: '30px' }} />}>
							<Link to="/">
								<span>Home</span>
							</Link>
						</Menu.Item>
						<SubMenu key="launchpad" icon={<CodepenOutlined style={{ fontSize: '30px' }} />} title="Launchpad">
							<Menu.Item key="ido-launchpad" onClick={handleHideMenu}>
								<Link to="/ido-launchpad">
									IDO Launchpad
								</Link>
							</Menu.Item>
							<Menu.Item key="ico-launchpad" onClick={handleHideMenu}>
								<Link to="/ico-launchpad">
									ICO Launchpad
								</Link>
							</Menu.Item>
							<Menu.Item key="create-ido" onClick={handleHideMenu}>
								<Link to="/ico/create">
									Create ICO
								</Link>
							</Menu.Item>
							<Menu.Item key="token-create" onClick={handleHideMenu}>
								<Link to="/token/create">
									Create Token
								</Link>
							</Menu.Item>

						</SubMenu>

						<SubMenu key="private-sale" icon={<DribbbleOutlined style={{ fontSize: '30px' }} />} title="Private Sale">
							<Menu.Item key="private-sale-list" onClick={handleHideMenu}>
								<Link to="/private-sale">
									Private Sale List
								</Link>
							</Menu.Item>
							<Menu.Item key="private-sale-create" onClick={handleHideMenu}>
								<Link to="/private-sale/create">
									Private Sale Create
								</Link>
							</Menu.Item>
						</SubMenu>

						<SubMenu key="staking" icon={<DotChartOutlined style={{ fontSize: '30px' }} />} title="Earn">
							<Menu.Item key="staking-list" onClick={handleHideMenu}>
								<Link to="/staking">Staking</Link>
							</Menu.Item>
							<Menu.Item key="staking-fixed" onClick={handleHideMenu}>
								<Link to="/fixed-staking">Fixed Staking</Link>
							</Menu.Item>
							<Menu.Item key="farm" onClick={handleHideMenu}>
								<Link to="/farm">
									Farm
								</Link>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key="ino" icon={<AccountBookOutlined style={{ fontSize: '30px' }} onClick={handleHideMenu} />}>
							<Link to="/ino">
								<span>INO Launchpad</span>
							</Link>
						</Menu.Item>
						<SubMenu key="services" icon={<DeploymentUnitOutlined style={{ fontSize: '30px' }} />} title="Services">
							<Menu.Item key="lock-list" onClick={handleHideMenu}>
								<Link to="/wave-lock/token">
									WaveLock
								</Link>
							</Menu.Item>
							<Menu.Item key="airdrop-list" onClick={handleHideMenu}>
								<Link to="/airdrop">
									Airdrop
								</Link>
							</Menu.Item>
							<Menu.Item key="anti-bot" onClick={handleHideMenu}>
								<Link to="/launchpad/anti-bot">
									Anti-bot
								</Link>
							</Menu.Item>
							<Menu.Item key="multi-sender" onClick={handleHideMenu}>
								<Link to="/multi-sender">
									<span>Multi-sender</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						<Menu.Item key="docs" icon={<FileSearchOutlined style={{ fontSize: '30px' }} />} title="Docs" onClick={handleHideMenu}>
							<a href='https://docs.wavesignal.finance' target={'_blank'}>
								<span>Docs</span>
							</a>
						</Menu.Item>
						<Menu.Item key="connect" className="connect-wallet">
							<ConnectWallet />
						</Menu.Item>

					</Menu>
				</MenuContent>
			</Wrapper>



		</>
	);
};

export default memo(SidebarApp);
