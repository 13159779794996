import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tag, Progress } from 'antd';
import { useActiveWeb3React } from 'hooks';
import Countdown from 'react-countdown';
import {
    getLaunchpadInfoByChainId
} from '../../../utils';
import launchpadAbi from 'config/abi/launchpadAbi.json';

import { ethers } from 'ethers';
import { getCurrencyDecimals } from 'config/network/config';

const IcoItem = (props: any) => {
    const { item, logoNetwork, titleNetwork } = props;

    const [hardCap, setHardCap] = useState(0);
    const [raisedAmount, setRaisedAmount] = useState(0);

    const { chainId, account, library } = useActiveWeb3React();
    let nowDate = new Date(Date.now());
    let startDate = new Date(item?.startTime);
    let endDate = new Date(item?.endTime);



    const getLaunchpadInformation = async (roundAddress, itemChainId) => {

        try {
            let infoSC = await getLaunchpadInfoByChainId(launchpadAbi, roundAddress, itemChainId);
            let decimals = item?.currencyPayDecimals || getCurrencyDecimals(itemChainId, item?.currencyPay) || 18
            if (infoSC) {
                setRaisedAmount(parseFloat(ethers.utils.formatUnits(infoSC.raisedAmount, decimals)));
                setHardCap(parseFloat(ethers.utils.formatUnits(infoSC.hardCap, decimals)));
            }

        } catch (e) {
            console.log(e);

        }

    }

    useEffect(() => {
        if (account && item?.roundAddress.length > 0 && item?.chainId) {
            getLaunchpadInformation(item?.roundAddress, item?.chainId)
        }

    }, [account, item?.roundAddress]);

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        }
        if (days > 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else if (days === 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        }
    };
    const getProgressTime = useCallback((startTime) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);


    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status: any = '';
        let color: any = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#87d068';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }

        return <Tag color={color}>{status}</Tag>;
    }



    return (
        <>

            <div className="project-item">
                <div className="project-image">
                    <Link to={`/ico/detail/${item?.campaign?.id}/${item?.campaign?.handle}`}><img src={item?.campaign?.backgroundLink?.length > 0 ? item?.campaign.backgroundLink : "../images/bg-default.webp"} /></Link>
                    <div className="project-chain">
                        <div className="project-token-main">{item?.campaign?.tokenInfo?.symbol}</div>
                        {titleNetwork.length > 0 && logoNetwork.length > 0 && (
                            <div className="project-token-chain">
                                <img title={titleNetwork} src={logoNetwork} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="project-item-content">
                    <div className="project-name"><Link className='text-info' to={`/ico/detail/${item?.campaign?.id}/${item?.campaign?.handle}`}>{item?.campaign?.name} {item?.name ? ' - ' + item?.name : ''}</Link></div>
                    <div className="project-status-social">
                        {StatusRender(nowDate, startDate, endDate, item?.roundStatus)}
                    </div>
                    <div className="project-total-raise">
                        <span>Soft/Hard Cap</span>
                        <span className='text-info'>{item?.campaign?.softCap} {item?.currencyPay}/ {item?.campaign?.hardCap} {item?.currencyPay}</span>
                    </div>
                    <div className="project-total-raise">
                        <span>Price</span>
                        <span className='text-info'>1 {item?.currencyPay} = {item?.swapRate.toLocaleString()} {item?.campaign?.tokenInfo?.symbol}</span>
                    </div>
                    <hr></hr>
                    <div className="project-registration">
                        {startDate > nowDate && endDate > nowDate && (
                            <>
                                <span className="sale-start-in">Sale start in </span>
                                <Countdown
                                    date={getProgressTime(startDate)}
                                    renderer={rendererCountDown}
                                />
                            </>
                        )}
                        {startDate < nowDate && endDate > nowDate && (
                            <>
                                <span className="sale-start-in">Sale end in </span>
                                <Countdown
                                    date={getProgressTime(endDate)}
                                    renderer={rendererCountDown}
                                />
                            </>
                        )}
                        {endDate < nowDate && (
                            <>
                                <span className="sale-start-in">Closed </span>
                            </>
                        )}

                    </div>
                    <Progress percent={item?.soldPercent !== null && item?.soldPercent > 0 ? item?.soldPercent : Math.ceil(raisedAmount / hardCap * 100)} className="project-progress-percent" />
                    <div className="project-total-sell">

                       
                        <span className={`text-info ${item?.soldPercent !== null ? 'text-hidden' : ''}`}>{raisedAmount.toLocaleString()} {item?.currencyPay}</span>
                        <span className='text-info'>{item?.campaign?.hardCap.toLocaleString()} {item?.currencyPay}</span>

                    </div>
                    <div className="project-total-raise">
                        <span>Liquidity %: </span>
                        <span className='text-info'>
                            {item?.doManualListing ? (
                                <>Manual Listing</>
                            ) : (
                                <>
                                    {item?.liquidityPercent} %
                                </>
                            )}

                        </span>
                    </div>
                    <div className="project-total-raise">
                        <span>Lockup Time : </span>
                        <span>
                            {item?.doManualListing ? (
                                <>
                                    Unlocked
                                </>
                            ) : (
                                <>
                                    {item?.campaign?.liquidityLockup} days
                                </>
                            )}

                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IcoItem
