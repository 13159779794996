import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Tag, Progress, Pagination, Collapse } from 'antd';
import HeaderTop from 'components/common/header-top';
import Countdown from 'react-countdown';
import Footer from 'components/common/footer';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import { InoLaunchpad } from 'config/api/inoLaunchpad';
import NoneData from '../element/NoneData';
import { STATUS } from './index.d';
// import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './style.css';

const { Content } = Layout;
const { Option } = Select;
const INOLaunchpad = () => {
	const { Panel } = Collapse;
	const [form] = Form.useForm();
	const onChange = (key: string | string[]) => {
		console.log(key);
	};

	// let navigate = useNavigate();
	// const viewDetail = (handle: any) => {
	//   navigate(`${"/ino/InoDetails"}/${handle}`);
	// };

	const [totalRow, setTotalRow] = useState(20);
	const [roundInosAl, setRoundInosAl]: any = useState([]);
	const [roundInosOp, setRoundInosOp]: any = useState([]);
	const [roundInosFi, setRoundInosFi]: any = useState([]);
	const [roundInosUp, setRoundInosUp]: any = useState([]);
	const [roundInosOpening, setRoundInosOpening]: any = useState([]);
	const [loadingData, setLoadingData] = useState(false);

	const [paramsAl, setParamAl] = useState({
		page: 0,
		size: 20,
		networkChain: 'ALL',
		statusFilter: 'ALL'
	});

	const [paramsUp, setParamUp] = useState({
		page: 0,
		size: 20,
		networkChain: 'ALL',
		statusFilter: 'UP_COMING'
	});
	const [paramsOp, setParamOp] = useState({
		page: 0,
		size: 20,
		networkChain: 'ALL',
		statusFilter: 'OPENING'
	});
	const [paramsFi, setParamFi] = useState({
		page: 0,
		size: 20,
		networkChain: 'ALL',
		statusFilter: 'FILLED'
	});
	const [paramsOpening, setParamOpening] = useState({
		networkChain: 'ALL'
	});

	useEffect(() => {
		InoLaunchpad.getInoList(paramsAl).then((res) => {
			setRoundInosAl(res.data);
		});
		InoLaunchpad.getInoList(paramsOp).then((res) => {
			setRoundInosOp(res.data);
		});
		InoLaunchpad.getInoList(paramsFi).then((res) => {
			setRoundInosFi(res.data);
		});
		InoLaunchpad.getInoList(paramsUp).then((res) => {
			setRoundInosUp(res.data);
		});
		InoLaunchpad.getInoListOpening(paramsOpening).then((res) => {
			setRoundInosOpening(res.data);
		});
	}, []);

	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();
	const setting = {
		asNavFor: nav2,
		slidesToShow: 1,
		dots: false,
		focusOnSelect: true,
		arrows: false,
		vertical: false
	};
	const settings = {
		asNavFor: nav1,
		slidesToShow: roundInosAl.length >= 4 ? 4 : roundInosAl.length,
		dots: false,
		focusOnSelect: true,
		arrows: false,
		vertical: false
	};
	const onSelectChain = (value: any) => {
		console.log('value', value);
		setParamFi({
			...paramsFi,
			networkChain: value
		});
		setParamAl({
			...paramsAl,
			networkChain: value
		});
		setParamUp({
			...paramsUp,
			networkChain: value
		});
		setParamOpening({
			...paramsOpening,
			networkChain: value
		});
		setParamOp({
			...paramsOp,
			networkChain: value
		});
	};
	// const onSearchLaunpad = (values: any) => {
	//     let search = values.target.value;
	//     setParamFi({
	//         ...paramsFi,
	//         tokenInfo: search
	//     });
	// };
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		// Render a countdown
		if (days > 1) {
			return (
				<span className="time-round">
					{days}days : {hours}h : {minutes}m : {seconds}s
				</span>
			);
		} else if (days === 1) {
			return (
				<span className="time-round">
					{days}day : {hours}h : {minutes}m : {seconds}s
				</span>
			);
		} else {
			return (
				<span className="time-round">
					{hours}h : {minutes}m : {seconds}s
				</span>
			);
		}
	};

	const getProgressTime = useCallback((startTime) => {
		if (!roundInosAl.startTime) {
			return Date.now();
		}
		const now = new Date();
		const utcDate = Date.UTC(
			now.getUTCFullYear(),
			now.getUTCMonth(),
			now.getUTCDate(),
			now.getUTCHours(),
			now.getUTCMinutes(),
			now.getUTCSeconds(),
			0
		);
		const startDate = new Date(startTime);

		const startTS = Date.UTC(
			startDate.getFullYear(),
			startDate.getMonth(),
			startDate.getDate(),
			startDate.getHours(),
			startDate.getMinutes(),
			startDate.getSeconds(),
			0
		);
		if (startTS <= utcDate) {
			return Date.now();
		} else {
			let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;

			const days = Math.floor(delta / 86400);
			delta -= days * 86400;
			const hours = Math.floor(delta / 3600);
			delta -= hours * 3600;
			const minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;
			const seconds = Math.floor(delta % 60); // in theory the modulus is not required

			// return `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${seconds}`
			if (days >= 1) {
				return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
			} else {
				return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
			}
		}
	}, []);
	const ItemBannerIno = (
		<>
			<div className="feature-ino-branch">
				<div className="content-ino-branch">
					<div className="slider-ino">
						<div className="slider-top">
							<Slider {...setting} ref={(slider1: any) => setNav1(slider1)} asNavFor={nav2}>
								{roundInosOpening.length > 0 &&
									roundInosOpening.map((item, i) => (
										<div className="item columns">
											<div className="colum w-4">
												<div className="banner-ino">
													<div className="title">
														{' '}
														<span className="icon">
															<img src="../images/i-bnb.png" alt="" />
														</span>{' '}
														{item?.title}
													</div>
													<div className="desc">{item?.inoRule?.content}</div>
													<div className="s-social">
														<div className="icon-social">
															<a href={item.inoRule?.content?.twitter} target="_blank">
																<img src="../images/s-twi.png" alt="" />
															</a>
														</div>
														<div className="icon-social">
															<a href={item.inoRule?.content?.telegram} target="_blank">
																<img src="../images/s-tele.png" alt="" />
															</a>
														</div>
														<div className="icon-social">
															<a href={item.inoRule?.content?.website} target="_blank">
																<img src="../images/s-earth.png" alt="" />
															</a>
														</div>
													</div>
													<ul className="list-price-ino">
														<li>
															<span className="txt-top">Price</span>
															<span className="txt-bot main-orange">
																{item?.inoBoxes[0]?.price} {item?.inoBoxes[0]?.currency}
															</span>
														</li>
														<li>
															<span className="txt-top">Total Sale</span>
															<span className="txt-bot">{item?.totalBoxSell} Boxes</span>
														</li>
													</ul>
													<div className="line-time-join">
														<div className="time-left">
															<div className="title-join">Start in</div>
															<div className="time-line">
																<Countdown
																	date={getProgressTime(roundInosAl.startTime)}
																	renderer={renderer}
																/>
															</div>
														</div>
														<div className="time-right">
															<button type="button" className="btn-all">
																Join Now
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="colum w-8">
												<div className="box-img">
													<img src={item?.logoLink} alt="" />
												</div>
											</div>
										</div>
									))}
							</Slider>
						</div>
						<div className="slider-bot">
							<Slider {...settings} ref={(slider2: any) => setNav2(slider2)} asNavFor={nav1}>
								{roundInosOpening &&
									roundInosOpening.map((item, i) => (
										<div className="item">
											<div className="box-img">
												<img src={item?.logoLink} alt="" />
											</div>
										</div>
									))}
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const ItemUpcoming = (item) => {
		const renderer = ({ days, hours, minutes, seconds, completed }) => {
			// Render a countdown
			if (days > 1) {
				return (
					<span>
						{days}days : {hours}h : {minutes}m : {seconds}s
					</span>
				);
			} else if (days === 1) {
				return (
					<span>
						{days}day : {hours}h : {minutes}m : {seconds}s
					</span>
				);
			} else {
				return (
					<span>
						{hours}h : {minutes}m : {seconds}s
					</span>
				);
			}
		};

		const getProgressTime = useCallback((startTime) => {
			if (!startTime) {
				return Date.now();
			}
			const now = new Date();
			const utcDate = Date.UTC(
				now.getUTCFullYear(),
				now.getUTCMonth(),
				now.getUTCDate(),
				now.getUTCHours(),
				now.getUTCMinutes(),
				now.getUTCSeconds(),
				0
			);
			const startDate = new Date(startTime);

			const startTS = Date.UTC(
				startDate.getFullYear(),
				startDate.getMonth(),
				startDate.getDate(),
				startDate.getHours(),
				startDate.getMinutes(),
				startDate.getSeconds(),
				0
			);
			if (startTS <= utcDate) {
				return Date.now();
			} else {
				let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;

				const days = Math.floor(delta / 86400);
				delta -= days * 86400;
				const hours = Math.floor(delta / 3600);
				delta -= hours * 3600;
				const minutes = Math.floor(delta / 60) % 60;
				delta -= minutes * 60;
				const seconds = Math.floor(delta % 60); // in theory the modulus is not required

				// return `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${seconds}`
				if (days >= 1) {
					return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
				} else {
					return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
				}
			}
		}, []);

		return (
			<>
				<div className="colum w-4">
					<div className="content-round-one">
						<div
							className="box-img"
							onClick={(event) => (window.location.href = `ino-launchpad/ino-detail?${item.item.handle}`)}
						>
							<img src={item.item.logoLink} alt="" />
						</div>
						<div className="title" onClick={(event) => (window.location.href = `ino-launchpad/ino-detail?${item.item.handle}`)}>
							{item.item.title}{' '}
							<span className="icon-w">
								{' '}
								<img src={item.item.logoChain} alt="" />
							</span>
						</div>
						<p className="desc">{item.item.inoRule?.content}</p>
						<div className="s-social">
							<div className="icon-social">
								<a href={item.item.inoRule?.content?.twitter} target="_blank">
									<img src="../images/s-twi.png" alt="" />
								</a>
							</div>
							<div className="icon-social">
								<a href={item.item.inoRule?.content?.telegram} target="_blank">
									<img src="../images/s-tele.png" alt="" />
								</a>
							</div>
							<div className="icon-social">
								<a href={item.item.inoRule?.content?.website} target="_blank">
									<img src="../images/s-earth.png" alt="" />
								</a>
							</div>
						</div>
						<ul className="list-price-ino">
							<li>
								<span className="txt-top">Price</span>
								<span className="txt-bot main-orange">
									{item.item.inoBoxes[0].price} {item.item.inoBoxes[0].currency}
								</span>
							</li>
							<li>
								<span className="txt-top">Total Sale</span>
								<span className="txt-bot">{item.item.totalBoxSell} Boxes</span>
							</li>
						</ul>
						<div className="line-time-join">
							<div className="time-left">
								<div className="title-join">Start in</div>
								<div className="time-line">
									<div className="time-s">
										<div className="time-now">
											<Countdown date={getProgressTime(item.startTime)} renderer={renderer} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	const ItemComplete = (item) => {
		return (
			<>
				<div className="colum w-3">
					<div className="content-round-complete">
						<div
							className="box-img"
							onClick={(event) => (window.location.href = `ino-launchpad/ino-detail?${item.item.handle}`)}
						>
							<img src={item.item.logoLink} alt="" />

						</div>
						<div className="content-bot">
							<div className="title">
								{item.item.title} <span>Public</span>
							</div>
							<ul className="list-complete">
								<li>
									<span className="txt-l">Total Raise</span>
									<span className="txt-r">{item.item.inoBoxes[0].total}</span>
								</li>
								<li>
									<span className="txt-l">Network</span>
									<span className="txt-r">
										<span className="icon-w">
											{' '}
											<img src={item.item.logoChain} alt="" />
										</span>{' '}
										{item.item.networkChain}
									</span>
								</li>
								<li>
									<span className="txt-l">Price</span>
									<span className="txt-r main-orange">
										{item.item.inoBoxes[0].price} {item.item.inoBoxes[0].currency}/BOX
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</>
		);
	};

	const headerFaqs = (
		<>
			<div className="header-faqs">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
		</>
	);
	const contentFaqs = (
		<>
			<div className="content-faqs">You can withdraw gained tokens on Biswap Launchpad immediately after the IDO completion.</div>
		</>
	);

	return (
		<>
			<HeaderMobile />
			<Content className="page-container">
				{/* <div className="sider-bar">
					<div className="menu-sidebar">
						<MenuSidebar />
					</div>
				</div> */}
				<div className="page-main">
					<HeaderTop pageType={'color'} />
					<div className="page-content">
						<div className="page-banner page-banner-before p-lr-30">
							<div className="box-img-ino">
								<img src="../images/farm.png" alt="" />
							</div>
							<div className="container-fl">
								{roundInosOpening.length > 0 ? (
									<>{ItemBannerIno}</>
								) : (
									<>
										<div className="feature-ino-branch">
											<div className="content-ino-branch">
												<div className="main-title">
													<h3 className="title">Opening Projects</h3>
												</div>
												<div className="box-data-mar">
													<NoneData text={'No Data'} />
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="main-ino">
							<div className="project-block">
								<div className="container-fl">
									<div className="swap-project-ino">
										<div className="main-title">
											<h3 className="title">Upcoming Projects</h3>
										</div>
										<div className="swap-pr-ino">
											<div className="columns">
												{roundInosUp.length > 0 ? (
													<>
														{roundInosUp?.map((item: any, i: any) => (
															<ItemUpcoming item={item} />
														))}
													</>
												) : (
													<NoneData text={'No Data'} />
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="project-block bg-white">
								<div className="container-fl">
									<div className="swap-project-ino">
										<div className="main-title">
											<h3 className="title">Completed Sales</h3>
										</div>
										<div className="fillter-release">
											<div className="project-filter renew">
												<Form form={form} name="horizontal_login">
													<Form.Item
														name="token_type"
														rules={[{ required: true, message: 'Please input type!' }]}
														initialValue="ALL"
													>
														<Select className="select-chain" onChange={onSelectChain}>
															<Option value="ALL">ALL Chain</Option>
															<Option value="BSC">Binance Smart Chain</Option>
															<Option value="ETHEREUM">Ethereum</Option>
															<Option value="FANTOM">Fantom Opera</Option>
															<Option value="SOLANA">Solana</Option>
															<Option value="CELO">Celo</Option>
															<Option value="TRON">Tron</Option>
															<Option value="AVAX">Avax</Option>
															<Option value="POLYGON">Polygon</Option>
														</Select>
													</Form.Item>
													<Form.Item
														name="search"
														rules={[{ required: true, message: 'Please input account address!' }]}
													>
														<Input className="input-search" placeholder="Search by keyword" />
													</Form.Item>
												</Form>
											</div>
										</div>
										<div className="swap-pr-ino">
											<div className="columns">
												{roundInosAl.length > 0 ? (
													<>
														{roundInosAl?.map((item: any, i: any) => (
															<ItemComplete item={item} />
														))}
													</>
												) : (
													<NoneData text={'No Data'} />
												)}
											</div>
											{/* <div className="list-pani">
												<Pagination defaultCurrent={1} total={50} />
											</div> */}
										</div>
									</div>
								</div>
							</div>
							<div className="project-block">
								<div className="container-fl">
									<div className="wrap-faqs swap-project-ino">
										<div className="main-title">
											<h3 className="title">FAQs</h3>
										</div>
										<div className="columns">
											<div className="colum w-6">
												<div className="collapse-faq">
													<Collapse defaultActiveKey={['0']} onChange={onChange}>
														<Panel header="What is an INO?" key="1">
															<p>INO, or Initial NFT Offering, is a relatively new crowdfunding type in which tokenized assets in the form of NFTs get sold on INO platform such as WaveSignal. INOs help start-ups and artists promote their works to investors and communities in a much easier way.</p>
														</Panel>
														<Panel header="How to buy NFTs on INO Launchpad?" key="2">
															<p>Firstly, connect wallet to WaveSignal, don’t forget to choose the right chain. Secondly, click on INO Launchpad section, pick your favorite project and deposit fund within applicable period. Finally, NFTs will be airdrop-ed to your wallet or claimed directly on our platform. Make sure the NFTs sit in your wallet. Easy!</p>
														</Panel>
														<Panel header="Where can I store my NFTs?" key="3">
															<p>You can store your NFT in your non-custodial wallets such as Metamask, the process is the same as storing your token.</p>
														</Panel>
													</Collapse>
												</div>
											</div>
											<div className="colum w-6">
												<div className="collapse-faq">
													<Collapse defaultActiveKey={['0']} onChange={onChange}>
														<Panel header="Where can I sell my NFTs?" key="4">
															<p>You can list your NFTs for sale on any supported marketplace or on our integrated NFT marketplace (coming soon).</p>
														</Panel>
														<Panel header="Why does my NFT not show up in Metamask?" key="5">
															<p>NFTs are shown as a token in your wallet (ERC-721 token on ETH for example). Visit projects’ website for more information of your NFTs.</p>
														</Panel>
														<Panel header="How can I launch my NFTs project on WaveSignal?" key="6">
															<p>Please contact us via Telegram or Twitter for support.</p>
														</Panel>
													</Collapse>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Content>
			<div className="page-main">
				<div className="page-content">
					<Footer />
				</div>
			</div>
		</>
	);
};

export default INOLaunchpad;
