import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Alert, Button, Card, Col, Empty, Form, Input, Modal, Row, Select, Spin, Table } from 'antd';
import './style.css';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { useWrap } from 'context/WrapperContext';
import { useAntiBotContract } from 'hooks/useContract';
import {
    blacklistUsers,
    configAntiBot,
    enableAntiBot,
    getAntiBotInfo,
    getBlackList,
    getTokenStatus,
    getWhiteList,
    whitelistUsers
} from '../../../utils';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { dexExchanges } from 'config/network/dex';
import { currencies } from '../../../../config/network/config';



declare const window: Window & typeof globalThis & { ethereum: any };
const { Option } = Select;
const { TextArea } = Input;

const AntiBotContent = (props) => {
    const { showNoti } = useWrap();
    const { account, chainId } = useActiveWeb3React();


    const [form] = Form.useForm();

    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [blackLists, setBlackLists] = useState();
    const [whiteLists, setWhiteLists] = useState();
    const [isFormAddBL, setIsFormAddBL] = useState<boolean>(false);
    const [isFormRemoveBL, setIsFormRemoveBL] = useState<boolean>(false);
    const [isFormAddWL, setIsFormAddWL] = useState<boolean>(false);
    const [isFormRemoveWL, setIsFormRemoveWL] = useState<boolean>(false);
    const [addWLForm] = Form.useForm();
    const [rmWLForm] = Form.useForm();
    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(-1);
    const [loadingSC, setLoadingSC] = useState(false);
    const [protectStatus, setProtectStatus] = useState('Not activate')
    const [amountLimit, setAmountLimit] = useState('N/A')
    const [timeLimit, setTimeLimit] = useState('N/A')
    const [blockLeft, setBlockLeft] = useState('N/A')
    const [currentBlock, setCurrentBlock] = useState('N/A')
    const [enableBot, setEnableBot] = useState<boolean>(false)
    const [funded, setFunded] = useState<boolean>(false)
    const [buttonEnableStatus, setButtonEnableStatus] = useState<boolean>(false)
    const [buttonDisableStatus, setButtonDisableStatus] = useState<boolean>(false)
    const [balanceLP, setBalanceLP] = useState(0)
    const [owner, setOwner] = useState('')
    const [routerAddress, setRouterAddress] = useState<any>('')

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [cexList, setCexList] = useState<any>([]);
    const [currenciesLP, setCurrenciesLP] = useState<any>([]);
    const [routers, setRouters] = useState<any>({});
    const [factories, setFactories] = useState<any>({});

    const antiBotContract = useAntiBotContract(antiBotAddress)



    let antiTokenInfoStorage = window.localStorage.getItem('antiTokenInfo');
    let tokenInfo;

    if (antiTokenInfoStorage) {
        tokenInfo = JSON.parse(antiTokenInfoStorage);
    }

    const getInfo = async (token) => {
        if (loadingSC) {
            return;
        }
        setLoadingSC(true)
        const antiBotInfo = await getAntiBotInfo(antiBotContract, token);
        const tokenStatus = await getTokenStatus(antiBotContract, token);
        const whitelistUsers = await getWhiteList(antiBotContract, token);
        const blackListUsers = await getBlackList(antiBotContract, token);
        setRouterAddress(antiBotInfo.routerExchange.toLowerCase());
        form.setFieldsValue({
            routerAddress: antiBotInfo.routerExchange,
            factoryAddress: antiBotInfo.factoryExchange,
        });

        if (whitelistUsers && whitelistUsers.length > 0) {
            const wlUsers = whitelistUsers.map((e, i) => {
                return { id: i + 1, address: e }
            })
            setWhiteLists(wlUsers)
        }

        if (blackListUsers && blackListUsers.length > 0) {
            const blUsers = blackListUsers.map((e, i) => {
                return { id: i + 1, address: e };
            })
            setBlackLists(blUsers)
        }

        if (antiBotInfo.pair.toLowerCase() !== ethers.constants.AddressZero.toLocaleLowerCase() && tokenStatus && antiBotInfo) {
            setAmountLimit(`${parseInt(ethers.utils.formatUnits(tokenStatus.amountLimit, tokenInfo.decimals))}`)
            setTimeLimit(`${parseInt(tokenStatus.timeLimit)}`)
            setBlockLeft(`${parseInt(tokenStatus.leftBlocks)}`)
            setCurrentBlock(`${parseInt(tokenStatus.currentBlock)}`);
            setBalanceLP(parseInt(ethers.utils.formatUnits(tokenStatus.balance, 18)))
            setEnableBot(antiBotInfo.enabled);
            setFunded(tokenStatus.funded);
            setOwner(antiBotInfo.owner);
            if (parseInt(tokenStatus.balance) == 0 && !antiBotInfo.enabled) {
                setButtonEnableStatus(true)
            }
            if (antiBotInfo.enabled) {
                setButtonDisableStatus(true)
            }

            form.setFieldsValue({
                pair_token: antiBotInfo.pairToken.toLowerCase(),
                amount_limit_per_trade: parseInt(ethers.utils.formatUnits(antiBotInfo.amountLimitPerTrade, tokenInfo.decimals)) ?? 0,
                amount_to_be_added_per_block: parseInt(ethers.utils.formatUnits(antiBotInfo.amountAddedPerLock, tokenInfo.decimals)) ?? '',
                time_limit_per_trade: antiBotInfo.timeLimitPerTrade ?? 0,
                block_number_to_disable_anti_bot: antiBotInfo.totalLockBlocks ?? 0
            });

            if (antiBotInfo.enabled) {
                if (parseInt(tokenStatus.balance) === 0) {
                    setProtectStatus('Waiting liquidity')
                } else {
                    if (tokenStatus.leftBlocks > 0) {
                        setProtectStatus('Protecting')
                    } else {
                        setProtectStatus('Anti Bot End')
                    }
                }
            }
        }

        setLoadingSC(false);
    }

    useEffect(() => {
        if (tokenInfo.token_address && account) {
            getInfo(tokenInfo.token_address)
        }
    }, [tokenInfo.token_address, account]);



    useEffect(() => {
        if (chainId) {
            const dexList = dexExchanges[chainId];
            setCexList(dexList)
            setCurrenciesLP(Object.entries(currencies[chainId]))
            const rt = dexList.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.routerAddress }), {});
            setRouters(rt)

            const ft = dexList.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.factoryAddress }), {});
            setFactories(ft)
        } else {
            setCexList([]);
            setCurrenciesLP([]);
        }
    }, [chainId])


    useEffect(() => {
        if (cexList && routerAddress) {
            for (let i = 0; i < cexList.length; i++) {
                if (cexList[i].routerAddress.toLowerCase() === routerAddress.toLowerCase()) {
                    form.setFieldsValue({ router_exchange: cexList[i].id })
                }
            }
        }
    }, [routerAddress, cexList.length]);


    const onConfirmConfigAnti = (values: any) => {
        if (loading) {
            return;
        }
        setLoading(true);
        setKey(3)
        try {
            configAntiBot(antiBotContract,
                tokenInfo.token_address,
                values.pair_token,
                values.routerAddress,
                values.factoryAddress,
                ethers.utils.parseUnits(`${values.amount_limit_per_trade}`, tokenInfo.decimals),
                ethers.utils.parseUnits(`${values.amount_to_be_added_per_block}`, tokenInfo.decimals),
                parseInt(values.time_limit_per_trade),
                parseInt(values.block_number_to_disable_anti_bot)
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Config Anti Bot Successfully!');
                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const onEnableAntiBot = (enabled) => {
        if (loading) {
            return;
        }
        setLoading(true);
        if (!enabled) {
            setKey(1)
        } else {
            setKey(2)
        }
        try {
            enableAntiBot(antiBotContract,
                tokenInfo.token_address,
                enabled,
                !funded ? ethers.utils.parseUnits(`0.01`, 18) : 0
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            if (enabled) {
                                                showNoti('success', 'Enable Anti Bot Successfully!');
                                                setButtonEnableStatus(false);
                                            } else {
                                                showNoti('success', 'Disable Anti Bot Successfully!');
                                                setButtonDisableStatus(false);
                                            }

                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const onSubmitAddBL = async (values: any) => {
        try {
            if (loading) {
                return;
            }
            const users = values.account_address.split(',').map(element => element.trim())
            setLoading(true);
            blacklistUsers(antiBotContract,
                tokenInfo.token_address,
                true,
                users
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Add Blacklist Successfully!');
                                            closeBox()
                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }

    };
    const onSubmitRemoveBL = async (values: any) => {
        try {
            if (loading) {
                return;
            }
            const users = values.addresses.split(',').map(element => element.trim())
            setLoading(true);
            blacklistUsers(antiBotContract,
                tokenInfo.token_address,
                false,
                users
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Remove Blacklist Successfully!');
                                            closeBox()
                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };
    const onSubmitAddWL = async (values: any) => {
        try {
            if (loading) {
                return;
            }
            const users = values.account_address.split(',').map(element => element.trim())
            setLoading(true);
            whitelistUsers(antiBotContract,
                tokenInfo.token_address,
                true,
                users
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Add Whitelist Successfully!');
                                            closeBox()
                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };
    const onSubmitRemoveWL = async (values: any) => {
        try {
            if (loading) {
                return;
            }
            const users = values.addresses.split(',').map(element => element.trim())
            setLoading(true);
            whitelistUsers(antiBotContract,
                tokenInfo.token_address,
                false,
                users
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Remove Whitelist Successfully!');
                                            closeBox()
                                            getInfo(tokenInfo.token_address)
                                        }
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });
        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const onShowAddBlackList = () => {
        setIsFormAddBL(true);
    };
    const onShowRmBlackList = () => {
        setIsFormRemoveBL(true);
    };
    const onShowAddWhiteList = () => {
        setIsFormAddWL(true);
    };
    const onShowRmWhiteList = () => {
        setIsFormRemoveWL(true);
    };
    const closeBox = () => {
        setIsFormAddBL(false);
        setIsFormRemoveBL(false);
        setIsFormAddWL(false);
        setIsFormRemoveWL(false);
        addWLForm.resetFields();
        rmWLForm.resetFields();
    };
    const onChangeDex = (e: any) => {
        const router = routers[e]
        const factory = factories[e]
        form.setFieldsValue({
            routerAddress: router,
            factoryAddress: factory,
        });
    };

    const columnsBlacklist = [
        {
            title: 'No.',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        }
    ];
    const columnsWhitelist = [
        {
            title: 'No.',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        }
    ];
    return (
        <>
            <Alert className="mb-15" message="You have not activated Wave Anti-Bot." showIcon type="warning" />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                    <Card title="Enable Wave Anti-bot" style={{ width: '100%' }} className="card-title">
                        <div className="grp-btn-anti">
                            <button type="button" className="btn-all" disabled={!buttonDisableStatus || loading} onClick={() => { onEnableAntiBot(false) }}>Disable WaveSignal AntiBot{(loading && key === 1) && <Spin size="small" />}</button>
                            <button type="button" className="btn-all" disabled={!buttonEnableStatus || loading} onClick={() => { onEnableAntiBot(true) }}>Enable WaveSignal AntiBot{(loading && key === 2) && <Spin size="small" />}</button>
                        </div>
                        <p className="anti-warning-fee">You need to pay 0.01 {getInformationByChain(chainId, 'REACT_APP_SYMBOL')} at first time enable</p>
                    </Card>
                    <Card title="Status" style={{ width: '100%' }} className="card-title">
                        <div className="token-info-anti">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-name">Protect Status</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-value">{protectStatus}</div>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-name">Amount Limit</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-value">{amountLimit}</div>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-name">Time Limit</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-value">{timeLimit}</div>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-name">Blocks left to disable</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-value">{blockLeft}</div>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-name">Current Block</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="token-value">{currentBlock}</div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                    <Card title="WaveSignal Anti-Bot config" style={{ width: '100%' }} className="card-title">
                        <Form form={form} layout="vertical" autoComplete="off"
                            onFinish={onConfirmConfigAnti}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <Form.Item name="router_exchange" label="Select Router Exchange"
                                        rules={[{ required: true, message: 'Please Select Router Exchange!' }]}
                                    >
                                        <Select className="select-create-ido" onChange={onChangeDex} >
                                            <Option value="">Select Router Exchange</Option>
                                            {cexList.map((item, index) => (
                                                <Option value={item.id} key={'cex-' + index}>{item.name}</Option>
                                            ))}
                                            {/*<Option value="pancakeswap">Pancakeswap</Option>*/}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="routerAddress" label="Router Address" hidden={true}>
                                        <Input className="amount-buy" type="text" />
                                    </Form.Item>
                                    <Form.Item name="factoryAddress" label="Router Address" hidden={true}>
                                        <Input className="amount-buy" type="text" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <Form.Item name="pair_token" label="Select Pair Token" initialValue={""}
                                        rules={[{ required: true, message: 'Please Select Pair Token!' }]}
                                    >
                                        <Select className="select-create-ido">
                                            <Option value="">Select Pair Token</Option>
                                            {currenciesLP.map((item, index) => (
                                                <Option value={item[1].toLowerCase()} key={'cex-' + index}>{item[0]}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <Form.Item name="amount_limit_per_trade" label={`Amount Limit Per Trade `}
                                        rules={[
                                            { required: true, message: 'Please input Amount Limit Per Trade!' },
                                            {
                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                    !value || parseFloat(value) <= 0 ? cb("Amount Limit Per Trade must be > 0") : cb();
                                                }
                                            }
                                        ]}>
                                        <Input className="amount-buy" type="number" placeholder="0" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <Form.Item name="amount_to_be_added_per_block" label={`Amount to Be Added Per Block`}
                                        rules={[
                                            { required: true, message: 'Please input Amount to Be Added Per Block!' },
                                            {
                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                    !value || parseFloat(value) <= 0 ? cb("Amount to Be Added Per Block must be > 0") : cb();
                                                }
                                            }
                                        ]}>
                                        <Input className="amount-buy" type="number" placeholder="0" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24}>
                                    <Form.Item name="time_limit_per_trade" label="Time Limit Per Trade (seconds)"
                                        rules={[
                                            { required: true, message: 'Please input Time Limit Per Trade!' },
                                            {
                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                    !value || parseFloat(value) <= 0 ? cb("Time Limit Per Trade must be > 0") : cb();
                                                }
                                            }
                                        ]}>
                                        <Input className="amount-buy" type="number" placeholder="0" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24}>
                                    <Form.Item name="block_number_to_disable_anti_bot" label="Block Number to Disable Anti-Bot (Listing is Block #1)"
                                        rules={[
                                            { required: true, message: 'Please input Block Number to Disable Anti-Bot!' },
                                            {
                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                    !value || parseFloat(value) <= 0 ? cb("Block Number to Disable Anti-Bot must be > 20") : cb();
                                                }
                                            }
                                        ]}>
                                        <Input className="amount-buy" type="number" placeholder="0" />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24}>
                                    <Form.Item className="btn-content btn-content-config">
                                        <button className="btn-all" disabled={loadingSC || loading || balanceLP > 0} type="submit">Save config {(loading && key === 3) && <Spin size="small" />}</button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col className="gutter-row" xs={24}>
                    <Card title="Blacklist" style={{ width: '100%' }}
                        extra={
                            <div className="card-btn">
                                <Button htmlType="button" onClick={onShowAddBlackList} disabled={loading || owner.toLowerCase() !== account?.toLowerCase()}>Add user to blacklist</Button>
                                <Button htmlType="button" onClick={onShowRmBlackList} disabled={loading || owner.toLowerCase() !== account?.toLowerCase()}>Remove user from blacklist</Button>
                            </div>
                        }
                        className="card-title"
                    >
                        {blackLists ? (
                            <>
                                <div className="whitelist-list">
                                    <Table pagination={false} dataSource={blackLists} columns={columnsBlacklist} />
                                </div>
                            </>
                        ) : (
                            <Empty />
                        )}
                    </Card>

                    <Card title="Whitelist" style={{ width: '100%' }}
                        extra={
                            <div className="card-btn">
                                <Button htmlType="button" onClick={onShowAddWhiteList} disabled={loading || owner.toLowerCase() !== account?.toLowerCase()}>Add user to Whitelist</Button>
                                <Button htmlType="button" onClick={onShowRmWhiteList} disabled={loading || owner.toLowerCase() !== account?.toLowerCase()}>Remove user from Whitelist</Button>
                            </div>
                        }
                        className="card-title"
                    >
                        {whiteLists ? (
                            <>
                                <div className="whitelist-list">
                                    <Table pagination={false} dataSource={whiteLists} columns={columnsWhitelist} />
                                </div>
                            </>
                        ) : (
                            <Empty />
                        )}
                    </Card>
                </Col>
            </Row>
            <Modal
                title={<div className="text-md custom-modal-title">Add Users Blacklist</div>}
                visible={isFormAddBL}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={addWLForm}
                        name="basic"
                        autoComplete="off"
                        onFinish={onSubmitAddBL}
                        layout="vertical"
                    >
                        <Form.Item name="account_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                            <TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Add User {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            <Modal
                title={<div className="text-md custom-modal-title">Remove Users Blacklist</div>}
                visible={isFormRemoveBL}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={rmWLForm}
                        name="rmWLForm"
                        autoComplete="off"
                        onFinish={onSubmitRemoveBL}
                        layout="vertical"
                    >
                        <Form.Item name="addresses" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                            <TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Remove User {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            <Modal
                title={<div className="text-md custom-modal-title">Add Users Whitelist</div>}
                visible={isFormAddWL}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={addWLForm}
                        name="basic"
                        autoComplete="off"
                        onFinish={onSubmitAddWL}
                        layout="vertical"
                    >
                        <Form.Item name="account_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                            <TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Add User {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            <Modal
                title={<div className="text-md custom-modal-title">Remove Users Whitelist</div>}
                visible={isFormRemoveWL}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={rmWLForm}
                        name="rmWLForm"
                        autoComplete="off"
                        onFinish={onSubmitRemoveWL}
                        layout="vertical"
                    >
                        <Form.Item name="addresses" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                            <TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Remove User {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            {loadingFull && (
                <LoadingFull />
            )}
        </>
    )
}

export default AntiBotContent
