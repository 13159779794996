export const chainList = {
	97: {
		'SYMBOL': 'BSC',
		'CHAIN_ID': 97
	},
	56: {
		'SYMBOL': 'BSC',
		'CHAIN_ID': 56
	},
	137: {
		'SYMBOL': 'POLYGON',
		'CHAIN_ID': 137
	},
	43114: {
		'SYMBOL': 'AVAX',
		'CHAIN_ID': 43114
	},
	66: {
		'SYMBOL': 'OKT',
		'CHAIN_ID': 66
	},
	5: {
		'SYMBOL': 'ETH',
		'CHAIN_ID': 5
	},
	1: {
		'SYMBOL': 'ETH',
		'CHAIN_ID': 1
	}
};

export const getChainListInfo = (chainId, key) => {
	const chainInfo = chainList[chainId];
	if (chainInfo) {
		return chainInfo[key];
	} else {
		return null;
	}
};
