import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useConnectWallet, useActiveWeb3React } from 'hooks';
import { Menu } from 'antd';
import { useWeb3React } from '@web3-react/core';

declare const window: Window & typeof globalThis & { ethereum: any };

const ChainPopup = (props: any) => {
    const { chain, chainNetworkListMain, chainNetworkListTest } = props;

    const { account } = useActiveWeb3React();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [activeChain, setActiveChain] = useState<boolean>(true);
    const [network, setNetwork] = useState<any>();

    let logoNetwork: any = '';
    let title: any = '';
    if (chain && chain[0]) {
        logoNetwork = chain[0].logoLink;
        title = chain[0].name;
    }
    const showPopupChain = () => {
        setIsModalVisible(true);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const context = useWeb3React();
    const { activate, deactivate, active, error, chainId } = context;

    const onClickNetwork = async (item) => {
        setActiveChain(false);
        setNetwork(item);

        changeNetwork(item.chainId);
    }

    const changeNetwork = async (chainIdDetail) => {
        let chain: any;
        chain = chainNetworkListMain.find((itemChain) => parseInt(chainIdDetail) === parseInt(itemChain.chainId));

        if (chain == undefined || chain.length == 0) {
            chain = chainNetworkListTest.find((itemChain) => parseInt(chainIdDetail) === parseInt(itemChain.chainId));
        }

        try {
            if (chain) {
                await window.ethereum.enable();
                const params = {
                    chainId: `0x${parseInt(chain.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
                    chainName: chain.name,
                    nativeCurrency: {
                        name: chain.symbol,
                        symbol: chain.symbol, // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: [chain.rpcUrl,],
                    blockExplorerUrls: [
                        chain.blockExplorer,
                    ],
                };
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params, account]
                    })
                    .then((result) => {
                        console.log(result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${parseInt(chain.chainId).toString(16)}` }], // chainId must be in hexadecimal numbers
                });
            }
        } catch (error: any) {
            if (error.code === 4902) {
                try {
                    if (chain) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: chainIdDetail,
                                    rpcUrl: chain.rpcUrl,
                                },
                            ],
                        });
                    }

                } catch (addError) {
                    // handle "add" error
                }
            }
            console.error(error);
        }
    };

    return (
        <>
            {title && logoNetwork && (
                <Menu.Item key="marketplace" className='menu-item-right chain-info'>
                    <a onClick={showPopupChain}>
                        <img src={logoNetwork} /> {title}
                    </a>
                </Menu.Item>
            )}

            <Modal
                title={<div className="custom-modal-title">Your Account</div>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="custom-modal wallet-modal"
                footer={false}
            >
                <div className="wallet-sub-title">Switch Network</div>
                <div className="choose-network">
                    {chainNetworkListMain && chainNetworkListMain?.length > 0 && (
                        <>
                            {chainNetworkListMain?.map((item, index) => (
                                item.chainId && (
                                    <button type="button"
                                        title={item.name} className={network?.chainId == item.chainId ? 'active' : (!network?.chainId && chainId == item.chainId) ? 'active' : ''}
                                        onClick={() => onClickNetwork(item)} key={'chain-main' + index}
                                    >
                                        <img src={item.logoLink} />
                                        {item.symbol}
                                    </button>
                                )
                            ))}
                        </>
                    )}
                </div>

                {chainNetworkListTest && chainNetworkListTest?.length > 0 && (
                    <>
                        <div className="wallet-sub-title">Switch Network (Testnet)</div>
                        <div className="choose-network">
                            {chainNetworkListTest.map((item, index) => (
                                item.chainId && (
                                    <button type="button" title={item.name}
                                        className={network?.chainId == item.chainId ? 'active' : (!network?.chainId && chainId == item.chainId) ? 'active' : ''}
                                        onClick={() => onClickNetwork(item)} key={'chain-test' + index}
                                    >
                                        <img src={item.logoLink} />
                                        {item.symbol}
                                    </button>
                                )
                            ))}
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

export default ChainPopup;
