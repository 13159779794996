
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
import TokenBalance from 'components/balance';
const { Content } = Layout;

const PrivateBanner = () => {
    return (
        <>
            <div className="private-banner-apply-launch bg-private-sale">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <div className="private-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="private-banner-apply-content text-center">
                                        <div className="private-banner-investor-name">
                                            PRIVATE SALE
                                        </div>
                                        <div className="private-banner-investor-des">
                                            Gain exponential profits by supporting quality projects at early stage.
                                        </div>
                                        <div className="mt-20 banner-button">
                                            <Link className="link-button link-hover" to="/private-sale/create">Create Private Sale</Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <div className="private-banner-faq-content">
                                        <div className="private-banner-apply-img mt-30">
                                            {/* <img src="../../images/earn/private-sale.gif" /> */}
                                            <video src="../../images/earn/private-sale.mp4" loop autoPlay={true} muted />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default PrivateBanner
