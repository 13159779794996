import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Tag, Progress, Pagination, Button, Modal, Table } from 'antd';
import { SearchOutlined, TwitterOutlined, CodeSandboxOutlined, GlobalOutlined, IssuesCloseOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import Countdown from 'react-countdown';
import NoneData from 'components/element/NoneData';
import { privatesSaleApi } from 'config/api/privatesSaleApi';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import './style.css';
import ReferralBanner from './referral-banner';

const { Option } = Select;
const { Content } = Layout;

const ReferralList = () => {
    let tokenID = window.localStorage.getItem('tokenId');
    const [classTab, setClassTabs] = useState('');
    const history = useHistory();
    const [showWithdrawalHistory, setShowWithdrawalHistory] = useState<any>(false);
    const [referralList, setReferralList] = useState<any>([]);
    
    const [loadingData, setLoadingData] = useState(false);
    const typeProject = history.location.search.replace('?', '');
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(0);
    const displayTabs = (action: string) => {
        history.push({
            pathname: '',
            search: action
        });
    };
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            tokenSaleFilter: 'ALL',
            networkChainEnum: 'BSC'
        }
    );

    const getReferralList = async (type, status) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            tokenSaleFilter: type
        };
        try {
            // api get ref list

            // let res = await privatesSaleApi.getPrivates(dataFilter);
            // setTotalRow(parseInt(res.headers['x-total-count']));
            // if (res.status === 200) {
            //     if (res.data.length > 0) {

            //         setLoadingData(false);
            //         setReferralList(res.data);

            //     } else {
            //         setLoadingData(false);
            //         setReferralList([]);
            //     }


            // }
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
        }
        finally {
            setLoadingData(false);
        }
    };


    useEffect(() => {
        if (classTab) {
            switch (classTab) {
                case 'all':
                    getReferralList('ALL1', 'all');
                    break;
                case 'farms':
                    getReferralList('Farms', 'farms');
                    break;
                case 'launchpools':
                    getReferralList('Launchpools', 'launchpools');
                    break;
                default:
                    break;
            }
        }
    }, [classTab, param]);


    useEffect(() => {
        if (typeProject) {
            setClassTabs(typeProject);
        } else {
            setClassTabs('all');
        }
    }, [typeProject]);

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const columnsList = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Hash',
            dataIndex: 'hash',
            key: 'hash',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];


    const ReferralContent = (props) => {
        const { type } = props;

        return (
            <>
                {type === "all" && (
                    <div className='referral-dashboard'>
                        <div className='dashboard-title'>Dashboard</div>
                        <div className='dashboard-content'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                    <div className='ref-dashboard-friend'>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" xs={6} xl={4} md={4} sm={4}>
                                                <div className='ref-friend-icon'>
                                                    <UsergroupAddOutlined className='icon-friend' />
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={18} xl={20} md={20} sm={20}>
                                                <div className='ref-friend-total'>
                                                    Active Friends / Total Friends
                                                </div>
                                                <div className='ref-friend-total'>
                                                    <span>5 / 15</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-15">
                                            <Col className="gutter-row" xs={6} xl={4} md={4} sm={4}>
                                                <div className='ref-friend-icon'>
                                                    <img src="../../../../images/logo-white.png" width={55} alt="logo" className='logo-header' />
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={18} xl={20} md={20} sm={20}>
                                                <div className='ref-friend-total'>
                                                    Total Earned
                                                </div>
                                                <div className='ref-friend-total'>
                                                    <span>0.000 W86</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={8} md={8} sm={8}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row mt-15" xs={24}>
                                            <div className='ref-friend-total'>
                                                Total Farms friends
                                            </div>
                                            <div className='ref-friend-total'>
                                                <span>0</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row mt-15" xs={24}>
                                            <div className='ref-friend-total'>
                                                Total Farms earned
                                            </div>
                                            <div className='ref-friend-total'>
                                                <span>0.00 W86</span>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className="gutter-row" xs={12} xl={8} md={8} sm={8}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row mt-15" xs={24}>
                                            <div className='ref-friend-total'>
                                                Total Launchpool friends
                                            </div>
                                            <div className='ref-friend-total'>
                                                <span>0</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row mt-15" xs={24}>
                                            <div className='ref-friend-total'>
                                                Total Launchpool earned
                                            </div>
                                            <div className='ref-friend-total'>
                                                <span>0.00 W86</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </div>
                    </div>
                )}
                <div className='ref-list-data'>
                    {!loadingData ? (
                        <>
                            {referralList?.length > 0 ? (
                                <>
                                    <Table pagination={false} dataSource={referralList} columns={columnsList} className="table-ref" />
                                </>
                            ) : (
                                <>
                                    <NoneData text={'No Data'} />
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </div>
            </>
        );
    };

    const returnContentProjects = () => {
        switch (classTab) {
            case 'all':
                return <ReferralContent type="all" />;
                break;
            case 'farms':
                return <ReferralContent type="farms" />;
                break;
            case 'launchpools':
                return <ReferralContent type="launchpools" />;
                break;
            default:
                break;
        }
    };
    

    

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content mt-100">
                        <div className="project-block container pt-0">
                            <div className="project-content container">
                                <ReferralBanner />

                                <div className="projects-top">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24}>
                                            <div className="projects-btn-status">
                                                <button type="button" className={classTab == 'all' ? 'active' : ''} onClick={() => displayTabs('all')}>
                                                    Referral list
                                                </button>
                                                <button type="button" className={classTab == 'farms' ? 'active' : ''} onClick={() => displayTabs('farms')}>
                                                    Farms
                                                </button>
                                                <button type="button" className={classTab == 'launchpools' ? 'active' : ''} onClick={() => displayTabs('launchpools')}>
                                                    Launchpools
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="projects-grid">
                                    {returnContentProjects()}
                                    {totalRow > 9 && (
                                        <Pagination
                                            current={param.page + 1}
                                            defaultCurrent={1}
                                            total={totalRow}
                                            pageSize={param.size}
                                            onChange={onChange_Pagi}
                                            showSizeChanger={false}
                                            className="pagiation-custom"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Content>
           
        </>

    )
}

export default ReferralList
