import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select } from 'antd';
import { chainApi } from 'config/api/chainApi';

const { Option, OptGroup } = Select;

const FilterChain = (props: any) => {
    const { handleChangeChain, active } = props;
    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);
    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 10, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                    if (isTestnet === true) {
                        setChainNetworkListTest(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList(false);
        getChainNetworkList(true);
    }, []);

    const onSelectChain = (value: any) => {
        handleChangeChain && handleChangeChain(value);
    };
    return (
        <>
            <Form.Item
                name="token_type"
                initialValue={`${active ? active : '0'}`}
                className='w-50-mobile mr-0-mobile'
            >
                <Select className="select-chain w-full-100" onChange={onSelectChain}>
                    <Option value="0">Select network</Option>
                    <OptGroup label="Mainnet">
                        {chainNetworkListMain && chainNetworkListMain?.length > 0 && (
                            <>
                                {chainNetworkListMain?.map((item, index) => {
                                    return (
                                        <Option key={'filter-main-' + index} value={item.chainId}>{item.name}</Option>
                                    )
                                })}
                            </>
                        )}
                    </OptGroup>
                    <OptGroup label="Testnet">
                        {chainNetworkListTest && chainNetworkListTest?.length > 0 && (
                            <>
                                {chainNetworkListTest?.map((item, index) => {
                                    return (
                                        <Option key={'filter-test-' + index} value={item.chainId}>{item.name}</Option>
                                    )
                                })}
                            </>
                        )}
                    </OptGroup>
                </Select>
            </Form.Item>
        </>
    )
}

export default FilterChain
