import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation } from 'react-router-dom';

import Home from '../../home';
import Marketplace from 'components/market';
import Launchpad from 'components/launchpad';
import CreateToken from 'components/launchpad/create-token';
import Services from 'components/services';
import INOLaunchpad from 'components/ino-launchpad';
import IcoDetail from 'components/launchpad/ico-detail';
import IdoUpdate from 'components/launchpad/ido-update';
import IcoCreate from 'components/launchpad/launchpad-create/ico-create';
import AntiBot from 'components/launchpad/anti-bot';
import LaunchpadCreate from 'components/launchpad/launchpad-create';
import CreateLock from 'components/wave-lock/create-lock';
import TokenLock from 'components/wave-lock/token';
import LiquidityLock from 'components/wave-lock/liquidity';
import LockRecord from 'components/wave-lock/lock-record';
import LockDetail from 'components/wave-lock/lock-detail';
import UpdateLock from 'components/wave-lock/update-lock';

import AirdropCreate from 'components/airdrop/create-airdrop';
import Airdrop from 'components/airdrop/airdrop';
import MultiSender from 'components/multi-sender';
import AirdropDetail from 'components/airdrop/airdrop-detail';
import InoDetails from "components/ino-launchpad/ino-detail"
import PrivateCreate from 'components/private-sale/private-create';
import PrivateList from 'components/private-sale/private-list';
import PrivateDetail from 'components/private-sale/private-detail';
import IdoDetail from 'components/ido-launchpad/ido-detail';
import IdoLaunchpad from 'components/ido-launchpad';
import Staking from 'components/staking/flexible-apr';
import Farming from 'components/farming';
import StakingFixed from 'components/staking/fixed-apr';
import ReferralList from 'components/referral/referral-list';
import UploadImage from 'components/upload-image';

const Header = () => {
	const location = useLocation();
	if (location.pathname === "/ido/create") {
		window.localStorage.removeItem('tokenApproval');
		window.localStorage.removeItem('tokenInfo');
		window.localStorage.removeItem('launchpadInfo');
		window.localStorage.removeItem('additionalInfo');
	}

	if (location.pathname === "/pre-sale/create" || location.pathname === "/ico/create") {
		window.localStorage.removeItem('ido_tokenApproval');
		window.localStorage.removeItem('ido_tokenInfo');
		window.localStorage.removeItem('ido_launchpadInfo');
		window.localStorage.removeItem('ido_additionalInfo');
	}

	if (location.pathname === "/" || location.pathname === "") {

		window.localStorage.removeItem('tokenApproval');
		window.localStorage.removeItem('tokenInfo');
		window.localStorage.removeItem('launchpadInfo');
		window.localStorage.removeItem('additionalInfo');

	}

	function NavMenu() {
		return (
			<Router>
				<Switch>
					<Route path="/marketplace" component={Marketplace} />
					<Route path="/ido-launchpad/:handle" component={IdoDetail} />
					<Route path="/ico/detail/:id" component={IcoDetail} />
					<Route path="/ido/update/:id" component={IdoUpdate} />
					<Route path="/ico/create" component={IcoCreate} />
					<Route path="/pre-sale/create" component={IcoCreate} />
					<Route path="/launchpad/create" component={LaunchpadCreate} />
					<Route path="/launchpad/anti-bot" component={AntiBot} />
					<Route path="/ico-launchpad" component={Launchpad} />
					<Route path="/ido-launchpad" component={IdoLaunchpad} />
					<Route path="/launchpad/my-project" component={Launchpad} />
					<Route path="/ino" component={INOLaunchpad} />
					<Route path="/ino-launchpad/ino-detail" component={InoDetails} />
					<Route path="/token/create" component={CreateToken} />
					<Route path="/services" component={Services} />
					<Route path="/wave-lock/record/:id" component={LockRecord} />
					<Route path="/wave-lock/detail/:address" component={LockDetail} />
					<Route path="/wave-lock/update/:id/:type" component={UpdateLock} />
					<Route path="/wave-lock/create" component={CreateLock} />
					<Route path="/wave-lock/token" component={TokenLock} />
					<Route path="/wave-lock/liquidity" component={LiquidityLock} />
					<Route path="/airdrop/detail/:airdropAddress/:handle" component={AirdropDetail} />
					<Route path="/airdrop/create" component={AirdropCreate} />
					<Route path="/airdrop" component={Airdrop} />
					<Route path="/staking" component={Staking} />
					<Route path="/fixed-staking" component={StakingFixed} />
					<Route path="/farm" component={Farming} />
					<Route path="/multi-sender" component={MultiSender} />
					<Route path="/private-sale/detail/:privateSaleId/:handle/:privateSaleAddress" component={PrivateDetail} />
					<Route path="/private-sale/create" component={PrivateCreate} />
					<Route path="/private-sale" component={PrivateList} />
					<Route path="/referral" component={ReferralList} />
					<Route path="/upload-image" component={UploadImage} />
					<Route path="/" component={Home} />
					<Route path="/home" component={Home} />

				</Switch>
			</Router>
		);
	}
	return (
		<>
			<Router>
				<NavMenu />
			</Router>
		</>

	);
};

export default Header;
