import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
import 'antd/dist/antd.css';
import {
	Layout, Row, Col, Form, Select, Button, Input, Spin, Alert, Table, Progress, Card, Modal, Radio, Space
} from 'antd';
import { CopyOutlined, DoubleRightOutlined, FacebookFilled, GithubOutlined, GlobalOutlined, InstagramOutlined, LeftCircleOutlined, RedditCircleFilled, TwitterCircleFilled } from '@ant-design/icons';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import LoadingFull from 'components/loading-full';

import {
	cancelAirdrop,
	getEnsureExactAmount,
	getAirdropIsWLAdmin,
	removeAllAirdropAllocations,
	setAirdropAllocations,
	setAirdropEnableExactAmount,
	setAirdropVesting,
	startAirdrop,
	getAirdropTGEDate,
	getAirdropTGEBps,
	getAirdropCycle,
	getAirdropCycleBps,
	getAirdropState,
	getAirdropFundPercent,
	getAirdropTotalAllocationTokens,
	getAirdropUserClaimedTokens,
	getAirdropUserAllocationInfos,
	getAllAirdropAllocationCount,
	getAirdropAllocations,
	getTokenInformation,
	getAirdropClaimable, claimAirdrop, getAirdropOwner, getTokenAllowance, approveToken
} from '../../utils';
import airdropAbi from '../../../config/abi/airdropAbi.json';
import web3 from 'web3';
import { useWrap } from '../../../context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { ethers } from 'ethers';
import { AirdropApi } from '../../../config/api/airdropApi';
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { convertDateTime } from 'utils/formatDatetime';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Content } = Layout;
const { TextArea } = Input;
declare const window: Window & typeof globalThis & { ethereum: any };

const AirdropDetail = (props) => {
	const { chainId, account, library } = useActiveWeb3React();
	const { showNoti } = useWrap();

	let { handle, airdropAddress }: { handle: string, airdropAddress: string } = useParams();
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [allocations, setAllocations] = useState<any>([]);
	const [isFormAllocation, setIsFormAllocation] = useState<boolean>(false);
	const [isFormVesting, setIsFormVesting] = useState<boolean>(false);
	const [isStartForm, setIsStartForm] = useState<boolean>(false);
	const [keyLoading, setKeyLoading] = useState(0);
	const [ensureExactAmount, setEnsureExactAmount] = useState<any>(false);
	const [tgeDate, setTgeDate] = useState<any>('');
	const [tgeBps, setTgeBps] = useState<any>(0);
	const [cycle, setCycle] = useState<any>(0);
	const [cycleBps, setCycleBps] = useState<any>(0);
	const [state, setState] = useState<any>(0);
	const [fundPercent, setFundPercent] = useState<any>(0);
	const [totalTokens, setTotalTokens] = useState<any>(0);
	const [claimedTokens, setClaimedTokens] = useState<any>(0);
	const [userAllocations, setUserAllocations] = useState<any>();
	const [isAdmin, setIsAdmin] = useState<any>(false);
	const [specificTime, setSpecificTime] = useState<any>(false);
	const [decimals, setDecimals] = useState<any>(18);
	const [dataAirdrop, setDataAirdrop] = useState<any>();
	const [totalAllocations, setTotalAllocations] = useState<any>(0);
	const [tokenAddress, setTokenAddress] = useState<any>();
	const [claimable, setClaimable] = useState<any>();
	const [isOwner, setIsOwner] = useState<any>(false);
	const [allowance, setAllowance] = useState<any>(0);
	const [progressPercent, setProgressPercent] = useState<any>(0);
	const [totalToStartTokens, setTotalToStartTokens] = useState<any>(0);


	const [addWLForm] = Form.useForm();
	const [vestingForm] = Form.useForm();
	const [startForm] = Form.useForm();
	const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

	const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

	let timeClaimReward = new Date(dataAirdrop?.timeClaimReward);

	const getAirdropInfos = async () => {

		try {
			setLoadingData(true);
			const tokenInfoSC = await getTokenInformation(tokenInfoAbi, dataAirdrop.tokenAddress);
			setDecimals(tokenInfoSC.decimals);
			const claimable = await getAirdropClaimable(airdropAbi, airdropAddress, library, account);
			setClaimable(parseFloat(ethers.utils.formatUnits(claimable, tokenInfoSC.decimals)))

			// console.log(parseInt(claimable));
			let allowance = await getTokenAllowance(tokenInfoAbi, dataAirdrop.tokenAddress, account, airdropAddress)
			setAllowance(parseFloat(ethers.utils.formatUnits(allowance, decimals)))


			const exactAmountSC = await getEnsureExactAmount(airdropAbi, airdropAddress, library, account);
			const wlAdminSC = await getAirdropIsWLAdmin(airdropAbi, airdropAddress, library, account);
			const tgeDateSC = await getAirdropTGEDate(airdropAbi, airdropAddress, library, account);
			const tgeBpsSC = await getAirdropTGEBps(airdropAbi, airdropAddress, library, account);
			const cycleSC = await getAirdropCycle(airdropAbi, airdropAddress, library, account);
			const cycleBpsSC = await getAirdropCycleBps(airdropAbi, airdropAddress, library, account);
			const stateSC = await getAirdropState(airdropAbi, airdropAddress, library, account);
			const fundPercentSc = await getAirdropFundPercent(airdropAbi, airdropAddress, library, account);

			// console.log(parseInt(fundPercentSc));

			const totalTokensSC = await getAirdropTotalAllocationTokens(airdropAbi, airdropAddress, library, account);
			const claimedTokensSC = await getAirdropUserClaimedTokens(airdropAbi, airdropAddress, library, account);
			const userAllocationsSC = await getAirdropUserAllocationInfos(airdropAbi, airdropAddress, library, account);
			const totalAllocationsSC = await getAllAirdropAllocationCount(airdropAbi, airdropAddress, library, account);
			const owner = await getAirdropOwner(airdropAbi, airdropAddress, library, account);


			const allocationsSC = await getAirdropAllocations(airdropAbi, airdropAddress, library, account, 0, parseInt(totalAllocationsSC));
			setAllocations(allocationsSC);

			setUserAllocations(userAllocationsSC);
			setTotalAllocations(parseInt(totalAllocationsSC));
			setIsOwner(account?.toLowerCase() == owner?.toLowerCase());
			setEnsureExactAmount(exactAmountSC);
			setIsAdmin(wlAdminSC);
			setTgeDate(tgeDateSC);
			setTgeBps(parseInt(tgeBpsSC) / 100);
			setCycle(parseInt(cycleSC) / 60);
			setCycleBps(parseInt(cycleBpsSC) / 100);
			setState(parseInt(stateSC));
			setFundPercent(parseInt(fundPercentSc) / 10000);
			setTotalTokens(parseFloat(ethers.utils.formatUnits(totalTokensSC, tokenInfoSC.decimals)));
			setClaimedTokens(parseFloat(ethers.utils.formatUnits(claimedTokensSC, tokenInfoSC.decimals)));
			setLoadingData(false);
		} catch (e) {
			console.log(e);
			setLoadingData(false);
		}

	}


	const getAirDrop = async (_handle) => {
		setLoading(true);

		try {
			let res = await AirdropApi.getAirdropDetail(_handle);
			if (res.status === 200) {
				setDataAirdrop(res.data);
				setTokenAddress(res.data.tokenAddress)
			}
			setLoading(false);
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (handle) {
			getAirDrop(handle);
		}
	}, [handle]);


	useEffect(() => {
		if (totalTokens !== 0) {
			setProgressPercent((claimedTokens / totalTokens) * 100)
		}
	}, [totalTokens, claimedTokens]);

	useEffect(() => {
		setTotalToStartTokens(totalTokens + (totalTokens * fundPercent))
	}, [totalTokens, fundPercent]);

	useEffect(() => {
		if (tokenAddress && account && dataAirdrop.tokenAddress && airdropAddress && dataAirdrop.chainId == chainId) {
			getAirdropInfos()
		}
	}, [tokenAddress, account, dataAirdrop, airdropAddress, chainId]);



	const closeBox = (e) => {
		e.preventDefault();
		setIsFormAllocation(false);
		setIsFormVesting(false);
		setIsStartForm(false)

		startForm.resetFields();
		vestingForm.resetFields();
		addWLForm.resetFields();
		setSpecificTime(0)
	};
	const showFormSetAllocation = () => {
		setIsFormAllocation(true);
	};

	const onShowStartForm = () => {
		setIsStartForm(true);
	};
	const showFormSetVesting = () => {
		vestingForm.setFieldsValue({
			tge_release_percent: tgeBps,
			cycle: cycle,
			cycle_release_percent: cycleBps
		});
		setIsFormVesting(true);
	};
	const removeAllocation = () => {
		try {
			setLoading(true);
			setKeyLoading(6);
			removeAllAirdropAllocations(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

										}
									} else {
										showNoti('warning', `Remove All Airdrop Allocations Failed`);
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	//onClaimTokens

	const onClaimTokens = async () => {
		try {
			setLoading(true);
			setKeyLoading(12);
			await claimAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', `Claim Successfully`);
										}
									} else {
										showNoti('warning', `Claim Failed`);
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	const disableExactAmount = async (status) => {
		try {
			setLoading(true);
			setKeyLoading(7);
			await setAirdropEnableExactAmount(airdropAbi, airdropAddress, library, account, status)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setEnsureExactAmount(status)
											showNoti('success', `${status ? 'Enable' : 'Disable'} Exact Amount Successfully`);
										}
									} else {
										showNoti('warning', `${status ? 'Enable' : 'Disable'} Exact Amount Failed`);
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const onCancelAirdrop = () => {
		try {
			setLoading(true);
			setKeyLoading(2);
			cancelAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;


											const payload = {
												id: dataAirdrop.id,
												airDropStatus: 'CANCEL',
												ownerAddress: account,
											};
											AirdropApi.updateAirDrop(dataAirdrop.id, payload);
											showNoti('success', 'Cancel Airdrop Successfully');
											setState(2);
										}
									} else {
										showNoti('warning', 'Cancel Airdrop Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const onSubmitAddAllocation = async (values: any) => {

		//console.log('payload', values);
		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropAllocations(airdropAbi, airdropAddress, library, account, JSON.parse(values.addressList),
				JSON.parse(values.amountList))
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Set Airdrop Allocations Successfully');
										}
									} else {
										showNoti('warning', 'Set Airdrop Allocations Failed');
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}



	};
	const onSubmitVesting = async (values: any) => {
		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropVesting(airdropAbi, airdropAddress, library, account, parseFloat(values.tge_release_percent) * 100, parseFloat(values.cycle) * 60, parseFloat(values.cycle_release_percent) * 100)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											showNoti('success', 'Set Airdrop Vesting Successfully');
											window.location.reload();
										}
									} else {
										showNoti('warning', 'Set Airdrop Vesting Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const handleApproveToken = async (e) => {
		try {
			e.preventDefault()
			setLoading(true);
			setKeyLoading(2);
			await approveToken(tokenInfoAbi, dataAirdrop.tokenAddress, library, account, airdropAddress)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										!countNoti && showNoti('success', `Approve ${dataAirdrop.tokenSymbol} Successfully`);
										countNoti++;
										setAllowance(Math.pow(2, 256));

									} else {
										showNoti('error', `Approve ${dataAirdrop.tokenSymbol} Failed`);
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (error: any) {
			console.log(error);
			setLoading(false);
		}
	};

	const onStartAirdrop = async (values: any) => {
		try {
			setLoading(true);
			setKeyLoading(1);
			let startTime = Math.floor((new Date().getTime()) / 1000);
			if (values.specType == 1) {
				startTime = Math.floor((new Date(values.start_time).getTime()) / 1000)
			}
			startAirdrop(airdropAbi, airdropAddress, library, account, startTime)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

											const payload = {
												id: dataAirdrop.id,
												airDropStatus: 'START',
												ownerAddress: account,
												startTime: new Date(startTime * 1000).toISOString()
											};
											AirdropApi.updateAirDrop(dataAirdrop.id, payload);
											setState(1);
											showNoti('success', 'Start Airdrop Successfully');
											setIsStartForm(false);
										}
									} else {
										showNoti('warning', 'Start Airdrop Failed');
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							showNoti('error', error.message);
						} else if (error.reason) {
							showNoti('error', error.reason);
						} else {
							if (error.data && error.data.message) {
								showNoti('error', error.data.message);
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};


	const columns = [
		{
			title: 'Id',
			dataIndex: 'Id',
			key: 'Id',
			render: (text, record, index) => {
				return (
					<span >
						{index + 1}
					</span>
				)
			}
		},
		{
			title: 'Wallet',
			dataIndex: 'user',
			key: 'user',
			render: (text, record) => {
				return (
					<span >
						{record.user?.substring(0, 6)}...{record.user?.substring(record.user.length - 6)}
					</span>
				)
			}
		},

		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (text, record) => {
				return (
					<span >
						{parseFloat(ethers.utils.formatUnits(record.amount, decimals)).toLocaleString()} {dataAirdrop.tokenSymbol}
					</span>
				)
			}
		}
	];
	const onCopy = () => {
		showNoti('success', 'Copied');
	};


	return (
		<>
			<HeaderMobile />
			<Content className='page-container'>
				<div className='page-main'>
					<HeaderTop pageType={'color'} chainIdDetail={dataAirdrop?.chainId} />
					<div className='page-content container'>
						<div className='project-block-create p-15'>
							<div className='back-history'>
								<Link to={`/airdrop`}><LeftCircleOutlined /> Back to Airdrop List</Link>
							</div>
							<div className='create-form'>
								<div className='create-title text-info mt-30 font-size-26'>{dataAirdrop?.airDropTitle}</div>
								<div className="ido-social airdrop-social">
									<div className="privates-social">
										{dataAirdrop?.website.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.website}><GlobalOutlined className="icon" /></a></span>
										)}
										{dataAirdrop?.facebook.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.facebook}><FacebookFilled className="icon" /></a></span>
										)}
										{dataAirdrop?.github.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.github}><GithubOutlined className="icon" /></a></span>
										)}
										{dataAirdrop?.instagram.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.instagram}><InstagramOutlined className="icon" /></a></span>
										)}
										{dataAirdrop?.telegram.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.telegram}><FontAwesomeIcon icon={faPaperPlane as IconProp} /></a></span>
										)}
										{dataAirdrop?.twitter.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.twitter}><TwitterCircleFilled className="icon" /></a></span>
										)}
										{dataAirdrop?.discord.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.discord}><FontAwesomeIcon icon={'fa-discord' as IconProp} /></a></span>
										)}
										{dataAirdrop?.reddit.length > 0 && (
											<span className="detail-social"><a target="_blank" href={dataAirdrop?.reddit}><RedditCircleFilled className="icon" /></a></span>
										)}
									</div>
								</div>
								{!account && (
									<>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={24} xl={8}>
												<p>
													<Alert message="Please connect wallet" type="error" showIcon />
												</p>
											</Col>
											<Col className='gutter-row' xs={24} xl={8}></Col>
										</Row>
									</>
								)}
								{account && chainId !== dataAirdrop?.chainId && (
									<>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={24} xl={8}>
												<p>
													<Alert message="Incorrect Network" type="error" showIcon />
												</p>
											</Col>
											<Col className='gutter-row' xs={24} xl={8}></Col>
										</Row>
									</>
								)}

								<div className='create-step mt-15'>
									<div className='airdrop-block'>
										{dataAirdrop?.description.length > 0 && (
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={24} xl={16}>
													<div className='airdrop-detail-des'>
														{dataAirdrop?.description}
													</div>
												</Col>
												<Col className='gutter-row' xs={24} xl={8}></Col>
											</Row>
										)}

										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={24} xl={16}>

												<Card title={'Airdrop Info'} extra={
													<>
														{dataAirdrop?.taskLink.length > 0 && (
															<>
																<Button htmlType="button" className="btn-all mr-10">
																	<a target="_blank" href={dataAirdrop?.taskLink}>Join Task <DoubleRightOutlined /></a>
																</Button>
															</>
														)}
													</>
												}>
													<div className='airdrop-line pt-0'>
														<div className='airdrop-banner-detail'>
															<img src={dataAirdrop?.logoUrl} />
														</div>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Token Address</span></Col>
															<Col className='gutter-row text-right' xs={12}>
																<div className='airdrop-token-address'>
																	<span className='text-info'>{dataAirdrop?.tokenAddress} </span>
																	<CopyToClipboard onCopy={onCopy} text={dataAirdrop?.tokenAddress}>
																		<CopyOutlined className='text-info' />
																	</CopyToClipboard>
																</div>
															</Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row' xs={12}><span>Name</span></Col>
															<Col className='gutter-row text-right' xs={12}><span>{dataAirdrop?.tokenName}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row'
																xs={12}><span>Symbol</span></Col>
															<Col className='gutter-row text-right'
																xs={12}><span>{dataAirdrop?.tokenSymbol}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row'
																xs={12}><span>Total Tokens</span></Col>
															<Col className='gutter-row text-right'
																xs={12}><span><span className='text-info'>{dataAirdrop?.totalTokenReward}</span> {dataAirdrop?.tokenSymbol}</span></Col>
														</Row>
													</div>

													{tgeBps > 0 && (
														<>
															<div className='airdrop-line'>
																<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
																	<Col className='gutter-row'
																		xs={12}><span>TGE Release Percent</span></Col>
																	<Col className='gutter-row text-right'
																		xs={12}><span>{tgeBps.toLocaleString()}%</span></Col>
																</Row>
															</div>
															<div className='airdrop-line'>
																<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
																	<Col className='gutter-row'
																		xs={12}><span>Cycle</span></Col>
																	<Col className='gutter-row text-right'
																		xs={12}><span>{cycle.toLocaleString()} Minutes</span></Col>
																</Row>
															</div>
															<div className='airdrop-line'>
																<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
																	<Col className='gutter-row'
																		xs={12}><span>Cycle Release Percent</span></Col>
																	<Col className='gutter-row text-right'
																		xs={12}><span>{cycleBps.toLocaleString()}%</span></Col>
																</Row>
															</div>
														</>
													)}
												</Card>
												<Card title={`Allocation`} style={{ marginTop: '15px' }}
												>
													{loadingData ? (
														<>
															<div className="loading-center">
																<Space size="middle"><Spin size="large" /></Space>
															</div>
														</>
													) : (
														<>
															{totalAllocations === 0 ? (
																<>
																	{dataAirdrop?.timeClaimReward && (
																		<p className='text-info' style={{ textAlign: 'center', fontSize: '16px' }}>
																			Claimable after {convertDateTime(timeClaimReward)}
																		</p>
																	)}
																</>
															) : (
																<>
																	{parseInt(dataAirdrop?.id) == 8 ? (
																		<>
																			<p style={{ textAlign: 'center', fontSize: '16px' }}>
																				Check your allocation here : <a target="_blank" href='https://docs.google.com/spreadsheets/d/1AKUakr8QgtDbQLQvIFPhZh3m4dQmgwibxGKEh7AHRh4/edit#gid=0'>Allocation</a>
																			</p>
																		</>
																	) : (
																		<div className='table-allocation-airdrop'>
																			<Table dataSource={allocations} columns={columns} />
																		</div>
																	)}

																</>
															)}
														</>
													)}

												</Card>
											</Col>
											<Col className='gutter-row' xs={24} xl={8}>
												<Card title=''>
													{state === 0 && (
														<Alert message="Waiting for airdrop start!" type="info" />
													)}
													{state === 1 && (
														claimedTokens >= totalTokens ?
															(
																<Alert message="Airdrop has been ended!" type="error" />
															) :
															(
																<>
																	{parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) > 0 ? (
																		<Alert message="Congrats winners. You can now claim your rewards!" type="success" />
																	) : (
																		<Alert message="Airdrop is live now!" type="warning" />
																	)}
																</>
															)
													)}
													{state === 2 && (
														<p className='airdrop-time'>Airdrop is cancelled</p>
													)}

													<Progress
														strokeColor={{
															'0%': '#0261a5',
															'100%': '#89befe'
														}}
														percent={progressPercent}
														showInfo={false}
														className="mt-15"
													/>
													<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
														<Col className='gutter-row' xs={12}><span>{claimedTokens.toLocaleString()}</span></Col>
														<Col className='gutter-row text-right'
															xs={12}><span>{dataAirdrop?.totalTokenReward} {dataAirdrop?.tokenSymbol}</span></Col>
													</Row>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row'
																xs={12}><span>Your Allocation</span></Col>
															<Col className='gutter-row text-right'
																xs={12}><span> {parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)).toLocaleString()}</span></Col>
														</Row>
													</div>
													<div className='airdrop-line'>
														<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
															<Col className='gutter-row'
																xs={12}><span>Your Claimed</span></Col>
															<Col className='gutter-row text-right'
																xs={12}><span>{parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals)).toLocaleString()}</span></Col>
														</Row>
													</div>
													{parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) > 0 && (
														<div className='airdrop-line'>
															<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
																<Col className='gutter-row'
																	xs={12}>
																	<Button htmlType='button' className='btn-all' disabled={dataAirdrop?.chainId != chainId || (parseFloat(claimable) === 0) || (loading && keyLoading === 12)} onClick={onClaimTokens}>Claim {claimable > 0 ? `${claimable} ${dataAirdrop?.tokenSymbol}` : ''} {(loading && keyLoading === 12) && <Spin className="ml-10" size="small" />}</Button>
																</Col>

															</Row>
														</div>
													)}
												</Card>
												{loadingData ? (
													<>
														<Card title={'Owner Zone'} style={{ marginTop: '15px' }}>
															<div className="loading-center">
																<Space size="middle"><Spin size="large" /></Space>
															</div>
														</Card>
													</>
												) : (
													<>
														{isOwner && state === 0 && (
															<Card title={'Owner Zone'} style={{ marginTop: '15px' }}>
																{/* <Alert
																	message="Please don't start the airdrop before you finalize the presale pool."
																	type='warning' /> */}
																<Alert
																	message='You must exclude fees, dividens, max tx for airdrop address to start the airdrop.'
																	className='mt-15' type='warning' />
																<div className='airdrop-btn-grp'>
																	<Button className='airdrop-btn' htmlType='button' onClick={onShowStartForm}>Start Airdrop</Button>
																	<Button className='airdrop-btn' htmlType='button'
																		disabled={loading && keyLoading === 2}
																		onClick={onCancelAirdrop}
																	>
																		Cancel Airdrop {(loading && keyLoading === 2) && <Spin className="ml-10" size="small" />}
																	</Button>
																</div>
																<p style={{ marginTop: '15px' }}><strong>Allocations Actions</strong></p>
																<div className='airdrop-btn-grp'>
																	<Button className='airdrop-btn' onClick={showFormSetAllocation}
																		htmlType='button'>Set Allocations</Button>
																	<Button className='airdrop-btn' onClick={showFormSetVesting}
																		htmlType='button'>Set Vesting</Button>
																	<Button className='airdrop-btn' onClick={removeAllocation} disabled={loading && keyLoading === 6}
																		htmlType='button'>Remove All Allocations {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}</Button>
																	{ensureExactAmount ?
																		(
																			<Button className='airdrop-btn' onClick={() => disableExactAmount(false)} disabled={loading && keyLoading === 7}
																				htmlType='button'>Disable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
																		) : (
																			<Button className='airdrop-btn' onClick={() => disableExactAmount(true)} disabled={loading && keyLoading === 7}
																				htmlType='button'>Enable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
																		)
																	}
																</div>
															</Card>
														)}
													</>
												)}

											</Col>
										</Row>
									</div>
								</div>
							</div>
						</div>

					</div>
					<Footer />
					<Modal
						title={<div className='text-md custom-modal-title'>Add Allocations</div>}
						visible={isFormAllocation}
						className='custom-modal create-token-modal'
						footer={false}
					>
						<div className='transer-box-content'>
							<Form
								form={addWLForm}
								name='basic'
								autoComplete='off'
								onFinish={onSubmitAddAllocation}
								layout='vertical'
							>

								<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item name='amountList' label='TGE release percent (%)' hidden={true}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>

								<Form.Item name='account_address' label='Allocations'
									rules={[
										{
											validator: (rule, value, cb: (msg?: string) => void) => {
												if (!value) {
													return cb('Allocations can not be blank');
												}
												if (value) {
													const wallets = value.trim().split('\n');
													let totalTokens = 0;

													let addressList: any = [];
													let allocationList: any = [];
													let amountList: any = [];
													for (let i = 0; i < wallets.length; i++) {
														const lineValues = wallets[i].trim().split(',');
														const address = (lineValues[0] || '').trim();
														const amount = (lineValues[1] || '0').trim();

														addressList.push(address);
														amountList.push(ethers.utils.parseUnits(`${amount}`, decimals).toHexString())
														allocationList.push({ address, amount })

														totalTokens = totalTokens + parseFloat(amount);

														if (!ethers.utils.isAddress(address) || !parseFloat(amount)) {
															return cb(`Incorrect value at line ${i + 1}`);
														}
													}
													addWLForm.setFieldsValue({
														'addressList': JSON.stringify(addressList),
														'amountList': JSON.stringify(amountList),
													});
													cb();
												}

											}
										}]}>
									<TextArea rows={8} className='amount-buy' placeholder='Insert address: separate with breaks line. The amount of each user separate by comma(,) symbol. The format just like CSV file
																							Ex:
																							0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6,1000
																							0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1,500
																							0x968136BB860D9534aF1563a7c7BdDa02B1A979C2,1234' />
								</Form.Item>
								<Form.Item className='modal-btn-group'>
									<button className='btn-all' disabled={loading} type='submit'>Add
										Allocations {loading && <Spin className='ml-10' size='small' />}</button>
									<button className='btn-default' disabled={loading} onClick={closeBox}>Close</button>
								</Form.Item>

							</Form>

						</div>
					</Modal>
					<Modal
						title={<div className='text-md custom-modal-title'>Set Vesting</div>}
						visible={isFormVesting}
						className='custom-modal create-token-modal'
						footer={false}
					>
						<div className='transer-box-content'>
							<Form
								form={vestingForm}
								name='basic'
								autoComplete='off'
								onFinish={onSubmitVesting}
								layout='vertical'
							>
								<Form.Item name='tge_release_percent' label='TGE release percent (%)'
									rules={[
										{
											validator: (rule, value, cb: (msg?: string) => void) => {
												!value || parseFloat(value) <= 0 || parseFloat(value) > 100
													? cb('TGE percent must be a positive number')
													: cb();
											}
										}
									]}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item name='cycle_release_percent' label='Cycle release percent (%)'
									rules={[
										{
											validator: (rule, value, cb: (msg?: string) => void) => {
												!value || parseFloat(value) <= 0 || parseFloat(value) > 100
													? cb('Cycle release percent must be a positive number')
													: cb();
											}
										}]}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item name='cycle' label='Cycle (Minutes)'
									rules={[
										{
											validator: (rule, value, cb: (msg?: string) => void) => {
												!value || parseFloat(value) <= 0
													? cb('Cycle must be a positive number')
													: cb();
											}
										}]}>
									<Input className='amount-buy' placeholder='Ex: 0' />
								</Form.Item>
								<Form.Item className='modal-btn-group'>
									<button className='btn-all' disabled={loading} type='submit'>Set vesting {loading &&
										<Spin className='ml-10' size='small' />}</button>
									<button className='btn-default' disabled={loading} onClick={closeBox}>Close</button>
								</Form.Item>
							</Form>

						</div>
					</Modal>

					<Modal
						title={<div className='text-md custom-modal-title'>Setting time to start</div>}
						visible={isStartForm}
						className='custom-modal create-token-modal'
						footer={false}
					>
						<div className='transer-box-content'>
							<Form
								form={startForm}
								name='basic'
								autoComplete='off'
								onFinish={onStartAirdrop}
								layout='vertical'
							>
								<Form.Item name="specType" label="Set time to start airdrop" initialValue={0}>
									<Radio.Group className="radio-gr-create" onChange={e => setSpecificTime(!(!e.target.value))}>
										<Radio value={0}>Start now</Radio>
										<Radio value={1}>Start with specific time</Radio>
									</Radio.Group>
								</Form.Item>
								{specificTime ? (
									<Form.Item name="start_time" label="Start time"
										rules={[
											{ required: true, message: 'Please input Start time!' },
										]}>
										<Input className="amount-buy" type="datetime-local" />
									</Form.Item>
								) : (<></>)}

								<div className="start-info">
									<span className="start-info-detail"> You need at least {totalToStartTokens} {dataAirdrop?.tokenSymbol} to start airdrop</span>
								</div>


								<Form.Item className='modal-btn-group'>

									{allowance < totalTokens ? (
										<button className='btn-all' disabled={loading} onClick={handleApproveToken}>approve {loading &&
											<Spin className='ml-10' size='small' />}</button>
									) : (
										<button className='btn-all' disabled={loading} type='submit'>Start Airdrop {loading &&
											<Spin className='ml-10' size='small' />}</button>
									)}
									<button className='btn-default' disabled={loading} onClick={closeBox}>Close</button>
								</Form.Item>
							</Form>

						</div>
					</Modal>
				</div>
				{loadingData && (<LoadingFull />)}
			</Content>
		</>
	);
};

export default AirdropDetail;
