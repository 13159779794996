import { instance } from '../../apiBase/instance';

export const chainApi = {

	getChainNetworkList(params) {
		return instance.get<any>(`chain-lists/client`, { params });
	},
	getSettingsByChain(chainId: any) {
		return instance.get<any>(`settings/chain-id/${chainId}`, {});
	},
	// getChainDetail(chainId) {
	// 	return instance.get<any>(`chain-lists/info?chainId=${chainId}&isTestnet=false`, {});
	// },
	// private api


};
