import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, useLocation, useParams } from 'react-router-dom';
import {
    Layout, Row, Col, Progress, Button, Tabs, Modal, Input, Form, InputNumber, Spin, Table, Space, Alert, Radio, Card
} from 'antd';
import type { RadioChangeEvent } from 'antd';
import queryString from 'query-string';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import Countdown from 'react-countdown';
import { CalculatorFilled, LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import ApplyToLaunch from '../apply-to-launch';
import DetailInfo from './detail-info';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';
import {
    approveToken,
    cancelLaunchpad,
    claimCanceledTokens,
    claimToken,
    contributeLaunchpad,
    emergencyWithdrawContribute,
    finalizeLaunchPad,
    getClaimable,
    getLaunchpadInfo,
    getOwnerZoneInfo,
    getTokenAllowance,
    getUserJoinInfo,
    setWhitelistLaunchpad,
    withdrawContribute,
    setClaimTimeICO,
    getSignature,
    getMaxInvest,
    getListingTime,
    getTokenInformation,
    getMaxLP,
    getVestingTimes
} from '../../utils';
import launchpadAbi from 'config/abi/launchpadAbi.json';
import web3 from 'web3';
import { ethers } from 'ethers';
import tokenInfoAbi from 'config/abi/tokenInfo.json';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { getDexInfo } from 'config/network/dex';
import { getCurrencyDecimals } from 'config/network/config';
import { chainApi } from 'config/api/chainApi';
import LoadingFull from 'components/loading-full';
import { convertDateTime } from 'utils/formatDatetime';


const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;

declare const window: Window & typeof globalThis & { ethereum: any };

const IcoDetail = () => {
    const { showNoti } = useWrap();
    const [addWLForm] = Form.useForm();
    const [rmWLForm] = Form.useForm();
    const [holdingTokenForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(0);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [detail, setDetail] = useState<any>();
    const [raiseAmount, setRaiseAmount] = useState(0);
    const [whitelists, setWhitelists] = useState<any>([]);
    const [isFormWL, setIsFormWL] = useState<boolean>(false);
    const [isFormRemoveWL, setIsFormRemoveWL] = useState<boolean>(false);
    const [isConfirmCancelModal, setIsConfirmCancelModal] = useState<boolean>(false);
    const [isShowPublicAnti, setIsShowPublicAnti] = useState<boolean>(false);
    const [totalInvestment, setTotalInvestment] = useState(0);
    const [claimedTokens, setClaimedToken] = useState(0);
    const [totalUserTokens, setTotalUserTokens] = useState(0);
    const [totalClaimable, setTotalClaimable] = useState(0);
    const [state, setState] = useState(0);
    const [softCap, setSoftCap] = useState(0);
    const [hardCap, setHardCap] = useState(0);
    const [maxLP, setMaxLP] = useState(0);
    const [maxInvest, setMaxInvest] = useState(0);
    const [raisedAmount, setRaisedAmount] = useState(0);
    const [refund, setRefund] = useState<boolean>();
    const [launchpadBalance, setLaunchpadBalance] = useState(0);
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [canCancel, setCanCancel] = useState<boolean>(false);

    const [canFinalize, setCanFinalize] = useState<boolean>(false);
    const [tokenApprove, setTokenApprove] = useState(0);
    const [feeToken, setFeeToken] = useState('');
    const [isWhitelisted, setIsWhitelisted] = useState<boolean>(false);
    const [sig, setSig] = useState('0x00');
    const [isWhitelistPool, setIsWhiteListPool] = useState<any>(false);
    const [isShowTimeClaim, setIsShowTimeClaim] = useState<any>(false);
    const [whitelistPool, setWhitelistPool] = useState<any>(false);
    const [holdingToken, setHoldingToken] = useState<any>('');
    const [holdingAmount, setHoldingAmount] = useState<any>('');
    const [holdingName, setHoldingName] = useState<any>('');
    const [holdingSymbol, setHoldingSymbol] = useState<any>('');
    const [holdingDecimals, setHoldingDecimals] = useState<any>(18);
    const [payDecimals, setPayDecimals] = useState<any>(18);
    const [listingTime, setListingTime] = useState<any>(0);
    const [listVesting, setListVesting] = useState<any>([]);

    const [showVestingSchedule, setShowVestingSchedule] = useState<any>(false);

    const [dex, setDex] = useState<any>({});

    const location = useLocation();

    let nowDate = new Date(Date.now());
    let startDate = new Date(detail?.rounds[0]?.startTime);
    let endDate = new Date(detail?.rounds[0]?.endTime);

    let isManualListing: any = detail?.rounds[0]?.doManualListing;



    const [form] = Form.useForm<{ name: string; age: number }>();
    let { id }: { id: string } = useParams();
    const { chainId, account, library } = useActiveWeb3React();
    const [totalRow, setTotalRow] = useState(20);
    let status: any = 'upcoming';
    let paramUrl: any = queryString.parse(location.search);
    if (paramUrl.status !== undefined && paramUrl.status.length > 0) {
        status = paramUrl.status;
    }

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const getDataLaunchpad = async (id) => {

        try {
            let res = await IdoLaunchpad.genLaunchpadById(id);
            if (res.status === 200) {
                if (res.data) {
                    setDetail(res.data);

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);
    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 20, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                    if (isTestnet === true) {
                        setChainNetworkListTest(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (account) {
            getChainNetworkList(false);
            getChainNetworkList(true);
        }
    }, [account]);

    useEffect(() => {
        let dexInfo = getDexInfo(detail?.rounds[0]?.chainId, detail?.dexId)
        setDex(dexInfo)
        let payDec = detail?.rounds[0]?.currencyPayDecimals || getCurrencyDecimals(detail?.rounds[0]?.chainId, detail?.rounds[0]?.currencyPay) || 18
        setPayDecimals(payDec)
    }, [detail?.rounds[0]?.chainId, detail?.dexId]);

    const verifyWhitelist = async (roundId, address) => {

        try {
            let res = await IdoLaunchpad.verifyWhitelist(roundId, address);
            if (res) {
                if (res.status === 404) {
                    setIsWhitelisted(false)
                }
                if (res.status === 200) {
                    setIsWhitelisted(true)
                    if (res.data && res.data.signature) {
                        setSig(res.data.signature)
                    }
                }
            }


        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    const handleApproveToken = async () => {
        try {
            setLoading(true);
            setKeyLoading(2);
            await approveToken(tokenInfoAbi, feeToken, library, account, detail?.rounds[0]?.roundAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve ${detail?.rounds[0]?.currencyPay} Successfully`);
                                        countNoti++;
                                        setTokenApprove(Math.pow(2, 256));

                                    } else {
                                        showNoti('error', `Approve ${detail?.rounds[0]?.currencyPay} Failed`);
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    if (error) {
                        if (error.code === 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            console.log(error);
            setLoading(false);
        }
    };

    const getLaunchpadInformation = async (roundAddress) => {
        try {
            setLoadingInfo(true);
            let infoSC = await getLaunchpadInfo(launchpadAbi, roundAddress, library, account);
            let maxLP = await getMaxLP(launchpadAbi, roundAddress, library, account)
            console.log(infoSC);
            setMaxLP(parseFloat(ethers.utils.formatUnits(maxLP, detail?.tokenInfo?.decimals)))
            if (infoSC) {
                // let raiseAmountSC = web3.utils.fromWei(infoSC.raisedAmount.toString(), 'ether');
                setRaiseAmount(parseInt(ethers.utils.formatUnits(infoSC.raisedAmount, payDecimals)));
                setState(parseInt(infoSC.state))
                setRaisedAmount(parseFloat(ethers.utils.formatUnits(infoSC.raisedAmount, payDecimals)));
                setSoftCap(parseFloat(ethers.utils.formatUnits(infoSC.softCap, payDecimals)));
                setHardCap(parseFloat(ethers.utils.formatUnits(infoSC.hardCap, payDecimals)));
                setLaunchpadBalance(parseFloat(ethers.utils.formatUnits(infoSC.balance, detail?.tokenInfo?.decimals)));
                // setEndTime(parseInt(infoSC.endTime));
                // setStartTime(parseInt(infoSC.startTime));
                setFeeToken(infoSC.feeToken);
                setIsWhiteListPool(parseInt(infoSC.whitelistPool) === 1);


                if (infoSC.holdingToken !== ethers.constants.AddressZero) {
                    const tokenInfo = await getTokenInformation(tokenInfoAbi, infoSC.holdingToken);

                    setHoldingName(tokenInfo.name);
                    setHoldingSymbol(tokenInfo.symbol);
                    setHoldingDecimals(tokenInfo.decimals);
                    setHoldingAmount(parseFloat(ethers.utils.formatUnits(infoSC.holdingTokenAmount, tokenInfo.decimals)));
                    setHoldingToken(infoSC.holdingToken);

                    holdingTokenForm.setFieldsValue({
                        'amount': parseFloat(ethers.utils.formatUnits(infoSC.holdingTokenAmount, tokenInfo.decimals)),
                        'token_address': infoSC.holdingToken ? infoSC.holdingToken : ''
                    });
                }

                if (infoSC.feeToken.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
                    const allowance = await getTokenAllowance(tokenInfoAbi, infoSC.feeToken, account, roundAddress);
                    setTokenApprove(parseInt(allowance));
                }
            }
            setLoadingInfo(false);
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }

    }

    const getMaxInvestment = async (roundAddress) => {
        try {
            setLoadingInfo(true);
            let maxInvestment = await getMaxInvest(launchpadAbi, roundAddress, library, account);
            maxInvestment = parseFloat(ethers.utils.formatUnits(maxInvestment, payDecimals));
            setMaxInvest(maxInvestment);
            setLoadingInfo(false);
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }

    };

    const getOwnerZoneInformation = async (roundAddress) => {
        try {
            setLoadingInfo(true)
            let ownerZoneInfo = await getOwnerZoneInfo(launchpadAbi, roundAddress, library, account);
            if (ownerZoneInfo) {
                setIsOwner(ownerZoneInfo.isOwner)
                setCanCancel(ownerZoneInfo.canCancel)
                // setCanUpdateWhiteListPool(ownerZoneInfo.canEnableWhitelist)
                setCanFinalize(ownerZoneInfo.canFinalize)
                setWhitelistPool(parseInt(ownerZoneInfo.whitelistPool))

            }
            setLoadingInfo(false)
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }
    }

    const getListingTimePool = async (roundAddress) => {
        try {
            setLoadingInfo(true)
            let listingTime = await getListingTime(launchpadAbi, roundAddress, library, account);
            if (listingTime) {
                setListingTime(parseInt(listingTime));
            }
            setLoadingInfo(false)
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }
    }

    const getVestingShedule = async (listingTime, detail, totalUserTokens) => {
        try {
            setLoadingInfo(true)
            let listVestingCaculator: any = await getVestingTimes(listingTime, (parseInt(detail?.vestingContributor?.tgeLock) * 60), (parseInt(detail?.vestingContributor?.lockAfterTGE) * 60), detail?.vestingContributor?.firstRelease, (detail?.vestingContributor?.vestingCycle * 60), detail?.vestingContributor?.tokenReleaseCycle, totalUserTokens);
            if (listVestingCaculator) {
                setListVesting(listVestingCaculator);
            }
            setLoadingInfo(false)
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }
    }

    const getUserJoin = async (roundAddress) => {
        try {
            setLoadingInfo(true)
            let userJoinInfoSC = await getUserJoinInfo(launchpadAbi, roundAddress, library, account);
            if (userJoinInfoSC) {
                setTotalInvestment(parseFloat(ethers.utils.formatUnits(userJoinInfoSC.totalInvestment, payDecimals)))
                setRefund(userJoinInfoSC.refund)
                setClaimedToken(parseFloat(ethers.utils.formatUnits(userJoinInfoSC.claimedTokens, detail?.tokenInfo?.decimals)));
                setTotalUserTokens(parseFloat(ethers.utils.formatUnits(userJoinInfoSC.totalTokens, detail?.tokenInfo?.decimals)));
            }
            setLoadingInfo(false)
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }
    }

    const getUserClaimable = async (roundAddress) => {
        try {
            setLoadingInfo(true)
            let userClaimable = await getClaimable(launchpadAbi, roundAddress, library, account);
            if (userClaimable) {
                setTotalClaimable(parseFloat(ethers.utils.formatUnits(userClaimable, detail?.tokenInfo?.decimals)));

            }
            setLoadingInfo(false)
        } catch (e) {
            console.log(e);
            setLoadingInfo(false)
        }

    }

    const getWhitelist = async (roundId) => {
        const dataFilter = {
            page: 0,
            size: 20,
            sort: 'id,desc'
        };
        try {
            let res = await IdoLaunchpad.getWhitelistByRound(roundId, dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setWhitelists(res.data);
                } else {
                    setWhitelists([]);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getDataLaunchpad(id);
            if (detail?.rounds[0]?.whiteListOnly) {
                getWhitelist(id);
            }

        }
    }, [id, detail?.rounds[0]?.whiteListOnly]);



    useEffect(() => {
        if (account && detail?.rounds[0]?.roundAddress.length > 0 && detail?.rounds[0]?.chainId === chainId) {
            getLaunchpadInformation(detail?.rounds[0]?.roundAddress)
            getMaxInvestment(detail?.rounds[0]?.roundAddress)
            getUserJoin(detail?.rounds[0]?.roundAddress)
            getUserClaimable(detail?.rounds[0]?.roundAddress)
            getOwnerZoneInformation(detail?.rounds[0]?.roundAddress)
            getListingTimePool(detail?.rounds[0]?.roundAddress)
        }
        if (account && detail?.rounds[0]?.id && detail?.rounds[0]?.whiteListOnly) {
            verifyWhitelist(detail?.rounds[0]?.id, account)
        }
    }, [account, detail?.rounds[0]?.roundAddress, chainId, payDecimals]);

    // useEffect(() => {
    //     if (account && detail && detail?.rounds[0]?.chainId === chainId && listingTime > 0) {
    //         if (listingTime > 0 && detail?.liquidityLockup > 0 && detail?.vestingContributor?.lockAfterTGE > 0) {
    //             getVestingShedule(listingTime, detail);
    //         }
    //     }
    // }, [account, detail, chainId, listingTime]);

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
            return;
        }
        return (
            <div className="process-tc">
                <div className="timer-count">
                    {days} <span>:</span><small>day</small>
                </div>
                <div className="timer-count">
                    {hours} <span>:</span><small>hours</small>
                </div>
                <div className="timer-count">
                    {minutes} <span>:</span><small>mins</small>
                </div>
                <div className="timer-count">
                    {seconds} <small>sec</small>
                </div>
            </div>
        );
    };
    const getProgressTime = useCallback((startTime) => {

        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);
    const tabCallback = (key) => {
        console.log(key);
    }
    const onSubmitBuy = async (values: any) => {
        try {
            setLoadingBuy(true);
            const decimals = detail.rounds[0]?.currencyPayDecimals || getCurrencyDecimals(detail.rounds[0].chainId, detail.rounds[0].currencyPay)
            // const sig = '0x00'
            contributeLaunchpad(launchpadAbi, detail.rounds[0].roundAddress, library, account, sig, values.amount, decimals)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Contribute Successfully');
                                            setTotalInvestment(totalInvestment + parseFloat(values.amount));
                                            setRaisedAmount(raisedAmount + parseFloat(values.amount));
                                            if (raisedAmount >= hardCap) {
                                                setCanFinalize(true);
                                            }
                                            form.resetFields()
                                        }
                                    } else {
                                        showNoti('warning', 'Contribute Failed');
                                    }
                                    setLoadingBuy(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoadingBuy(false);
                    if (error) {
                        if (error.code === 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            showNoti('error', error.message);
                            // if (error.data && error.data.message) {
                            //     showNoti('error', error.data.message);
                            // }
                        }
                    }
                    form.resetFields()
                });
        } catch (e: any) {
            console.log(e);
            setLoadingBuy(false);
            form.resetFields()
        }
    };

    const onSubmitAddWL = async (values: any) => {
        let roundId = detail?.rounds[0].id;
        setLoading(true);

        if (detail.rounds[0].id > 0) {

            setLoading(true);
            try {
                const signatureWeb3 = await getSignature(account, library, 'white-lists/add');
                const payload = {
                    addressList: values.account_address.split(','),
                    roundId: roundId,
                    message: 'white-lists/add',
                    signature: signatureWeb3
                };
                let res = await IdoLaunchpad.addWhiteList(payload);

                if (res.status === 204) {
                    setLoading(false);
                    showNoti('success', 'Add Whitelist Successfully');
                    setIsFormWL(false);
                    getWhitelist(roundId);
                }
            } catch (error) {
                console.log('Error: ', error);
                showNoti('danger', 'Add Whitelist Failed');
            } finally {
                setLoading(false);
            }
        } else {
            showNoti('danger', 'Not found round id');
        }
    };
    const onSubmitRemoveWL = async (values: any) => {
        let roundId = detail?.rounds[0].id;
        setLoading(true);
        console.log('values.account_address', values.addresses);
        if (detail.rounds[0].id > 0) {

            setLoading(true);

            try {
                const signatureWeb3 = await getSignature(account, library, 'white-lists/remove');
                const payload = {
                    addressList: values.addresses.split(','),
                    roundId: roundId,
                    message: 'white-lists/remove',
                    signature: signatureWeb3
                };
                let res = await IdoLaunchpad.removeWhiteList(payload);

                if (res.status === 204) {
                    setLoading(false);
                    showNoti('success', 'Remove Successfully');
                    setIsFormRemoveWL(false);
                    getWhitelist(roundId);
                }
            } catch (error) {
                console.log('Error: ', error);
                showNoti('danger', 'Remove Failed');
            } finally {
                setLoading(false);
            }
        } else {
            showNoti('danger', 'Not found round id');
        }
    };
    const onCancelPool = async (status) => {
        try {
            let roundId = detail?.rounds[0].id;
            if (roundId <= 0) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            if (state === 3) {
                const payload = {
                    id: roundId,
                    actionEnum: status
                };
                let res = await IdoLaunchpad.updateStatusIdoPool(roundId, payload);
                if (res.status === 201) {
                    if (res.data) {
                        setLoading(false);
                        showNoti('success', 'Cancel Launchpad Successfully');
                        setIsConfirmCancelModal(false);
                        getDataLaunchpad(id);
                    }
                }
                return;
            }
            setLoading(true);
            setKeyLoading(6);
            cancelLaunchpad(launchpadAbi, detail?.rounds[0].roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            const payload = {
                                                id: roundId,
                                                actionEnum: status
                                            };
                                            let response = await IdoLaunchpad.updateStatusIdoPool(roundId, payload);
                                            console.log(response);
                                            if (response.status === 200) {
                                                showNoti('success', 'Cancel Launchpad Successfully');
                                                setState(3);
                                                setCanCancel(false);
                                                setIsConfirmCancelModal(false);
                                                getDataLaunchpad(id);
                                            }
                                        }
                                    } else {
                                        showNoti('warning', 'Cancel Launchpad Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }

    const finalizePool = async () => {
        try {
            let roundId = detail?.rounds[0].id;
            if (roundId <= 0) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            if (state == 2) {
                const payload = {
                    id: roundId,
                    actionEnum: 'FINALIZE'
                };
                let res = await IdoLaunchpad.updateStatusIdoPool(roundId, payload);
                if (res && res.status === 200) {
                    if (res.data) {
                        setLoading(false);
                        showNoti('success', 'Finalize Launchpad Successfully');
                        window.location.reload();
                    }
                }
                return;
            }
            setLoading(true);
            setKeyLoading(4)
            finalizeLaunchPad(launchpadAbi, detail?.rounds[0].roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            const payload = {
                                                id: roundId,
                                                actionEnum: 'FINALIZE'
                                            };
                                            let res = await IdoLaunchpad.updateStatusIdoPool(roundId, payload);
                                            if (res.status === 200) {
                                                if (res.data) {
                                                    setLoading(false);
                                                    showNoti('success', 'Finalize Launchpad Successfully');
                                                    window.location.reload();
                                                }
                                            }
                                        }
                                    } else {
                                        showNoti('warning', 'Finalize Launchpad Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }


    const options = [
        { label: 'Public', value: 0 },
        { label: 'Whitelist', value: 1 },
        { label: 'Public Anti', value: 2 }
    ];
    const onchangeRadioBtn = ({ target: { value } }: RadioChangeEvent) => {
        try {

            console.log('radio checked', value);
            if (value == whitelistPool) {
                return;
            }
            if (value < 2) {
                let roundId = detail?.rounds[0].id;
                if (roundId <= 0) {
                    showNoti('warming', 'Invalid Launchpad!');
                    return;
                }
                setLoading(true);
                setWhitelistLaunchpad(launchpadAbi, detail?.rounds[0].roundAddress, library, account, value, ethers.constants.AddressZero, 0)
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                const payload = {
                                                    id: roundId,
                                                    whiteListOnly: value === 1
                                                };
                                                let res = await IdoLaunchpad.updateIdoWhitelist(roundId, payload);

                                                if (res.status === 200) {
                                                    if (res.data) {
                                                        setLoading(false);
                                                        showNoti('success', 'Update Successfully');
                                                        // setCanUpdateWhiteListPool(!val);
                                                        getDataLaunchpad(id);
                                                        setWhitelistPool(value);
                                                        holdingTokenForm.resetFields();
                                                        setHoldingToken('');
                                                        setHoldingName('');
                                                        setHoldingSymbol('');
                                                        setHoldingDecimals('');
                                                        setHoldingAmount(0);
                                                    }
                                                }
                                            }
                                        } else {
                                            showNoti('warning', 'Update Launchpad Failed');
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                showNoti('error', error.message);
                            } else if (error.reason) {
                                showNoti('error', error.reason);
                            } else {
                                if (error.data && error.data.message) {
                                    showNoti('error', error.data.message);
                                }
                            }
                        }
                    });
            } else if (value == 2) {
                setIsShowPublicAnti(true);
            }

        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }


    };


    const onSetHoldingToken = (values) => {
        try {

            if (!values.token_address || !ethers.utils.isAddress(values.token_address) || !values.amount || !holdingDecimals) {
                return;
            }
            let roundId = detail?.rounds[0].id;
            if (roundId <= 0) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(11);
            console.log(values.token_address);
            console.log(ethers.utils.parseUnits(`${values.amount}`, holdingDecimals));
            setWhitelistLaunchpad(launchpadAbi, detail?.rounds[0].roundAddress, library, account, 2, values.token_address, ethers.utils.parseUnits(`${values.amount}`, holdingDecimals))
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            // const payload = {
                                            //     id: roundId,
                                            //     whiteListOnly: value === 1
                                            // };
                                            // let res = await IdoLaunchpad.updateIdoWhitelist(roundId, payload);
                                            showNoti('success', 'Update Successfully');
                                            getDataLaunchpad(id);
                                            setWhitelistPool(2);
                                            setIsShowPublicAnti(false);
                                            // if (res.status === 200) {
                                            //     if (res.data) {
                                            //         setLoading(false);
                                            //         showNoti('success', 'Update Successfully');
                                            //         // setCanUpdateWhiteListPool(!val);
                                            //         getDataLaunchpad(id);
                                            //         setWhitelistPool(value);
                                            //     }
                                            // }
                                        }
                                    } else {
                                        showNoti('warning', 'Update Launchpad Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });


        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }


    };
    const onEnableWhitelist = async (val: boolean) => {
        try {
            let roundId = detail?.rounds[0].id;
            if (roundId <= 0) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(3)
            setWhitelistLaunchpad(launchpadAbi, detail?.rounds[0].roundAddress, library, account, val ? 1 : 0, ethers.constants.AddressZero, 0)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            const payload = {
                                                id: roundId,
                                                whiteListOnly: val
                                            };
                                            let res = await IdoLaunchpad.updateIdoWhitelist(roundId, payload);

                                            if (res.status === 200) {
                                                if (res.data) {
                                                    setLoading(false);
                                                    showNoti('success', 'Update Successfully');
                                                    // setCanUpdateWhiteListPool(!val);
                                                    getDataLaunchpad(id);
                                                }
                                            }
                                        }
                                    } else {
                                        showNoti('warning', 'Update Launchpad Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    };
    const showFormAddWL = () => {
        setIsFormWL(true);
        addWLForm.resetFields();
    };
    const showFormRemoveWL = () => {
        setIsFormRemoveWL(true);
        rmWLForm.resetFields();
    };
    const showCancelPoll = () => {
        setIsConfirmCancelModal(true);
    };
    const showTimeClaim = () => {
        setIsShowTimeClaim(true);
    };
    const closeBox = (e) => {
        e.preventDefault()
        setIsFormWL(false);
        setIsFormRemoveWL(false);
        setIsConfirmCancelModal(false);
        setIsShowPublicAnti(false);
        setIsShowTimeClaim(false);
        setShowVestingSchedule(false);
    };

    let listingTimeClaim = listingTime > 0 ? new Date(1000 * listingTime) : 0;

    const columnsWhitelist = [
        {
            title: 'No.',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, row: any, index: any) => {
                return (
                    <>
                        <span>{index + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        }
    ];
    const onEmergencyWithdraw = () => {
        try {
            let roundId = detail?.rounds[0].id;
            let roundAddress = detail?.rounds[0].roundAddress;
            if (roundId <= 0 || !roundAddress) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(1);
            emergencyWithdrawContribute(launchpadAbi, roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Emergency Withdraw Successfully');
                                            setRaisedAmount(raisedAmount - totalInvestment);
                                            setTotalInvestment(0);
                                        }
                                    } else {
                                        showNoti('warning', 'Emergency Withdraw Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }
    const onClaimToken = () => {
        try {
            let roundId = detail?.rounds[0].id;
            let roundAddress = detail?.rounds[0].roundAddress;
            if (roundId <= 0 || !roundAddress) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(8)
            claimToken(launchpadAbi, roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Claim Successfully');
                                            setClaimedToken(claimedTokens + totalClaimable);
                                            setTotalClaimable(0);

                                        }
                                    } else {
                                        showNoti('warning', 'Claim Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }

    const onClaimCanceledTokens = () => {
        try {
            let roundId = detail?.rounds[0].id;
            let roundAddress = detail?.rounds[0].roundAddress;
            if (roundId <= 0 || !roundAddress) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(5);
            claimCanceledTokens(launchpadAbi, roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Claim Canceled Tokens Successfully');
                                            setLaunchpadBalance(0)
                                        }
                                    } else {
                                        showNoti('warning', 'Claim Canceled Tokens Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }

    const onWithdrawContribute = () => {
        try {
            let roundId = detail?.rounds[0].id;
            let roundAddress = detail?.rounds[0].roundAddress;
            if (roundId <= 0 || !roundAddress) {
                showNoti('warming', 'Invalid Launchpad!');
                return;
            }
            setLoading(true);
            setKeyLoading(7);
            withdrawContribute(launchpadAbi, roundAddress, library, account)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;
                                            showNoti('success', 'Withdraw Successfully');
                                            setTotalInvestment(0)
                                        }
                                    } else {
                                        showNoti('warning', 'Withdraw Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }

    const onChangeTokenAddress = async (values: any) => {
        const tokenAddressVal: any = values.target.value;

        try {
            const tokenInfo = await getTokenInformation(tokenInfoAbi, tokenAddressVal);
            setHoldingName(tokenInfo.name);
            setHoldingSymbol(tokenInfo.symbol);
            setHoldingDecimals(tokenInfo.decimals);

        } catch (e: any) {
            console.log(e);
        }
    };

    const onCloseSetting = async (e) => {
        try {
            e.preventDefault();
            setIsShowPublicAnti(false);
            holdingTokenForm.setFieldsValue({
                'amount': holdingAmount,
                'token_address': holdingToken
            });

        } catch (e: any) {
            console.log(e);
        }
    };

    let percentProcess: any = detail?.rounds[0].soldPercent != null && detail?.rounds[0].soldPercent > 0 ? detail?.rounds[0].soldPercent : Math.ceil(raisedAmount / hardCap * 100);
    let dataNetwork: any;
    dataNetwork = chainNetworkListMain.find((itemChain) => parseInt(detail?.rounds[0].chainId) === parseInt(itemChain.chainId));
    if (dataNetwork == undefined || dataNetwork.length == 0) {
        dataNetwork = chainNetworkListTest.find((itemChain) => parseInt(detail?.rounds[0].chainId) === parseInt(itemChain.chainId));
    }

    const changeNetwork = async (chainIdDetail, data) => {

        try {
            if (data) {
                console.log('chainId', data.chainId);
                await window.ethereum.enable();
                const params = {
                    chainId: `0x${parseInt(data.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
                    chainName: data.name,
                    nativeCurrency: {
                        name: data.symbol,
                        symbol: data.symbol, // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: [data.rpcUrl,],
                    blockExplorerUrls: [
                        data.blockExplorer,
                    ],
                };
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params, account]
                    })
                    .then((result) => {
                        console.log(result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${parseInt(data.chainId).toString(16)}` }], // chainId must be in hexadecimal numbers
                });
            }
        } catch (error: any) {
            if (error.code === 4902) {
                try {
                    if (data) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: chainIdDetail,
                                    rpcUrl: data.rpcUrl,
                                },
                            ],
                        });
                    }

                } catch (addError) {
                    // handle "add" error
                }
            }
            console.error(error);
        }
    };

    const onStartTimeClaim = async (values: any) => {

        let roundAddress = detail?.rounds[0].roundAddress;

        if (!roundAddress || roundAddress.length == 0) {
            setLoading(false);
            showNoti('error', 'Start Claim Failed');
            return;
        }
        try {
            setLoading(true);
            setKeyLoading(1);
            let startTime = Math.floor((new Date(values.start_time).getTime()) / 1000);
            // if (values.specType == 1) {
            //     startTime = 
            // }
            setClaimTimeICO(launchpadAbi, roundAddress, library, account, startTime)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        if (!countNoti) {
                                            countNoti++;

                                            // const payload = {
                                            //     id: dataAirdrop.id,
                                            //     airDropStatus: 'START',
                                            //     ownerAddress: account,
                                            //     startTime: new Date(startTime * 1000).toISOString()
                                            // };
                                            // AirdropApi.updateAirDrop(dataAirdrop.id, payload);
                                            // setState(1);
                                            showNoti('success', 'Start Claim Successfully');
                                            setIsShowTimeClaim(false);
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('error', 'Start Claim Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (e: any) {
            console.log(e);
            setLoading(false);
        }
    }

    const onShowVestingSchedule = () => {
        if (account && detail?.rounds[0]?.chainId === chainId && listingTime > 0 && totalUserTokens > 0) {

            if (listingTime > 0) {
                getVestingShedule(listingTime, detail, totalUserTokens);
            }
        }
        setShowVestingSchedule(true);
    }

    const columnsVestingSchedule = [
        {
            title: 'No.',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, row: any, index: any) => {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'Time Claim',
            dataIndex: 'time',
            key: 'time',
            render: (text: any, row: any, index: any) => {
                return (
                    <span>{convertDateTime(new Date(1000 * row.time))}</span>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: any, row: any, index: any) => {
                return (
                    <span className='text-info'>{row.amount}</span>
                )
            }
        }
    ];


    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainIdDetail={detail?.rounds[0].chainId} />
                    <div className="page-content container">
                        <div className="project-block-detail">
                            <div className="back-history">
                                <Link to="/ico-launchpad"><LeftCircleOutlined /> Back to Launchpad List</Link>
                            </div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                    <div className="detail-left pb-0">
                                        <div className="detail-left-img">
                                            <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                        </div>
                                    </div>
                                    {loadingInfo ? (
                                        <div className="loading-center">
                                            <Space size="middle"><Spin size="large" /></Space>
                                        </div>
                                    ) : (
                                        <>
                                            {state == 1 && (
                                                <>
                                                    {nowDate < startDate &&
                                                        (<div className="detail-left">
                                                            {/* <div className="detail-left-img">
                                                                <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                                            </div> */}
                                                            <div className="detail-time pt-15">
                                                                <>
                                                                    <span className="text-time">Sale start in </span>
                                                                    <div className="time-coundown">
                                                                        <Countdown
                                                                            date={getProgressTime(startDate)}
                                                                            renderer={rendererCountDown}
                                                                        />
                                                                    </div>
                                                                </>
                                                                <Progress percent={0} className="detail-progress-percent" />
                                                                <div className="project-total-sell">
                                                                    <span className='progress-number text-info'>{raisedAmount.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                    <span className='progress-number text-info'>{detail?.hardCap.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}

                                                    {startDate <= nowDate && endDate >= nowDate && (
                                                        <div className="detail-left">
                                                            {/* <div className="detail-left-img">
                                                                <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                                            </div> */}
                                                            <div className="detail-time pt-15">
                                                                {raisedAmount >= hardCap ? (
                                                                    <div className="text-time">Sold Out</div>
                                                                ) : (
                                                                    <>
                                                                        <div className="text-time">End in</div>
                                                                        <div className="time-coundown">
                                                                            <Countdown
                                                                                date={getProgressTime(endDate)}
                                                                                renderer={rendererCountDown}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}

                                                                <Progress percent={percentProcess} className="detail-progress-percent" />
                                                                <div className="project-total-sell">
                                                                    <span className={`text-info ${detail?.rounds[0]?.soldPercent !== null ? 'text-hidden' : ''}`}>{raisedAmount > 0 ? raisedAmount.toLocaleString() : 0} {detail?.rounds[0]?.currencyPay}</span>
                                                                    <span className='text-info'>{detail?.hardCap.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                </div>
                                                            </div>
                                                            <div className="detail-buy">
                                                                <Form form={form} layout="vertical" autoComplete="off" onFinish={onSubmitBuy}>
                                                                    <Form.Item name="amount"
                                                                        initialValue={0}
                                                                        label={`Amount (min: ${detail?.rounds[0]?.minBuy} ${detail?.rounds[0]?.currencyPay}, max: ${detail?.rounds[0]?.maxBuy} ${detail?.rounds[0]?.currencyPay})`}
                                                                        rules={[
                                                                            {
                                                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                    !value || parseFloat(value) <= 0 || parseFloat(value) + totalInvestment < detail?.rounds[0]?.minBuy || parseFloat(value) + totalInvestment > detail?.rounds[0]?.maxBuy
                                                                                        ? cb('Invalid value')
                                                                                        : cb();
                                                                                }
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <InputNumber className="amount-buy" />
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        {isWhitelistPool ?
                                                                            (<>
                                                                                {isWhitelisted ? (
                                                                                    <>
                                                                                        {(parseInt(feeToken) != 0 && tokenApprove <= 0) ? (
                                                                                            <Button type="primary" disabled={loading && keyLoading === 2} className="btn-all" onClick={handleApproveToken} >Approve {detail?.rounds[0]?.currencyPay} {(loading && keyLoading === 2) && <Spin className="ml-10" size="small" />}</Button>

                                                                                        ) : (
                                                                                            <Button type="primary" disabled={loadingBuy || (totalInvestment >= maxInvest) || (raisedAmount >= hardCap)} className="btn-all" htmlType="submit" >Buy With {detail?.rounds[0]?.currencyPay} {loadingBuy && <Spin className="ml-10" size="small" />}</Button>

                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <Alert message="You are not in whitelist" showIcon type="info" />
                                                                                )}
                                                                            </>

                                                                            ) : (<>
                                                                                {(parseInt(feeToken) != 0 && tokenApprove <= 0) ? (
                                                                                    <Button type="primary" disabled={loading && keyLoading === 2} className="btn-all" onClick={handleApproveToken} >Approve {detail?.rounds[0]?.currencyPay} {(loading && keyLoading === 2) && <Spin className="ml-10" size="small" />}</Button>

                                                                                ) : (
                                                                                    <Button type="primary" disabled={loadingBuy || (totalInvestment >= maxInvest) || (raisedAmount >= hardCap)} className="btn-all" htmlType="submit" >Buy With {detail?.rounds[0]?.currencyPay} {loadingBuy && <Spin className="ml-10" size="small" />}</Button>

                                                                                )}
                                                                            </>)

                                                                        }

                                                                    </Form.Item>
                                                                    {totalInvestment > 0 &&
                                                                        (<Form.Item>
                                                                            <Button className="btn-buy" htmlType="button" onClick={onEmergencyWithdraw}
                                                                                disabled={loading && keyLoading === 1}>Emergency Withdraw {(loading && keyLoading === 1) && <Spin className="ml-10" size="small" />}</Button>
                                                                        </Form.Item>)}
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {endDate < nowDate && (
                                                        <div className="detail-left">
                                                            {/* <div className="detail-left-img">
                                                                <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                                            </div> */}
                                                            <div className="detail-time pt-15">
                                                                <>
                                                                    <span className="text-time">Launchpad Ended </span>
                                                                </>
                                                                <Progress percent={percentProcess} className="detail-progress-percent" />
                                                                <div className="project-total-sell">
                                                                    <span className={`text-info ${detail?.rounds[0]?.soldPercent !== null ? 'text-hidden' : ''}`}>{raisedAmount > 0 ? raisedAmount.toLocaleString() : 0} {detail?.rounds[0]?.currencyPay}</span>
                                                                    <span className='text-info'>{detail?.hardCap.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                </div>
                                                            </div>
                                                            {(totalInvestment > 0 && !refund && raisedAmount < softCap) &&
                                                                (<div className="grp-btn-claim">
                                                                    <Button className="btn-all" htmlType="button" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
                                                                </div>)}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {state == 2 &&
                                                (
                                                    <>
                                                        <div className="detail-left">
                                                            {/* <div className="detail-left-img">
                                                                <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                                            </div> */}
                                                            <div className="detail-time pt-15">
                                                                <>
                                                                    {!isManualListing ? (
                                                                        <span className="text-time">Launchpad Finalized </span>
                                                                    ) : (
                                                                        <>
                                                                            {listingTime > 0 && nowDate < listingTimeClaim ? (
                                                                                <>
                                                                                    <span className="text-time">Claim start in </span>
                                                                                    <div className="time-coundown">
                                                                                        <Countdown
                                                                                            date={getProgressTime(listingTimeClaim)}
                                                                                            renderer={rendererCountDown}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {listingTime == 0 ? (
                                                                                        <span className="text-time text-warning">Claim is coming soon </span>
                                                                                    ) : (
                                                                                        <span className="text-time text-info">Claim is ready </span>
                                                                                    )}

                                                                                </>
                                                                            )}

                                                                        </>
                                                                    )}
                                                                </>
                                                                <Progress percent={percentProcess} className="detail-progress-percent" />
                                                                <div className="project-total-sell">
                                                                    <span className={`text-info ${detail?.rounds[0]?.soldPercent !== null ? 'text-hidden' : ''}`}>{raisedAmount > 0 ? raisedAmount.toLocaleString() : 0} {detail?.rounds[0]?.currencyPay}</span>
                                                                    <span className='text-info'>{detail?.hardCap.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                </div>
                                                            </div>
                                                            <div className="grp-btn-claim">
                                                                {isManualListing ? (
                                                                    <>
                                                                        {listingTime > 0 && (
                                                                            <Button htmlType="button" className="btn-all" onClick={onClaimToken} disabled={!totalClaimable || (loading && keyLoading === 8)}>{totalClaimable > 0 ? `Claim (${totalClaimable.toLocaleString()} ${detail?.tokenInfo.symbol})` : `Claim (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${detail?.tokenInfo.symbol})`} {(loading && keyLoading === 8) && <Spin className="ml-10" size="small" />}</Button>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button htmlType="button" className="btn-all" onClick={onClaimToken} disabled={!totalClaimable || (loading && keyLoading === 8)}>{totalClaimable > 0 ? `Claim (${totalClaimable.toLocaleString()} ${detail?.tokenInfo.symbol})` : `Claim (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${detail?.tokenInfo.symbol})`} {(loading && keyLoading === 8) && <Spin className="ml-10" size="small" />}</Button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                            {state == 3 &&
                                                (
                                                    <>
                                                        <div className="detail-left">
                                                            {/* <div className="detail-left-img">
                                                                <img src={detail?.backgroundLink ? detail?.backgroundLink : "../../images/project-3.png"} />
                                                            </div> */}
                                                            <div className="detail-time pt-15">
                                                                <>
                                                                    <span className="text-time">Launchpad Canceled </span>
                                                                </>
                                                                <Progress percent={0} className="detail-progress-percent" />
                                                                <div className="project-total-sell">
                                                                    <span className='text-info'>{raisedAmount > 0 ? raisedAmount.toLocaleString() : 0} {detail?.rounds[0]?.currencyPay}</span>
                                                                    <span className='text-info'>{detail?.hardCap.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                                </div>
                                                            </div>
                                                            {(totalInvestment > 0 && !refund) &&
                                                                (<div className="grp-btn-claim">
                                                                    <Button className="btn-all" htmlType="button" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute  {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
                                                                </div>)}
                                                        </div>
                                                    </>
                                                )}
                                        </>
                                    )}
                                    <div className="detail-left">
                                        <div className="detail-info-tabs">
                                            {account && chainId !== detail?.rounds[0].chainId && (
                                                <>
                                                    <p>
                                                        <Alert message="Incorrect Network" type="error" showIcon />
                                                    </p>
                                                    <Button className="btn-all" onClick={() => changeNetwork(detail?.rounds[0].chainId, dataNetwork)}>
                                                        Switch network to {dataNetwork?.symbol}
                                                    </Button>
                                                </>
                                            )}
                                            <Tabs defaultActiveKey="info" onChange={tabCallback} className="info-tab-cus">

                                                <TabPane tab="Info" key="info">
                                                    <div className="tab-detail-info">
                                                        <span>Sale Type</span><span>{detail?.rounds[0]?.whiteListOnly ? 'Private' : 'Public'}</span>
                                                    </div>
                                                    <div className="tab-detail-info">
                                                        <span>Minimum Buy</span><span className='text-info'>{detail?.rounds[0]?.minBuy.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                    </div>
                                                    <div className="tab-detail-info">
                                                        <span>Maximum Buy</span><span className='text-info'>{detail?.rounds[0]?.maxBuy.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                    </div>
                                                    <div className="tab-detail-info">
                                                        <span>Your Purchased</span><span className='text-info'>{totalInvestment.toLocaleString()} {detail?.rounds[0]?.currencyPay}</span>
                                                    </div>
                                                    {state == 2 && (
                                                        <div className="tab-detail-info">
                                                            <span>Vesting Schedule</span>
                                                            <span className='text-info'><CalculatorFilled className='caculator-vs-schedule' size={30} onClick={() => onShowVestingSchedule()} /></span>
                                                        </div>
                                                    )}


                                                    {/*<div className="tab-detail-info">*/}
                                                    {/*    <span>Current Vesting Cycle</span><span>0 - (8/8)</span>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="tab-detail-info">*/}
                                                    {/*    <span>Remaining Vesting Cycle</span><span>2 hours ago</span>*/}
                                                    {/*</div>*/}
                                                </TabPane>
                                                {isOwner && (<TabPane tab="Owner zone" key="owner-zone">
                                                    <div className="tab-detail-info">
                                                        <>
                                                            {state == 1 && (
                                                                <>
                                                                    {startDate >= nowDate && (
                                                                        <>
                                                                            <Radio.Group
                                                                                className="m-10"
                                                                                options={options}
                                                                                onChange={onchangeRadioBtn}
                                                                                value={whitelistPool}
                                                                            />
                                                                            {whitelistPool == 0 && (
                                                                                <Button htmlType="button" className="btn-tab-detail" onClick={() => onEnableWhitelist(true)} disabled={loading && keyLoading === 3 || nowDate >= startDate}>Enable Whitelist {(loading && keyLoading === 3) && <Spin className="ml-10" size="small" />}</Button>
                                                                            )}
                                                                            {whitelistPool == 1 && (
                                                                                <>
                                                                                    <Button htmlType="button" className="btn-tab-detail" onClick={showFormAddWL} disabled={nowDate >= startDate}>Add users to whitelist</Button>
                                                                                    <Button htmlType="button" disabled={!whitelists.length || (nowDate >= startDate)} className="btn-tab-detail" onClick={showFormRemoveWL}>Remove users from whitelist</Button>
                                                                                    <Button htmlType="button" className="btn-tab-detail" onClick={() => onEnableWhitelist(false)} disabled={loading && keyLoading === 3 || (nowDate >= startDate)}>Disable whitelist {(loading && keyLoading === 3) && <Spin className="ml-10" size="small" />}</Button>
                                                                                </>
                                                                            )}

                                                                            {whitelistPool == 2 && (
                                                                                <>
                                                                                    <Button htmlType="button" className="btn-tab-detail" onClick={() => setIsShowPublicAnti(true)} >Setting Token Holding</Button>
                                                                                </>
                                                                            )}
                                                                            {canCancel && (
                                                                                <Button htmlType="button" className="btn-tab-detail btn-cancel-pool" onClick={showCancelPoll} disabled={!canCancel}>Cancel Pool </Button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {(startDate < nowDate && nowDate < endDate) && (
                                                                        <>
                                                                            {raisedAmount >= hardCap ? (
                                                                                <Button htmlType="button" className="btn-tab-detail" onClick={finalizePool} disabled={!canFinalize || (loading && keyLoading === 4)}>Finalize Pool {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
                                                                            ) : (
                                                                                <Alert message='Launchpad is opening' showIcon type='info' />
                                                                            )}
                                                                            {detail?.rounds[0]?.whiteListOnly && (
                                                                                <>
                                                                                    <Button htmlType="button" className="btn-tab-detail" onClick={showFormAddWL} >Add users to whitelist</Button>
                                                                                    <Button htmlType="button" disabled={!whitelists.length} className="btn-tab-detail" onClick={showFormRemoveWL}>Remove users from whitelist</Button>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {(nowDate >= endDate) && (
                                                                        <>
                                                                            <Button htmlType="button" className="btn-tab-detail" onClick={finalizePool} disabled={!canFinalize || (loading && keyLoading === 4)}>Finalize Pool {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
                                                                            {canCancel && (
                                                                                <Button htmlType="button" className="btn-tab-detail btn-cancel-pool" onClick={showCancelPoll} disabled={!canCancel}>Cancel Pool </Button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {state == 2 && (
                                                                <>
                                                                    <Alert message="Launchpad is filled" showIcon type="info" />
                                                                    {isManualListing && (
                                                                        <>
                                                                            {listingTimeClaim <= nowDate && (
                                                                                <Button htmlType="button" className="btn-tab-detail mt-15" onClick={showTimeClaim} disabled={(loading && keyLoading === 4)}>Set Time Claim {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
                                                                            )}
                                                                        </>
                                                                    )}

                                                                </>
                                                            )}
                                                        </>
                                                        {!canCancel && state == 3 && launchpadBalance > 0 && (
                                                            <Button htmlType="button" className="btn-tab-detail" onClick={onClaimCanceledTokens} disabled={loading && keyLoading === 5}>Claim Canceled Tokens {(loading && keyLoading === 5) && <Spin className="ml-10" size="small" />}</Button>
                                                        )}
                                                    </div>
                                                </TabPane>)}
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                                    <DetailInfo detailInfo={detail} idoId={id} status={status} chainNetworkListMain={chainNetworkListMain} chainNetworkListTest={chainNetworkListTest} maxLP={maxLP} dex={dex} />

                                    {whitelists.length > 0 && (
                                        <>
                                            <Card title="User Whitelist" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                                                <div className="whitelist-list">
                                                    <Table pagination={false} dataSource={whitelists} columns={columnsWhitelist} />
                                                </div>
                                            </Card>
                                        </>
                                    )}

                                </Col>
                            </Row>
                        </div>

                    </div>
                    <ApplyToLaunch />
                    <Footer />
                </div>
                <Modal
                    title={<div className="text-md custom-modal-title">Add Users</div>}
                    visible={isFormWL}
                    className="custom-modal create-token-modal"
                    footer={false}
                >
                    <div className="transer-box-content">
                        <Form
                            form={addWLForm}
                            name="basic"
                            autoComplete="off"
                            onFinish={onSubmitAddWL}
                            layout="vertical"
                        >
                            <Form.Item name="account_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                                <TextArea rows={8} className="amount-buy" placeholder="Example:
0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6,
0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1" />
                            </Form.Item>
                            <Form.Item className="modal-btn-group">
                                <button className="btn-all" disabled={loading} type="submit">Add User {loading && <Spin className="ml-10" size="small" />}</button>
                                <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                            </Form.Item>
                        </Form>

                    </div>
                </Modal>
                <Modal
                    title={<div className="text-md custom-modal-title">Remove Users</div>}
                    visible={isFormRemoveWL}
                    className="custom-modal create-token-modal"
                    footer={false}
                >
                    <div className="transer-box-content">
                        <Form
                            form={rmWLForm}
                            name="rmWLForm"
                            autoComplete="off"
                            onFinish={onSubmitRemoveWL}
                            layout="vertical"
                        >
                            <Form.Item name="addresses" label="Users" rules={[{ required: true, message: 'Please input Users!' }]}>
                                <TextArea rows={8} className="amount-buy" placeholder="Example:
0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6,
0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1" />
                            </Form.Item>
                            <Form.Item className="modal-btn-group">
                                <button className="btn-all" disabled={loading} type="submit">Remove User {loading && <Spin className="ml-10" size="small" />}</button>
                                <button className="btn-default" disabled={loading} onClick={(e) => closeBox(e)}>Close</button>
                            </Form.Item>
                        </Form>

                    </div>
                </Modal>
                <Modal
                    title={<div className="text-md custom-modal-title">Confirm Cancel Pool</div>}
                    visible={isConfirmCancelModal}
                    className="custom-modal create-token-modal"
                    footer={false}
                >
                    <div className="transer-box-content">
                        <p><Alert message="Confirm Cancel This Pool" type="warning" showIcon /></p>
                        <div className="modal-btn-group">
                            <button className="btn-all" disabled={loading && keyLoading === 6} type="button" onClick={() => onCancelPool('CANCEL')}>Confirm {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}</button>
                            <button className="btn-default" disabled={loading && keyLoading === 6} onClick={closeBox}>Close</button>
                        </div>


                    </div>
                </Modal>

                <Modal
                    title={<div className="text-md custom-modal-title">Public with holding condition</div>}
                    visible={isShowPublicAnti}
                    className="custom-modal create-token-modal"
                    footer={false}
                >
                    <div className="transer-box-content">
                        <p><Alert message="With this option you can control who can contribute to the pool. Only Users who hold a minimum amount of token you suggest would be able to contribute" type="warning" showIcon /></p>
                        <Form
                            form={holdingTokenForm}
                            name="basic"
                            autoComplete="off"
                            onFinish={onSetHoldingToken}
                            layout="vertical"
                        >
                            <Form.Item name="token_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]} initialValue={holdingToken}>
                                <input className="amount-buy" placeholder="Enter address" onBlur={onChangeTokenAddress} />
                            </Form.Item>

                            <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input Amount!' }]} initialValue={0}>
                                <input className="amount-buy" placeholder="Enter Amount" />
                            </Form.Item>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24}>
                                    {holdingName && holdingDecimals && holdingSymbol && (
                                        <div className="bg-token-info token-info-detail">
                                            <div className="detail-text">
                                                <div className="left">Name</div>
                                                <div className="right">{holdingName}</div>
                                            </div>
                                            <div className="detail-text">
                                                <div className="left">Symbol</div>
                                                <div className="right">{holdingSymbol}</div>
                                            </div>
                                            <div className="detail-text mb-15">
                                                <div className="left">Decimals</div>
                                                <div className="right">{holdingDecimals}</div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24}>
                                    <Form.Item className="modal-btn-group">
                                        <button className="btn-all" disabled={loading && keyLoading === 11} type="submit">Confirm {loading && keyLoading === 11 && <Spin className="ml-10" size="small" />}</button>
                                        <button className="btn-default" disabled={loading && keyLoading === 11} onClick={e => onCloseSetting(e)}>Close</button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Modal>

                <Modal
                    title={<div className='text-md custom-modal-title'>Set Claim Time</div>}
                    visible={isShowTimeClaim}
                    className='custom-modal create-token-modal'
                    footer={false}
                >
                    <div className='transer-box-content'>
                        <Form
                            form={form}
                            name='basic'
                            autoComplete='off'
                            onFinish={onStartTimeClaim}
                            layout='vertical'
                        >
                            {/* <Form.Item name="specType" label="Set time to start airdrop" initialValue={0}>
                                <Radio.Group className="radio-gr-create" onChange={e => setSpecificTime(!(!e.target.value))}>
                                    <Radio value={0}>Start now</Radio>
                                    <Radio value={1}>Start with specific time</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {specificTime ? ( */}
                            <Form.Item name="start_time" label="Claim Time"
                                rules={[
                                    { required: true, message: 'Please input Start time!' },
                                    {
                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                            !value || new Date(value) <= new Date(Date.now())
                                                ? cb("Start time needs to be after now")
                                                : cb();
                                        }
                                    }
                                ]}>
                                <Input className="amount-buy" type="datetime-local" />
                            </Form.Item>
                            {/* ) : (<></>)} */}

                            <Form.Item className='modal-btn-group'>
                                <button className='btn-all' disabled={loading} type='submit'>Start claim {loading && <Spin className='ml-10' size='small' />}</button>
                                <button className='btn-default' disabled={loading} onClick={closeBox}>Close</button>
                            </Form.Item>
                        </Form>

                    </div>
                </Modal>
                <Modal
                    title={<div className="text-md custom-modal-title">Vesting Schedule</div>}
                    visible={showVestingSchedule}
                    className="custom-modal create-token-modal"
                    footer={false}
                >
                    <div className="transer-box-content">
                        <Table pagination={false} dataSource={listVesting} columns={columnsVestingSchedule} />
                        <div className="modal-btn-group mt-15">
                            <button className="btn-default" onClick={closeBox}>Close</button>
                        </div>
                    </div>

                </Modal>


            </Content>
            {loadingBuy || loading && (
                <LoadingFull />
            )}
        </>
    )
}

export default IcoDetail
