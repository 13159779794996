import { faPlus, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Button, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import './header.css';
import ConnectWallet from 'components/connect-wallet/ConnectWallet';
import { useActiveWeb3React } from 'hooks';
import { chainApi } from 'config/api/chainApi';

import { CloseOutlined, CodepenOutlined, DeploymentUnitOutlined, DotChartOutlined, DribbbleOutlined, FileSearchOutlined, HomeFilled, MenuUnfoldOutlined } from '@ant-design/icons';
import ChainPopup from './ChainPopup';


declare const window: Window & typeof globalThis & { ethereum: any };
const { SubMenu } = Menu;

const HeaderTop = (props) => {
	const { pageType, chainIdDetail, color } = props;
	const { chainId, account, library } = useActiveWeb3React();

	const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
	const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);

	const location = useLocation();

	let defaultMenu;
	switch (location.pathname) {
		case '/ico-launchpad':
			defaultMenu = 'launchpad';
			break;
		case '/ido-launchpad':
			defaultMenu = 'launchpad';
			break;
		case '/ido-launchpad/:handle':
			defaultMenu = 'launchpad';
			break;
		case '/ido/create':
			defaultMenu = 'launchpad';
			break;
		case '/ico/create':
			defaultMenu = 'launchpad';
			break;
		case '/pre-sale/create':
			defaultMenu = 'launchpad';
			break;
		case '/launchpad/create':
			defaultMenu = 'launchpad';
			break;
		case '/launchpad/anti-bot':
			defaultMenu = 'services';
			break;
		case '/token/create':
			defaultMenu = 'launchpad';
			break;
		case '/ido/anti-bot':
			defaultMenu = 'launchpad';
			break;
		case '/ino':
			defaultMenu = 'ino';
			break;
		case 'ino-launchpad/ino-detail':
			defaultMenu = 'ino-launchpad/ino-detail';
			break;
		case '/services':
			defaultMenu = 'services';
			break;
		case '/wave-lock':
			defaultMenu = 'services';
			break;
		case '/wave-lock/detail/:address':
			defaultMenu = 'services';
			break;
		case '/wave-lock/record/:id':
			defaultMenu = 'services';
			break;
		case '/wave-lock/create':
			defaultMenu = 'services';
			break;
		case '/wave-lock/token':
			defaultMenu = 'services';
			break;
		case '/wave-lock/liquidity':
			defaultMenu = 'services';
			break;
		case '/airdrop':
			defaultMenu = 'services';
			break;
		case '/airdrop/create':
			defaultMenu = 'services';
			break;
		case 'airdrop/detail':
			defaultMenu = 'services';
			break;
		case '/multi-sender':
			defaultMenu = 'services';
			break;
		case '/staking':
			defaultMenu = 'staking';
			break;
		case '/fixed-staking':
			defaultMenu = 'staking';
			break;
		case '/farm':
			defaultMenu = 'staking';
			break;
		case '/private-sale/create':
			defaultMenu = 'private-sale';
			break;
		case '/private-sale':
			defaultMenu = 'private-sale';
			break;
		default:
			defaultMenu = 'home';
	}
	const [current, setCurrent] = useState(defaultMenu);

	const activeMenu = (e: { key: React.SetStateAction<string> }) => {
		if (e.key === 'logo') {
			setCurrent('home');
			return;
		}
		setCurrent(e.key);
	};
	const menu = (
		<Menu>
			<Menu.Item><Link to="/ico/create">ICO</Link></Menu.Item>
			<Menu.Item><Link to="/token/create">Token</Link></Menu.Item>
			<Menu.Item><Link to="/wave-lock/create">Wavelock</Link></Menu.Item>
			<Menu.Item><Link to="/airdrop/create">Airdrop</Link></Menu.Item>
			<Menu.Item><Link to="/private-sale/create">Private Sale</Link></Menu.Item>
		</Menu>
	);

	const [isActive, setActive] = useState(false);
	const handleToggle = () => {
		setActive(!isActive);
	};
	const handleHideMenuMobile = () => {
		setActive(false);
	};

	const getChainNetworkList = async (isTestnet: any) => {
		let dataFilter: any = { page: 0, size: 20, sort: 'id,desc', vm: 'EVM' };
		if (isTestnet !== null) {
			dataFilter = { ...dataFilter, isTestnet: isTestnet }
		}

		try {
			let res = await chainApi.getChainNetworkList(dataFilter);
			if (res.status === 200) {
				if (res.data.length > 0) {
					if (isTestnet == true) {
						setChainNetworkListTest(res.data);
					}
					if (isTestnet == false) {
						setChainNetworkListMain(res.data);
					}
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		getChainNetworkList(true);
		getChainNetworkList(false);
	}, []);


	const ChainNetworkRender = (chainId) => {
		let contentChain: any = '';
		if (chainId && chainNetworkListMain) {
			let chain: any;
			chain = chainNetworkListMain.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));

			if (chain == undefined || chain.length == 0) {
				chain = chainNetworkListTest.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
			}

			contentChain = (
				<ChainPopup
					chainNetworkListMain={chainNetworkListMain}
					chainNetworkListTest={chainNetworkListTest}
					chain={chain}
				/>
			);
		}
		return contentChain;
	}

	return (
		<>
			<div className={`header ${pageType == 'color' ? 'header-bg' : ''}`}>
				<Menu
					selectedKeys={[current]}
					defaultOpenKeys={['sub1']}
					mode="horizontal"
					onClick={activeMenu}
					className="header-desktop"
				>

					<Menu.Item key="image" className='logo-item-menu'>
						<Link to="/">
							<img src="../../../../images/logo-white.png" alt="logo" className='logo-header' />
							<span className='logo-text'>WaveSignal</span>
						</Link>

					</Menu.Item>
					<SubMenu key="launchpad" title="Launchpads">
						<Menu.Item key="ido-launchpad">
							<Link to="/ido-launchpad">
								IDO Launchpad
							</Link>
						</Menu.Item>
						<Menu.Item key="ico-launchpad">
							<Link to="/ico-launchpad">
								ICO Launchpad
							</Link>
						</Menu.Item>
						<Menu.Item key="create-ido">
							<Link to="/ico/create">
								Create ICO launchpad
							</Link>
						</Menu.Item>
						<Menu.Item key="token-create">
							<Link to="/token/create">
								Create Token
							</Link>
						</Menu.Item>

					</SubMenu>

					<SubMenu key="private-sale" title="Private Sale">
						<Menu.Item key="private-sale-list">
							<Link to="/private-sale">
								Private Sale List
							</Link>
						</Menu.Item>
						<Menu.Item key="private-sale-create">
							<Link to="/private-sale/create">
								Private Sale Create
							</Link>
						</Menu.Item>
					</SubMenu>

					<SubMenu key="staking" title="Earn">
						<Menu.Item key="staking-list">
							<Link to="/staking">Staking</Link>
						</Menu.Item>
						<Menu.Item key="staking-fixed">
							<Link to="/fixed-staking">Fixed Staking</Link>
						</Menu.Item>
						<Menu.Item key="farm">
							<Link to="/farm">
								Farms
							</Link>
						</Menu.Item>
					</SubMenu>
					{/* <Menu.Item key="ino" title="INO Launchpad">
						<Link to="/ino">
							<span>INO Launchpad</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="referral" title="Referral">
						<Link to="/referral">
							<span>Referral</span>
						</Link>
					</Menu.Item> */}
					<SubMenu key="services" title="Services">
						<Menu.Item key="lock-list">
							<Link to="/wave-lock/token">
								WaveLock
							</Link>
						</Menu.Item>
						<Menu.Item key="airdrop-list">
							<Link to="/airdrop">
								Airdrop
							</Link>
						</Menu.Item>
						<Menu.Item key="anti-bot">
							<Link to="/launchpad/anti-bot">
								Anti-bot
							</Link>
						</Menu.Item>
						<Menu.Item key="multi-sender">
							<Link to="/multi-sender">
								<span>Multi-sender</span>
							</Link>
						</Menu.Item>
					</SubMenu>
					<Menu.Item key="docs">
						<a href='https://docs.wavesignal.finance' target={'_blank'}>
							<span>Docs</span>
						</a>
					</Menu.Item>

					<Menu.Item key="connect" className="connect-wallet menu-item-right">
						<ConnectWallet
							chainNetworkListMain={chainNetworkListMain}
							chainNetworkListTest={chainNetworkListTest}
							chainIdDetail={chainIdDetail}
						/>
					</Menu.Item>

					{ChainNetworkRender(chainId)}
					<Menu.Item key="create-token" className='menu-item-right'>
						<Dropdown overlay={menu} placement="bottomCenter" arrow>
							<Button htmlType='button' className='btn-top-create-white'><FontAwesomeIcon color='#fff' style={{ marginRight: '10px' }} icon={faPlus as IconProp} /> Create</Button>
						</Dropdown>
					</Menu.Item>
				</Menu>

				<div>
					<div className={`header-mobile header-bg`}>
						<div className="header-left">
							<Link to="/">
								<img src="../../../../images/logo-white.png" alt="logo" className='logo-mobile' />
							</Link>

						</div>
						<div className='header-center'>
							<div className='connect-wallet'>
								<ConnectWallet
									chainNetworkListMain={chainNetworkListMain}
									chainNetworkListTest={chainNetworkListTest}
									chainIdDetail={chainIdDetail}
								/>
							</div>
						</div>
						<div className="header-right">

							<Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
								<MenuUnfoldOutlined />
							</Button>
						</div>
					</div>
					<div className="menu-mobile">
						<Menu
							defaultSelectedKeys={['']}
							defaultOpenKeys={['']}
							mode="inline"
							theme="dark"
							className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
						>
							<Menu.Item key="11" className='item-menu-clo'>
								<Button type="primary" onClick={handleToggle} className="btn-menu-mobile-close">
									<CloseOutlined />
								</Button>
							</Menu.Item>
							<Menu.Item key="home" icon={<HomeFilled className='mobile-icon-menu' style={{ fontSize: '20px' }} />}>
								<Link to="/" onClick={handleHideMenuMobile}>
									<span>Home</span>
								</Link>
							</Menu.Item>

							<SubMenu key="launchpad" icon={<CodepenOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} />} title="Launchpad">
								<Menu.Item key="ido-launchpad" onClick={handleHideMenuMobile}>
									<Link to="/ido-launchpad">
										IDO Launchpad
									</Link>
								</Menu.Item>
								<Menu.Item key="ico-launchpad" onClick={handleHideMenuMobile}>
									<Link to="/ico-launchpad">
										Launchpad list
									</Link>
								</Menu.Item>
								<Menu.Item key="create-ido" onClick={handleHideMenuMobile}>
									<Link to="/ico/create">
										Create launchpad
									</Link>
								</Menu.Item>
								<Menu.Item key="token-create" onClick={handleHideMenuMobile}>
									<Link to="/token/create">
										Create Token
									</Link>
								</Menu.Item>

							</SubMenu>

							<SubMenu key="private-sale" icon={<DribbbleOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} />} title="Private Sale">
								<Menu.Item key="private-sale-list" onClick={handleHideMenuMobile}>
									<Link to="/private-sale">
										Private Sale List
									</Link>
								</Menu.Item>
								<Menu.Item key="private-sale-create" onClick={handleHideMenuMobile}>
									<Link to="/private-sale/create">
										Private Sale Create
									</Link>
								</Menu.Item>
							</SubMenu>

							<SubMenu key="staking" icon={<DotChartOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} />} title="Earn">
								<Menu.Item key="staking-list" onClick={handleHideMenuMobile}>
									<Link to="/staking">Staking</Link>
								</Menu.Item>
								<Menu.Item key="staking-fixed" onClick={handleHideMenuMobile}>
									<Link to="/fixed-staking">Fixed Staking</Link>
								</Menu.Item>
								<Menu.Item key="farm" onClick={handleHideMenuMobile}>
									<Link to="/farm">
										Farm
									</Link>
								</Menu.Item>
							</SubMenu>
							{/* <Menu.Item key="ino" icon={<AccountBookOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} onClick={handleHideMenuMobile} />}>
								<Link to="/ino">
									<span>INO Launchpad</span>
								</Link>
							</Menu.Item> */}
							<SubMenu key="services" icon={<DeploymentUnitOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} />} title="Services">
								<Menu.Item key="lock-list" onClick={handleHideMenuMobile}>
									<Link to="/wave-lock/token">
										WaveLock
									</Link>
								</Menu.Item>
								<Menu.Item key="mobile-airdrop-list" onClick={handleHideMenuMobile}>
									<Link to="/airdrop">
										Airdrop
									</Link>
								</Menu.Item>
								<Menu.Item key="mobile-anti-bot" onClick={handleHideMenuMobile}>
									<Link to="/launchpad/anti-bot">
										Anti-bot
									</Link>
								</Menu.Item>
								<Menu.Item key="mobile-multi-sender" onClick={handleHideMenuMobile}>
									<Link to="/multi-sender">
										<span>Multi-sender</span>
									</Link>
								</Menu.Item>
							</SubMenu>

							<Menu.Item key="docs" icon={<FileSearchOutlined className='mobile-icon-menu' style={{ fontSize: '20px' }} />} title="Docs" onClick={handleHideMenuMobile}>
								<a href='https://docs.wavesignal.finance' target={'_blank'}>
									<span>Docs</span>
								</a>
							</Menu.Item>


						</Menu>
					</div>
				</div>

			</div>

		</>

	);
};

export default HeaderTop;
