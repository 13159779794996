
import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Steps } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import PageBanner from './page-banner';
import { PlusOutlined } from '@ant-design/icons';
import './style.css';
import ProjectMenu from './menu-project';
import MyProject from './my-project';
import IcoProject from './ico-project';
import HowToJoinIdo from './how-to-join';
import FAQ from './faq';
import ApplyToLaunch from './apply-to-launch';
import IcoBanner from './ico-banner';
const { Content } = Layout;
const { Step } = Steps;
const Launchpad = () => {
    const location = useLocation();

    const ContentPage = (typePage) => {

        let content;
        if (typePage == '/launchpad/my-project') {
            content = <MyProject />;
        }
        else if (typePage == '/ico-launchpad') {
            content = <IcoProject />;
        }
        return content;
    }
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">


                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content mt-100">
                        <div className="project-block container">
                            <IcoBanner />
                            {location.pathname !== "/ido-launchpad" && (
                                <>
                                    <ProjectMenu />
                                </>
                            )}

                            <div className="project-content">
                                {ContentPage(location.pathname)}
                            </div>
                        </div>
                        <HowToJoinIdo />
                        <FAQ />
                        <ApplyToLaunch />
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default Launchpad
