
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
const { Content } = Layout;
const { Panel } = Collapse;
const FAQ = () => {
    return (
        <>
            <div className="faq ">
                <div className='container'>
                    <div className="faq-title">FAQs</div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                            <div className="faq-content">
                                <Collapse accordion className="faq-cus">
                                    <Panel header="What is an IDO?" key="1">
                                        <p>Initial dex offering, or IDO, is a new way of raising funds in the crypto industry. Typically, It is done by swapping tokens that represent various assets hosted on a decentralized exchange such as WaveSignal.</p>
                                    </Panel>
                                    <Panel header="How to join an IDO on WaveSignal?" key="2">
                                        <p>Firstly, you need to have allocation which are granted to users who stake Wavesignal (calculated based on tier system) or win the whitelist campaign. Secondly, go to IDO Launchpad page, choose the project you want to join, click "Join pool" to deposit fund within IDO period. Finally, claim IDO token to your wallet at TGE or refund. Please check our docs for more details on tier system and Refund regime.</p>
                                    </Panel>
                                    <Panel header="When do I receive my token?" key="3">
                                        <p>After TGE, "Claim" portal on WaveSignal will open for you to claim token to your wallet.</p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                            <div className="faq-content">
                                <Collapse accordion className="faq-cus">
                                    <Panel header="How much is the participation fee?" key="4">
                                        <p>We don't charge users for joining IDO (yessss)! Everyone can join an IDO pool without paying any fee to us.</p>
                                    </Panel>
                                    <Panel header="Which token should I buy?" key="5">
                                        <p>It's up to you to make the investment decision. Always DYOR first and never risk the money you can not afford to lose.</p>
                                    </Panel>
                                    <Panel header="How much can I invest?" key="5">
                                        <p>The total investment amount varies from project to project, depending on the amount raised and the allocation amount you have. Please check our docs for more details on tier system and Refund regime.</p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    )
}

export default FAQ
