
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Button, Form, Input, Spin, Space, Modal, InputNumber, Alert, Checkbox } from 'antd';
import {
    getTokenInformation
} from '../../../utils';

import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import pairAbi from '../../../../config/abi/pairAbi.json';

declare const window: Window & typeof globalThis & { ethereum: any };

const AntiBotVerifyToken = (props) => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const { next } = props;


    let storageAirdropTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    let airdropTokenInfo;
    if (storageAirdropTokenInfo) {
        airdropTokenInfo = JSON.parse(storageAirdropTokenInfo);
    }

    const fundAddress = process.env.REACT_APP_FUND_ADDRESS;
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');



    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [tokenAddress, setTokenAddress] = useState(airdropTokenInfo?.token_address);

    const [tokenSymbol, setTokenSymbol] = useState(airdropTokenInfo?.token_symbol);
    const [tokenName, setTokenName] = useState(airdropTokenInfo?.token_name);
    const [tokenDecimal, setTokenDecimal] = useState(airdropTokenInfo?.token_decimal);

    const [feeList, setFeeList] = useState<any>([]);

    const [errorCallSC, setErrorCallSC] = useState(false);
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [initTokenFee, setInitTokenFee] = useState(0);
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);


    useEffect(() => {

        if (!checkUpdate && chainId) {
            if (airdropTokenInfo) {
                let {
                    chainId: _id,
                    token_address: _address,
                    token_name: _name,
                    token_symbol: _symbol,
                    token_decimal: _decimal
                } = airdropTokenInfo;
                if (_id && _name && _symbol && _decimal && _address) {
                    form.setFieldsValue({
                        chainId: _id,
                        token_address: _address,
                        token_name: _name,
                        token_symbol: _symbol,
                        token_decimal: _decimal
                    });
                } else {
                    form.resetFields();
                    setTokenAddress('');
                    setTokenSymbol('');
                    setTokenName('');
                    setTokenDecimal('');
                }
            }

            setCheckUpdate(true);
        }
    }, [airdropTokenInfo, account, chainId]);



    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettings();
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => "INIT_TOKEN_FEE" === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                    }
                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => "INIT_LAUNCHPAD_FEE" === itemSetting.settingKey);

                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getSettings();
    }, []);



    const onChangeTokenAddress = async (values: any) => {
        setErrorCallSC(false);
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);
        try {
            const tokenInfo = await getTokenInformation(pairAbi, tokenAddressVal);
            if (tokenInfo.symbol && tokenInfo.name && tokenInfo.decimals) {

                setTokenSymbol(tokenInfo.symbol);
                setTokenName(tokenInfo.name);
                setTokenDecimal(tokenInfo.decimals);

                form.setFieldsValue({
                    chainId: chainId,
                    token_address: tokenAddressVal,
                    token_name: tokenInfo.name,
                    token_symbol: tokenInfo.symbol,
                    token_decimal: tokenInfo.decimals,
                });
                setLoadingTokenInfo(false);

            } else {
                setLoadingTokenInfo(false);
                setErrorCallSC(true);
            }
        } catch (e: any) {
            setLoadingTokenInfo(false);
            setErrorCallSC(true);
        }
    };


    const onConfirmAntiBotToken = (values: any) => {
        if (values.token_address) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('airdropTokenInfo', data);
            next(1)
        }
    }
    // useEffect(() => {
    //     form.setFieldsValue({
    //         chainId: chainId,
    //         token_address: tokenAddress,
    //         token_name: tokenName,
    //         token_symbol: tokenSymbol,
    //         token_decimal: tokenDecimal
    //     });
    // }, [tokenAddress]);
    return (
        <>
            <div className="sub-title-steps">Token Infomation</div>
            <div className="inputs-steps">
                <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onConfirmAntiBotToken}
                >
                    <Form.Item name="token_name" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="token_symbol" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="token_decimal" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="chainId" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item
                        label="Token Address"
                        name="token_address"
                        rules={[{ required: true, message: 'Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4' }]}
                        help=''
                    >
                        <Input disabled={account ? false : true} className="amount-buy" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4" onBlur={onChangeTokenAddress} />

                    </Form.Item>
                    {!errorCallSC ? (
                        loadingTokenInfo ? (
                            <div className="loading-info">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        ) : (
                            tokenAddress && tokenSymbol && tokenName && tokenDecimal && (
                                <div className="token-info-anti">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Name</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenName}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Symbol</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenSymbol}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Decimals</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenDecimal}</div>
                                        </Col>
                                    </Row>

                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24}>
                                            <Form.Item className="btn-content">
                                                <button className="btn-all" disabled={loading} type="submit">Next {loading && <Spin size="small" />}</button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        )

                    ) : (
                        <>
                            <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                        </>
                    )}

                </Form>

            </div>
            {loadingFull && (
                <LoadingFull />
            )}
        </>
    )
}

export default AntiBotVerifyToken
