import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Input, Space, Spin, Tabs, Pagination } from 'antd';
import { chainApi } from 'config/api/chainApi';
import { useActiveWeb3React } from 'hooks';
import NoneData from 'components/element/NoneData';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import './style.css';
import IcoItem from './ico-item';
import FilterChain from 'components/FilterChain';

const { TabPane } = Tabs;

const IcoProject = () => {

    const [dataLaunchpad, setDataLaunchpad] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React();
    const [activeTab, setActiveTab] = useState('all');

    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(0);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            tokenInfo: '',
            filterEnum: 'ALL',
            chainId: 0
        }
    );

    const getLaunchpadList = async (type, status, chainId) => {

        setLoadingData(true);
        let dataFilter: any = {
            ...param,
            filterEnum: type,
            chainId: param.chainId ? param.chainId : chainId ? chainId : 0
        };
        try {
            let res = await IdoLaunchpad.getLaunchpads(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let launchpadList: any = [];
                    if (status == 'all' || status == 'filled') {
                        setLoadingData(false);
                        setDataLaunchpad(res.data);

                    } else {
                        res.data.forEach((item, index) => {
                            let nowDate = new Date(Date.now());
                            let startDate = new Date(item.startTime);
                            let endDate = new Date(item.endTime);

                            if (status == 'upcoming') {
                                if (startDate > nowDate && endDate > nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'opening') {
                                if (startDate < nowDate && endDate > nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'closed') {
                                if (startDate < nowDate && endDate < nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }

                        });
                        setLoadingData(false);
                        setDataLaunchpad(launchpadList);
                        launchpadList.length > 0 ? setTotalRow(parseInt(res.headers['x-total-count'])) : setTotalRow(0);
                    }

                } else {
                    setLoadingData(false);
                    setDataLaunchpad([]);
                }


            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    };
    useEffect(() => {
        if (activeTab) {
            switch (activeTab) {
                case 'all':
                    getLaunchpadList('ALL', 'all', chainId);
                    break;
                case 'upcoming':
                    getLaunchpadList('ALL', 'upcoming', chainId);
                    break;
                case 'opening':
                    getLaunchpadList('ALL', 'opening', chainId);
                    break;
                case 'filled':
                    getLaunchpadList('FILLED', 'filled', chainId);
                    break;
                case 'closed':
                    getLaunchpadList('ALL', 'closed', chainId);
                    break;
                default:
                    break;
            }
        }

    }, [activeTab, param, chainId]);



    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 20, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                    if (isTestnet === true) {
                        setChainNetworkListTest(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList(false);
        getChainNetworkList(true);
    }, []);



    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const IcoContent = () => {

        return (
            <>
                <Row gutter={30}>
                    {!loadingData ? (
                        <>
                            {dataLaunchpad?.length > 0 ? (
                                dataLaunchpad.map((item, index) => {

                                    let logoNetwork: any = '';
                                    let title: any = '';
                                    let chain: any;
                                    chain = chainNetworkListMain.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    if (chain == undefined || chain.length == 0) {
                                        chain = chainNetworkListTest.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    }
                                    if (chain[0]) {
                                        logoNetwork = chain[0].logoLink;
                                        title = chain[0].name;
                                    }

                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'item' + index}>
                                            <IcoItem logoNetwork={logoNetwork} titleNetwork={title} item={item} />
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <NoneData text={'No Data'} link='/ico/create' />
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </Row>
            </>
        );
    };

    const onSearchLaunpad = (values: any) => {
        let search = values.target.value;
        setParam({
            ...param,
            tokenInfo: search
        });
    };

    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };
    return (
        <>
            <div className="projects-grid">
                <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="filter-block"
                    tabBarExtraContent={
                        <>
                            <div className='filter-block-form'>
                                <Form form={form} name="horizontal_login" layout="inline">

                                    <FilterChain
                                        key={122}
                                        active={chainId}
                                        handleChangeChain={(value) => {
                                            setParam({
                                                ...param,
                                                chainId: value
                                            });
                                        }}
                                    />

                                    <Form.Item
                                        name="search"
                                        rules={[{ required: true, message: 'Please input account address!' }]}
                                        className="mr-0 w-50-mobile pl-15-mobile"
                                    >
                                        <Input className="input-search" placeholder="Search by keyword" onChange={onSearchLaunpad} />
                                    </Form.Item>
                                </Form>
                            </div>
                        </>
                    }
                >
                    <TabPane tab="All" key="all">
                        <IcoContent />
                    </TabPane>
                    <TabPane tab="Upcoming" key="upcoming">
                        <IcoContent />
                    </TabPane>
                    <TabPane tab="Opening" key="opening">
                        <IcoContent />
                    </TabPane>
                    <TabPane tab="Filled" key="filled">
                        <IcoContent />
                    </TabPane>
                    <TabPane tab="Closed" key="closed">
                        <IcoContent />
                    </TabPane>
                </Tabs>

                {totalRow > 9 && (
                    <Pagination
                        current={param.page + 1}
                        defaultCurrent={1}
                        total={totalRow}
                        pageSize={param.size}
                        onChange={onChange_Pagi}
                        showSizeChanger={false}
                        className="pagiation-custom"
                    />
                )}
            </div>
        </>
    )
}

export default IcoProject
