import React, { useState, useEffect } from "react";
import axios from "axios";
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Button } from 'antd';
import './style.css';
import { Helmet } from "react-helmet"
import BannerSlide from "../banner-slide";

const { Content } = Layout;

const Banner = () => {

    return (
        <>
            <div className="home-banner">
                {/* <video loop autoPlay={true} muted>
                    <source src="../../images/main_video_baner.webm" type="video/mp4" />
                </video> */}
                <div className="banner-home-desktop">
                    <video id="video-container" loop autoPlay={true} muted>
                        <source src="../../images/video_banner/video_main web.webm" type="video/webm" />
                    </video>
                    <div className="banner-video-desktop">
                        <div className="banner-description-video">
                            <h3>Decentralized Launchpads <br></br> & Token Services for Projects</h3>
                            <h4>Bridging ideas, community and Web3</h4>
                        </div>
                        <div className="banner-title-video">
                            <div className="btn-shine">WAVE SIGNAL</div>
                        </div>
                    </div>
                </div>
                <div className="banner-home-mobile">
                    <img src="../images/video_banner/video_main web.gif" />
                    <div className="banner-video-mobile">
                        <div className="banner-description-video-mobile">
                            <h3>Decentralized Launchpads <br></br> & Token Services for Projects</h3>
                            <h4>Bridging ideas, community and Web3</h4>
                        </div>
                        <div className="banner-title-video-mobile">
                            <div className="btn-shine">WAVE SIGNAL</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="home-banner-info">
                <BannerSlide />
                <div className="container">
                    <div className="banner-growth">
                        <Row gutter={{ xs: 8, sm: 8, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                                <div className="growth-item">
                                    <img src="../images/icon-service/block-reward.png" />
                                    <div className="growth-percent">100%</div>
                                    <div className="growth-description">
                                        IDO Refund time up to 3 hours to protect your investment
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                                <div className="growth-item gr-mobile">
                                    <img src="../images/icon-service/proof-of-burn.png" />
                                    <div className="growth-percent">up to 20%</div>
                                    <div className="growth-description">
                                        Quarterly, Up to 20% of profits will be spent on #WaveSignal buy back and burn
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                                <div className="growth-item">
                                    <img src="../images/icon-service/proof-of-stake.png" />
                                    <div className="growth-percent">$0</div>
                                    <div className="growth-description">
                                        Total Value Locked
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                                <div className="growth-item gr-mobile">
                                    <img src="../images/icon-service/bitcoin-exchange.png" />
                                    <div className="growth-percent">$0</div>
                                    <div className="growth-description">
                                        Total Trading Volume
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="coin-market">

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={24}>
                                <Helmet>
                                    <script src="https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js"></script>
                                    <script>
                                        {`
                                            $(function () {
                                                let price = "<coingecko-coin-price-marquee-widget coin-ids='bitcoin,ethereum,binancecoin,ripple,litecoin' currency='usd' background-color='#fff' font-color='#2f70f4' locale='en'></coingecko - coin - price - marquee - widget>"
                                                $('#token-price-lits').html(price);
                                            })
                                        `}
                                    </script>
                                </Helmet>
                                <div id="token-price-lits"></div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner


