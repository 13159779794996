
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Card, Button, Select, Pagination, Table, Upload, Space, Spin } from 'antd';
import type { UploadProps } from 'antd/es/upload/interface';
import { EyeOutlined, PlusCircleFilled, LeftCircleOutlined, DeleteFilled, UploadOutlined, InboxOutlined, CopyOutlined } from '@ant-design/icons';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import './style.css';
import { useWrap } from 'context/WrapperContext';
import CopyToClipboard from 'react-copy-to-clipboard';

const { Content } = Layout;
const { Dragger } = Upload;

const UploadImage = () => {

    const { showNoti } = useWrap();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState<any>();


    const props: UploadProps = {
        multiple: false,
        beforeUpload: file => {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            fetch(
                `${process.env.REACT_APP_WAVE_API}upload-to-s3`,
                {
                    method: 'POST',
                    body: formData,
                    headers: {
                        // Authorization: `Bearer ${tokenId}`
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result) {
                        setImage(result);
                        showNoti('success', 'Upload Successfully');
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    showNoti('error', 'Upload Failed');
                });
            return;
        }
    };
    const onCopy = () => {
        showNoti('success', 'Copied');
    };

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-create container">
                            <div className="back-history">
                                <Link to={`/ido`}><LeftCircleOutlined /> Cancel upload image</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title upload-title">Upload Image</div>
                                <div className="upload-block">
                                    <>
                                        <Dragger {...props}
                                            accept=".png,.jpg,.jpeg,.svg, gif"
                                            showUploadList={false}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single upload. Strictly prohibit from uploading company data or other band files
                                            </p>
                                            <Button className='mt-15' style={{ marginRight: '10px' }} type="dashed" danger htmlType='button' icon={<UploadOutlined />}>Upload Image</Button>
                                        </Dragger>
                                        {loading ? (
                                            <>
                                                <div className="loading-center">
                                                    <Space size="middle"><Spin size="large" /></Space>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {image && (
                                                    <Card title="Image Info" className='mt-15' style={{ width: '100%' }}>
                                                        <div className='image-upload-review'>
                                                            <p>Image link: </p>
                                                            <p>
                                                                <a target="_blank" href={`${image?.urlPrefix}/${image?.name}`}>{`${image?.urlPrefix}/${image?.name}`}</a>
                                                                <CopyToClipboard onCopy={onCopy} text={`${image?.urlPrefix}/${image?.name}`}>
                                                                    <CopyOutlined className='upload-icon-copy' />
                                                                </CopyToClipboard>
                                                            </p>
                                                            <p>Image preview: </p>
                                                            <p><img src={`${image?.urlPrefix}/${image?.name}`} /></p>
                                                        </div>
                                                    </Card>
                                                )}
                                            </>
                                        )}


                                    </>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default UploadImage
