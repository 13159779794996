
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
const { Content } = Layout;

const ApplyToLaunch = () => {
    return (
        <>
            <div className="apply-launch">
                <div className="apply-launch-content">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" xs={24} xl={13} md={13} sm={13}>
                            <div className="apply-content">
                                <div className="investor-name">
                                    Want to launch your project on WaveSignal IDO Launchpad?
                                </div>
                                <div className="investor-des">
                                    Apply, launch and introduce your project to our most rocket-growing and vibrant community across multiple chains.
                                </div>
                                <div className="investor-tradenow">
                                    <a className="link-button" target="_blank" href="https://docs.google.com/forms/d/1_82HuQLqK9BSd_7jnu3hiQk8BvbkXffgVFI9KhEYQRg/edit">Apply to Launch</a>
                                </div>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} xl={11} md={11} sm={11}>
                            <div className="faq-content">
                                <div className="apply-img">
                                    <img src="../../images/sky-2.png" />
                                    <img src="../../images/sky-1.png" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ApplyToLaunch
