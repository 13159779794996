
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
    Layout, Row, Col, Button, Form, Input, Steps, Spin, Space, Modal, InputNumber, Alert, Radio, Checkbox, Select
} from 'antd';
import {
    createToken,
    getSymbolToken,
    getTotalSupply,
    getTokenName,
    getTokenDecimal,
    approveToken,
    getTokenAllowance,
    createStandardToken,
    createAntiBotStandardToken,
    createLPGeneratorToken,
    createAntiBotLPGeneratorToken
} from '../../../../utils';
import standardTokenAbi from 'config/abi/standardTokenAbi.json';
import antiBotStandardTokenAbi from 'config/abi/antiBotStandardTokenAbi.json';
import tokenInfoAbi from 'config/abi/tokenInfo.json';
import {
    ANTI_BOT_LP_GENERATOR_TOKEN_BYTECODE,
    ANTI_BOT_TOKEN_BYTECODE,
    LP_GENERATOR_TOKEN_BYTECODE,
    STANDARD_TOKEN_BYTECODE
} from 'components/bytecodes/bytecodes';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useTokenInfoContract } from '../../../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import lpGeneratorTokenAbi from '../../../../../config/abi/lpGeneratorTokenAbi.json';
import antiBotLpGeneratorTokenAbi from '../../../../../config/abi/antiBotLpGeneratorTokenAbi.json';
import { dexExchanges } from 'config/network/dex';
import {
    INIT_LAUNCHPAD_FEE,
    INIT_LIQUIDITY_TOKEN_FEE_WALLET,
    INIT_TOKEN_FEE,
    INIT_TOKEN_FEE_WALLET,
    LAUNCHPAD_AMOUNT_RAISED_FEE,
    LIST_LAUNCHPAD_RAISED_FEE_ONLY,
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE,
    LIST_LAUNCHPAD_RAISED_FEE,
    LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1,
    LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2
} from 'components/keyConfig';
import { currencies, getCurrencyAddress, getCurrencyDecimals } from 'config/network/config';


declare const window: Window & typeof globalThis & { ethereum: any };
const { Option } = Select;

const VerifyToken = (props) => {
    const { showNoti, tokenID } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const { form } = props;
    let storageTokenInfo = window.localStorage.getItem('tokenInfo');
    let storageTokenApproval = window.localStorage.getItem('tokenApproval');

    let tokenInfo;
    if (storageTokenInfo) {
        tokenInfo = JSON.parse(storageTokenInfo);
    }
    let tokenApproval;
    if (storageTokenApproval) {
        tokenApproval = JSON.parse(storageTokenApproval);
    }

    const fundAddress = process.env.REACT_APP_FUND_ADDRESS;
    const fundAmount = process.env.REACT_APP_FUND_AMOUNT;
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const deployLaunchpadAddress = getInformationByChain(chainId, 'REACT_APP_DEPLOY_LAUNCHPAD');
    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [tokenAddress, setTokenAddress] = useState(tokenInfo ? tokenInfo.token_address : '');
    const [tokenType, setTokenType] = useState('standard');
    const [cexList, setCexList] = useState<any>([]);
    const [baseCurrencies, setBaseCurrencies] = useState<any>([]);
    const [chainNetworkList, setChainNetworkList] = useState<any>([]);

    let feeOptionDefault = JSON.stringify([INIT_LAUNCHPAD_FEE, LIST_LAUNCHPAD_RAISED_FEE_ONLY]);
    let listingOptionDefault = 0;

    let activeCurrencyPay = '';

    if (tokenInfo && tokenInfo.fee_options) {
        feeOptionDefault = tokenInfo.fee_options;
    }
    if (tokenInfo && tokenInfo.listing_option) {
        listingOptionDefault = tokenInfo.listing_option;
    }

    let networkChain = 'BSC';
    if (chainNetworkList && chainId) {
        let currentChain = chainNetworkList.filter((itemChain) => chainId === parseInt(itemChain.chainId));
        if (currentChain.length > 0) {
            networkChain = currentChain[0].symbol;
        }
    }

    const [feeList, setFeeList] = useState<any>([]);

    const [currencyPay, setCurrencyPay] = useState(activeCurrencyPay);
    const [feeOptions, setFeeOptions] = useState(feeOptionDefault);
    const [listingOptions, setListingOptions] = useState(listingOptionDefault);
    const [errorCallSC, setErrorCallSC] = useState(false);
    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState<boolean>(false);

    //INIT_LAUNCHPAD_FEE
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);

    const [launchpadRaisedFeeOnly, setLaunchpadRaisedFeeOnly] = useState<any>([]);

    // LIST_LAUNCHPAD_TOKEN_RAISED_FEE
    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>([]);

    //LAUNCHPAD_RAISED_FEE
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>([]);

    //TOKEN_W86_FEE_OPTION_1
    const [tokeW86FeeOption1, setTokeW86FeeOption1] = useState<any>([]);

    //TOKEN_W86_FEE_OPTION_2
    const [tokeW86FeeOption2, setTokeW86FeeOption2] = useState<any>([]);

    const [createTokenForm] = Form.useForm();
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [tokenApprove, setTokenApprove] = useState(0);
    const [amountRaisedFee, setAmountRaisedFee] = useState<any>([]);
    const [initTokenFee, setInitTokenFee] = useState(0);
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [standardFeeWallet, setStandardFeeWallet] = useState<any>("");
    const [lpFeeWallet, setLpFeeWallet] = useState<any>("");



    const getChainNetworkList = async () => {
        const dataFilter = { page: 0, size: 10, sort: 'id,desc', vm: 'EVM' };
        try {
            let res = await IdoLaunchpad.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setChainNetworkList(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };
    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => INIT_TOKEN_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                    }

                    let standardWallet = res.data.filter((itemSetting) => INIT_TOKEN_FEE_WALLET === itemSetting.settingKey);
                    if (standardWallet.length > 0) {
                        setStandardFeeWallet(standardWallet[0].settingValue)
                    }

                    let lpWallet = res.data.filter((itemSetting) => INIT_LIQUIDITY_TOKEN_FEE_WALLET === itemSetting.settingKey);
                    if (lpWallet.length > 0) {
                        setLpFeeWallet(lpWallet[0].settingValue)
                    }

                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => INIT_LAUNCHPAD_FEE === itemSetting.settingKey);

                    let launchpadRaisedFeeOnlyObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE_ONLY === itemSetting.settingKey);

                    let launchpadTokenRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);

                    let launchpadRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE === itemSetting.settingKey);

                    let tokeW86FeeOption1Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1 === itemSetting.settingKey);

                    let tokeW86FeeOption2Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2 === itemSetting.settingKey);

                    let amountRaised = res.data.filter((itemSetting) => LAUNCHPAD_AMOUNT_RAISED_FEE === itemSetting.settingKey);
                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }
                    if (launchpadRaisedFeeOnlyObj && launchpadRaisedFeeOnlyObj[0]) {
                        setLaunchpadRaisedFeeOnly(launchpadRaisedFeeOnlyObj[0]);
                    }
                    if (launchpadTokenRaisedFeeObj && launchpadTokenRaisedFeeObj[0]) {
                        setLaunchpadTokenRaisedFee(launchpadTokenRaisedFeeObj[0]);
                    }
                    if (launchpadRaisedFeeObj && launchpadRaisedFeeObj[0]) {
                        setLaunchpadRaisedFee(launchpadRaisedFeeObj[0]);
                    }
                    if (tokeW86FeeOption1Obj && tokeW86FeeOption1Obj[0]) {
                        setTokeW86FeeOption1(tokeW86FeeOption1Obj[0]);
                    }
                    if (tokeW86FeeOption2Obj && tokeW86FeeOption2Obj[0]) {
                        setTokeW86FeeOption2(tokeW86FeeOption2Obj[0]);
                    }
                    if (amountRaised[0]) {
                        setAmountRaisedFee(amountRaised[0]);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };


    useEffect(() => {
        getChainNetworkList();
        if (chainId) {
            getSettings();
        }
    }, [account, chainId]);


    useEffect(() => {
        if (chainId) {
            const dexList = dexExchanges[chainId];
            setCexList(dexList)
            setBaseCurrencies(Object.keys(currencies[chainId]))
            if (tokenInfo && tokenInfo.currency_pay) {
                if (Object.keys(currencies[chainId][tokenInfo.currency_pay])) {
                    activeCurrencyPay = tokenInfo.currency_pay;
                } else {
                    activeCurrencyPay = Object.keys(currencies[chainId])[0];
                }
            } else {
                activeCurrencyPay = Object.keys(currencies[chainId])[0];
            }

            form.setFieldsValue({
                currency_pay: activeCurrencyPay,
                currency_pay_address: getCurrencyAddress(chainId, activeCurrencyPay),
                currency_pay_decimals: getCurrencyDecimals(chainId, activeCurrencyPay)
            })

        } else {
            setCexList([])
        }
    }, [chainId])

    useEffect(() => {

        if (!checkUpdate && chainId) {
            if ((tokenInfo && tokenInfo.chainId == chainId) || tokenApproval) {
                form.setFieldsValue({
                    init_launchpad_fee: createPoolFee?.settingValue,
                    chainId: chainId,
                    token_network_chain: networkChain,
                    token_address: tokenInfo ? tokenInfo.token_address : tokenApproval?.token_address,
                    token_name: tokenInfo ? tokenInfo.token_name : tokenApproval?.token_name,
                    token_symbol: tokenInfo ? tokenInfo.token_symbol : tokenApproval?.token_symbol,
                    token_decimal: tokenInfo ? tokenInfo.token_decimal : tokenApproval?.token_decimal,
                    total_supply: tokenInfo ? tokenInfo.total_supply : tokenApproval?.total_supply,
                    currency_pay: tokenInfo ? tokenInfo.currency_pay : tokenApproval?.currency_pay || activeCurrencyPay,
                    currency_pay_address: tokenInfo ? tokenInfo.currency_pay_address : tokenApproval?.currency_pay_address || getCurrencyAddress(chainId, activeCurrencyPay),
                    currency_pay_decimals: tokenInfo ? tokenInfo.currency_pay_decimals : tokenApproval?.currency_pay_decimals || getCurrencyDecimals(chainId, activeCurrencyPay),
                    fee_options: tokenInfo ? tokenInfo.fee_options : tokenApproval?.fee_options || feeOptions,
                    listing_option: tokenInfo ? tokenInfo.listing_option : tokenApproval?.listing_option || listingOptions
                })
            } else {
                form.setFieldsValue({
                    init_launchpad_fee: createPoolFee?.settingValue,
                    chainId: chainId,
                    token_network_chain: networkChain,
                    token_address: '',
                    token_name: '',
                    token_symbol: '',
                    token_decimal: 18,
                    total_supply: 0,
                    currency_pay: activeCurrencyPay,
                    currency_pay_address: getCurrencyAddress(chainId, activeCurrencyPay),
                    currency_pay_decimals: getCurrencyDecimals(chainId, activeCurrencyPay),
                    fee_options: feeOptions,
                    listing_option: listingOptions
                });
            }

            setCheckUpdate(true);
        }
    }, [tokenInfo, account, chainId]);

    const onChangeTokenType = (value: string) => {
        setTokenType(value);
    };

    const onChangeTokenAddress = async (values: any) => {
        setErrorCallSC(false);
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);
        try {
            let allowance = await getTokenAllowance(tokenInfoAbi, tokenAddressVal, account, deployLaunchpadAddress)
            let [
                token_symbol,
                total_supply,
                token_name,
                token_decimal] = await Promise.all([

                    getSymbolToken(tokenInfoAbi, tokenAddressVal),
                    getTotalSupply(tokenInfoAbi, tokenAddressVal),
                    getTokenName(tokenInfoAbi, tokenAddressVal),
                    getTokenDecimal(tokenInfoAbi, tokenAddressVal)
                ]);

            setTokenApprove(parseInt(allowance));

            if (token_symbol && total_supply && token_name && token_decimal) {
                let tokenInfoNew = {
                    token_approval: parseInt(allowance) > 0 ? true : false,
                    token_address: tokenAddressVal,
                    token_name: token_name,
                    token_symbol: token_symbol,
                    token_decimal: token_decimal,
                    total_supply: parseFloat(ethers.utils.formatUnits(total_supply.toString(), token_decimal)),
                    currency_pay: form.getFieldValue('currency_pay'),
                    currency_pay_address: getCurrencyAddress(form.getFieldValue('currency_pay')),
                    currency_pay_decimals: getCurrencyDecimals(form.getFieldValue('currency_pay')),
                };
                window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoNew));
                window.location.reload();

                window.localStorage.removeItem('tokenInfo');
                window.localStorage.removeItem('launchpadInfo');
                window.localStorage.removeItem('additionalInfo');
                setLoadingTokenInfo(false);

            } else {
                setTokenAddress(tokenAddressVal);
                window.localStorage.removeItem('tokenApproval');
                window.localStorage.removeItem('tokenInfo');
                window.localStorage.removeItem('launchpadInfo');
                window.localStorage.removeItem('additionalInfo');
                setLoadingTokenInfo(false);
                setErrorCallSC(true);
            }
        } catch (e: any) {
            console.log(e);
            setTokenAddress(tokenAddressVal);
            window.localStorage.removeItem('tokenApproval');
            window.localStorage.removeItem('tokenInfo');
            window.localStorage.removeItem('launchpadInfo');
            window.localStorage.removeItem('additionalInfo');
            setLoadingTokenInfo(false);
            setErrorCallSC(true);
        }
    };


    const createStandardTokenForm = async (values: any) => {
        try {
            setLoading(true);
            setLoadingTokenInfo(true);
            await createStandardToken(standardTokenAbi,
                `0x${STANDARD_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString()
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;
                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create Standard Token Successfully');
                                            closeBox()

                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            let tokenInfoApproval = {
                                                token_approval: false,
                                                token_address: res.contractAddress,
                                                token_name: values.name,
                                                token_symbol: values.symbol,
                                                token_decimal: parseInt(values.decimals),
                                                total_supply: values.total_supply,
                                            };

                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoApproval));
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('warning', 'Create Standard Token Failed');
                                    }
                                    setLoading(false);
                                    setLoadingTokenInfo(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    setIsCreateTokenVisible(false);
                    setLoadingTokenInfo(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            setIsCreateTokenVisible(false);
            setLoadingTokenInfo(false);
            console.log(e);
            showNoti('error', 'Error!');
        }
    };

    const createAntiStandardTokenForm = async (values: any) => {
        try {
            setLoading(true);
            setLoadingTokenInfo(true);
            await createAntiBotStandardToken(antiBotStandardTokenAbi,
                `0x${ANTI_BOT_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
                antiBotAddress
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create Standard Token Successfully');
                                            closeBox()

                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            let tokenInfoApproval = {
                                                token_approval: false,
                                                token_address: res.contractAddress,
                                                token_name: values.name,
                                                token_symbol: values.symbol,
                                                token_decimal: parseInt(values.decimals),
                                                total_supply: values.total_supply,
                                            };

                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoApproval));
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('warning', 'Create Standard Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const createLPGeneratorTokenForm = async (values: any) => {
        try {

            if (parseFloat(values.tax_fee_percent) + parseFloat(values.liquidity_fee_percent) + parseFloat(values.charity_percent) > 25) {
                showNoti('warning', 'Total Fee Must Be Less Than 25%');
                setLoading(false);
                setLoadingTokenInfo(false);
                return;
            }
            setLoading(true);
            setLoadingTokenInfo(true);
            await createLPGeneratorToken(lpGeneratorTokenAbi,
                `0x${LP_GENERATOR_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                ethers.utils.parseUnits(`${values.total_supply}`, 9),
                values.router_address,
                values.charity_address || ethers.constants.AddressZero,
                parseFloat(values.tax_fee_percent || 0) * 100,
                parseFloat(values.liquidity_fee_percent || 0) * 100,
                parseFloat(values.charity_percent || 0) * 100,
                lpFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;
                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create LP Token Successfully');
                                            closeBox()

                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: 9,
                                                supply: values.total_supply
                                            };
                                            let tokenInfoApproval = {
                                                token_approval: false,
                                                token_address: res.contractAddress,
                                                token_name: values.name,
                                                token_symbol: values.symbol,
                                                token_decimal: 9,
                                                total_supply: values.total_supply,
                                            };

                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoApproval));
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('warning', 'Create LP Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const createAntiBotLPGeneratorTokenForm = async (values: any) => {
        try {
            if (parseFloat(values.tax_fee_percent) + parseFloat(values.liquidity_fee_percent) + parseFloat(values.charity_percent) > 25) {
                showNoti('warning', 'Total Fee Must Be Less Than 25%');
                setLoading(false);
                setLoadingTokenInfo(false);
                return;
            }
            setLoading(true);
            setLoadingTokenInfo(true);
            await createAntiBotLPGeneratorToken(antiBotLpGeneratorTokenAbi,
                `0x${ANTI_BOT_LP_GENERATOR_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                ethers.utils.parseUnits(`${values.total_supply}`, 9),
                antiBotAddress,
                values.router_address,
                values.charity_address || ethers.constants.AddressZero,
                parseFloat(values.tax_fee_percent || 0) * 100,
                parseFloat(values.liquidity_fee_percent || 0) * 100,
                parseFloat(values.charity_percent || 0) * 100,
                lpFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString()
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create LP Token Successfully');
                                            closeBox()

                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: 9,
                                                supply: values.total_supply
                                            };
                                            let tokenInfoApproval = {
                                                token_approval: false,
                                                token_address: res.contractAddress,
                                                token_name: values.name,
                                                token_symbol: values.symbol,
                                                token_decimal: 9,
                                                total_supply: values.total_supply,
                                            };

                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoApproval));
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('warning', 'Create LP Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const onSubmitCreateTokenForm = async (values: any) => {
        switch (values.token_type) {
            case 'standard':
                if (values.implement_anti_bot) {
                    return createAntiStandardTokenForm(values);
                } else {
                    return createStandardTokenForm(values);
                }
            case 'liquidity':
                if (values.implement_anti_bot) {
                    return createAntiBotLPGeneratorTokenForm(values);
                } else {
                    return createLPGeneratorTokenForm(values);
                }

            default:
                break;
        }
    };


    const showCreateTokenModal = () => {
        createTokenForm.resetFields()
        setIsCreateTokenVisible(true);
    }

    const closeBox = () => {
        setIsCreateTokenVisible(false);
    };

    const onChangeCurrencyPay = e => {
        setCurrencyPay(e.target.value);
        let address = getCurrencyAddress(chainId, e.target.value)
        let decimals = getCurrencyDecimals(chainId, e.target.value)
        form.setFieldsValue({
            currency_pay_address: address,
            currency_pay_decimals: decimals
        })
    };
    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };
    const onChangeListing = e => {
        setListingOptions(e.target.value);
    };

    const handleApproveToken = async () => {
        let tokenAddressVal = form.getFieldValue('token_address');
        try {
            if (!tokenAddressVal) {
                return;
            }
            // const tokenContract  = useTokenInfoContract(tokenInfo?.token_address);
            setLoadingFull(true);
            await approveToken(tokenInfoAbi, tokenAddressVal, library, account, deployLaunchpadAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve Successfully`);
                                        countNoti++;
                                        setTokenApprove(Math.pow(2, 256));
                                        if (tokenApproval) {
                                            let tokenInfoNew = {
                                                token_approval: true,
                                                currency_pay: form.getFieldValue('currency_pay'),
                                                currency_pay_address: getCurrencyAddress(chainId, form.getFieldValue('currency_pay')),
                                                currency_pay_decimals: getCurrencyDecimals(chainId, form.getFieldValue('currency_pay')),
                                                fee_options: feeOptions,
                                                listing_option: listingOptions,
                                                token_address: tokenApproval?.token_address,
                                                token_name: tokenApproval?.token_name,
                                                token_symbol: tokenApproval?.token_symbol,
                                                token_decimal: tokenApproval?.token_decimal,
                                                total_supply: tokenApproval?.total_supply,
                                            };
                                            window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoNew));
                                            window.location.reload();
                                        }
                                    } else {
                                        showNoti('error', 'Approve Failed');
                                    }
                                    setLoadingFull(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingFull(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            setLoadingFull(false);
            console.log(error);
        }
    };
    let createPoolFeeDisOP1: any = 0;
    let launchpadRaisedFeeOnlyDisOP1: any = 0;
    if (createPoolFee && launchpadRaisedFeeOnly && tokeW86FeeOption1) {
        createPoolFeeDisOP1 = createPoolFee.settingValue - (createPoolFee.settingValue * (tokeW86FeeOption1.settingValue / 100));
        launchpadRaisedFeeOnlyDisOP1 = launchpadRaisedFeeOnly.settingValue - (launchpadRaisedFeeOnly.settingValue * (tokeW86FeeOption1.settingValue / 100));
    }
    let createPoolFeeOP2: any = 0;
    let launchpadTokenRaisedFeeOP2: any = 0;
    let launchpadRaisedFeeOP2: any = 0;
    if (createPoolFee && launchpadTokenRaisedFee && launchpadRaisedFee && tokeW86FeeOption2) {
        createPoolFeeOP2 = createPoolFee.settingValue - (createPoolFee.settingValue * (tokeW86FeeOption2.settingValue / 100));
        launchpadTokenRaisedFeeOP2 = launchpadTokenRaisedFee.settingValue - (launchpadTokenRaisedFee.settingValue * (tokeW86FeeOption2.settingValue / 100));
        launchpadRaisedFeeOP2 = launchpadRaisedFee.settingValue - (launchpadRaisedFee.settingValue * (tokeW86FeeOption2.settingValue / 100));
    }



    return (
        <>
            <div className="title-steps">
                VerifyToken
            </div>

            <hr className="hr-cus-cre"></hr>
            <div className="sub-title-steps">
                Token Information
                <button type="button" className="btn-create-token" disabled={networkChain.length > 0 && account ? false : true} onClick={showCreateTokenModal}>Create Token</button>
            </div>
            <div className="inputs-steps">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item name="step_verify_token" initialValue={1} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="init_launchpad_fee" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="chainId" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="token_network_chain" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="token_name" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="token_symbol" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="token_decimal" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="total_supply" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="currency_pay_decimals" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="currency_pay_address" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Token Address"
                            name="token_address"
                            rules={[{ required: true, message: 'Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4' }]}
                            help={<><small className="warning-form blue-text">Create token fee: {initTokenFee} {createPoolFee?.payToken}</small></>}
                        >
                            <Input disabled={account ? false : true} className="amount-buy" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4" onBlur={onChangeTokenAddress} />

                        </Form.Item>
                        {!errorCallSC ? (
                            loadingTokenInfo ? (
                                <div className="loading-info">
                                    <Space size="middle">
                                        <Spin size="large" />
                                    </Space>
                                </div>
                            ) : (
                                tokenApproval?.token_address && tokenApproval?.token_symbol && tokenApproval?.token_decimal && (
                                    <>

                                        <div className="bg-token-info">
                                            <div className="detail-text">
                                                <div className="left">Name</div>
                                                <div className="right">{tokenApproval?.token_name}</div>
                                            </div>
                                            <div className="detail-text">
                                                <div className="left">Symbol</div>
                                                <div className="right">{tokenApproval?.token_symbol}</div>
                                            </div>
                                            <div className="detail-text mb-15">
                                                <div className="left">Decimals</div>
                                                <div className="right">{tokenApproval?.token_decimal}</div>
                                            </div>
                                        </div>

                                    </>
                                )
                            )

                        ) : (
                            <>
                                <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                            </>
                        )}
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="currency_pay"
                            label="Currency Pay"
                            initialValue={activeCurrencyPay}
                            rules={[{ required: true, message: 'Please pick Currency Pay!' }]}
                        >

                            <Radio.Group style={{ width: '80%' }} onChange={onChangeCurrencyPay}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {baseCurrencies.map((item, index) => (
                                        <Col key={'currencies' + index} className='gutter-row' xs={24} xl={4} md={4} sm={4}>
                                            <Radio value={item}>{item}</Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="fee_options"
                            label="Fee Options"
                            initialValue={feeOptions}
                            rules={[{ required: true, message: 'Please pick fee options!' }]}
                        >
                            <Radio.Group onChange={onChangeFee}>
                                <Space direction="vertical">
                                    {createPoolFee && launchpadRaisedFeeOnly && (
                                        <Radio value={JSON.stringify([INIT_LAUNCHPAD_FEE, LIST_LAUNCHPAD_RAISED_FEE_ONLY])} key={'fee-1'}>
                                            {createPoolFee.settingValue} {createPoolFee.feeType == "PERCENT" ? '%' : createPoolFee.payToken} +
                                            {launchpadRaisedFeeOnly.settingValue}{launchpadRaisedFeeOnly.feeType == "PERCENT" ? '%' : ''} {form.getFieldValue('currency_pay')} Sold
                                        </Radio>
                                    )}
                                    {createPoolFee && launchpadTokenRaisedFee && launchpadRaisedFee && (
                                        <Radio value={JSON.stringify([INIT_LAUNCHPAD_FEE, LIST_LAUNCHPAD_TOKEN_RAISED_FEE, LIST_LAUNCHPAD_RAISED_FEE])} key={'fee-2'}>
                                            {createPoolFee.settingValue} {createPoolFee.feeType == "PERCENT" ? '%' : createPoolFee.payToken} +
                                            {launchpadTokenRaisedFee.settingValue}{launchpadTokenRaisedFee.feeType == "PERCENT" ? '%' : ''} of Tokens Sold +
                                            {launchpadRaisedFee.settingValue}{launchpadRaisedFee.feeType == "PERCENT" ? '%' : ''} of {form.getFieldValue('currency_pay')} Sold
                                        </Radio>
                                    )}

                                    {/* option 1 */}
                                    {createPoolFee && launchpadRaisedFeeOnly && tokeW86FeeOption1 && createPoolFeeDisOP1 > 0 && launchpadRaisedFeeOnlyDisOP1 > 0 && (
                                        <>
                                            <Radio disabled={true} value={JSON.stringify([INIT_LAUNCHPAD_FEE, LIST_LAUNCHPAD_RAISED_FEE_ONLY, LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1])} key={'fee-3'}>
                                                Amount of W86 equivalent to {createPoolFeeDisOP1} {createPoolFee.feeType == "PERCENT" ? '%' : createPoolFee.payToken} +
                                                {launchpadRaisedFeeOnlyDisOP1}{launchpadRaisedFeeOnly.feeType == "PERCENT" ? '%' : ''} {form.getFieldValue('currency_pay')} Sold <span className='text-danger'>({tokeW86FeeOption1.settingValue}% discount of option 1) </span> <small className='text-info'>(Coming soon)</small>
                                            </Radio>
                                        </>
                                    )}

                                    {/* option 1 */}
                                    {createPoolFee && launchpadTokenRaisedFee && launchpadRaisedFee && tokeW86FeeOption2 && createPoolFeeOP2 > 0 && launchpadTokenRaisedFeeOP2 > 0 && launchpadRaisedFeeOP2 > 0 && (
                                        <>
                                            <Radio disabled={true} value={JSON.stringify([INIT_LAUNCHPAD_FEE, LIST_LAUNCHPAD_TOKEN_RAISED_FEE, LIST_LAUNCHPAD_RAISED_FEE, LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2])} key={'fee-4'}>
                                                Amount of W86 equivalent to {createPoolFeeOP2} {createPoolFee.feeType == "PERCENT" ? '%' : createPoolFee.payToken} +
                                                {launchpadTokenRaisedFeeOP2}{launchpadTokenRaisedFee.feeType == "PERCENT" ? '%' : ''} of Tokens Sold +
                                                {launchpadRaisedFeeOP2}{launchpadRaisedFee.feeType == "PERCENT" ? '%' : ''} of {form.getFieldValue('currency_pay')} Sold <span className='text-danger'>({tokeW86FeeOption2.settingValue}% discount of option 2) </span> <small className='text-info'>(Coming soon)</small>
                                            </Radio>
                                        </>
                                    )}

                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="listing_option"
                            label="Listing Options"
                            rules={[{ required: true, message: 'Please pick listing options!' }]}
                        >
                            <Radio.Group onChange={onChangeListing}>
                                <Space direction="vertical">
                                    <Radio value={0} key={'listing-1'}>Auto Listing</Radio>
                                    <Radio value={1} key={'listing-2'}>Manual listing</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        {listingOptions == 0 ? (
                            <Alert className="mb-15" message="For auto listing, after you finalize the pool your token will be auto listed on DEX." showIcon type="warning" />
                        ) : (
                            <>
                                <Alert className="mb-15" message={`For manual listing, WaveSignal won't charge tokens for liquidity.
                            You may withdraw ${currencyPay ? currencyPay : baseCurrencies[0]} after the pool ends then do DEX listing yourself.`} showIcon type="warning" />
                            </>
                        )}
                        <Alert className="mb-15" message="Make sure the token has 'Exclude transfer fee' function if it has transfer fees." showIcon type="warning" />
                    </Col>
                </Row>

                {tokenApproval?.token_address && tokenApproval?.token_symbol && tokenApproval?.token_decimal && (
                    <>
                        {!tokenApproval?.token_approval && (
                            <div className="steps-action">
                                <Button htmlType="button" onClick={handleApproveToken} className="btn-all">Click to Approval</Button>
                            </div>
                        )}
                    </>
                )}

            </div>

            <Modal
                title={<div className="text-md custom-modal-title">Create Token</div>}
                visible={isCreateTokenVisible}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={createTokenForm}
                        name="basic"
                        initialValues={{ price: 0 }}
                        autoComplete="off"
                        onFinish={onSubmitCreateTokenForm}
                        layout="vertical"
                    >
                        <Form.Item name="token_type" label="Token type" initialValue={tokenType}
                            rules={[{ required: true, message: 'Please input round type!' }]}
                            help={<><small className="warning-form blue-text">Create fee: {initTokenFee} {createPoolFee?.payToken}</small></>}
                        >
                            <Select className="select-create-ido" onChange={onChangeTokenType}>
                                {/*<Option value="">Select token type</Option>*/}
                                <Option value="standard">Standard Token</Option>
                                <Option value="liquidity">Liquidity Generator Token</Option>
                                {/*<Option value="baby">Baby Token</Option>*/}
                                {/*<Option value="buyback">Buyback Baby Token</Option>*/}
                            </Select>
                        </Form.Item>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input token name!' }]}>
                            <Input className="amount-buy" placeholder="Ex: Ethereum" />
                        </Form.Item>
                        <Form.Item name="symbol" label="Ticker" rules={[{ required: true, message: 'Please input token Ticker!' }]}>
                            <Input className="amount-buy" placeholder="Ex: ETH" />
                        </Form.Item>
                        {tokenType != 'liquidity' && (
                            <Form.Item name="decimals" label="Decimals" rules={[{ required: true, message: 'Please input Decimals!' }]}>
                                <InputNumber className="amount-buy" placeholder="Ex: 18" />
                            </Form.Item>
                        )}
                        <Form.Item name="total_supply" label="Total supply" rules={[{ required: true, message: 'Please input Total supply!' }]}>
                            <InputNumber className="amount-buy" placeholder="Ex: 1000000" />
                        </Form.Item>

                        {tokenType !== "standard" && (
                            <>
                                <Form.Item name="router_address" label="Router"
                                    initialValue={''}
                                    rules={[{ required: true, message: 'Please input Router!' }]}>
                                    <Select className="select-create-ido" >
                                        <Option value=''>Select Router Exchange</Option>
                                        {cexList.map((item, index) => (
                                            <Option value={item.routerAddress} key={'cex-' + index}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>
                        )}

                        {tokenType === "liquidity" && (
                            <>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="tax_fee_percent" label="Transaction fee to generate yield (%)"
                                            rules={[
                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        (parseFloat(createTokenForm.getFieldValue('liquidity_fee_percent') || 0) +
                                                            parseFloat(createTokenForm.getFieldValue('charity_percent') || 0) +
                                                            parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                    }
                                                }

                                            ]}
                                        >
                                            <Input type="number" placeholder="Ex: 1" className="amount-buy"
                                                onChange={() => {
                                                    createTokenForm.validateFields(['liquidity_fee_percent'])
                                                    createTokenForm.validateFields(['charity_percent'])
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="liquidity_fee_percent" label="Transaction fee to generate liquidity (%)"
                                            rules={[

                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        (parseFloat(createTokenForm.getFieldValue('tax_fee_percent') || 0) +
                                                            parseFloat(createTokenForm.getFieldValue('charity_percent') || 0) +
                                                            parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                    }
                                                }

                                            ]}
                                        >
                                            <Input type="number" placeholder="Ex: 1" className="amount-buy"
                                                onChange={() => {
                                                    createTokenForm.validateFields(['tax_fee_percent'])
                                                    createTokenForm.validateFields(['charity_percent'])
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name='charity_address' label='Charity/Marketing address'
                                            rules={[
                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        (value && !ethers.utils.isAddress(value)) ? cb('Invalid Address') : cb();
                                                    }
                                                }

                                            ]}
                                        >
                                            <Input className="amount-buy" type="text" placeholder="Ex: 0x..." onChange={() => {
                                                createTokenForm.validateFields(['charity_percent'])
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="charity_percent" label="Charity/Marketing percent (%)"
                                            rules={
                                                [
                                                    // { required: true, message: 'Please input Charity/Marketing percent!' },
                                                    {
                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                            (value && parseFloat(value) > 0 &&
                                                                !createTokenForm.getFieldValue('charity_address'))
                                                                ? cb("Can't set both charity address to addressZero and charity percent > 0")
                                                                : (parseFloat(createTokenForm.getFieldValue('liquidity_fee_percent') || 0) +
                                                                    parseFloat(createTokenForm.getFieldValue('tax_fee_percent') || 0) +
                                                                    parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                        }
                                                    }
                                                ]
                                            }
                                        >
                                            <Input className="amount-buy" type="text" placeholder="0-25"
                                                onChange={() => {
                                                    createTokenForm.validateFields(['liquidity_fee_percent'])
                                                    createTokenForm.validateFields(['tax_fee_percent'])
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {tokenType === "baby" && (
                            <>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="reward_token*" label="Reward token"
                                            rules={[{ required: true, message: 'Please input Reward token!' }]}
                                            help="If you want to reward DOGE, please enter 0xba2ae424d960c26247dd6c32edc70b295c744c43."
                                        >
                                            <Input type="text" placeholder="Ex: 0x..." className="amount-buy" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="minimum_token_balance_for_dividends" label="Minimum token balance for dividends"
                                            rules={[{ required: true, message: 'Please input token balance for dividends!' }]}
                                            help="Min hold each wallet must be over $50 to receive rewards."
                                        >
                                            <Input type="text" placeholder="Ex: 1000000000" className="amount-buy" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="token_reward _fee" label="Token reward fee (%)"
                                            rules={[
                                                { required: true, message: 'Please input Token reward fee!' },
                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                            ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                            : cb();
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input className="amount-buy" type="number" placeholder="0-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="auto_add_liquidity" label="Auto add liquidity (%)"
                                            rules={
                                                [
                                                    { required: true, message: 'Please input Auto add liquidity!' },
                                                    {
                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                            !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                ? cb("Auto add liquidity must be >= 0 and <= 100 !")
                                                                : cb();
                                                        }
                                                    }
                                                ]
                                            }
                                        >
                                            <Input className="amount-buy" type="number" placeholder="0-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="marketing_fee" label="Marketing fee (%)"
                                            rules={[
                                                { required: true, message: 'Please input Marketing fee!' },
                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                            ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                            : cb();
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input className="amount-buy" type="number" placeholder="0-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="marketing_wallet" label="Marketing wallet"
                                            rules={
                                                [{ required: true, message: 'Please input Marketing wallet!' }]
                                            }
                                        >
                                            <Input className="amount-buy" type="text" placeholder="0x..." />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {tokenType === "buyback" && (
                            <>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="reward_token*" label="Reward token"
                                            rules={[{ required: true, message: 'Please input Reward token!' }]}
                                            help="If you want to reward DOGE, please enter 0xba2ae424d960c26247dd6c32edc70b295c744c43."
                                        >
                                            <Input type="text" placeholder="Ex: 0x..." className="amount-buy" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="liquidity_fee" label="Liquidity Fee (%)"
                                            rules={[{ required: true, message: 'Please input Liquidity Fee (%)!' },
                                            {
                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                    !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                        ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                        : cb();
                                                }
                                            }]}
                                        >
                                            <Input type="number" placeholder="0-100" className="amount-buy" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="buyback_fee" label="Buyback Fee (%)" initialValue={3}
                                            rules={[
                                                { required: true, message: 'Please input Buyback Fee!' },
                                                {
                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                        !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                            ? cb("Buyback Fee must be >= 0 and <= 100 !")
                                                            : cb();
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input className="amount-buy" type="number" placeholder="0-100" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <Form.Item name="reflection_fee" label="Reflection Fee (%)" initialValue={8}
                                            rules={
                                                [
                                                    { required: true, message: 'Please input Reflection Fee!' },
                                                    {
                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                            !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                ? cb("Reflection Fee must be >= 0 and <= 100 !")
                                                                : cb();
                                                        }
                                                    }
                                                ]
                                            }
                                        >
                                            <Input className="amount-buy" type="number" placeholder="0-100" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item name="marketing_wallet" label="Marketing wallet"
                                    rules={
                                        [{ required: true, message: 'Please input Marketing wallet!' }]
                                    }
                                >
                                    <Input className="amount-buy" type="text" placeholder="0x..." />
                                </Form.Item>

                            </>
                        )}

                        <Form.Item name="implement_anti_bot" valuePropName="checked" initialValue={false}>
                            <Checkbox>Implement WaveSignal Anti-Bot System?</Checkbox>
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Create Token {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            {loadingFull && (
                <LoadingFull />
            )}
        </>
    )
}

export default VerifyToken
