import { instance } from '../../apiBase/instance';
let tokenID = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY1MTk0MDI4MX0.OGkSOjRJjH5519O4K94Oiw7Qavs-FpYFryb8O_5JOoKbJxmw6-Ho3Zo5oHj_HidbZ42oNl3hdNMlzk6qvGBp0g';
export const privatesSaleApi = {
	getSettings() {
		return instance.get<any>(`settings`, {});
	},
	getSettingsByChain(chainId: any) {
		return instance.get<any>(`settings/chain-id/${chainId}`, {});
	},
	getChainNetworkList(params) {
		return instance.get<any>(`chain-lists/client`, { params });
	},
	// private api

	createPrivate(data: any) {
		return instance.post<any>(`private-sale`, data, {});
	},

	getPrivates(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post<any>(`token-sale/filter`, data, { params });
	},
	getPrivateSaleId(id) {
		return instance.get<any>(`my-private-sale/${id}/client`);
	},

	updateStatusWhitelist(roundId, data) {
		return instance.put<any>(`private-sale/${roundId}/update-type`, data, {});
	},
	addWhiteList(data) { // add wl user
		return instance.post<any>(`white-lists-private-sale/add`, data, {});
	},
	joinPool(data) { // add wl user
		return instance.post<any>(`token-sales/join-pool`, data, {});
	},
	removeWhiteList(data) {
		return instance.delete<any>(`white-lists-private-sale/remove`, { data });
	},
	updateStatusIdoPool(roundId, data) {
		return instance.put<any>(`private-sale/${roundId}/update-status`, data, {});
	},
	getWhitelistByRound(roundId, params) {
		return instance.get<any>(`/private-sale/${roundId}/white-lists`, { params });
	},

	checkWLByAcc(address, id) {
		return instance.get<any>(`/white-list-private-sale/${address}/verify/${id}`);
	},
	//	api/white-list-private-sale/{address}/verify/{private-sale-id} -> vua check co WL va co sign lun
};
