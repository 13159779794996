import React, { useState, useEffect } from 'react';
import { CloudServerOutlined, FolderOpenOutlined } from '@ant-design/icons';
import './style.css';
import tokenInfoAbi from '../../config/abi/tokenInfo.json';
import { useActiveWeb3React } from 'hooks';
import {
    useContract,
} from "../../hooks/useContract";
import {
    _tokenBalance,
} from './utils';

const TokenBalance = () => {

    const { chainId, account, library } = useActiveWeb3React();
    const [balance, setBalance] = useState(0);
    // const tokenAddress = process.env.REACT_APP_MAIN_TOKEN_ADDRESS ?? '0000000000000';

    // const tokenContract = useContract(tokenAddress, tokenInfoAbi);

    // useEffect(() => {
    //     if (tokenContract && account) {
    //         _tokenBalance(tokenContract, account).then((res) => {
    //             setBalance(res);
    //         });
    //     }
    // }, [account, tokenContract]);
    return (
        <div className='private-staking-banner'>
            <div className="right-banner">
                <div className="top-image">
                    <img src="https://saros.finance/static/media/saros-atom.a37df4bc.png" alt="" />
                </div>
                <div className="bottom-image">
                    <img src="https://saros.finance/static/media/saros-atom.a37df4bc.png" alt="" />
                </div>
                <div className="images-ball">
                    <div className="right-banner__content">
                        <div className="content-text text-sm">Your Balance</div>
                        <div className="content-text text-l color-theme bold mt-2"><div>
                            <span>{balance.toLocaleString()}</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenBalance;
