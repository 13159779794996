import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Button, Space, Spin, Tag, Tabs, Pagination } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer';
import { useActiveWeb3React } from 'hooks';
import FilterChain from 'components/FilterChain';
import '../style.css';
import { AirdropApi } from '../../../config/api/airdropApi';
import { convertDateTime } from 'utils/formatDatetime';
import NoneData from 'components/element/NoneData';

const { TabPane } = Tabs;
const { Content } = Layout;

const Airdrop = () => {

    const [dataAirDrops, setDataAirDrops] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const { account, chainId } = useActiveWeb3React();
    const [activeTab, setActiveTab] = useState('all');
    const [totalRow, setTotalRow] = useState(0);
    const [form] = Form.useForm();
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            chainId: 0
        }
    );

    const getAirDrops = async (chainId) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            chainId: param.chainId ? param.chainId : chainId ? chainId : 0
        };
        try {
            let res = await AirdropApi.getAirdrops(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setDataAirDrops(res.data);

            } else {
                setDataAirDrops([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setLoadingData(false);
        }
    };

    const getYourAirdrops = async () => {
        setLoadingData(true);
        const dataFilter = {
            ...param
        };
        try {
            let res = await AirdropApi.getOwnerAirdrops(dataFilter, account);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setDataAirDrops(res.data);

            } else {
                setDataAirDrops([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        if (activeTab) {
            switch (activeTab) {
                case 'all':
                    getAirDrops(chainId);
                    break;
                case 'create-by-you':
                    if (account) {
                        getYourAirdrops();
                    }
                    break;
                default:
                    break;
            }
        }
    }, [activeTab, param, chainId]);

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const StatusRender = (nowDate, startDate, roundStatus) => {
        let status: any = '';
        let color: any = '';
        if (startDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag className='tag-status' color={color}>{status}</Tag>;
    }

    const AirdropContent = () => {
        return (
            <>
                <Row gutter={30}>
                    {!loadingData ? (
                        <>
                            {dataAirDrops?.length > 0 ? (
                                dataAirDrops.map((item, index) => {
                                    let nowDate = new Date(Date.now());
                                    let startDate = new Date(item?.startTime);

                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'item' + index}>
                                            <div className="project-item airdrop-item">
                                                <div className="airdrop-image">
                                                    <Link to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}>
                                                        <img src={item?.logoUrl?.length > 0 ? item?.logoUrl : "../images/bg-default.webp"} />
                                                    </Link>
                                                </div>
                                                <div className="project-item-content">
                                                    <div className="airdrop-name">
                                                        <Link title={item.airDropTitle} to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}>{item.airDropTitle}</Link>
                                                    </div>
                                                    <div className="project-total-raise">
                                                        <span>Start time</span>
                                                        <span className='text-orange'>{item?.startTime ? convertDateTime(startDate) : '---'}</span>
                                                    </div>
                                                    <div className="project-total-raise">
                                                        <span>Token reward</span>
                                                        <span><span className='text-info'>{item.totalTokenReward}</span> {item.tokenSymbol}</span>
                                                    </div>
                                                    <div className="project-total-raise">
                                                        <span>Status</span>
                                                        <span>{StatusRender(nowDate, startDate, item.airDropStatus)}</span>
                                                    </div>
                                                    <div className="airdrop-btn">
                                                        <div className="ido-btn-grid">

                                                            {item.taskLink.length > 0 && (
                                                                <Button htmlType="button" className="btn-all mr-10">
                                                                    <a target="_blank" href={item.taskLink}>Join Airdrop <DoubleRightOutlined /></a>
                                                                </Button>
                                                            )}
                                                            <Button htmlType="button" className={item.taskLink.length > 0 ? 'btn-default' : 'btn-all'}>
                                                                <Link to={`/airdrop/detail/${item.airDropAddress}/${item.handle}`}>
                                                                    {item.taskLink.length > 0 ? (
                                                                        <>View detail <DoubleRightOutlined /></>
                                                                    ) : (
                                                                        <>Claim your reward <DoubleRightOutlined /></>
                                                                    )}

                                                                </Link>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <NoneData text={'No Data'} link='/airdrop/create' />
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </Row>
            </>
        );
    };

    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };

    return (
        <>
            <HeaderMobile />

            <Content className="page-container">
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container mt-100">
                        <div className="airdrop-block p-mobile-15">
                            <div className='airdrop-title'>Airdrop</div>
                            <div className="project-content">
                                <div className="projects-grid mt-15">
                                    <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="filter-block"
                                        tabBarExtraContent={
                                            <>
                                                <div className='filter-block-form'>


                                                    <Form form={form} name="horizontal_login" layout="inline">
                                                        <FilterChain
                                                            key={122}
                                                            active={chainId}
                                                            handleChangeChain={(value) => {
                                                                setParam({
                                                                    ...param,
                                                                    chainId: value
                                                                });
                                                            }}
                                                        />
                                                        <Form.Item
                                                            name="www"
                                                            className='mr-0 w-50-mobile pl-15-mobile'
                                                        >
                                                            <a className="link-button link-hover m-t-15" target="_blank" href="create/airdrop">Create Airdrop</a>
                                                        </Form.Item>

                                                    </Form>
                                                </div>
                                            </>
                                        }
                                    >
                                        <TabPane tab="All" key="all">
                                            <AirdropContent />
                                        </TabPane>
                                        <TabPane tab="Created By You" key="create-by-you">
                                            <AirdropContent />
                                        </TabPane>

                                    </Tabs>

                                    {totalRow > 9 && (
                                        <Pagination
                                            current={param.page + 1}
                                            defaultCurrent={1}
                                            total={totalRow}
                                            pageSize={param.size}
                                            onChange={onChange_Pagi}
                                            showSizeChanger={false}
                                            className="pagiation-custom"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default Airdrop
