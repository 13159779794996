export const currencies = {
	97: {
		"BNB": "0x0000000000000000000000000000000000000000",
		"BUSD": "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
		"USDT": "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
		"USDC": "0x64544969ed7EBf5f083679233325356EbE738930",
	},
	56: {
		"BNB": "0x0000000000000000000000000000000000000000",
		"BUSD": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
		"USDT": "0x55d398326f99059ff775485246999027b3197955",
		"USDC": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
	},
	1: {
		"ETH": "0x0000000000000000000000000000000000000000",
		"BUSD": "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
		"USDT": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
	},
	250: {
		"FTM": "0x0000000000000000000000000000000000000000",
		"USDT": "0x049d68029688eabf473097a2fc38ef61633a3c7a",
		"USDC": "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
	},
	137: {
		"MATIC": "0x0000000000000000000000000000000000000000",
		"BUSD": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
		"USDT": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
		"USDC": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
	},
	43114: {
		"AVAX": "0x0000000000000000000000000000000000000000",
		"BUSD": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
		"USDT": "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
		"USDC": "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
	},
	66: {
		"OKT": "0x0000000000000000000000000000000000000000",
		"USDT": "0x382bb369d343125bfb2117af9c149795c6c65c50",
		"USDC": "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
	},
	5: {
		"ETH": "0x0000000000000000000000000000000000000000",
		"USDT": "0x3F32bE93a8708Fe059745D7b277eBFed1ed95528",
	}
}

export const currencyDecimals = {
	97: {
		"BNB": 18,
		"BUSD": 18,
		"USDT": 18,
		"USDC": 18,
	},
	56: {
		"BNB": 18,
		"BUSD": 18,
		"USDT": 18,
		"USDC": 18,
	},
	1: {
		"ETH": 18,
		"BUSD": 18,
		"USDT": 6,
	},
	250: {
		"FTM": 18,
		"USDT": 6,
		"USDC": 6,
	},
	137: {
		"MATIC": 18,
		"BUSD": 18,
		"USDT": 6,
		"USDC": 6,
	},
	43114: {
		"AVAX": 18,
		"BUSD": 18,
		"USDT": 6,
		"USDC": 6,
	},
	66: {
		"OKT": 18,
		"USDT": 18,
		"USDC": 18,
	},
	5: {
		"ETH": 18,
		"USDT": 6,
	}
}

export const ZOOM = 10000;

export const getCurrencyDecimals = (_chainId, _symbol) => {
	if (!_chainId || !_symbol) {
		return 18
	}
	return currencyDecimals[_chainId][_symbol];
};

export const getCurrencyAddress = (_chainId, _symbol) => {
	if (!_chainId || !_symbol) {
		return "0x0000000000000000000000000000000000000000"
	}
	return currencies[_chainId][_symbol];
};


