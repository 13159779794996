import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tag, Progress, Button } from 'antd';
import { TwitterOutlined, CodeSandboxOutlined, GlobalOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import Countdown from 'react-countdown';
import {
	privateSaleGetHardCap, privateSaleGetRaisedAmount, privateSaleGetRaisedAmountByChainId
} from '../../../utils';

import { ethers } from 'ethers';
import { getCurrencyDecimals } from 'config/network/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import privateSaleAbi from '../../../../config/abi/privateSaleAbi.json';

const PrivateSaleItem = (props: any) => {
	const { item, logoNetwork, titleNetwork } = props;

	const [raisedAmount, setRaisedAmount] = useState(0);

	const { chainId, account, library } = useActiveWeb3React();
	let nowDate = new Date(Date.now());
	let startDate = new Date(item?.startTime);
	let endDate = new Date(item?.endTime);


	const getPrivateSaleInfo = async (itemAddress, itemChainId) => {

		try {
			const raisedAmountSC = await privateSaleGetRaisedAmountByChainId(privateSaleAbi, itemAddress, itemChainId);
			// const hardCapSC = await privateSaleGetHardCap(privateSaleAbi, itemAddress, library, account);

			const decimal = item?.currencyPayDecimals || getCurrencyDecimals(chainId, item?.currencyApply) || 18;
			setRaisedAmount(parseFloat(ethers.utils.formatUnits(raisedAmountSC, decimal)));
			// setHardCap(parseFloat(ethers.utils.formatUnits(hardCapSC, decimal)));

		} catch (e) {
			console.log(e);
		}

	};

	useEffect(() => {
		if (account && item?.joinContract.length > 0 && item?.chainId) {
			getPrivateSaleInfo(item.joinContract, item.chainId);
		}
	}, [account, item.joinContract, item?.chainId]);


	const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			window.location.reload();
		}
		if (days > 1) {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		} else if (days === 1) {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		} else {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		}
	};
	const getProgressTime = useCallback((startTime) => {
		const now = new Date();
		const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
		const startDate = new Date(startTime);
		const startTS = Date.UTC(
			startDate.getFullYear(),
			startDate.getMonth(),
			startDate.getDate(),
			startDate.getHours(),
			startDate.getMinutes(),
			startDate.getSeconds(),
			0
		);
		let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
		const days = Math.floor(delta / 86400);
		delta -= days * 86400;
		const hours = Math.floor(delta / 3600);
		delta -= hours * 3600;
		const minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;
		const seconds = Math.floor(delta % 60);
		if (days >= 1) {
			return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		} else {
			return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		}
	}, []);

	const StatusRender = (nowDate, startDate, endDate, stt) => {
		let status: any = '';
		let color: any = '';
		if (startDate > nowDate && endDate > nowDate && stt != 'CANCEL') {
			status = 'upcoming';
			color = '#108ee9';
		}
		if (startDate < nowDate && endDate > nowDate && stt != 'CANCEL') {
			status = 'opening';
			color = '#f5c112';
		}
		if ((startDate < nowDate && endDate < nowDate && stt != 'CANCEL')) {
			status = 'closed';
			color = '#919191';
		}
		if (stt == 'CANCEL') {
			status = 'canceled';
			color = 'red';
		}

		return <Tag color={color}>{status}</Tag>;
	};

	return (
		<>
			<div className='project-item mt-15'>
				<div className='project-image'>
					<Link to={`/private-sale/detail/${item?.id}/${item?.handle}/${item?.joinContract}`}><img
						src={item?.logoLink.length > 0 ? item?.logoLink : '../images/bg-default.webp'} /></Link>
					<div className='project-chain'>

						<div className='project-token-chain'>
							<img title={titleNetwork} src={logoNetwork} />
						</div>
					</div>
				</div>
				<div className='project-item-content'>
					<div className='project-name'><Link
						to={`/private-sale/detail/${item?.id}/${item?.handle}/${item?.joinContract}`}>{item?.title}</Link>
					</div>
					<div className='project-status-social'>
						{StatusRender(nowDate, startDate, endDate, item?.roundAction)}
						<span className='social'>
							{item?.twitter.length > 0 && (
								<a target='_blank' href={item?.twitter}><TwitterOutlined
									className='icon' /></a>
							)}
							{item?.telegram.length > 0 && (
								<a target='_blank' href={item?.telegram}><FontAwesomeIcon
									icon={faPaperPlane as IconProp} /></a>
							)}
						</span>
					</div>
					<div className='project-total-raise'>
						<span>Soft/Hard Cap</span>
						<span
							className='text-info'>{item?.softCap} {item?.currencyApply}/ {item?.hardCap} {item?.currencyApply}</span>
					</div>
					<div className='project-total-raise'>
						<span>Price</span>
						<span>1 {item?.currencyApply} = {item?.swapRate}</span>
					</div>
					<hr></hr>
					<div className='project-registration'>
						{startDate > nowDate && endDate > nowDate && item?.roundAction != 'CANCEL' && (
							<>
								<span className='sale-start-in'>Sale start in </span>
								<Countdown
									date={getProgressTime(startDate)}
									renderer={rendererCountDown}
								/>
							</>
						)}
						{startDate < nowDate && endDate > nowDate && item?.roundAction != 'CANCEL' && (
							<>
								<span className='sale-start-in'>Sale end in </span>
								<Countdown
									date={getProgressTime(endDate)}
									renderer={rendererCountDown}
								/>
							</>
						)}
						{(endDate < nowDate || item?.roundAction == 'CANCEL') && (
							<>
								<span className='sale-start-in'>Closed </span>
							</>
						)}

					</div>
					<Progress percent={Math.ceil(raisedAmount / item?.hardCap * 100)} className='project-progress-percent' />
					<div className='project-total-sell'>
						<span>{raisedAmount.toLocaleString()} {item?.currencyApply}</span>
						<span className='text-info'>{item?.hardCap.toLocaleString()} {item?.currencyApply}</span>
					</div>
					<div className='project-normal text-center'>
						<Button htmlType='button' className={'btn-all'}>
							<Link to={`/private-sale/detail/${item?.id}/${item?.handle}/${item?.joinContract}`}>
								View detail <DoubleRightOutlined className='view-detail-icon' />
							</Link>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivateSaleItem;
