import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Menu, Modal, Spin, Tag } from 'antd';
import { CloseCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useConnectWallet, useActiveWeb3React } from 'hooks';
import { NEED_A_PLACEHOLDER, WALLET_LIST } from 'constants/index';
import WalletItem from 'components/connect-wallet/WalletItem';
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { useWrap } from 'context/WrapperContext';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import './style.css';
import { Metamask, TrustWallet, WalletConnect } from '../icons';

declare const window: Window & typeof globalThis & { ethereum: any };

const ConnectWallet = (props: any) => {

	const { textBtn, chainIdDetail, chainNetworkListMain, chainNetworkListTest } = props;
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [termsConditions, setTermsConditions] = useState<boolean>(true);
	const [activeChain, setActiveChain] = useState<boolean>(true);
	const [isModalWarningChain, setIsModalWarningChain] = useState<boolean>(false);
	const { walletLogin, walletLogout } = useConnectWallet();


	const { account } = useActiveWeb3React();

	const [network, setNetwork] = useState<any>();

	const context = useWeb3React();
	const { activate, deactivate, active, error, chainId } = context;

	if (window.ethereum) {
		window.ethereum.on('chainChanged', (chainId) => {
			window.location.reload();
		});

		window.ethereum.on('accountsChanged', (chainId: string) => {
			window.location.reload();
		});
	}



	useEffect(() => {

		if (account && chainIdDetail && chainId) {
			if (window.ethereum) {

				if (chainId !== chainIdDetail) {
					changeNetwork(chainIdDetail);
				}
			}
		}
	}, [chainId, chainIdDetail, account]);

	const onClickNetwork = async (item) => {
		setActiveChain(false);
		setNetwork(item);
	}

	const onClickWallets = async (item, activeChain, termsConditions) => {
		if (!network) {
			return;
		}
		if (!active) {
			if (activeChain || termsConditions) {
				return;
			}
		}

		try {
			await walletLogin(item.connectorId);
		} catch (e) {
			console.error('Login failed');
		}
		// setChainId(chainIdNew);
		//setNetworkChain(item.symbol);
		window.localStorage.setItem('walletInfo', JSON.stringify(network));
		changeNetwork(network.chainId);
		setIsModalVisible(false);
	}


	// --- ACTION IN MODAL ---
	const showModal = () => {
		setNetwork({})
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	//  --- Return address wallet with substring ---
	const accountEllipsis = active ? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}` : 'Connect Wallet';

	// ---- HANDLE DISCONNECT ----
	const handleDisconnect = () => {
		walletLogout();
		window.localStorage.removeItem('walletChainId');
		setIsModalVisible(false);
		setTermsConditions(true);
		setActiveChain(true);
		setNetwork('');
	};

	// ---- HANDLE CONNECT ----
	// const handleConnect = async (connectorId: string) => {
	// 	try {
	// 		const rs = await walletLogin(connectorId);
	// 		setIsModalVisible(false);
	// 	} catch (e) {
	// 		console.error('Login failed');
	// 	}
	// };

	const changeNetwork = async (chainIdDetail) => {
		let data: any;
		data = chainNetworkListMain.find((itemChain) => parseInt(chainIdDetail) === parseInt(itemChain.chainId));
		if (data == undefined || data.length == 0 || !data) {
			data = chainNetworkListTest.find((itemChain) => parseInt(chainIdDetail) === parseInt(itemChain.chainId));
		}
		try {
			if (data) {
				console.log('chainId', data.chainId);
				await window.ethereum.enable();
				const params = {
					chainId: `0x${parseInt(data.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
					chainName: data.name,
					nativeCurrency: {
						name: data.symbol,
						symbol: data.symbol, // 2-6 characters long
						decimals: 18,
					},
					rpcUrls: [data.rpcUrl,],
					blockExplorerUrls: [
						data.blockExplorer,
					],
				};
				window.ethereum
					.request({
						method: 'wallet_addEthereumChain',
						params: [params, account]
					})
					.then((result) => {
						console.log(result);
					})
					.catch((error) => {
						console.log(error);
					});
				await window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{ chainId: `0x${parseInt(data.chainId).toString(16)}` }], // chainId must be in hexadecimal numbers
				});
			}
		} catch (error: any) {
			if (error.code === 4902) {
				try {
					if (data) {
						await window.ethereum.request({
							method: "wallet_addEthereumChain",
							params: [
								{
									chainId: chainIdDetail,
									rpcUrl: data.rpcUrl,
								},
							],
						});
					}

				} catch (addError) {
					// handle "add" error
				}
			}
			console.error(error);
		}
	};

	const ChainNetworkRender = (chainId) => {
		let logoNetwork: any = '';
		let title: any = '';
		let chain: any;
		chain = chainNetworkListMain.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
		if (chain == undefined || chain.length == 0 || !chain) {
			chain = chainNetworkListTest.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
		}
		if (chain[0]) {
			logoNetwork = chain[0].logoLink;
			title = chain[0].name;
		}
		return (
			<>
				<img title={title} src={logoNetwork} />
			</>
		);
	}
	const closeBox = () => {
		setIsModalWarningChain(false);
	};
	const switchChain = () => {
		setLoading(true);
	};

	const WALLET_LIST = [
		{ icon: Metamask, title: 'Metamask', connectorId: 'injected' },
		// { icon: SafePalWallet, title: 'SalePal Wallet', connectorId: 'injected' },
		{ icon: WalletConnect, title: 'Wallet Connect', connectorId: 'walletconnect' },
		{ icon: TrustWallet, title: 'Trust Wallet', connectorId: 'trust' }
	];



	function WalletRender() {
		return (
			<>
				{((!active && network) || (active && network?.chainId && chainId !== network?.chainId)) && (
					<>
						<div className='wallet-sub-title'>Choose Wallet</div>
						<div className='choose-network'>
							{WALLET_LIST.map((wallet) => {

								return (
									<WalletItem
										className={activeChain ? `wallet-item wallet-item-dis` : `wallet-item`}
										key={wallet.title}
										onClick={() => onClickWallets(wallet, activeChain, termsConditions)}
										icon={<wallet.icon width='48px' />}
										title={wallet.title}
									/>
								);
							})}
						</div>
					</>

				)}

			</>);
	}
	function changeTermsConditions(e) {
		setTermsConditions(!termsConditions);

		//setActiveChain(!e.target.checked);
		if (!e.target.checked) {
			setActiveChain(!e.target.checked);
			setNetwork('');
		}

	}
	return (
		<>

			<Button className="btnConnect" onClick={showModal}>
				{textBtn ? textBtn : accountEllipsis}
			</Button>

			<Modal
				title={<div className="custom-modal-title">{!account ? 'Connect Wallet' : 'Account'}</div>}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				className="custom-modal wallet-modal"
				footer={false}
			>
				{!active && (
					<>
						<div className="wallet-sub-title"> Agree To WAVESIGNAL Terms & Conditions</div>
						<Checkbox.Group>
							<Checkbox onChange={changeTermsConditions} value={true} style={{ marginBottom: '15px' }}>I have read and agreed with the Terms of Service and Privacy Policy.</Checkbox>
						</Checkbox.Group>
					</>
				)}

				<div className="wallet-sub-title">Choose Network</div>
				<div className="choose-network">
					{chainNetworkListMain && chainNetworkListMain?.length > 0 && (
						<>
							{chainNetworkListMain.map((item, index) => (
								item.chainId && (
									<button type="button" disabled={termsConditions && !active}
										title={item.name} className={network?.chainId == item.chainId ? 'active' : (!network?.chainId && chainId == item.chainId) ? 'active' : ''}
										onClick={() => onClickNetwork(item)} key={'chain-main' + index}
									>
										<img src={item.logoLink} />
										{item.symbol}
									</button>
								)
							))}
						</>
					)}
				</div>

				{chainNetworkListTest && chainNetworkListTest.length > 0 && (
					<>
						<div className="wallet-sub-title">Choose Network (Testnet)</div>
						<div className="choose-network">
							{chainNetworkListTest.map((item, index) => (
								item.chainId && (
									<button type="button" title={item.name} disabled={termsConditions && !active}
										className={network?.chainId == item.chainId ? 'active' : (!network?.chainId && chainId == item.chainId) ? 'active' : ''}
										onClick={() => onClickNetwork(item)} key={'chain-test' + index}
									>
										<img src={item.logoLink} />
										{item.symbol}
									</button>
								)
							))}
						</div>
					</>
				)}
				<WalletRender />
				{!active ? (
					<>

					</>
				) : (
					<div className="wallet-connected">
						<div className="wallet-sub-title">Account detail</div>
						<div className="wallet-address">
							<div className="address-left">
								{ChainNetworkRender(chainId)} <span className="pl-5">{account?.substring(0, 5)}...{account?.substring(account.length - 5)}</span>
							</div>
							<div className="address-right">
								<Button className="btn-disconnect" htmlType="button" onClick={handleDisconnect}>
									<LogoutOutlined style={{ fontSize: '14px', color: '#ff3b6b' }} /> Disconnect
								</Button>
							</div>
						</div>
						<div className="noti-out-button">

						</div>
					</div>
				)}
			</Modal>

		</>
	);
};

export default ConnectWallet;
