
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Steps, message, Modal, InputNumber, Alert } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import VerifyToken from './verify-token';
import DefiLaunchpadInfo from './defi-launchpad-info';
import AdditonalInfo from './additional-info';
import Finish from './finish';
import { useWrap } from 'context/WrapperContext';
import LoadingFull from 'components/loading-full';
import { privatesSaleApi } from 'config/api/privatesSaleApi';
import { useActiveWeb3React } from 'hooks';
import ConnectWallet from 'components/connect-wallet/ConnectWallet';
import { useDeployPrivateSaleContract } from '../../../hooks/useContract';
import { ethers } from 'ethers';
import { deployPrivateSale } from '../../utils';
import web3 from 'web3';
import { currencies, currencyDecimals } from '../../../config/network/config';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { INIT_PRIVATE_SALE_FEE } from 'components/keyConfig';
import { getChainListInfo } from 'config/network/chainlist';

const { Step } = Steps;
const { Content } = Layout;
declare const window: Window & typeof globalThis & { ethereum: any };

const PrivateCreate = () => {
    const history = useHistory();
    const { showNoti } = useWrap();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [raisedFeeOnly, setRaisedFeeOnly] = useState<any>([]);
    const [tokenRaisedFee, setTokenRaisedFee] = useState<any>([]);
    const [initPrivateSaleFee, setInitPrivateSaleFee] = useState(0.01);
    const [initPrivateSaleFeeOpj, setInitPrivateSaleFee0pj] = useState<any>();
    const [amountRaisedFee, setAmountRaisedFee] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React();
    const [chainNetworkList, setChainNetworkList] = useState<any>([]);


    let storageStep1 = window.localStorage.getItem('step1');
    let storageLaunchpadInfo = window.localStorage.getItem('privateSaleInfo');
    let storageAdditionalInfo = window.localStorage.getItem('privateSaleAdditionalInfo');

    const deployPrivateSaleAddress = getInformationByChain(chainId, 'REACT_APP_DEPLOY_PRIVATE_SALE');

    const deployPrivateSaleContract = useDeployPrivateSaleContract(deployPrivateSaleAddress);
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const getChainNetworkList = async () => {
        const dataFilter = { page: 0, size: 10, sort: 'id,desc', vm: 'EVM' };
        try {
            let res = await privatesSaleApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setChainNetworkList(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    let launchpadInfo;
    let additionalInfo
    let step1;
    if (storageStep1) {
        step1 = JSON.parse(storageStep1);
    }
    if (storageLaunchpadInfo) {
        launchpadInfo = JSON.parse(storageLaunchpadInfo);
    }
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }

    const steps = [
        {
            title: 'Pick book by the cover',
            content: <VerifyToken form={form} />,
            description: 'Fill in a nice title and choose currency for your campaign.'
        },
        {
            title: 'Private sale Info',
            content: <DefiLaunchpadInfo form={form} />,
            description: 'Please fill out all the information of your Private sale campaign.'
        },
        {
            title: 'Team & Project',
            content: <AdditonalInfo form={form} />,
            description: 'Let community know more about your project'
        },
        {
            title: 'Completion',
            content: <Finish />,
            description: "Review everything. It's time for magic!"
        },
    ];
    let initCurrentStep = 0;
    if (storageStep1 && storageLaunchpadInfo) {
        initCurrentStep = 1
    }
    if (storageLaunchpadInfo) {
        initCurrentStep = 2
    }
    if (storageAdditionalInfo) {
        initCurrentStep = 3
    }

    const [current, setCurrent] = React.useState(initCurrentStep);


    const prev = () => {
        setCurrent(current - 1);
    };

    const getSettings = async () => {

        try {
            let res = await privatesSaleApi.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => INIT_PRIVATE_SALE_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitPrivateSaleFee(parseFloat(initFee[0].settingValue));
                        setInitPrivateSaleFee0pj(initFee[0]);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        if (chainId) {
            getSettings();
        }
        getChainNetworkList();
    }, [chainId]);

    const confirmCreatePrivate = async () => {

        try {
            if (!account || !chainId) {
                showNoti('warning', 'Please Connect Wallet!');
                return;
            }
            const networkSymbol = getChainListInfo(chainId, 'SYMBOL')
            const networkChainId = getChainListInfo(chainId, 'CHAIN_ID')
            let startTimeDB = new Date(launchpadInfo.start_time);
            let endTimeDB = new Date(launchpadInfo.end_time);
            let claimTime = new Date(Date.now()).toISOString();

            let payloadPrivate = {
                title: step1.title,
                currencyApply: step1.currencyApply,
                startTime: launchpadInfo.start_time ? startTimeDB.toISOString() : '',
                endTime: launchpadInfo.end_time ? endTimeDB.toISOString() : '',
                claimTime: claimTime,
                softCap: launchpadInfo.softCap ? parseFloat(launchpadInfo.softCap) : 0,
                hardCap: launchpadInfo.hardCap ? parseFloat(launchpadInfo.hardCap) : 0,
                minBuy: launchpadInfo.minimum_buy ? parseFloat(launchpadInfo.minimum_buy) : 0,
                maxBuy: launchpadInfo.maximum_buy ? parseFloat(launchpadInfo.maximum_buy) : 0,
                swapRate: launchpadInfo.swapRate ? parseFloat(launchpadInfo.swapRate) : 0,
                joinContract: "",
                ownerAddress: account,
                description: additionalInfo.description,
                hostLink: additionalInfo.hostLink,
                logoLink: additionalInfo.logoLink,
                claimLink: additionalInfo.claimLink,
                facebook: additionalInfo.facebook,
                twitter: additionalInfo.twitter,
                git: additionalInfo.git,
                telegram: additionalInfo.telegram,
                instagram: additionalInfo.instagram,
                discord: additionalInfo.discord,
                reddit: additionalInfo.reddit,
                youtube: additionalInfo.youtube,
                firstReleasePercent: launchpadInfo.first_fund_release ? parseInt(launchpadInfo.first_fund_release) : 0,
                claimTypeToken: "DAILY", // launchpadInfo.claimTypeToken "DAILY || WEEKLY || MONTHLY"
                releaseEachCyclePercent: launchpadInfo.fund_release_each_cycle ? parseInt(launchpadInfo.fund_release_each_cycle) : 0,
                saleType: launchpadInfo.saleType, //"PUBLIC || WHITE_LIST || ANTI_BOT",
                chainId: networkChainId,
                networkChain: networkSymbol,
                virtualMachine: "EVM",
                currencyPayAddress: step1.currency_pay_address,
                currencyPayDecimals: step1.currency_pay_decimals
            }



            setLoading(true);
            const feeToken = currencies[chainId][step1.currencyApply];
            const decimal = currencyDecimals[chainId][step1.currencyApply];
            const whitelistPool = launchpadInfo.saleType === 'WHITE_LIST';
            const softCap = ethers.utils.parseUnits(`${launchpadInfo.softCap}`, decimal);
            const hardCap = ethers.utils.parseUnits(`${launchpadInfo.hardCap}`, decimal);
            const minInvest = ethers.utils.parseUnits(`${launchpadInfo.minimum_buy}`, decimal);
            const maxInvest = ethers.utils.parseUnits(`${launchpadInfo.maximum_buy}`, decimal);
            const startTime = (new Date(launchpadInfo.start_time).getTime()) / 1000;
            const endTime = (new Date(launchpadInfo.end_time).getTime()) / 1000;
            const privateSaleInfo = [feeToken, whitelistPool, softCap, hardCap, minInvest, maxInvest, startTime, endTime];

            const tgeBps = parseInt(`${parseFloat(launchpadInfo.first_fund_release) * 100}`);
            const cycle = parseInt(launchpadInfo.fund_vesting_period_each_cycle) * 60; //TODO: PARSE TIMESTAMP
            const cycleBps = parseInt(`${parseFloat(launchpadInfo.fund_release_each_cycle) * 100}`);

            const vestingInfo = [tgeBps, cycle, cycleBps];

            const fee = ethers.utils.parseUnits(`${initPrivateSaleFee}`, 18)
            const fundPercent = 500;


            await deployPrivateSale(deployPrivateSaleContract, privateSaleInfo, vestingInfo, fee, fundPercent).then((txn) => {
                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        let newSCAddress;
                                        for (let i = 0; i < res.logs.length; i++) {
                                            if (res?.logs[i]?.topics[0] === '0x9f7bf10c8c87a3ad335c13ec6da6b793cce7208f02cdf5f12a0a3b92e47a6d2b') {
                                                newSCAddress = res.logs[i].topics[1];
                                            }
                                        }
                                        if (newSCAddress) {
                                            newSCAddress = w3.eth.abi.decodeParameter('address', newSCAddress)
                                            payloadPrivate = {
                                                ...payloadPrivate,
                                                joinContract: newSCAddress
                                            };
                                            console.log(payloadPrivate);

                                            let res = await privatesSaleApi.createPrivate(payloadPrivate);

                                            if (res.status === 201) {
                                                if (res.data) {

                                                    window.localStorage.removeItem('privateSaleInfo');
                                                    window.localStorage.removeItem('step1');
                                                    window.localStorage.removeItem('privateSaleAdditionalInfo');
                                                    setLoading(false);
                                                    setTimeout(() => {
                                                        history.push({
                                                            pathname: `/private-sale/detail/${res.data.id}/${res.data.handle}/${res.data.joinContract}`,
                                                            search: ''
                                                        });
                                                    }, 500);
                                                }
                                            }
                                        }
                                        showNoti('success', 'Create Private Sale Successfully!');

                                    }
                                } else {
                                    showNoti('warning', 'Create Private Sale Failed!');

                                }
                                setLoading(false);

                            }
                        })();
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
            });
        } catch (error) {
            console.log('Error: ', error);
            showNoti('error', 'Confirm Failed');
        }
    };

    const onConfirmSteps = (values: any) => {
        if (values.step_verify_token && values.step_verify_token == 1) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('step1', data);
            // window.location.reload();
        }
        if (step1 && values.step_launchpad_info && values.step_launchpad_info == 2) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('privateSaleInfo', data);
            window.location.reload();
        }
        if (step1 && values.step_additional_info && values.step_additional_info == 3) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('privateSaleAdditionalInfo', data);
            // window.location.reload();
        }
        setCurrent(current + 1);
    }

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="back-history">
                                <Link to={`/private-sale`}><LeftCircleOutlined /> Cancel create private sale </Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Private Sale Create</div>
                                <div className="create-step">
                                    <>
                                        <Steps current={current} className="create-step-cus">
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} description={item.description} />
                                            ))}
                                        </Steps>
                                        <div className="private-create-block">
                                            <Form form={form} layout="vertical" autoComplete="off"
                                                onFinish={onConfirmSteps}
                                            >
                                                <div className="steps-content">{steps[current].content}</div>
                                                {initCurrentStep == 3 && (
                                                    <p className='text-info'>Create private sale fee: {initPrivateSaleFeeOpj?.settingValue} {initPrivateSaleFeeOpj?.payToken}</p>
                                                )}

                                                <div className="steps-action">
                                                    {current > 0 && (
                                                        <button className="btn-default" type="button" style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                            Previous
                                                        </button>
                                                    )}
                                                    {current < steps.length - 1 && (
                                                        <>
                                                            {current == 0 && (
                                                                <button disabled={account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                            {current == 1 && (
                                                                <button disabled={account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                            {current == 2 && (
                                                                <button disabled={account ? false : true} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {current === steps.length - 1 && (
                                                        <button type="button" disabled={storageLaunchpadInfo && storageStep1 && storageAdditionalInfo && account ? false : true} className="btn-all" onClick={confirmCreatePrivate}>
                                                            {account ? 'Confirm' : 'Connect Wallet'}
                                                        </button>
                                                    )}
                                                </div>
                                            </Form>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
                {loading && (
                    <>
                        <LoadingFull />
                    </>
                )}
            </Content>
        </>
    )
}

export default PrivateCreate
