
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Table, Space } from 'antd';


const TableRoi = (props) => {

    const { period, apr, earn } = props;

    const dataSource = [
        {
            id: 1,
            period: period,
            apr: apr,
            earn: earn
        }
    ];

    const columnsAllocations = [
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'APR',
            dataIndex: 'apr',
            key: 'apr',
            render: (text: any, record: any) => {
                return (
                    <Space align='center' key={'roi-' + record.id}>{record.apr}%</Space>
                )
            }
        },
        {
            title: 'Earn',
            dataIndex: 'earn',
            key: 'earn',
        }
    ];
    return (
        <>
            <Table pagination={false} dataSource={dataSource} columns={columnsAllocations} />
        </>
    )
}

export default TableRoi
