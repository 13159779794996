import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Progress, Button, Tabs, Modal, Input, Form, Tag, Spin, Table, Space, Alert, Card } from 'antd';
// import queryString from 'query-string'
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import Countdown from 'react-countdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TwitterOutlined, LeftCircleOutlined, GlobalOutlined, CodeSandboxOutlined, CopyOutlined, DoubleRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './style.css';
import ApplyToLaunch from '../apply-to-launch';
import { IdoLaunchpad } from '../../../config/api/idoLaunchpad';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';

import launchpadAbi from 'config/abi/launchpadAbi.json';
import web3 from 'web3';
import { convertDateTime } from 'utils/formatDatetime';
import { ethers } from 'ethers';
import tokenInfoAbi from 'config/abi/tokenInfo.json';
import { getInformationByChain } from 'config/network/multichainAddresses';
import RoundItem from './round-item';

const { Content } = Layout;

declare const window: Window & typeof globalThis & { ethereum: any };

const IdoDetail = () => {
    let { handle }: { handle: string } = useParams();
    const { showNoti } = useWrap();
    const [detail, setDetail] = useState<any>();

    const [chainNetworkList, setChainNetworkList] = useState<any>([]);
    const [form] = Form.useForm<{ name: string; age: number }>();

    const { chainId, account, library } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const getLaunchpadDetailByHandle = async (account: any) => {
        try {
            let res = await IdoLaunchpad.getLaunchpadDetailByHandle(handle, account);
            if (res.status === 200) {
                setDetail(res.data);
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    const getChainNetworkList = async () => {
        const dataFilter = {
            page: 0, size: 20, sort: 'id,desc', vm: 'EVM'
        };
        try {
            let res = await IdoLaunchpad.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setChainNetworkList(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList();
    }, []);

    useEffect(() => {
        if (account) {
            getLaunchpadDetailByHandle(account);
        }
    }, [account]);

    const onCopy = () => {
        showNoti('success', 'Copied');
    };

    const ChainNetworkRender = (chainSymbol: string) => {


        let logoNetwork: any = '';
        let title: any = '';
        let chain = chainNetworkList.filter((itemChain) => chainSymbol === itemChain.symbol);
        if (chain[0]) {
            logoNetwork = chain[0].logoLink;
            title = chain[0].name;
            let chainIdNew = chain[0].chainId;
            if (process.env.REACT_APP_ENV != "production") {
                chainIdNew = chain[0].testnetChainId
            }
        } else {
            logoNetwork = chainNetworkList[8]?.logoLink;
            title = chainNetworkList[8]?.name;
            let chainIdNew = chainNetworkList[8]?.chainId;
            if (process.env.REACT_APP_ENV != "production") {
                chainIdNew = chainNetworkList[8]?.testnetChainId
            }
        }
        return (
            <>
                <img title={title} src={logoNetwork} /> <span>{title}</span>
            </>
        );
    }

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                {/* 
                <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} />
                    <div className="page-content container">
                        <div className="project-block-detail">
                            <div className="back-history">
                                <Link to="/launchpad"><LeftCircleOutlined /> Back to Ido Launchpad</Link>
                            </div>
                            <div className="detail-content">
                                <Card>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row pr-35" xs={24} xl={10} md={10} sm={10}>
                                            <div className="ido-info">
                                                <Row gutter={{ xs: 0 }} className="mt-15">
                                                    <Col className="gutter-row" xs={6} xl={5} md={5} sm={5}>
                                                        <div className="ido-info-image">
                                                            <img src={detail?.tokenInfoIdo?.logoURL} />
                                                        </div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={18} xl={19} md={19} sm={19}>
                                                        <div className="ido-name">{detail?.tokenInfoIdo?.name} <span>{detail?.tokenInfoIdo?.symbol}/ {detail?.currencyPay}</span></div>
                                                        <div className="ido-social">
                                                            <span className="social">
                                                                <Link to=""><TwitterOutlined className="icon" /></Link>
                                                                <Link to=""><GlobalOutlined className="icon" /></Link>
                                                                <Link to=""><CodeSandboxOutlined className="icon" /></Link>
                                                            </span>
                                                            <span className="address">
                                                                <i style={{ marginRight: '10px' }}>0x94949...98349348</i>
                                                                {/* {record.token?.substring(0, 8)}...{record.token?.substring(record.token.length - 6)} */}
                                                                <CopyToClipboard onCopy={onCopy} text={'asjkdhkjashdkasd'}>
                                                                    <CopyOutlined />
                                                                </CopyToClipboard>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="ido-description">
                                                    {detail?.description}
                                                </div>
                                            </div>

                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                            <div className="ido-name">Token Info</div>
                                            <Row gutter={{ xs: 0 }} className="mt-15">
                                                <Col className="gutter-row border-right" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="ido-text">
                                                        <span className="left">Token Symbol</span>
                                                        <span className="right">{detail?.tokenInfoIdo?.symbol}</span>
                                                    </div>
                                                    <div className="ido-text">
                                                        <span className="left">Network</span>
                                                        <span className="right">{ChainNetworkRender(detail?.tokenInfoIdo?.networkChain)}</span>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row pl-15" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="ido-text">
                                                        <div className="left">Token Decimal:</div>
                                                        <div className="right">{detail?.tokenInfoIdo?.decimals}</div>
                                                    </div>
                                                    {/* <div className="ido-text">
                                                        <div className="left">IDO Supply:</div>
                                                        <div className="right">0 MST</div>
                                                    </div> */}
                                                    <div className="ido-text">
                                                        <div className="left">Total Supply:</div>
                                                        <div className="right">{detail?.tokenInfoIdo?.totalSupply.toLocaleString()} {detail?.tokenInfoIdo?.symbol}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>

                                <div className="list-ido-detail"><Link to="">Full Research Project <DoubleRightOutlined /></Link></div>

                                <Row gutter={30} className="mt-15">
                                    {
                                        detail?.roundIdos.length > 0 ? (
                                            detail?.roundIdos.map((round, index) => {
                                                return (
                                                    <Col xs={24} sm={8} xl={8} md={8} key={'round-' + index}>
                                                        <RoundItem detail={detail} tokenInfoIdo={detail?.tokenInfoIdo} item={round} />
                                                    </Col>
                                                )
                                            })
                                        ) : (
                                            <>No data </>
                                        )
                                    }
                                </Row>
                            </div>
                        </div>


                    </div>
                    <ApplyToLaunch />
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default IdoDetail
