
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Input, Spin, Space, Table, Tabs, Menu, Pagination } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import web3 from 'web3';
import { ethers } from 'ethers';
import { useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { RightCircleOutlined } from '@ant-design/icons';
import {
    getCumulativeLpTokenLockInfo,
    getLP,
    getNumberOfLPLock,
    lpLockCountForUser, lpLocksForUser
} from '../../utils';
import pairAbi from '../../../config/abi/pairAbi.json';
import NoneData from 'components/element/NoneData';

declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { TabPane } = Tabs;

const LiquidityLock = () => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const [activeTab, setActiveTab] = useState('all');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');
    const waveLockContract = useWaveLockContract(waveLockAddress)
    const [dataLocks, setDataLocks] = useState<any>();
    const [dataMyLocks, setDataMyLocks] = useState<any>();
    const [totalLocks, setTotalLocks] = useState<any>(0);
    const [totalMyLocks, setTotalMyLocks] = useState<any>(0);
    const [loading, setLoading] = useState<any>(false);
    const [searchAddress, setSearchAddress] = useState<any>('');

    const [param, setParam] = useState(
        {
            page: 0,
            size: 10,
            sort: 'id,desc'
        }
    );

    useEffect(() => {
        getLPLocks();
        if (activeTab === 'my-lock') {
            getMyLPLocks();
        }
    }, [account, param, searchAddress, activeTab]);

    const getLPLocks = async () => {
        setLoading(true);
        try {
            if (account) {
                let result: any[] = []
                const total = await getNumberOfLPLock(waveLockContract);

                if (parseInt(total) > 0) {
                    setTotalLocks(parseInt(total.toString()));

                    const startIndex = param.page * param.size;
                    let endIndex = startIndex + param.size;
                    if (endIndex > parseInt(total)) {
                        endIndex = parseInt(total);
                    }


                    const allLPLocks = await getCumulativeLpTokenLockInfo(waveLockContract, 0, parseInt(total));
                    const rvAllLPLocks = [...allLPLocks].reverse();
                    let pagingLPLocks: any = [];
                    if (searchAddress) {
                        pagingLPLocks = rvAllLPLocks.filter(e => {
                            return e.token.toLowerCase() === searchAddress.toLowerCase();
                        })
                    } else {
                        pagingLPLocks = [...rvAllLPLocks].slice(startIndex, endIndex);
                    }

                    if (pagingLPLocks) {
                        for (let i = 0; i < parseInt(total); i++) {
                            if (pagingLPLocks[i]) {
                                const tokenInfo = await getLP(pagingLPLocks[i].token, pairAbi, library, account);
                                result.push({
                                    id: i,
                                    shortName: `${tokenInfo?.token0Info.symbol}/${tokenInfo?.token1Info.symbol}`,
                                    fullName: `${tokenInfo?.token0Info.name}/${tokenInfo?.token1Info.name}`,
                                    token: pagingLPLocks[i].token,
                                    amount: parseFloat(ethers.utils.formatUnits(pagingLPLocks[i].amount, 18))
                                });
                            }

                        }
                    }
                    setDataLocks(result);
                }
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const getMyLPLocks = async () => {
        setLoading(true);
        try {
            if (account) {
                let result: any[] = []
                const total = await lpLockCountForUser(waveLockContract, account);
                if (parseInt(total) > 0) {
                    const myLPLocks = await lpLocksForUser(waveLockContract, account);
                    if (myLPLocks) {
                        for (let i = 0; i < parseInt(total); i++) {
                            if (myLPLocks[i]) {
                                const tokenInfo = await getLP(myLPLocks[i].token, pairAbi, library, account);
                                result.push({
                                    id: i,
                                    shortName: `${tokenInfo?.token0Info.symbol}/${tokenInfo?.token1Info.symbol}`,
                                    fullName: `${tokenInfo?.token0Info.name}/${tokenInfo?.token1Info.name}`,
                                    token: myLPLocks[i].token,
                                    amount: parseFloat(ethers.utils.formatUnits(myLPLocks[i].amount, 18))
                                });
                            }
                        }
                    }
                    setDataMyLocks(result);
                }
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };

    let totalRow = activeTab == 'my-lock' ? totalMyLocks : totalLocks;

    const LockContent = (props) => {
        const { type } = props;

        let data: any = searchAddress ? dataLocks : (type == 'my-lock' ? dataMyLocks : dataLocks);

        const columns = [
            {
                title: 'Liquidity token',
                dataIndex: 'token',
                key: 'token',
                render: (text, record) => {
                    let linkPath: any = type == 'my-lock' ? 'record' : 'detail';
                    let linkId: any = type == 'my-lock' ? record.id : record.token;
                    let linkDetail: any = '/wave-lock/' + linkPath + '/' + linkId;
                    return (
                        <Space key={record.id} size="middle">
                            <Link to={linkDetail}>{record.fullName}</Link>
                        </Space>
                    )
                }
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '',
                dataIndex: 'view',
                key: 'view',
                render: (text, record) => {
                    let linkPath: any = type == 'my-lock' ? 'record' : 'detail';
                    let linkId: any = type == 'my-lock' ? record.id : record.token;
                    let linkDetail: any = '/wave-lock/' + linkPath + '/' + linkId;
                    return (
                        <Space key={record.id} size="middle">
                            <Link to={linkDetail}>View</Link>
                        </Space>
                    )
                }
            },
        ];

        return (
            <>
                {loading ? (
                    <>
                        <div className="loading-center">
                            <Space size="middle"><Spin size="large" /></Space>
                        </div>
                    </>
                ) : (
                    <>
                        <Row gutter={30}>
                            {
                                data && data.length > 0 ? (
                                    data.map((item, index) => {
                                        let linkPath: any = type == 'my-lock' ? 'record' : 'detail';
                                        let linkId: any = type == 'my-lock' ? item.id : item.token;
                                        let linkDetail: any = '/wave-lock/' + linkPath + '/' + linkId;
                                        return (
                                            <Col xs={24} sm={6} xl={6} md={6} key={'round-' + index}>
                                                <div className='lock-item'>
                                                    <div className='lock-title'> <Link to={linkDetail}>{item.fullName}</Link> </div>
                                                    <div className='lock-amount'>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                <p className='label-cus mb-0'>Amount</p>
                                                            </Col>
                                                            <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                                <label className='label-cus text-danger'>
                                                                    <span className="mr-10">
                                                                        {item.amount}
                                                                    </span>
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='lock-view-detail'> <Link to={linkDetail}>View detail <RightCircleOutlined /></Link> </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                ) : (
                                    <NoneData text={'No data'} />
                                )
                            }

                        </Row>

                        {totalRow > param.size && (
                            <Pagination
                                // style={{ padding: 15, float: 'right' }}
                                current={param.page + 1}
                                defaultCurrent={1}
                                total={totalRow}
                                pageSize={param.size}
                                onChange={onChange_Pagi}
                                showSizeChanger={false}
                                className="pagiation-custom"
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const returnContentTab = () => {
        switch (activeTab) {
            case 'all':
                return <LockContent type="all" />;
                break;
            case 'my-lock':
                return <LockContent type="my-lock" />;
                break;
            default:
                break;
        }
    };
    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const address = e.target.value;
        if (ethers.utils.isAddress(address) || !address.trim()) {
            setSearchAddress(address);
        }
    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="lock-content-block">
                                <h3>WaveLock</h3>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className='gutter-row' xs={16}>
                                        <Menu mode="horizontal" defaultSelectedKeys={['liquidity']} className="lock-menu">
                                            <Menu.Item key="token">
                                                <Link to="/wave-lock/token">
                                                    Token
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="liquidity">
                                                <Link to="/wave-lock/liquidity">
                                                    Liquidity
                                                </Link>
                                            </Menu.Item>
                                        </Menu>
                                    </Col>
                                    <Col className='gutter-row text-right text-danger' xs={8}>
                                        <div className="lock-search">
                                            <Input className="amount-buy" placeholder="Search by LP token address ..." onChange={onChangeSearch} />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="lock-token-list">
                                    <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="tab-lock-cus">
                                        <TabPane tab="All Lock" key="all">
                                            {returnContentTab()}
                                        </TabPane>
                                        <TabPane tab="My Lock" key="my-lock">
                                            {returnContentTab()}
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default LiquidityLock
