import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Pagination, Tabs } from 'antd';
import { useActiveWeb3React } from 'hooks';
import NoneData from 'components/element/NoneData';
import { privatesSaleApi } from 'config/api/privatesSaleApi';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import './style.css';
import PrivateBanner from './private-banner';
import FilterChain from 'components/FilterChain';
import { chainApi } from 'config/api/chainApi';
import PrivateSaleItem from './private-sale-item';

const { TabPane } = Tabs;
const { Content } = Layout;

const PrivateList = () => {

    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);
    const [dataLaunchpad, setDataLaunchpad] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);

    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(0);
    const [activeTab, setActiveTab] = useState('all');
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            tokenSaleFilter: 'ALL',
            chainId: 0
        }
    );

    const getPrivateList = async (type, status, chainId) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            tokenSaleFilter: type,
            chainId: param.chainId ? param.chainId : chainId ? chainId : 0
        };
        try {
            let res = await privatesSaleApi.getPrivates(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {

                if (res.data.length > 0) {
                    let launchpadList: any = [];
                    if (status == 'all' || status == 'filled') {
                        setLoadingData(false);
                        setDataLaunchpad(res.data);

                    } else {
                        res.data.forEach((item, index) => {
                            let nowDate = new Date(Date.now());
                            let startDate = new Date(item.startTime);
                            let endDate = new Date(item.endTime);

                            if (status == 'upcoming') {
                                if (startDate > nowDate && endDate > nowDate && item.roundAction != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'opening') {
                                if (startDate < nowDate && endDate > nowDate && item.roundAction != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'closed') {
                                if (startDate < nowDate && endDate < nowDate && item.roundAction != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }

                        });
                        setLoadingData(false);
                        setDataLaunchpad(launchpadList);
                        launchpadList.length > 0 ? setTotalRow(parseInt(res.headers['x-total-count'])) : setTotalRow(0);
                    }

                } else {
                    setLoadingData(false);
                    setDataLaunchpad([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    };
    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 20, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                    if (isTestnet === true) {
                        setChainNetworkListTest(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList(false);
        getChainNetworkList(true);
    }, []);

    useEffect(() => {
        if (activeTab) {
            switch (activeTab) {
                case 'all':
                    getPrivateList('ALL', 'all', chainId);
                    break;
                case 'upcoming':
                    getPrivateList('ALL', 'upcoming', chainId);
                    break;
                case 'opening':
                    getPrivateList('ALL', 'opening', chainId);
                    break;
                case 'filled':
                    getPrivateList('FILLED', 'filled', chainId);
                    break;
                case 'closed':
                    getPrivateList('ALL', 'closed', chainId);
                    break;
                default:
                    break;
            }
        }
    }, [activeTab, param, chainId]);

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };


    const PrivateContent = () => {
        return (
            <>
                <Row gutter={30}>
                    {!loadingData ? (
                        <>
                            {dataLaunchpad?.length > 0 ? (
                                dataLaunchpad.map((item, index) => {

                                    let logoNetwork: any = '';
                                    let title: any = '';
                                    let chain: any;
                                    chain = chainNetworkListMain.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    if (chain == undefined || chain.length == 0) {
                                        chain = chainNetworkListTest.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    }
                                    if (chain[0]) {
                                        logoNetwork = chain[0].logoLink;
                                        title = chain[0].name;
                                    }

                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'private-' + index}>
                                            <PrivateSaleItem logoNetwork={logoNetwork} titleNetwork={title} item={item} />
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <NoneData text={'No Data'} link="/private-sale/create" />
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </Row>
            </>
        );
    };

    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content bg-white-ps">
                        <PrivateBanner />
                        <div className="project-block ps-project-block">
                            <div className="project-content container">
                                <div className="projects-grid">
                                    <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="filter-block"
                                        tabBarExtraContent={
                                            <div className='filter-block-form'>
                                                <Form form={form} name="horizontal_login" layout="inline">
                                                    <FilterChain
                                                        key={122}
                                                        active={chainId}
                                                        handleChangeChain={(value) => {
                                                            setParam({
                                                                ...param,
                                                                chainId: value
                                                            });
                                                        }}
                                                    />
                                                </Form>
                                            </div>
                                        }
                                    >
                                        <TabPane tab="All" key="all">
                                            <PrivateContent />
                                        </TabPane>
                                        <TabPane tab="Upcoming" key="upcoming">
                                            <PrivateContent />
                                        </TabPane>
                                        <TabPane tab="Opening" key="opening">
                                            <PrivateContent />
                                        </TabPane>
                                        <TabPane tab="Filled" key="filled">
                                            <PrivateContent />
                                        </TabPane>
                                        <TabPane tab="Closed" key="closed">
                                            <PrivateContent />
                                        </TabPane>
                                    </Tabs>

                                    {totalRow > 9 && (
                                        <Pagination
                                            current={param.page + 1}
                                            defaultCurrent={1}
                                            total={totalRow}
                                            pageSize={param.size}
                                            onChange={onChange_Pagi}
                                            showSizeChanger={false}
                                            className="pagiation-custom"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Content>
        </>

    )
}

export default PrivateList
