import 'antd/dist/antd.css';
import Slider from "react-slick";
import './style.css';

const Partners = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        row: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>

            <div className="partners">

                {/* <div className="our-investor">
                    <Row gutter={{ xs: 8, sm: 15, md: 15, lg: 15 }}>
                        <Col className="gutter-row" xs={24} xl={15} md={15} sm={15}>
                            <div className="our-investor-info">
                                <div className="investor-name">
                                    Why we do this?
                                </div>
                                <div className="investor-des">
                                    First, this is what we do best - coding. With tech skill as our main strength, we bring smarter, more cost-efficient, and most importantly, safer tools to users. Second, we love those turbulent waves of crypto!
                                    <p><i>“Because we can. And if we can we do. - Thomas Shelby”</i></p>
                                </div>
                                <div className="investor-tradenow">
                                    <Link className="link-button" to="">More about us</Link>
                                </div>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} xl={9} md={9} sm={9}>
                            <div className="our-investor-img">
                                <img src='../images/logo-white.png' />
                            </div>
                        </Col>
                    </Row>
                </div> */}
                <div className="partners-content container">

                    <div className="partners-title">
                        Exchanges & Partners
                    </div>
                    <div className="partners-slide-content">
                        <div className="slide-partners">
                            <Slider {...settings} className="partners-slide">
                                <div className="p-lr-15">
                                    <div className="partners-item">
                                        <img src="../images/wise-crypto-w.png" />
                                    </div>
                                </div>
                                <div className="p-lr-15 ">
                                    <div className="partners-item mt-20">
                                        <img src="../images/taskon-other.png" />
                                    </div>
                                </div>
                                <div className="p-lr-15">
                                    <div className="partners-item">
                                        <img src="../images/logo_shield_text_white.svg" />
                                    </div>

                                </div>
                                <div className="p-lr-15">
                                    <div className="partners-item mt-20">
                                        <img src="../images/cyberconnect.png" />
                                    </div>
                                </div>
                                <div className="p-lr-15">
                                    <div className="partners-item">
                                        <img src="../images/partner/web3-space.png" className='special-img-partner'/>
                                    </div>
                                </div>
                                {/* <div className="p-lr-15">
                                    <div className="partners-item">
                                        <img src="../images/partner-tokocrypto.png" />
                                    </div>

                                </div>

                                <div className="p-lr-15">
                                    <div className="partners-item mt-20">
                                        <img src="../images/partner-mexc-global.png" />
                                    </div>

                                </div>
                                <div className="p-lr-15">
                                    <div className="partners-item">
                                        <img src="../images/partner-lbank.png" />
                                    </div>

                                </div> */}


                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Partners
