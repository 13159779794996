import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select, Input, Button, Tag, Progress, Pagination } from 'antd';
import { SearchOutlined, TwitterOutlined, CodeSandboxOutlined, GlobalOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import NoneData from 'components/element/NoneData';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import Countdown from 'react-countdown';
import '../ico-project/style.css';
import './style.css';
import FilterChain from 'components/FilterChain';

const { Option } = Select;
const { Content } = Layout;

const MyProject = () => {
    let tokenID = window.localStorage.getItem('tokenId');
    const [classTab, setClassTabs] = useState('');
    const history = useHistory();
    const [chainNetworkList, setChainNetworkList] = useState<any>([]);
    const [dataLaunchpad, setDataLaunchpad] = useState<any>([]);
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(20);
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            chainId: chainId ? chainId : 0
        }
    );

    const getDataLaunchpad = async (account) => {
        const dataFilter = {
            ...param
        };
        try {
            let res = await IdoLaunchpad.getLaunchpadByAddress(account, dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setDataLaunchpad(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (account) {
            getDataLaunchpad(account);
        }
    }, [account, param]);
    const getChainNetworkList = async () => {
        const dataFilter = {
            page: 0,
            size: 20,
            sort: 'id,desc', vm: 'EVM'
        };
        try {
            let res = await IdoLaunchpad.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setChainNetworkList(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList();
    }, []);


    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
        const dataFilter = {
            ...param,
            page: pageNumber - 1
        };
        // getDataAsset(dataFilter, tokenID);

    };
    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        }
        if (days > 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else if (days === 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        }
    };
    const getProgressTime = useCallback((startTime) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);
    const ChainNetworkRender = (chainSymbol) => {
        let logoNetwork: any = '';
        let title: any = '';
        let chain = chainNetworkList.filter(itemChain => chainSymbol === itemChain.symbol);
        if (chain[0]) {
            logoNetwork = chain[0].logoLink;
            title = chain[0].name;
        }
        return <img title={title} src={logoNetwork} />;
    }
    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status: any = '';
        let color: any = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#87d068';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag color={color}>{status}</Tag>;
    }
    const LaunchpadContent = () => {
        let nowDate = new Date(Date.now());
        return (
            <>
                <Row gutter={30}>
                    {dataLaunchpad?.length > 0 ? (
                        dataLaunchpad.map((item, index) => {
                            let startDate = new Date(item?.rounds[0]?.startTime);
                            let endDate = new Date(item?.rounds[0]?.endTime);

                            return (
                                <Col xs={24} sm={8} xl={8} md={8} key={index}>
                                    <div className="project-item project-item-close">
                                        <div className="project-image">
                                            <img src={item?.backgroundLink ? item?.backgroundLink : "../images/project-1.png"} />
                                            <div className="project-chain">
                                                <div className="project-token-main">{item?.tokenInfo?.symbol}</div>
                                                <div className="project-token-chain">
                                                    {ChainNetworkRender(item?.rounds[0]?.networkChain)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="project-item-content">
                                            <div className="project-name"><Link className='text-info' to={`/ico/detail/${item?.id}?status=upcoming`}>{item?.name} {item?.rounds[0]?.name ? ' - ' + item?.rounds[0]?.name : ''}</Link></div>
                                            <div className="project-status-social">
                                                {StatusRender(nowDate, startDate, endDate, item?.rounds[0]?.roundStatus)}
                                                <span className="social">
                                                    <Link to=""><TwitterOutlined className="icon" /></Link>
                                                    <Link to=""><GlobalOutlined className="icon" /></Link>
                                                    <Link to=""><CodeSandboxOutlined className="icon" /></Link>
                                                </span>
                                            </div>
                                            <div className="project-total-raise">
                                                <span>Soft /Hard Cap</span>
                                                <span className='text-info'>{item?.softCap} {item?.rounds[0]?.currencyPay}/ {item?.hardCap} {item?.rounds[0]?.currencyPay}</span>
                                            </div>
                                            <div className="project-total-raise">
                                                <span>Price</span>
                                                <span className='text-info'>1 {item?.rounds[0]?.currencyPay} = {item?.rounds[0]?.swapRate} {item?.tokenInfo?.symbol}</span>
                                            </div>
                                            <hr></hr>
                                            <div className="project-registration">
                                                {startDate > nowDate && endDate > nowDate && (
                                                    <>
                                                        <span className="sale-start-in">Sale start in </span>
                                                        <Countdown
                                                            date={getProgressTime(startDate)}
                                                            renderer={rendererCountDown}
                                                        />
                                                    </>
                                                )}
                                                {startDate < nowDate && endDate > nowDate && (
                                                    <>
                                                        <span className="sale-start-in">Sale end in </span>
                                                        <Countdown
                                                            date={getProgressTime(endDate)}
                                                            renderer={rendererCountDown}
                                                        />
                                                    </>
                                                )}
                                                {endDate < nowDate && (
                                                    <>
                                                        <span className="sale-start-in">Canceled </span>
                                                    </>
                                                )}
                                            </div>
                                            <Progress percent={0} className="project-progress-percent" />


                                            <div className="project-total-raise">
                                                <span>Lockup Time : </span>
                                                <span>
                                                    {item?.doManualListing ? (
                                                        <>
                                                            Unlocked
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item?.liquidityLockup} days
                                                        </>
                                                    )}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    ) : (
                        <>
                            <NoneData text={'No Data'} link="/ico/create" />
                        </>
                    )}
                </Row>
            </>
        );
    };


    const onFinish = (values: any) => {

    };
    return (
        <>
            <div className="projects-top">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                    <Col className="gutter-row" xs={24}>
                        <div className="project-filter">
                            <Form form={form} name="horizontal_login" onFinish={onFinish}>

                                <FilterChain
                                    handleChangeChain={(value) => {
                                        setParam({
                                            ...param,
                                            chainId: value
                                        });
                                    }}
                                />
                                <Form.Item
                                    name="search"
                                    rules={[{ required: true, message: 'Please input account address!' }]}
                                >
                                    <Input className="input-search" placeholder="Search by keyword" />
                                </Form.Item>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="projects-grid">
                <LaunchpadContent />

                {totalRow > 10 && (
                    <Pagination
                        // style={{ padding: 15, float: 'right' }}
                        current={param.page + 1}
                        defaultCurrent={1}
                        total={totalRow}
                        pageSize={param.size}
                        onChange={onChange_Pagi}
                        showSizeChanger={false}
                        className="pagiation-custom"
                    />
                )}
            </div>
        </>
    )
}

export default MyProject
