
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { stakingApi } from 'config/api/stakingApi';
import '../style.css';
import NoneData from 'components/element/NoneData';
import StakingItem from './staking-item';
import FlexibleBanner from './banner';
import { Helmet } from "react-helmet"

const { Content } = Layout;
const { Option } = Select;
const Staking = () => {

    const [totalRow, setTotalRow] = useState(0);
    const [classTab, setClassTabs] = useState('');
    const history = useHistory();
    const typeProject = history.location.search.replace('?', '');
    const [form] = Form.useForm();
    const [stakingList, setStakingList] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);

    let typeList: any = 'STAKE';

    const displayTabs = (action: string) => {
        history.push({
            pathname: '',
            search: action
        });
    };
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            typeEnum: 'STAKE',
            keyword: '',
            w86: true,
        }
    );

    const getStakingList = async (status) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            active: status
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setLoadingData(false);
                    setStakingList(res.data);
                } else {
                    setLoadingData(false);
                    setStakingList([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        if (classTab) {
            switch (classTab) {
                case 'active':
                    getStakingList(true);
                    break;
                case 'inactive':
                    getStakingList(false);
                    break;
                default:
                    break;
            }
        }
    }, [classTab]);


    useEffect(() => {
        if (typeProject) {
            setClassTabs(typeProject);
        } else {
            setClassTabs('active');
        }
    }, [typeProject]);

    const StakeItemContent = (props) => {

        const { type } = props;

        return (
            <>

                {!loadingData ? (
                    <>
                        {stakingList?.length > 0 ? (
                            stakingList.map((item, index) => {
                                return (
                                    <StakingItem item={item} />
                                )
                            })
                        ) : (
                            <>
                                <NoneData text={'No Data'} link="https://docs.google.com/forms/d/e/1FAIpQLSeImXqte1_xMaNqLVuLBxGAgEpx0OUeAxj7HQhMWX-OniNawg/viewform" linkText="Apply for Staking" />
                            </>
                        )}
                    </>
                ) : (<>
                    <div className="loading-data">
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    </div>
                </>)}


            </>
        );
    };

    const returnContentProjects = () => {
        switch (classTab) {
            case 'active':
                return <StakeItemContent type="active" />;
                break;
            case 'inactive':
                return <StakeItemContent type="inactive" />;
                break;
            default:
                break;
        }
    };
    return (
        <>

            <HeaderMobile />
            <Content className="page-container">
                <HeaderTop pageType={'color'} chainId={''} />
                <div className='page-main bg-white-ps'>
                    <FlexibleBanner />
                    <div className="page-content container">

                        <div className="project-block">
                            <div className="projects-top">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                        <div className='filter-staking-status'>
                                            <div className="projects-btn-status">
                                                <button type="button" className={classTab == 'active' ? 'active' : ''} onClick={() => displayTabs('active')}>
                                                    Active
                                                </button>
                                                <button type="button" className={classTab == 'inactive' ? 'active' : ''} onClick={() => displayTabs('inactive')}>
                                                    InActive
                                                </button>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                        <div className="project-filter">
                                            <Form form={form} name="horizontal_login">
                                                <Form.Item
                                                    name="token_type"
                                                    rules={[{ required: true, message: 'Please input type!' }]}
                                                    initialValue="ALL"
                                                >
                                                    <Select className="select-chain" >
                                                        <Option value="ALL">ALL Chain</Option>
                                                        <Option value="BSC">Binance Smart Chain</Option>
                                                        <Option value="ETHEREUM">Ethereum</Option>
                                                        <Option value="FANTOM">Fantom Opera</Option>
                                                        <Option value="SOLANA">Solana</Option>
                                                        <Option value="CELO">Celo</Option>
                                                        <Option value="TRON">Tron</Option>
                                                        <Option value="AVAX">Avax</Option>
                                                        <Option value="POLYGON">Polygon</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name="search"
                                                    rules={[{ required: true, message: 'Please input account address!' }]}
                                                >
                                                    <Input className="input-search" placeholder="Search by keyword" />
                                                </Form.Item>

                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="project-content">

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {returnContentProjects()}

                                </Row>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

            </Content>
        </>
    )
}

export default Staking
