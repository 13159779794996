
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Select, Input, Tooltip, Button, Table, Space, Spin } from 'antd';
import { CalculatorFilled, DownOutlined, PlusOutlined, QuestionCircleOutlined, RiseOutlined, UpOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import LoadingFull from 'components/loading-full';
import ConnectWallet from 'components/connect-wallet/ConnectWallet';
import web3 from 'web3';
import { useWrap } from '../../../context/WrapperContext';
import { ethers } from 'ethers';
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';
import { getInformationByChain } from 'config/network/multichainAddresses';
import stakingAbi from '../../../config/abi/stakingAbi.json';
import {
    useContract
} from "../../../hooks/useContract";

import {
    approveToken, getTokenAllowance, getTokenInformation
} from '../../utils';
import {
    getProgressTime,
    rendererCountDown,
    _totalStakedUser,
    _totalStaked,
    _stakeToken,
    _totalReward,
    _harvetReward,
    _unstaking
} from "../utilsStaking";
import TableRoi from './TableRoi';
import NoneData from 'components/element/NoneData';
import ApyCalculatorModal from '../ApyCalculatorModal';

declare const window: Window & typeof globalThis & { ethereum: any };
let intervalReward: any = null;

const FarmingItem = (props) => {
    const { item } = props;
    const { chainId, account, library } = useActiveWeb3React();
    const { showNoti } = useWrap();
    const [form] = Form.useForm();
    const [allowance, setAllowance] = useState(0);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [showRoi, setShowRoi] = useState(false);
    const [showAmountPopup, setShowAmountPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalStakedUsers, setTotalStakedUser] = useState<any>(0);
    const [totalStaked, setTotalStaked] = useState<any>(0);
    const [totalReward, setTotalReward] = useState<any>(0);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [stakeTokenBalance, setStakeTokenBalance] = useState(0);
    const [decimal, setDecimal] = useState(0);
    const [tokenEarnPrice, setTokenEarnPrice] = useState<any>(10);


    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let earnTokenAddress: any = item.earnToken;
    let stakeTokenAddress: any = item.stakeToken;
    let poolContractAddress: any = item.poolContract;

    const poolContract = useContract(poolContractAddress, stakingAbi);
    const stakeTokenContract = useContract(stakeTokenAddress, tokenInfoAbi);

    const onShowRoi = () => {
        setShowRoi(true);
    }
    const showDetail = () => {
        setIsShowDetail(!isShowDetail);
    }

    const getTokenInfo = async (stakeTokenAddress, poolContractAddress) => {
        setIsLoadingInfo(true);
        let allowanceSC = await getTokenAllowance(tokenInfoAbi, stakeTokenAddress, account, poolContractAddress)
        const tokenInfoSC = await getTokenInformation(tokenInfoAbi, stakeTokenAddress);
        setDecimal(tokenInfoSC.decimals);
        setIsLoadingInfo(false);
        setAllowance(parseFloat(ethers.utils.formatUnits(allowanceSC, parseInt(tokenInfoSC.decimals))));
    }

    const getRewardTokenInterval = () => {
        intervalReward = setInterval(() => {
            (async () => {
                try {
                    _totalReward(poolContract, earnTokenAddress, account).then(
                        (res: any) => {
                            let totalReward: any = ethers.utils.formatUnits(res, 'ether');
                            setTotalReward(totalReward);
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            })();
        }, 5000);
    };

    useEffect(() => {
        if (account) {
            _totalStakedUser(poolContract, account).then(
                (res: any) => {
                    let totalStakedUser: any = ethers.utils.formatUnits(res.amount, 'ether');
                    setTotalStakedUser(totalStakedUser);
                }
            );
            _totalStaked(poolContract).then(
                (res: any) => {
                    let totalStaked: any = ethers.utils.formatUnits(res, 'ether');
                    setTotalStaked(totalStaked);
                }
            );
            _totalReward(poolContract, earnTokenAddress, account).then(
                (res: any) => {
                    let totalReward: any = ethers.utils.formatUnits(res, 'ether');
                    setTotalReward(totalReward);
                }
            );
            getRewardTokenInterval();
        }

    }, [account])

    useEffect(() => {
        if (stakeTokenAddress && poolContractAddress && account) {

            getTokenInfo(stakeTokenAddress, poolContractAddress)
        }
    }, [account, stakeTokenAddress, poolContractAddress]);

    const closeBox = (e) => {
        e.preventDefault()
        setShowRoi(false);
        setShowAmountPopup(false);
    };

    // APPROVE TOKEN

    const handleApproveToken = async (e) => {
        try {
            setIsLoading(true);
            e.preventDefault()

            await approveToken(tokenInfoAbi, stakeTokenAddress, library, account, poolContractAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve Successfully`);
                                        countNoti++;
                                        setAllowance(Math.pow(2, 256));
                                    } else {
                                        showNoti('error', `Approve Failed`);
                                    }
                                    setIsLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const onShowAddAmount = () => {
        setShowAmountPopup(true);
    }

    // confirm stake
    const onConfirmAmount = async (values: any) => {

        setIsLoading(true);

        await _stakeToken(poolContract, values.amount)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        let totalStakedNew: any = parseFloat(totalStaked) + parseFloat(values.amount);
                                        let totalStakedUsersNew: any = parseFloat(totalStakedUsers) + parseFloat(values.amount);

                                        showNoti('success', 'Stake Successfully');
                                        setShowAmountPopup(false);
                                        setIsLoading(false);
                                        setTotalStaked(totalStakedNew);
                                        setTotalStakedUser(totalStakedUsersNew);
                                    }
                                } else {
                                    showNoti('error', 'Stake Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });
    }

    const onHarvetReward = async () => {

        setIsLoading(true);

        await _harvetReward(poolContract, true)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        showNoti('success', 'Hartvet Successfully');
                                        setIsLoading(false);
                                        setTotalReward(0);
                                    }
                                } else {
                                    showNoti('error', 'Hartvet Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                console.log(error);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });


    }

    const onUnstake = async () => {

        setIsLoading(true);

        await _unstaking(poolContract, false)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        showNoti('success', 'Unlock Successfully');
                                        setIsLoading(false);
                                        setTotalReward(0);
                                        setTotalStaked(0);
                                        setTotalStakedUser(0);
                                    }
                                } else {
                                    showNoti('error', 'Unlock Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                console.log(error);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });


    }

    const onSetMax = (stakeTokenBalance: any) => {
        form.setFieldsValue(
            {
                amount: stakeTokenBalance
            }
        )
    }

    return (
        <>
            <div className='farm-item'>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                    <Col className="gutter-row" xs={24} xl={5} md={5} sm={5}>
                        <div className='farm-token-name pt-5'>
                            <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                                <Col className="gutter-row" xs={12} xl={8} md={8} sm={8}>
                                    <div className="item-img">
                                        <img src={item.stakeTokenLogo} />
                                        <img src={item.earnTokenLogo} />
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={16} md={16} sm={16}>
                                    <div className='token-farm-name'>{item.title}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={4} md={4} sm={4}>
                        <div className='farm-title'>
                            <span className='head-title'>APR</span>
                            <Tooltip placement="top" title={`APR is calculated by summing up the rewards of the liquidity providers 9.61% and the rewards ${item.apr} %.`}>
                                <QuestionCircleOutlined className='icon-title' />
                            </Tooltip>
                        </div>
                        <div className='farm-percent'>
                            {item.apr}%
                            <span><CalculatorFilled className='icon-title' onClick={() => onShowRoi()} /></span>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={5} md={5} sm={5}>
                        <div className='farm-title'>
                            <span className='head-title'>Liquidity</span>
                        </div>
                        <div className='farm-percent'>
                            {totalStaked.toLocaleString()} {item.stakeTokenName}
                            <Tooltip placement="top" title={'The total value of the funds in this farm’s liquidity pool.'}>
                                <QuestionCircleOutlined className='icon-title' />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={4} md={4} sm={4}>
                        <div className='farm-title'>
                            <span className='head-title'>Earned</span>
                        </div>
                        <div className='farm-percent'>
                            {totalReward > 0 ? (
                                <>
                                    {totalReward.toLocaleString()}
                                </>
                            ) : (
                                <>-</>
                            )}
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={4} md={4} sm={4}>
                        <div className='farm-detail'>
                            <Button htmlType='button' onClick={showDetail}>
                                Detail
                                {isShowDetail ? (
                                    <UpOutlined />
                                ) : (
                                    <DownOutlined className='icon-title' />
                                )}
                            </Button>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={1} md={1} sm={1}>
                        <div className='farm-title'>
                            <QuestionCircleOutlined className='icon-title mt-15' />
                        </div>
                    </Col>
                </Row>
                {isShowDetail && (
                    <div className='farm-detail-content'>
                        <Row gutter={{ xs: 8, sm: 20, md: 20, lg: 20 }}>
                            <Col className="gutter-row line-right" xs={24} xl={8} md={8} sm={8}>
                                <div className='farm-get-lp'>
                                    <Button htmlType='button'>
                                        Get LP
                                    </Button>
                                </div>
                                <div className='farm-get-lp'>
                                    <p className='detail-title-farm'>Available LP</p>
                                    <p>{stakeTokenBalance.toLocaleString()} LP</p>
                                    <p>$0.0000</p>
                                </div>
                            </Col>
                            <Col className="gutter-row line-right" xs={24} xl={8} md={8} sm={8}>
                                <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <div className='btn-farm text-center'>
                                            {account ? (
                                                <>
                                                    {allowance > 0 ? (
                                                        <Button htmlType='button' disabled={isLoading} className='btn-all' onClick={onShowAddAmount}>Stake LP {isLoading && <Spin className="ml-10" size="small" />}</Button>
                                                    ) : (
                                                        <Button htmlType='button' disabled={isLoading} onClick={handleApproveToken} className='btn-all'>Enable Farm {isLoading && <Spin className="ml-10" size="small" />}</Button>
                                                    )}
                                                </>
                                            ) : (
                                                <ConnectWallet />
                                            )}

                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        {allowance > 0 && (
                                            <div className='farm-get-lp text-left'>
                                                <p className='detail-title-farm'>Stake LP</p>
                                                <p>{totalStakedUsers.toLocaleString()} LP</p>
                                                <p>$0.0000</p>
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                            </Col>
                            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                <div className='farm-get-lp'>
                                    <Button disabled={parseFloat(totalReward) > 0 ? false : true} htmlType='button' className='btn-all text-white' onClick={onHarvetReward}>Harvest {isLoading && <Spin className="ml-10" size="small" />}</Button>
                                </div>
                                <div className='farm-get-lp'>
                                    <p className='detail-title-farm'>Earned</p>
                                    <p>
                                        {totalReward > 0 ? (
                                            <>
                                                {totalReward.toLocaleString()}
                                            </>
                                        ) : (
                                            <>0</>
                                        )} {item.earnTokenName}</p>
                                    <p>$0.0000</p>
                                </div>
                            </Col>

                        </Row>
                    </div>
                )}
            </div>
            <Modal
                title={<div className="text-md custom-modal-title">ROI</div>}
                visible={showRoi}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p>Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</p>

                    <ApyCalculatorModal
                        tokenPrice={tokenEarnPrice}
                        apr={item.apr}
                        earningTokenSymbol={item.earnTokenName}
                        performanceFee={item.unStakeFee}
                    />

                    <div className="modal-btn-group mt-15">
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>
            <Modal
                title={<div className="text-md custom-modal-title">Stake {item.stakeTokenName} Tokens</div>}
                visible={showAmountPopup}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">

                    <Form
                        form={form}
                        name="basic"
                        autoComplete="off"
                        onFinish={onConfirmAmount}
                    >

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={12}>
                                <p className='mb-0' style={{ paddingTop: '7px' }}>
                                    max stake: {item.maxAmountStake > 0 ? (
                                        <span className='text-info'>{item.maxAmountStake.toLocaleString()}</span>
                                    ) : (
                                        <>Unlimit</>
                                    )}
                                </p>
                            </Col>
                            <Col className="gutter-row" xs={12}>
                                <p className='text-right text-info'>
                                    <strong className='text-danger'>{stakeTokenBalance.toLocaleString()}</strong> {item.stakeTokenName} Available
                                    <Button htmlType='button' onClick={() => onSetMax(stakeTokenBalance)} className='btn-max btn-all'>Max</Button>
                                </p>
                            </Col>
                        </Row>
                        <Form.Item name="amount" label="Amount"
                            rules={[
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {

                                        if (!value || parseFloat(value) <= 0) {
                                            cb('Invalid value')
                                        } else if (item.maxAmountStake > 0 && parseFloat(value) > item.maxAmountStake) {
                                            cb('Invalid limit')
                                        } else if (parseFloat(value) > stakeTokenBalance) {
                                            cb("Insufficient Balance");
                                        } else {
                                            cb();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" placeholder="Ex: 1000" />

                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" type="submit" disabled={isLoading}>Confirm {isLoading && <Spin className="ml-10" size="small" />}</button>
                            <button className="btn-default" onClick={closeBox} disabled={isLoading}>Close {isLoading && <Spin className="ml-10" size="small" />}</button>
                        </Form.Item>
                    </Form>

                </div>

            </Modal>
            {isLoading && (
                <LoadingFull />
            )}
        </>
    )
}

export default FarmingItem
