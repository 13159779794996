
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, message, Modal, InputNumber, Alert, Radio, Checkbox } from 'antd';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { dexExchanges } from 'config/network/dex';
import {
    INIT_LAUNCHPAD_FEE,
    LAUNCHPAD_AMOUNT_RAISED_FEE,
    LAUNCHPAD_TOKEN_RAISED_FEE,
    LAUNCHPAD_TOKEN_RAISED_FEE_ONLY,
    LIST_LAUNCHPAD_RAISED_FEE,
    LIST_LAUNCHPAD_RAISED_FEE_ONLY,
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE, LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1, LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2
} from '../../../../keyConfig';

const { Option } = Select;
const { Content } = Layout;

declare const window: Window & typeof globalThis & { ethereum: any };

const DefiLaunchpadInfo = (props) => {
    const { form } = props;

    const { showNoti } = useWrap();
    const [cexList, setCexList] = useState<any>([]);
    let storageLaunchpadInfo = window.localStorage.getItem('launchpadInfo');
    let storageTokenInfo = window.localStorage.getItem('tokenInfo');
    let launchpadInfo;
    let tokenInfo;
    if (storageLaunchpadInfo) {
        launchpadInfo = JSON.parse(storageLaunchpadInfo);
    }
    if (storageTokenInfo) {
        tokenInfo = JSON.parse(storageTokenInfo);
    }

    let isManualListing: any = tokenInfo && tokenInfo.listing_option == 0 ? false : true;

    const [listingRateQuantity, setListingRateQuantity] = useState(0);
    const [presaleRate, setPresaleRate] = useState(0);
    const [hardCap, setHardCap] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [listingRate, setListingRate] = useState(0);
    const [teamTokens, setTeamTokens] = useState(0);
    const [showVestingContributor, setShowVestingContributor] = useState(launchpadInfo?.vesting_contributor ? true : false);
    const [totalNeededTokens, setTotalNeededTokens] = useState(launchpadInfo?.vesting_contributor ? true : false);
    const [raisedFeeOnly, setRaisedFeeOnly] = useState<any>(0);
    const [tokenRaisedFee, setTokenRaisedFee] = useState<any>(0);
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [routers, setRouters] = useState<any>({});
    const [factories, setFactories] = useState<any>({});
    const { chainId } = useActiveWeb3React();


    //BNB FEE PERCENT
    const [bnbFeePercent, setBNBFeePercent] = useState<any>(0);




    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>(0);


    //TOKEN_W86_FEE_OPTION_1
    const [tokeW86FeeOption1, setTokeW86FeeOption1] = useState<any>(0);

    //TOKEN_W86_FEE_OPTION_2
    const [tokeW86FeeOption2, setTokeW86FeeOption2] = useState<any>(0);


    function onChangeVestingContributor(e) {
        setShowVestingContributor(e.target.checked);
    }

    const calculateTotalNeededTokens = (presaleRate, listingRate, liquidity, hardCap, totalTeamTokens, tokenFeePercent, nativeTokenFeePercent) => {
        presaleRate = presaleRate ?? 0;
        listingRate = listingRate ?? 0;
        liquidity = liquidity ?? 0;
        hardCap = hardCap ?? 0;
        totalTeamTokens = totalTeamTokens ?? 0;
        tokenFeePercent = tokenFeePercent ?? 0;
        nativeTokenFeePercent = nativeTokenFeePercent ?? 0;
        let totalPresaleTokens = presaleRate * hardCap;
        let totalLiquidityTokens = (((hardCap - (hardCap * nativeTokenFeePercent) / 100) * liquidity) / 100) * listingRate;
        let totalFeeTokens = (totalPresaleTokens * tokenFeePercent) / 100;
        return totalTeamTokens + totalPresaleTokens + totalLiquidityTokens + totalFeeTokens;
    };


    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0 && tokenInfo?.fee_options) {

                    JSON.parse(tokenInfo?.fee_options).forEach(element => {


                        if (element == LIST_LAUNCHPAD_RAISED_FEE_ONLY) {
                            let launchpadRaisedFeeOnlyObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE_ONLY === itemSetting.settingKey);
                            if (launchpadRaisedFeeOnlyObj && launchpadRaisedFeeOnlyObj[0]) {
                                setBNBFeePercent(parseFloat(launchpadRaisedFeeOnlyObj[0].settingValue));

                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_RAISED_FEE) {
                            let launchpadTokenRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                            if (launchpadTokenRaisedFeeObj && launchpadTokenRaisedFeeObj[0]) {
                                setLaunchpadTokenRaisedFee(parseFloat(launchpadTokenRaisedFeeObj[0].settingValue));
                            }
                        }

                        if (element == LIST_LAUNCHPAD_RAISED_FEE) {
                            let launchpadRaisedFeeObj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_RAISED_FEE === itemSetting.settingKey);
                            if (launchpadRaisedFeeObj && launchpadRaisedFeeObj[0]) {
                                setBNBFeePercent(parseFloat(launchpadRaisedFeeObj[0].settingValue));
                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1) {
                            let tokeW86FeeOption1Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1 === itemSetting.settingKey);
                            if (tokeW86FeeOption1Obj && tokeW86FeeOption1Obj[0]) {
                                setTokeW86FeeOption1(tokeW86FeeOption1Obj[0]);
                            }
                        }

                        if (element == LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2) {
                            let tokeW86FeeOption2Obj = res.data.filter((itemSetting) => LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2 === itemSetting.settingKey);
                            if (tokeW86FeeOption2Obj && tokeW86FeeOption2Obj[0]) {
                                setTokeW86FeeOption2(tokeW86FeeOption2Obj[0]);
                            }
                        }
                    })


                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        if (chainId) {
            const dexList = dexExchanges[chainId];

            if (dexList) {
                setCexList(dexList);
                const rt = dexList.reduce(
                    (obj, item) => Object.assign(obj, { [item.id]: item.routerAddress }), {});
                setRouters(rt)
                const ft = dexList.reduce(
                    (obj, item) => Object.assign(obj, { [item.id]: item.factoryAddress }), {});
                setFactories(ft)
            }



            // let idDex = launchpadInfo && launchpadInfo.router ? launchpadInfo.router : 1;

            form.setFieldsValue({
                'routerAddress': launchpadInfo ? launchpadInfo.routerAddress : '',
                'factoryAddress': launchpadInfo ? launchpadInfo.factoryAddress : ''
            })

        } else {
            setCexList([])
        }
    }, [chainId])

    useEffect(() => {
        let neededTokens = calculateTotalNeededTokens(presaleRate, listingRate, liquidity, hardCap, teamTokens, launchpadTokenRaisedFee, bnbFeePercent);
        setTotalNeededTokens(neededTokens);
    }, [presaleRate, hardCap, liquidity, listingRate, teamTokens, launchpadTokenRaisedFee, bnbFeePercent]);

    useEffect(() => {
        if (!checkUpdate) {
            form.setFieldsValue({
                presale_rate: launchpadInfo ? launchpadInfo.presale_rate : '',
                softcap: launchpadInfo ? launchpadInfo.softcap : 0,
                minimum_buy: launchpadInfo ? launchpadInfo.minimum_buy : '',
                refund_type: launchpadInfo ? launchpadInfo.refund_type : 'refund',
                liquidity: launchpadInfo ? launchpadInfo.liquidity : '',
                whitelist: launchpadInfo ? launchpadInfo.whitelist : 'disable',
                hardCap: launchpadInfo ? launchpadInfo.hardCap : 0,
                maximum_buy: launchpadInfo ? launchpadInfo.maximum_buy : '',
                router: launchpadInfo ? launchpadInfo.router : "",
                listing_rate: launchpadInfo ? launchpadInfo.listing_rate : '',
                start_time: launchpadInfo ? launchpadInfo.start_time : '',
                liquidity_lockup: launchpadInfo ? launchpadInfo.liquidity_lockup : '',
                end_time: launchpadInfo ? launchpadInfo.end_time : '',
                title: launchpadInfo ? launchpadInfo.title : '',
                // team_vesting_system: launchpadInfo ? launchpadInfo.team_vesting_system : '',
                // vesting_contributor: launchpadInfo ? launchpadInfo.vesting_contributor : '',
                total_vesting_tokens: launchpadInfo ? launchpadInfo.total_vesting_tokens : '',
                first_token_release_amount: launchpadInfo ? launchpadInfo.first_token_release_amount : '',
                token_period_each_cycle: launchpadInfo ? launchpadInfo.token_period_each_cycle : '',
                first_token_release: launchpadInfo ? launchpadInfo.first_token_release : '',
                vesting_period_each_cycle: launchpadInfo ? launchpadInfo.vesting_period_each_cycle : '',
                vc_first_release: launchpadInfo ? launchpadInfo.vc_first_release : '',
                vc_vesting_cycle: launchpadInfo ? launchpadInfo.vc_vesting_cycle : '',
                vc_tge_lock: launchpadInfo ? launchpadInfo.vc_tge_lock : '',
                vc_lock_after_tge: launchpadInfo ? launchpadInfo.vc_lock_after_tge : '',
                vc_token_release_cycle: launchpadInfo ? launchpadInfo.vc_token_release_cycle : '',
                routerAddress: launchpadInfo ? launchpadInfo.routerAddress : "",
                factoryAddress: launchpadInfo ? launchpadInfo.factoryAddress : "",
            })

            setPresaleRate(parseFloat(launchpadInfo?.presale_rate ?? '0'))
            setListingRate(parseFloat(launchpadInfo?.listing_rate ?? '0'))
            setLiquidity(parseFloat(launchpadInfo?.liquidity ?? '0'))
            setHardCap(parseFloat(launchpadInfo?.hardCap ?? '0'))
            setTeamTokens(parseFloat(launchpadInfo?.total_vesting_tokens ?? '0'))
            setCheckUpdate(true);
        }

    }, [launchpadInfo]);

    let show_vesting_contributor = false;

    if (launchpadInfo) {
        if (showVestingContributor && launchpadInfo.vesting_contributor) {
            show_vesting_contributor = true;
        } else if (!showVestingContributor && launchpadInfo.vesting_contributor) {
            show_vesting_contributor = false;
        } else if (showVestingContributor && !launchpadInfo.vesting_contributor) {
            show_vesting_contributor = true;
        } else {
            show_vesting_contributor = false;
        }
    } else {
        if (showVestingContributor) {
            show_vesting_contributor = true;
        }
    }

    const onChangeListingRateQuantity = (values: any) => {
        const val: any = values.target.value;
        setListingRateQuantity(val);
    };
    const changeValueHardCap = (values: any) => {
        form.setFieldsValue({
            softcap: parseFloat(values),
        });
    };

    const changeValueMaxBuy = (values: any) => {
        form.setFieldsValue({
            minimum_buy: parseFloat(values),
        });
    };
    const changeMinBuy = (values: any) => {
        form.resetFields('maximum_buy')
    };

    const onChangeDex = (e: any) => {
        const router = routers[e] ?? ""
        const factory = factories[e] ?? ""
        form.setFieldsValue({
            routerAddress: router,
            factoryAddress: factory,
        });
    };

    return (
        <>
            <div className="title-steps">Defi Launchpad Info</div>
            <div className="launchpad-info-form">

                {/* <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onConfirmLaunchpadInfo}
                    onFieldsChange={() => {
                        form.isFieldsTouched()
                    }}
                > */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="step_launchpad_info" label="Presale rate" initialValue={2} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="presale_rate" label={<>Presale rate <span className='text-gray font-13 ml-5'> ( If I spend 1 {tokenInfo.currency_pay} how many tokens will I receive?)</span></>}
                            rules={[
                                { required: true, message: 'Please input Presale rate!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || parseFloat(value) <= 0
                                            ? cb("Presale rate must be > 0")
                                            : cb();
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" type="number" placeholder="0" onChange={(e) => {
                                e.preventDefault();
                                setPresaleRate(parseFloat(e.target.value))
                            }} />

                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="whitelist" label="Whitelist"
                            help="You can enable/disable whitelist anytime">
                            <Radio.Group className="radio-gr-create" value="disable">
                                <Radio value="disable">Disable</Radio>
                                <Radio value="enable">Enable</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="softcap" label={`Softcap (${tokenInfo.currency_pay})`}
                            rules={
                                [
                                    { required: true, message: 'Please input Softcap!' },
                                    {
                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                            !value || parseFloat(value) <= 0 || form.getFieldValue('hardCap') <= 0 || parseFloat(value) > form.getFieldValue('hardCap') / 2
                                                ? cb("Softcap must be <= 50% of Hardcap")
                                                : cb();
                                        }
                                    }
                                ]
                            }
                        >
                            <Input className="amount-buy" type="number" placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="hardCap" label={`HardCap (${tokenInfo.currency_pay})`}
                            rules={[
                                { required: true, message: 'Please input HardCap!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || parseFloat(value) <= 0 || form.getFieldValue('softcap') <= 0 || parseFloat(value) < form.getFieldValue('softcap') * 2
                                            ? cb("Hardcap must be >= 50% of Softcap!")
                                            : cb();
                                    }
                                }
                            ]}
                            help="Hardcap must be >= 50% of Softcap!">
                            <Input className="amount-buy" type="number" placeholder="0" onChange={(e) => {

                                changeValueHardCap(form.getFieldValue('softcap'));
                                setHardCap(parseFloat(e.target.value))
                            }} />

                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="minimum_buy" label={`Minimum buy (${tokenInfo.currency_pay})`}
                            rules={[
                                { required: true, message: 'Please input Minimum buy!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || parseFloat(value) <= 0 || form.getFieldValue('maximum_buy') <= 0 || parseFloat(value) >= form.getFieldValue('maximum_buy')
                                            ? cb("Minimum Buy Must Be Less Than Maximum Buy")
                                            : cb();
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" type="number" placeholder="0" onChange={changeMinBuy} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="maximum_buy" label={`Maximum buy (${tokenInfo.currency_pay})`}
                            rules={[
                                { required: true, message: 'Please input Maximum buy!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || parseFloat(value) <= 0 || form.getFieldValue('minimum_buy') <= 0 || parseFloat(value) <= form.getFieldValue('minimum_buy')
                                            ? cb("Maximum must be > 0 and > Minimum buy")
                                            : cb();
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="number" placeholder="0"
                                onChange={(e) => {
                                    changeValueMaxBuy(form.getFieldValue('minimum_buy'));
                                }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="refund_type" label="Refund type">
                            <Select className="select-create-ido">
                                <Option value="burn">Burn</Option>
                                <Option value="refund">Refund</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {!isManualListing && (
                        <>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="router" label="Router"
                                    initialValue={1}
                                    rules={[{ required: true, message: 'Please input Router!' }]}>

                                    <Select className="select-create-ido" onChange={onChangeDex}>
                                        <Option value="">Select Router Exchange</Option>
                                        {cexList.map((item, index) => (
                                            <Option value={item.id} key={'cex-' + index}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="routerAddress" label="Router Address" hidden={true}>
                                    <Input className="amount-buy" type="text" />
                                </Form.Item>
                                <Form.Item name="factoryAddress" label="Router Address" hidden={true}>
                                    <Input className="amount-buy" type="text" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="liquidity" label="Liquidity (%)"
                                    rules={[
                                        { required: true, message: 'Please input Liquidity!' },
                                        {
                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                !value || parseFloat(value) < 51
                                                    ? cb("Liquidity must be >= 51%")
                                                    : cb();
                                            }
                                        }
                                    ]}>
                                    <Input className="amount-buy" type="number" placeholder="51" onChange={(e) => { setLiquidity(parseFloat(e.target.value)) }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="listing_rate" label={<>Listing rate <span className='text-gray font-13 ml-5'>( 1 ${tokenInfo.currency_pay} = ${listingRateQuantity} ${tokenInfo.token_symbol})</span></>}
                                    rules={[
                                        { required: true, message: 'Please input Listing rate!' },
                                        {
                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                !value || parseFloat(value) <= 0
                                                    ? cb("Listing rate must be > 0")
                                                    : cb();
                                            }
                                        }
                                    ]}
                                >
                                    <Input className="amount-buy" type="number" onChange={(e) => {
                                        onChangeListingRateQuantity(e)
                                        setListingRate(parseFloat(e.target.value))
                                    }} placeholder="0" />
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="title" label="Title"
                            rules={[{ required: true, message: 'Please input Title!' }]}>
                            <Input className="amount-buy" type="text" placeholder="Title..." />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <p>
                            <span className="warning-form"><i>Enter the percentage of raised funds that should be allocated to Liquidity on DEX (Min 51% Max 100%).</i></span><br />
                            <span className="warning-form"><i>If I spend 1 {tokenInfo.currency_pay}. how many tokens will I receive? Usually this amount is lower than presale rate to allow for a higher listing price on Pancakeswap.</i></span>
                        </p>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="start_time" label="Start time"
                            rules={[
                                { required: true, message: 'Please input Start time!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || new Date(value) <= new Date(Date.now())
                                            ? cb("Start time needs to be after now")
                                            : cb();
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="datetime-local" placeholder="0" />
                        </Form.Item>
                        {!isManualListing && (
                            <>
                                <Form.Item name="liquidity_lockup" label="Liquidity lockup (days)"
                                    rules={[{ required: true, message: 'Please input Liquidity lockup!' }]}>
                                    <Input className="amount-buy" type="number" placeholder="0" />
                                </Form.Item>
                            </>
                        )}

                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="end_time" label="End time"
                            rules={[
                                { required: true, message: 'Please input End time!' },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {

                                        if (!value || new Date(value) <= new Date(Date.now())) {
                                            cb("End time needs to be after now time");
                                        } else if (new Date(value) <= form.getFieldValue('start_time')) {
                                            cb("End time needs to be after start time");
                                        } else {
                                            cb();
                                        }
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="datetime-local" placeholder="0" />
                        </Form.Item>


                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item name="vesting_contributor" initialValue={launchpadInfo?.vesting_contributor}>
                            <Checkbox.Group>
                                <Checkbox onChange={onChangeVestingContributor} value={true} style={{ lineHeight: '32px' }}>Using Vesting Contributor?</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    {show_vesting_contributor ? (
                        <>

                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="vc_tge_lock" label="TGE Lock (minutes)" rules={[{ required: true, message: 'Please input TGE Lock' }]}>
                                    <Input className="amount-buy" type="number" placeholder="20" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="vc_lock_after_tge" label="Cliff after TGE (days)" rules={[{ required: true, message: 'Please input Cliff after TGE!' }]}>
                                    <Input className="amount-buy" type="number" placeholder="30" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="vc_first_release" label="First release for presale (percent)" rules={[{ required: true, message: 'Please input First release!' }]}>
                                    <Input className="amount-buy" type="number" placeholder="20" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="vc_vesting_cycle" label="Vesting period each cycle (days)" rules={[{ required: true, message: 'Please input Vesting period each cycle!' }]}>
                                    <Input className="amount-buy" type="number" placeholder="30" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="vc_token_release_cycle" label="Presale token release each cycle (percent)"
                                    initialValue={0}
                                    rules={[
                                        { required: true, message: 'Please input Presale token release each cycle!' },
                                        {
                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                if (!value || parseFloat(value) <= 0) {
                                                    cb("Token release each cycle must > 0")
                                                } else if ((parseFloat(value) + parseFloat(form.getFieldValue('vc_first_release'))) > 100) {
                                                    cb("First release for presale and Presale token release each cycle must be less than 100 percent")
                                                } else {
                                                    cb()
                                                }
                                            }
                                        }
                                    ]}>
                                    <Input className="amount-buy" type="number" placeholder="30" />
                                </Form.Item>
                            </Col>
                        </>
                    ) : (<></>)}

                </Row>
                <div className="txt-need-amount-create text-center text-info">Need <strong className='text-danger'>{totalNeededTokens.toLocaleString()} {tokenInfo.token_symbol}</strong> to create launchpad.</div>


            </div>
        </>
    )
}

export default DefiLaunchpadInfo
