
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Layout, Row, Col, Button, Form, Input, Upload, Spin, Space, Modal, Alert, Radio } from 'antd';
import {
    getBalance,
    approveToken, getTokenAllowance, getTokenInformation, approveExactAmountToken
} from '../../utils';

import tokenInfoAbi from 'config/abi/tokenInfo.json';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import pairAbi from '../../../config/abi/pairAbi.json';

const { TextArea } = Input;
declare const window: Window & typeof globalThis & { ethereum: any };

const VerifyToken = (props) => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const { form, setCanNext } = props;
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const multiSenderAddress = getInformationByChain(chainId, 'REACT_APP_MULTI_SENDER');
    const symbolChain = getInformationByChain(chainId, 'REACT_APP_SYMBOL');
    const decimalsChain = getInformationByChain(chainId, 'REACT_APP_DECIMALS');


    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [tokenAddress, setTokenAddress] = useState<any>(form.getFieldValue('token_address') || '');
    const [name, setName] = useState(form.getFieldValue('name') ? form.getFieldValue('name') : symbolChain);
    const [symbol, setSymbol] = useState(form.getFieldValue('symbol') ? form.getFieldValue('symbol') : symbolChain);
    const [balance, setBalance] = useState(form.getFieldValue('balances') || 0);
    const [decimals, setDecimals] = useState(form.getFieldValue('decimals') ? form.getFieldValue('decimals') : decimalsChain);
    const [totalSupply, setTotalSupply] = useState<any>(form.getFieldValue('supply') || 0);

    const [isSampleFile, setIsSampleFile] = useState<boolean>(false);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [tokenAllowance, setTokenAllowance] = useState<any>(form.getFieldValue('allowance') || 0);
    const [totalTokenToSend, setTotalTokenToSend] = useState<any>(form.getFieldValue('tokenToSend') || 0);
    const [approveOption, setApproveOption] = useState<any>('unlimited_amount');


    useEffect(() => {
        if (!checkUpdate && chainId) {
            setTokenAddress(form.getFieldValue('token_address'))
            if (!tokenAddress) {
                form.setFieldsValue({
                    'token_address': '',
                    'name': symbolChain,
                    'symbol': symbolChain,
                    'decimals': decimalsChain,
                });
                setSymbol(symbolChain)
                setName(symbolChain)
                setDecimals(decimalsChain)
            }
            setCheckUpdate(true);
        }
    }, [chainId]);

    useEffect(() => {
        if (!tokenAddress) {
            if (totalTokenToSend > 0) {
                setCanNext(true);
            } else {
                setCanNext(false)
            }
        } else if (ethers.utils.isAddress(tokenAddress) && totalTokenToSend > 0) {
            if (tokenAllowance >= totalTokenToSend) {
                setCanNext(true)
            } else {
                setCanNext(false)
            }
        } else {
            setCanNext(false);
        }
    }, [tokenAllowance, totalTokenToSend, tokenAddress]);

    const onChangeTokenAddress = async (values: any) => {
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);
        try {
            if (!tokenAddressVal || !ethers.utils.isAddress(tokenAddressVal)) {
                let balance = await w3.eth.getBalance(account as string);
                form.setFieldsValue({
                    'token_address': '',
                    'name': symbolChain,
                    'symbol': symbolChain,
                    'decimals': decimalsChain,
                    'balances': parseFloat(ethers.utils.formatUnits(balance, decimalsChain))
                });
                setLoadingTokenInfo(false);
                return;
            }
            let allowance = await getTokenAllowance(tokenInfoAbi, tokenAddressVal, account, multiSenderAddress)
            const tokenInfo = await getTokenInformation(pairAbi, tokenAddressVal);
            const balance_ = await getBalance(tokenInfoAbi, tokenAddressVal, account);

            setName(tokenInfo.name);
            setSymbol(tokenInfo.symbol);
            setDecimals(parseInt(tokenInfo.decimals));
            setTotalSupply(tokenInfo.supply)
            setTokenAllowance(ethers.utils.formatUnits(allowance, parseInt(tokenInfo.decimals)));
            setBalance(parseFloat(ethers.utils.formatUnits(balance_, parseInt(tokenInfo.decimals))))
            form.setFieldsValue({
                'token_address': tokenAddressVal,
                'name': tokenInfo.name,
                'symbol': tokenInfo.symbol,
                'supply': tokenInfo.supply,
                'decimals': parseInt(tokenInfo.decimals),
                'allowance': ethers.utils.formatUnits(allowance, parseInt(tokenInfo.decimals)),
                'balances': parseFloat(ethers.utils.formatUnits(balance_, parseInt(tokenInfo.decimals)))
            });
            setLoadingTokenInfo(false);
        } catch (e: any) {
            console.log(e);
            setTokenAddress(tokenAddressVal);
            setLoadingTokenInfo(false);
        }
    };

    const closeBox = () => {
        setIsSampleFile(false);
    };
    const showSampleFileModal = () => {
        setIsSampleFile(true);
    }



    const handleApproveToken = async () => {
        try {
            setLoading(true);
            if (approveOption === 'unlimited_amount') {
                await approveToken(tokenInfoAbi, tokenAddress, library, account, multiSenderAddress)
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            !countNoti && showNoti('success', `Approve ${name} Successfully`);
                                            countNoti++;
                                            setTokenAllowance(Math.pow(2, 256));
                                            form.setFieldsValue({ 'allowance': Math.pow(2, 256) })

                                        } else {
                                            showNoti('error', `Approve ${name} Failed`);
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                showNoti('warning', error.message);
                            } else {
                                if (error.data && error.data.message) {
                                    showNoti('warning', error.data.message);
                                }
                            }
                        }
                    });
            } else {
                await approveExactAmountToken(tokenInfoAbi, tokenAddress, library, account, multiSenderAddress, ethers.utils.parseUnits(`${totalTokenToSend - tokenAllowance}`, decimals))
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            !countNoti && showNoti('success', `Approve ${name} Successfully`);
                                            countNoti++;
                                            setTokenAllowance(totalTokenToSend);
                                            form.setFieldsValue({ 'allowance': totalTokenToSend })

                                        } else {
                                            showNoti('error', `Approve ${name} Failed`);
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                showNoti('warning', error.message);
                            } else {
                                if (error.data && error.data.message) {
                                    showNoti('warning', error.data.message);
                                }
                            }
                        }
                    });
            }


        } catch (error: any) {
            console.log(error);
            setLoading(false);
        }
    };

    const uploadFile = (file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            let dataList: any = e.target.result.replace('Address,Amount', '');
            form.setFieldsValue({
                allocations: dataList.trim().replaceAll(',', ' ')
            })
            form.validateFields(['allocations'])
        };
        reader.readAsText(file);
        return false;
    }

    const onChangeApproveOptions = (e) => {
        setApproveOption(e.target.value);
    }
    const onChangeTokenAddr = (e) => {
        setTokenAddress(e.target.value);
    }

    return (
        <>
            <div className="inputs-steps">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item name="step_verify_token" initialValue={1} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Token Address"
                            name="token_address"
                            initialValue={tokenAddress}
                            rules={[
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        value && !ethers.utils.isAddress(value) ? cb("Invalid Token Address") : cb();
                                    }
                                }
                            ]}
                        >
                            <Input disabled={account ? false : true} className="amount-buy" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4" value={tokenAddress} onBlur={onChangeTokenAddress} onChange={onChangeTokenAddr} />
                            <p className="multi-info">Wave Multi-sender allows you to send ERC20 token in batch by easiest way. You can enter token address to send specific token or leave it blank to send chain token such as {symbolChain}....</p>
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Symbol"
                            name="symbol"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Supply"
                            name="supply"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Decimals"
                            name="decimals"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Balance"
                            name="balances"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Allowance"
                            name="allowance"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="Token To Send"
                            name="tokenToSend"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label="addressList"
                            name="addressList"
                            hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item
                            label='amountList'
                            name='amountList'
                            hidden={true}>
                            <Input className='amount-buy' />
                        </Form.Item>
                        <Form.Item
                            label='allocationList'
                            name='allocationList'
                            hidden={true}>
                            <Input className='amount-buy' />
                        </Form.Item>

                        {loadingTokenInfo ? (
                            <div className="loading-info">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        ) :
                            (tokenAddress && name && symbol && decimals ? (
                                <>
                                    <div className="bg-token-info">
                                        <div className="detail-text">
                                            <div className="left">Name</div>
                                            <div className="right">{name}</div>
                                        </div>
                                        <div className="detail-text">
                                            <div className="left">Symbol</div>
                                            <div className="right">{symbol}</div>
                                        </div>
                                        <div className="detail-text">
                                            <div className="left">Decimals</div>
                                            <div className="right">{decimals}</div>
                                        </div>
                                        <div className="detail-text mb-15">
                                            <div className="left">Balance</div>
                                            <div className="right">{balance.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </>
                            ) : (<></>)
                            )
                        }

                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item name='allocations' label='Allocations' rules={[

                            {
                                validator: (rule, value, cb: (msg?: string) => void) => {
                                    if (!value) {
                                        setTotalTokenToSend(0);
                                        return cb('Allocations can not be blank');
                                    }
                                    if (value) {
                                        const wallets = value.trim().split('\n');
                                        let totalTokens = 0;

                                        let addressList: any = [];
                                        let allocationList: any = [];
                                        let amountList: any = [];
                                        for (let i = 0; i < wallets.length; i++) {
                                            const lineValues = wallets[i].trim().split(' ');
                                            const address = (lineValues[0] || '').trim();
                                            const amount = (lineValues[1] || '0').trim();

                                            addressList.push(address);
                                            amountList.push(ethers.utils.parseUnits(`${amount}`, decimals).toHexString())
                                            allocationList.push({ address, amount })

                                            totalTokens = totalTokens + parseFloat(amount);

                                            if (!ethers.utils.isAddress(address) || !parseFloat(amount)) {
                                                setTotalTokenToSend(0);
                                                return cb(`Incorrect value at line ${i + 1}`);
                                            }
                                        }
                                        setTotalTokenToSend(totalTokens);
                                        form.setFieldsValue({
                                            'tokenToSend': totalTokens,
                                            'addressList': JSON.stringify(addressList),
                                            'amountList': JSON.stringify(amountList),
                                            'allocationList': JSON.stringify(allocationList),
                                        });
                                        cb();
                                    }

                                }
                            }


                        ]}>
                            <TextArea rows={10} className='amount-buy'
                                placeholder='Insert allocation: separate with breaks line. By format: address,amount or address amount
                                                    Ex:
                                                    0x0000000000000000000000000000000000001000 13.45
                                                    0x0000000000000000000000000000000000001000 1.049
                                                    0x0000000000000000000000000000000000001000 1' />
                        </Form.Item>

                        <Upload
                            accept=".txt, .csv"
                            showUploadList={false}
                            beforeUpload={uploadFile}
                        >
                            <Button htmlType='button' icon={<UploadOutlined />}>Or choose from CSV file</Button>
                        </Upload>
                        {/*<div className='sample-file' onClick={showSampleFileModal} style={{ marginTop: '5px', color: '#b1abab' }}>Sample data Allocations</div>*/}
                        <div className='sample-file' style={{ marginTop: '5px', color: '#b1abab' }}>
                            <a href="/excel/wave_multisender__example.csv" download>Download CSV file sample</a>
                        </div>

                    </Col>

                    {(tokenAllowance < totalTokenToSend && ethers.utils.isAddress(tokenAddress)) && (
                        <>
                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Item label="Amount to approve" name="amount_to_approve" initialValue={approveOption} rules={[{ required: true, message: 'Please choice option' }]}>
                                    <Radio.Group value={'unlimited_amount'} onChange={onChangeApproveOptions}>
                                        <Space direction="vertical">
                                            <Radio value={'unlimited_amount'}>Unlimited amount</Radio>
                                            <Radio value={'exact_amount'}>Exact amount ({totalTokenToSend - tokenAllowance} {symbol})</Radio>

                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                            </Col>
                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                <div className="click-approve">
                                    <Button htmlType="button" onClick={handleApproveToken} className="btn-all" disabled={loading}>Approve {loading && <Spin size="small" />}</Button>
                                </div>
                            </Col>
                        </>
                    )}
                    {/*<Col className="gutter-row" xs={24} xl={24} md={24} sm={24} style={{ marginTop: '15px', marginBottom: '15px' }}>*/}
                    {/*    <Alert message="Please exclude 0x39D3653Be6F64427fa82C9B02D8669c3D4339F85 from fees, rewards, max tx amount to start sending tokens." type="warning" />*/}
                    {/*</Col>*/}
                </Row>
            </div>
            <Modal
                title={<div className="text-md custom-modal-title">Sample CSV file</div>}
                visible={isSampleFile}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        name="basic"
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Form.Item name="description"
                            initialValue="0x0000000000000000000000000000000000001000,13.45
                            0x0000000000000000000000000000000000001001,1.049
                            0x0000000000000000000000000000000000001002,1"
                        >
                            <TextArea rows={10} className="amount-buy" placeholder="Enter description" />
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        </>
    )
}

export default VerifyToken
