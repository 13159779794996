
import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Steps } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import AntiBotVerifyToken from './antibot-verify-token';
import AntiBotContent from './anti-bot-content';
const { Content } = Layout;
const { Step } = Steps;
const AntiBot = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    let initStep = 0;
    const [current, setCurrent] = React.useState(initStep);



    const steps = [
        {
            title: 'Anti Bot Verify Token',
            content: <AntiBotVerifyToken next={setCurrent} />,
            description: ''
        },
        {
            title: 'Defi Launchpad Info',
            content: <AntiBotContent />,
            description: 'Enter the launchpad information that you want to raise, that should be enter all details about your resale'
        }
    ];
    let storageAntiTokenInfo = window.localStorage.getItem('antiTokenInfo');
    let antiTokenInfo;
    if (storageAntiTokenInfo) {
        antiTokenInfo = JSON.parse(storageAntiTokenInfo);
    }

    // if (antiTokenInfo) {
    //     initStep = 1;
    // }

    const prev = () => {
        setCurrent(current - 1);
    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-create container">
                            <div className="back-history">
                                <Link to={`/ido`}><LeftCircleOutlined /> Cancel Anti-bot</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">WaveSignal Anti-bot</div>
                                <div className="create-step">
                                    <div className="anti-bot-block">
                                        <div className="steps-content-antibot">
                                            {steps[current].content}
                                        </div>
                                        <div className="steps-action">
                                            {current > 0 && (
                                                <button className="btn-default" type="button" style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                    Previous
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default AntiBot
