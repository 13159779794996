
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
import TokenBalance from 'components/balance';
const { Content } = Layout;

const FlexibleBanner = () => {
    return (
        <>
            {/* <div className="banner-apply-launch">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                        <div className="banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={16} xl={13} md={13} sm={13}>
                                    <div className="banner-apply-content">
                                        <div className="banner-investor-name">
                                            Staking
                                        </div>
                                        <div className="banner-investor-des">
                                            This is what we call “Earn in your sleep”. Use tokens to earn more tokens without any fee.
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={8} xl={11} md={11} sm={11}>
                                    <div className="banner-faq-content">
                                        <div className="banner-apply-img animation-css-scale">
                                            <img src="../../images/staking.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                        <TokenBalance />
                    </Col>
                </Row>

            </div> */}
            <div className="private-banner-apply-launch bg-private-sale">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <div className="private-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="private-banner-apply-content text-center">
                                        <div className="private-banner-investor-name">
                                            STAKING
                                        </div>
                                        <div className="private-banner-investor-des">
                                            <p>This is what we call “Earn in your sleep”.</p>
                                            <p> Use tokens to earn more tokens without any fee.</p>
                                        </div>
                                        <div className="mt-20 banner-button">
                                            <a className="link-button link-hover" target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeImXqte1_xMaNqLVuLBxGAgEpx0OUeAxj7HQhMWX-OniNawg/viewform'>Open stake pool</a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                    <div className="private-banner-faq-content text-left mt-15">
                                        <div className="private-banner-apply-img text-left">
                                            {/* <img src="../../images/img-stake/stake-bg.png" /> */}
                                            <video src="../../images/earn/staking.mp4" loop autoPlay={true} muted />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default FlexibleBanner
