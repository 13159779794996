
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Select, Input, Tooltip, Button, Table, Space, Spin } from 'antd';
import { CalculatorOutlined, QuestionCircleOutlined, RightCircleOutlined, RiseOutlined, UpOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { useWrap } from '../../../../context/WrapperContext';
import { ethers } from 'ethers';
import tokenInfoAbi from '../../../../config/abi/tokenInfo.json';
import { getInformationByChain } from 'config/network/multichainAddresses';

import {
    useContract
} from "../../../../hooks/useContract";

import ApyCalculatorModal from '../ApyCalculatorModal';

declare const window: Window & typeof globalThis & { ethereum: any };
let intervalReward: any = null;
const PoolItem = (props) => {
    const { item } = props;
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [showRoi, setShowRoi] = useState(false);

    const [tokenEarnPrice, setTokenEarnPrice] = useState<any>(10);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const onShowRoi = () => {
        setShowRoi(true);
    }


    const closeBox = (e) => {
        e.preventDefault()
        setShowRoi(false);
    };


    return (
        <>
            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                <div className="launch-pool-item">
                    <div className="pool-content">
                        <div className="launch-pool-img">
                            <div className="item-img">
                                <img src={item.stakeTokenLogo} />
                                <img src={item.earnTokenLogo} />
                            </div>
                        </div>
                        <div className="launch-pool-info">
                            <div className="pool-name">{item.title}</div>
                            <div className="item-name" title={`Stake ${item.stakeTokenName} - Earn ${item.earnTokenName}`}>Stake {item.stakeTokenName} - Earn {item.earnTokenName}</div>
                            <div className="item-apy">
                                <span>APY <CalculatorOutlined onClick={() => onShowRoi()} /></span>
                                <span>{item.apr}%
                                    <Tooltip placement="top" title={item.aprTip}>
                                        <QuestionCircleOutlined className='icon-title' />
                                    </Tooltip>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="pool-item-link">
                        <Link to="/fixed-staking">Stake now <RightCircleOutlined className="icon-link" /></Link>
                    </div>
                </div>
            </Col>

            <Modal
                title={<div className="text-md custom-modal-title">ROI</div>}
                visible={showRoi}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p>Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</p>

                    <ApyCalculatorModal
                        tokenPrice={tokenEarnPrice}
                        apr={item.apr}
                        earningTokenSymbol={item.earnTokenName}
                        performanceFee={item.unStakeFee}
                    />

                    <div className="modal-btn-group mt-15">
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>



        </>
    )
}

export default PoolItem
