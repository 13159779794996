/* eslint-disable consistent-return */
/* eslint-disable object-shorthand */
import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state'
import axios from 'axios'

const StoreIno = createStore({
    initialState: {
      
    },
    actions: {
      genSign: (id, acc, box)  => async ({ setState }) => {
        console.log('params: ',id, acc, box)
        return new Promise((resolve, reject) => {
          axios.get(
            `https://dev-api-wavecoin.hubecom.com/api/ino-round/gen-sign-buy-box`, 
             id,acc,box
          
          ).then((res) => {
            resolve(res)
          })
        })
      },
  
      // buyBoxAPI: (obj) => async ({ setState }) => {
      //   return new Promise((resolve, reject) => {
      //     axios.post(
      //       `${API_NFT}/ino/buy`, {
      //       "inoId": obj.inoId,
      //       "typeId": obj.typeId,
      //       "quantity": obj.quantity,
      //       "ownerAddress": obj.ownerAddress,
      //       "tnxHash": obj.tnxHash
      //     }
      //     ).then((res) => {
      //       resolve(res)
      //     })
      //   })
      // },
      // genSign: (typeId, account) => async ({ setState }) => {
      //   return new Promise((resolve, reject) => {
      //     axios.post(
      //       `${API_NFT}/ino/gen_sign`, {
      //       "typeId": typeId,
      //       "ownerAddress": account
      //     }
      //     ).then((res) => {
      //       resolve(res)
      //     })
      //   })
      // },
      // getInfoINO: () => async ({ setState, getState }) => {
      //   try {
      //     const res = await axios.post(`${API_NFT}/ino/infos`, {})
      //     setState({ inoList: res.data.data } || [])
      //     // return res
      //   } catch (err) {
      //     // TODO
      //   }
      // },
      // getDetailINO: (param) => async ({ setState, getState }) => {
      //   try {
      //     const res = await axios.post(`${API_NFT}/ino/detail`, { ...param })
      //     // console.log('res: ', res)
      //     setState({ inoItem: res.data.data } || {})
      //     // return res
      //   } catch (err) {
      //     // TODO
      //   }
      // },
      // openBox: (inoId, boxId, ownerAddress) => ({ getState, setState, dispatch }) => {
      //   return new Promise((resolve, reject) => {
      //     const res = axios.post(`${API_NFT}/ino/open_box`, {
      //       inoId,
      //       boxId,
      //       ownerAddress
      //     }).then((resData) => {
      //       resolve(resData)
      //     })
      //   })
  
      // },
      // afterOpenBox: (nftId, inoId, boxId, ownerAddress, txHash) => ({ getState, setState, dispatch }) => {
      //   return new Promise((resolve, reject) => {
      //     const res = axios.post(`${API_NFT}/ino/update_open_box`, {
      //       inoId,
      //       boxId,
      //       nftId,
      //       ownerAddress,
      //       txHash
      //     }).then((resData) => {
      //       resolve(resData)
      //     })
      //   })
  
      // },
      // getMyNfts: (data, account) => async ({ setState, getState }) => {
      //   try {
      //     const res = await axios.post(`${API_NFT}/ino/box_nft_detail`, {
      //       "nftIds": data,
      //       "ownerAddress":account
      //     })
      //     setState({ myNFTs: res.data.data || [] })
      //   } catch (err) {
      //     // TODO
      //   }
      // },
    },
    name: 'StoreIno',
  })
  
  export const useHookIno = createHook(StoreIno)
  export const Container = createContainer(StoreIno, {
    onInit: () => ({ setState }, props) => {
      setState({ ...props })
    },
  })
  export const Subscriber = createSubscriber(StoreIno)