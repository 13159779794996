
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Form, Input, Table, Button, Radio, Select, Space } from 'antd';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';
import { convertDateTime } from 'utils/formatDatetime';

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

declare const window: Window & typeof globalThis & { ethereum: any };

const Finish = (props) => {

    const { form } = props;
    const columns = [
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        }
    ];

    return (
        <>
            <div className="title-steps">Finish</div>
            <div className="launchpad-info-form">
                <div className="token-info-anti">
                    {form.getFieldValue('token_address') &&
                    (<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='token-info-row'>
                        <Col className='gutter-row' xs={12} xl={12} md={12} sm={12}>
                            <div className='token-name'>Token address</div>
                        </Col>
                        <Col className='gutter-row' xs={12} xl={12} md={12} sm={12}>
                            <div className='token-value'>{form.getFieldValue('token_address') || ''}</div>
                        </Col>
                    </Row>)}
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-name">Total Amount To Send</div>
                        </Col>
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-value"><span style={{ color: '#f95192' }}>{(form.getFieldValue('tokenToSend') ||  0).toLocaleString()}</span> {form.getFieldValue('symbol') || ''}</div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-name">Number of transaction</div>
                        </Col>
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-value">{1}</div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-name">Your token balance</div>
                        </Col>
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-value">{(form.getFieldValue('balances') ||  0).toLocaleString()} {form.getFieldValue('symbol' || '')}</div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-name">Token to send</div>
                        </Col>
                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                            <div className="token-value">{(form.getFieldValue('tokenToSend') ||  0).toLocaleString()} {form.getFieldValue('symbol') || ''} </div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={24}>
                            <Table style={{ marginTop: '15px' }} dataSource={JSON.parse(form.getFieldValue('allocationList') || '[]')} columns={columns} />
                        </Col>
                        <Col className="gutter-row" xs={24} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Form.Item name="step_finish" initialValue={1} hidden={true}>
                                <Input className="amount-buy" />
                            </Form.Item>
                            {form.getFieldValue('token_address') &&
                                <Form.Item label="Send options" name="send_options" initialValue={'safe_mode'} rules={[{ required: true, message: 'Please choice option' }]}>
                                    <Radio.Group value={'safe_mode'}>
                                        <Space>
                                            <Radio value={'safe_mode'}>Safe mode</Radio>
                                            <Radio value={'unsafe_mode'}>Unsafe mode</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            }

                        </Col>
                    </Row>


                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" xs={24}>
                            <Form.Item className="btn-content">
                                <button className="btn-all" disabled={loading || !antiBot} type="submit">Next {loading && <Spin size="small" />}</button>
                            </Form.Item>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    )
}

export default Finish
