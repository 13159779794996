import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Button } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { isMobile } from 'react-device-detect';
import { bannerApi } from 'config/api/bannerApi';


const BannerSlide = () => {

    const [banners, setBanners] = useState<any>([]);
    const getBanners = async () => {
        let dataFilter: any = { page: 0, size: 20, sort: 'id,desc' };
        try {
            let res = await bannerApi.getBanners('BANNER', dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setBanners(res.data);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally { }
    };

    useEffect(() => {
        getBanners();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            {banners.length > 0 && (
                <div className="banner-slide">
                    <div className="container-slide">
                        <Slider {...settings} className="slide-banner-slick">
                            {banners?.map((item, index) => {
                                return (

                                    <div key={'banner-' + index}>
                                        <a target="_blank" href={item.linkBanner} className='link-ico-full' key={'banner-link-' + index}>
                                            <div className="banner-item img-banner-desktop" key={'banner-btn' + index}>
                                                <img key={'banner-img-' + index} className='' src={item.imageUrl} alt={item.name} />
                                                {item.linkButton.length > 0 && (
                                                    <a className='banner-slide-btn' href={item.linkButton} target="_blank">
                                                        <span> </span>
                                                        <span> </span>
                                                        <span> </span>
                                                        <span> </span>
                                                        Best Deals
                                                    </a>
                                                )}

                                            </div>
                                            <div className="banner-item img-banner-mobile" key={'banner-btn-' + index}>
                                                <img key={'banner-img-' + index} className='' src={item.imageUrlMobile} alt={item.name} />
                                                {item.linkButton.length > 0 && (
                                                    <Button htmlType="button" className="banner-slide-btn btn-all">
                                                        <a className='' href={item.linkButton} target="_blank">
                                                            <span> </span>
                                                            <span> </span>
                                                            <span> </span>
                                                            <span> </span>
                                                            Best Deals
                                                        </a>
                                                    </Button>
                                                )}

                                            </div>
                                        </a>
                                    </div>

                                )
                            })}

                        </Slider>
                    </div>
                </div>
            )}
        </>
    )
}

export default BannerSlide
