
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import './style.css';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { chainApi } from 'config/api/chainApi';
import { useTokenInfoContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import {
    createAntiBotLPGeneratorToken,
    createAntiBotStandardToken,
    createLPGeneratorToken,
    createStandardToken,
    createToken
} from '../../utils';
import antiBotStandardTokenAbi from '../../../config/abi/antiBotStandardTokenAbi.json';
import lpGeneratorTokenAbi from '../../../config/abi/lpGeneratorTokenAbi.json';
import standardTokenAbi from '../../../config/abi/standardTokenAbi.json';
import antiBotLpGeneratorTokenAbi from '../../../config/abi/antiBotLpGeneratorTokenAbi.json';
import {
    ANTI_BOT_TOKEN_BYTECODE,
    STANDARD_TOKEN_BYTECODE,
    LP_GENERATOR_TOKEN_BYTECODE,
    ANTI_BOT_LP_GENERATOR_TOKEN_BYTECODE
} from '../../bytecodes/bytecodes';
import {
    INIT_LAUNCHPAD_FEE,
    INIT_LIQUIDITY_TOKEN_FEE_WALLET,
    INIT_TOKEN_FEE,
    INIT_TOKEN_FEE_WALLET,
    INIT_TOKEN_W86_FEE
} from 'components/keyConfig';
import { dexExchanges } from '../../../config/network/dex';
import { CopyToClipboard } from 'react-copy-to-clipboard';

declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { Option } = Select;

const CreateToken = (props) => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();

    const fundAddress = process.env.REACT_APP_FUND_ADDRESS;
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');
    const scanUrl = getInformationByChain(chainId, 'REACT_APP_SCAN_URL');

    const [loading, setLoading] = useState(false);
    const [cexList, setCexList] = useState<any>([]);
    const [tokenType, setTokenType] = useState('standard');

    const [feeList, setFeeList] = useState<any>([]);
    const [feeOptions, setFeeOptions] = useState<any>();
    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState<boolean>(false);
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);
    const [tokenAddress, setTokenAddress] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState();
    const [tokenName, setTokenName] = useState();
    const [tokenDecimal, setTokenDecimal] = useState();
    const [hash, setHash] = useState<any>();
    const [created, setCreated] = useState(false);

    const [createTokenForm] = Form.useForm();

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [initTokenFee, setInitTokenFee] = useState<any>(0);
    const [initToken, setInitToken] = useState<any>();

    const [standardFeeWallet, setStandardFeeWallet] = useState<any>("");
    const [lpFeeWallet, setLpFeeWallet] = useState<any>("");



    const getSettings = async () => {

        try {
            let res = await chainApi.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => INIT_TOKEN_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                        setInitToken(initFee[0]);
                    }

                    let standardWallet = res.data.filter((itemSetting) => INIT_TOKEN_FEE_WALLET === itemSetting.settingKey);
                    if (standardWallet.length > 0) {
                        setStandardFeeWallet(standardWallet[0].settingValue)
                    }

                    let lpWallet = res.data.filter((itemSetting) => INIT_LIQUIDITY_TOKEN_FEE_WALLET === itemSetting.settingKey);
                    if (lpWallet.length > 0) {
                        setLpFeeWallet(lpWallet[0].settingValue)
                    }

                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => INIT_LAUNCHPAD_FEE === itemSetting.settingKey);
                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };



    useEffect(() => {
        if (chainId) {
            const dexList = dexExchanges[chainId];
            setCexList(dexList)
            getSettings();
        } else {
            setCexList([])
        }
    }, [chainId])

    const createStandardTokenForm = async (values: any) => {
        setLoading(true);
        try {
            await createStandardToken(standardTokenAbi,
                `0x${STANDARD_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString()
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;
                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create Standard Token Successfully');
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        showNoti('warning', 'Create Standard Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const createAntiStandardTokenForm = async (values: any) => {
        setLoading(true);
        try {
            await createAntiBotStandardToken(antiBotStandardTokenAbi,
                `0x${ANTI_BOT_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
                antiBotAddress
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create Standard Token Successfully');
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        showNoti('warning', 'Create Standard Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const createLPGeneratorTokenForm = async (values: any) => {
        setLoading(true);
        try {
            if (parseFloat(values.tax_fee_percent) + parseFloat(values.liquidity_fee_percent) + parseFloat(values.charity_percent) > 25) {
                showNoti('warning', 'Total Fee Must Be Less Than 25%');
                setLoading(false);
                return;
            }
            console.log(values.router_address);
            await createLPGeneratorToken(lpGeneratorTokenAbi,
                `0x${LP_GENERATOR_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                ethers.utils.parseUnits(`${values.total_supply}`, 9),
                values.router_address,
                values.charity_address || ethers.constants.AddressZero,
                parseFloat(values.tax_fee_percent || 0) * 100,
                parseFloat(values.liquidity_fee_percent || 0) * 100,
                parseFloat(values.charity_percent || 0) * 100,
                lpFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create LP Token Successfully');
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: 9,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        showNoti('warning', 'Create LP Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const createAntiBotLPGeneratorTokenForm = async (values: any) => {
        setLoading(true);
        try {
            if (parseFloat(values.tax_fee_percent) + parseFloat(values.liquidity_fee_percent) + parseFloat(values.charity_percent) > 25) {
                showNoti('warning', 'Total Fee Must Be Less Than 25%');
                setLoading(false);
                return;
            }
            await createAntiBotLPGeneratorToken(antiBotLpGeneratorTokenAbi,
                `0x${ANTI_BOT_LP_GENERATOR_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                ethers.utils.parseUnits(`${values.total_supply}`, 9),
                antiBotAddress,
                values.router_address,
                values.charity_address || ethers.constants.AddressZero,
                parseFloat(values.tax_fee_percent || 0) * 100,
                parseFloat(values.liquidity_fee_percent || 0) * 100,
                parseFloat(values.charity_percent || 0) * 100,
                lpFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString()
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            showNoti('success', 'Create LP Token Successfully');
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: 9,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        showNoti('warning', 'Create LP Token Failed');
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };


    const onSubmitCreateTokenForm = async (values: any) => {
        switch (values.token_type) {
            case 'standard':
                if (values.implement_anti_bot) {
                    return createAntiStandardTokenForm(values);
                } else {
                    return createStandardTokenForm(values);
                }
            case 'liquidity':
                if (values.implement_anti_bot) {
                    return createAntiBotLPGeneratorTokenForm(values);
                } else {
                    return createLPGeneratorTokenForm(values);
                }

            default:
                break;
        }
    };

    const closeBox = () => {
        setIsCreateTokenVisible(false);
    };

    const onChangeTokenType = (value: string) => {
        setTokenType(value);
    };

    const onCopy = () => {
        showNoti('success', 'Copied');
    };
    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="back-history">
                                <Link to={`/launchpad`}><LeftCircleOutlined /> Cancel Create Token</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Create Token</div>
                                <div className="create-step">
                                    <div className="create-step">
                                        <div className="anti-bot-block">
                                            {created ? (
                                                <div className='token-created'>
                                                    <div className='message'>
                                                        <Alert message="Your token was created!" type="success" showIcon />
                                                    </div>
                                                    <div className="token-info-anti">
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Name</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenName}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Address</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenAddress}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Symbol</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenSymbol}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Decimals</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenDecimal}</div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='btn-list'>
                                                        <Button className="btn-default" onClick={(event) => (window.location.href = `${scanUrl}tx/${hash}`)}>View transaction</Button>
                                                        <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                            <Button className="btn-default" htmlType='button'>Copy address</Button>
                                                        </CopyToClipboard>

                                                        <Button className="btn-all" htmlType='button' onClick={(event) => (window.location.href = `/ico/create?token=${tokenAddress}`)}>Create launchpad</Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="steps-content-antibot">
                                                    <div className="sub-title-steps">
                                                        Token Infomation
                                                    </div>
                                                    <div className="inputs-steps">
                                                        <Form
                                                            form={createTokenForm}
                                                            name="basic"
                                                            autoComplete="off"
                                                            onFinish={onSubmitCreateTokenForm}
                                                            layout="vertical"
                                                        >
                                                            <Form.Item name="token_type" label="Token type" initialValue={tokenType}
                                                                rules={[{ required: true, message: 'Please input round type!' }]}
                                                            // help={<><small className="warning-form blue-text">Create fee: {initTokenFee} {createPoolFee?.payToken}</small></>}
                                                            >
                                                                <Select className="select-create-ido" onChange={onChangeTokenType}>
                                                                    {/*<Option value="">Select token type</Option>*/}
                                                                    <Option value="standard">Standard Token</Option>
                                                                    <Option value="liquidity">Liquidity Generator Token</Option>
                                                                    {/*<Option value="baby">Baby Token</Option>*/}
                                                                    {/*<Option value="buyback">Buyback Baby Token</Option>*/}
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input token name!' }]}>
                                                                <Input className="amount-buy" placeholder="Ex: Ethereum" />
                                                            </Form.Item>

                                                            <Form.Item name="symbol" label="Symbol" rules={[{ required: true, message: 'Please input token Symbol!' }]}>
                                                                <Input className="amount-buy" placeholder="Ex: ETH" />
                                                            </Form.Item>

                                                            {tokenType !== "liquidity" && (
                                                                <Form.Item name="decimals" label="Decimals" rules={[{ required: true, message: 'Please input Decimals!' }]}>
                                                                    <InputNumber className="amount-buy" placeholder="Ex: 18" />
                                                                </Form.Item>
                                                            )}

                                                            <Form.Item name="total_supply" label="Total supply" rules={[{ required: true, message: 'Please input Total supply!' }]}>
                                                                <InputNumber className="amount-buy" placeholder="Ex: 1000000" />
                                                            </Form.Item>

                                                            {tokenType !== "standard" && (
                                                                <>
                                                                    <Form.Item name="router_address" label="Router"
                                                                        initialValue={''}
                                                                        rules={[{ required: true, message: 'Please input Router!' }]}>
                                                                        <Select className="select-create-ido" >
                                                                            <Option value=''>Select Router Exchange</Option>
                                                                            {cexList.map((item, index) => (
                                                                                <Option value={item.routerAddress} key={'cex-' + index}>{item.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                </>
                                                            )}
                                                            {tokenType === "liquidity" && (
                                                                <>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="tax_fee_percent" label="Transaction fee to generate yield (%)"
                                                                                rules={[
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            (parseFloat(createTokenForm.getFieldValue('liquidity_fee_percent') || 0) +
                                                                                                parseFloat(createTokenForm.getFieldValue('charity_percent') || 0) +
                                                                                                parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                                                        }
                                                                                    }

                                                                                ]}
                                                                            >
                                                                                <Input type="number" placeholder="Ex: 1" className="amount-buy"
                                                                                    onChange={() => {
                                                                                        createTokenForm.validateFields(['liquidity_fee_percent'])
                                                                                        createTokenForm.validateFields(['charity_percent'])
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="liquidity_fee_percent" label="Transaction fee to generate liquidity (%)"
                                                                                rules={[

                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            (parseFloat(createTokenForm.getFieldValue('tax_fee_percent') || 0) +
                                                                                                parseFloat(createTokenForm.getFieldValue('charity_percent') || 0) +
                                                                                                parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                                                        }
                                                                                    }

                                                                                ]}
                                                                            >
                                                                                <Input type="number" placeholder="Ex: 1" className="amount-buy"
                                                                                    onChange={() => {
                                                                                        createTokenForm.validateFields(['tax_fee_percent'])
                                                                                        createTokenForm.validateFields(['charity_percent'])
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="charity_address" label="Charity/Marketing address"
                                                                                rules={[
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            (value && !ethers.utils.isAddress(value)) ? cb('Invalid Address') : cb();
                                                                                        }
                                                                                    }

                                                                                ]}
                                                                            >
                                                                                <Input className="amount-buy" type="text" placeholder="Ex: 0x..."
                                                                                    onChange={() => {
                                                                                        createTokenForm.validateFields(['charity_percent'])
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="charity_percent" label="Charity/Marketing percent (%)"
                                                                                rules={
                                                                                    [
                                                                                        // { required: true, message: 'Please input Charity/Marketing percent!' },
                                                                                        {
                                                                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                                (value && parseFloat(value) > 0 &&
                                                                                                    !createTokenForm.getFieldValue('charity_address'))
                                                                                                    ? cb("Can't set both charity address to addressZero and charity percent > 0")
                                                                                                    : (parseFloat(createTokenForm.getFieldValue('liquidity_fee_percent') || 0) +
                                                                                                        parseFloat(createTokenForm.getFieldValue('tax_fee_percent') || 0) +
                                                                                                        parseFloat(value) > 25) ? cb('total fee must be < 25%') : cb();
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <Input className="amount-buy" type="text" placeholder="0-25"
                                                                                    onChange={() => {
                                                                                        createTokenForm.validateFields(['liquidity_fee_percent'])
                                                                                        createTokenForm.validateFields(['tax_fee_percent'])
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}

                                                            {tokenType === "baby" && (
                                                                <>

                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="reward_token*" label="Reward token"
                                                                                rules={[{ required: true, message: 'Please input Reward token!' }]}
                                                                                help="If you want to reward DOGE, please enter 0xba2ae424d960c26247dd6c32edc70b295c744c43."
                                                                            >
                                                                                <Input type="text" placeholder="Ex: 0x..." className="amount-buy" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="minimum_token_balance_for_dividends" label="Minimum token balance for dividends"
                                                                                rules={[{ required: true, message: 'Please input token balance for dividends!' }]}
                                                                                help="Min hold each wallet must be over $50 to receive rewards."
                                                                            >
                                                                                <Input type="text" placeholder="Ex: 1000000000" className="amount-buy" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="token_reward _fee" label="Token reward fee (%)"
                                                                                rules={[
                                                                                    { required: true, message: 'Please input Token reward fee!' },
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                                ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                                                                : cb();
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Input className="amount-buy" type="number" placeholder="0-100" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="auto_add_liquidity" label="Auto add liquidity (%)"
                                                                                rules={
                                                                                    [
                                                                                        { required: true, message: 'Please input Auto add liquidity!' },
                                                                                        {
                                                                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                                !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                                    ? cb("Auto add liquidity must be >= 0 and <= 100 !")
                                                                                                    : cb();
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <Input className="amount-buy" type="number" placeholder="0-100" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="marketing_fee" label="Marketing fee (%)"
                                                                                rules={[
                                                                                    { required: true, message: 'Please input Marketing fee!' },
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                                ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                                                                : cb();
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Input className="amount-buy" type="number" placeholder="0-100" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="marketing_wallet" label="Marketing wallet"
                                                                                rules={
                                                                                    [{ required: true, message: 'Please input Marketing wallet!' }]
                                                                                }
                                                                            >
                                                                                <Input className="amount-buy" type="text" placeholder="0x..." />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}

                                                            {tokenType === "buyback" && (
                                                                <>

                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="reward_token*" label="Reward token"
                                                                                rules={[{ required: true, message: 'Please input Reward token!' }]}
                                                                                help="If you want to reward DOGE, please enter 0xba2ae424d960c26247dd6c32edc70b295c744c43."
                                                                            >
                                                                                <Input type="text" placeholder="Ex: 0x..." className="amount-buy" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="liquidity_fee" label="Liquidity Fee (%)"
                                                                                rules={[{ required: true, message: 'Please input Liquidity Fee (%)!' },
                                                                                {
                                                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                        !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                            ? cb("Token reward fee must be >= 0 and <= 100 !")
                                                                                            : cb();
                                                                                    }
                                                                                }]}
                                                                            >
                                                                                <Input type="number" placeholder="0-100" className="amount-buy" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="buyback_fee" label="Buyback Fee (%)" initialValue={3}
                                                                                rules={[
                                                                                    { required: true, message: 'Please input Buyback Fee!' },
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                                ? cb("Buyback Fee must be >= 0 and <= 100 !")
                                                                                                : cb();
                                                                                        }
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Input className="amount-buy" type="number" placeholder="0-100" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                            <Form.Item name="reflection_fee" label="Reflection Fee (%)" initialValue={8}
                                                                                rules={
                                                                                    [
                                                                                        { required: true, message: 'Please input Reflection Fee!' },
                                                                                        {
                                                                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                                !value || parseFloat(value) < 0 || parseFloat(value) > 100
                                                                                                    ? cb("Reflection Fee must be >= 0 and <= 100 !")
                                                                                                    : cb();
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <Input className="amount-buy" type="number" placeholder="0-100" />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Form.Item name="marketing_wallet" label="Marketing wallet"
                                                                        rules={
                                                                            [{ required: true, message: 'Please input Marketing wallet!' }]
                                                                        }
                                                                    >
                                                                        <Input className="amount-buy" type="text" placeholder="0x..." />
                                                                    </Form.Item>

                                                                </>
                                                            )}


                                                            {/* set fee */}

                                                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                                                <Form.Item
                                                                    name="fee_options"
                                                                    label="Fee Options"
                                                                    initialValue={feeOptions}
                                                                    rules={[{ required: true, message: 'Please pick fee options!' }]}
                                                                >
                                                                    <Radio.Group onChange={onChangeFee}>
                                                                        <Space direction="vertical" className='ml-15'>
                                                                            {feeList.map((item, index) => {
                                                                                let valFeeMainToken: any;
                                                                                if (item.settingKey === INIT_TOKEN_W86_FEE && item.feeType === "PERCENT") {
                                                                                    valFeeMainToken = parseFloat(initTokenFee) - (initTokenFee * (item.settingValue / 100));
                                                                                }
                                                                                return (
                                                                                    <>
                                                                                        {item.settingKey === INIT_TOKEN_FEE && ( // BNB fee
                                                                                            <Radio value={JSON.stringify(['INIT_TOKEN_FEE'])} key={'fee-' + index}>
                                                                                                {item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} {initToken?.payToken}
                                                                                            </Radio>
                                                                                        )}
                                                                                        {item.settingKey === INIT_TOKEN_W86_FEE && ( // BNB only fee
                                                                                            <Radio disabled={true} value={JSON.stringify(['INIT_TOKEN_W86_FEE', 'INIT_TOKEN_FEE'])} key={'fee-11' + index}>
                                                                                                Amount of <span className='text-info'>W86</span> equivalent to {valFeeMainToken} {initToken?.payToken} <span className='text-danger'>({item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} discount)</span>
                                                                                            </Radio>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </Space>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>

                                                            <Form.Item name="implement_anti_bot" valuePropName="checked" initialValue={false}>
                                                                <Checkbox>Implement WaveSignal Anti-Bot System?</Checkbox>
                                                            </Form.Item>
                                                            <Form.Item className="modal-btn-group">
                                                                <button className="btn-all" disabled={loading} type="submit">Create Token {loading && <Spin size="small" />}</button>
                                                                <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                                                            </Form.Item>
                                                        </Form>

                                                    </div>


                                                    {loading && (
                                                        <LoadingFull />
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default CreateToken
