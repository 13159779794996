import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Button, Space, Spin } from 'antd';
import { CalculatorOutlined, QuestionCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { stakingApi } from 'config/api/stakingApi';
import NoneData from 'components/element/NoneData';
import PoolItem from './staking-item';

const { Content } = Layout;

const LaunchPool = () => {

    const [stakingList, setStakingList] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [totalRow, setTotalRow] = useState(0);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 4,
            sort: 'id,desc',
            typeEnum: 'STAKE_FIXED_APR',
            keyword: '',
            w86: false,
        }
    );

    const getStakingList = async (status) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            active: status
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setLoadingData(false);
                    setStakingList(res.data);
                } else {
                    setLoadingData(false);
                    setStakingList([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        getStakingList(true);
    }, []);

    return (
        <>
            <div className="launch-pool">
                <div className="launch-pool-content container">
                    <div className="launch-pool-title">
                        Launchpools
                    </div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        {!loadingData ? (
                            <>
                                {stakingList?.length > 0 ? (
                                    stakingList.map((item, index) => {
                                        return (
                                            <PoolItem item={item} key={'pool' + index} />
                                        )
                                    })
                                ) : (
                                    <>
                                        <NoneData text={'No Data'} />
                                    </>
                                )}
                            </>
                        ) : (<>
                            <div className="loading-data">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        </>)}

                        
                    </Row>
                    <div className="see-pool-link">
                        <Link to="/fixed-staking">See More <RightCircleOutlined className="icon-link" /></Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LaunchPool
