
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Select, Input, Tooltip, Button, Table, Space, Spin } from 'antd';
import { CalculatorFilled, DownOutlined, PlusOutlined, QuestionCircleOutlined, RiseOutlined, UpOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import LoadingFull from 'components/loading-full';
import ConnectWallet from 'components/connect-wallet/ConnectWallet';
import web3 from 'web3';
import { useWrap } from '../../../../context/WrapperContext';
import { ethers } from 'ethers';
import tokenInfoAbi from '../../../../config/abi/tokenInfo.json';
import { getInformationByChain } from 'config/network/multichainAddresses';
import stakingAbi from '../../../../config/abi/stakingAbi.json';
import {
    useContract
} from "../../../../hooks/useContract";

import {
    approveToken, getTokenAllowance, getTokenInformation
} from '../../../utils';
import {
    getProgressTime,
    rendererCountDown,
    _totalStakedUser,
    _totalStaked,
    _stakeToken,
    _totalReward,
    _harvetReward,
    _unstaking
} from "../utilsStaking"
import TableRoi from './TableRoi';
import ApyCalculatorModal from '../ApyCalculatorModal';

declare const window: Window & typeof globalThis & { ethereum: any };
let intervalReward: any = null;
const StakingItem = (props) => {
    const { item } = props;
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [showRoi, setShowRoi] = useState(false);
    const [showAmountPopup, setShowAmountPopup] = useState(false);
    const [allowance, setAllowance] = useState(0);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const { showNoti } = useWrap();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [stakeTokenBalance, setStakeTokenBalance] = useState(0);
    const [totalStakedUsers, setTotalStakedUser] = useState<any>(0);
    const [totalStaked, setTotalStaked] = useState<any>(0);
    const [totalReward, setTotalReward] = useState(0);
    const [decimal, setDecimal] = useState(0);
    const [tokenEarnPrice, setTokenEarnPrice] = useState<any>(10);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let earnTokenAddress: any = item.earnToken;
    let stakeTokenAddress: any = item.stakeToken;
    let poolContractAddress: any = item.poolContract;

    const poolContract = useContract(poolContractAddress, stakingAbi);
    const stakeTokenContract = useContract(stakeTokenAddress, tokenInfoAbi);

    const onShowRoi = () => {
        setShowRoi(true);
    }
    const onShowAddAmount = () => {
        setShowAmountPopup(true);
    }

    const onShowDetail = (id: any) => {
        if (isShowDetail) {
            setIsShowDetail(false);
        } else {
            setIsShowDetail(true);
        }
    }

    async function stakeTokenBalanceSc() {
        if (typeof window.ethereum !== 'undefined') {
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const contract = new ethers.Contract(stakeTokenAddress, tokenInfoAbi, provider);
                const tokenBalance = await contract.balanceOf(account);
                return tokenBalance.toString();
            } else {
                return 0;
            }
        }
    }
    const getStakeTokenBalance = () => {
        stakeTokenBalanceSc()
            .then((val) => {
                if (val > 0) {
                    let _balance: any = web3.utils.fromWei(web3.utils.toBN(val), 'ether');
                    let tokenBalance = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setStakeTokenBalance(tokenBalance);
                } else {
                    setStakeTokenBalance(0);
                }
            })
            .catch((e) => { });
    };

    const closeBox = (e) => {
        e.preventDefault()
        setShowRoi(false);
        setShowAmountPopup(false);
    };
    // APPROVE TOKEN

    const handleApproveToken = async (e) => {
        try {
            setIsLoading(true);
            e.preventDefault()

            await approveToken(tokenInfoAbi, stakeTokenAddress, library, account, poolContractAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve Successfully`);
                                        countNoti++;
                                        setAllowance(Math.pow(2, 256));
                                    } else {
                                        showNoti('error', `Approve Failed`);
                                    }
                                    setIsLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('error', error.message);
                        } else if (error.reason) {
                            showNoti('error', error.reason);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('error', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            console.log(error);
            setIsLoading(false);
        }
    };

    // confirm stake
    const onConfirmAmount = async (values: any) => {

        setIsLoading(true);

        await _stakeToken(poolContract, values.amount)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        let totalStakedNew: any = parseFloat(totalStaked) + parseFloat(values.amount);
                                        let totalStakedUsersNew: any = parseFloat(totalStakedUsers) + parseFloat(values.amount);

                                        showNoti('success', 'Stake Successfully');
                                        setShowAmountPopup(false);
                                        setIsLoading(false);
                                        setTotalStaked(totalStakedNew);
                                        setTotalStakedUser(totalStakedUsersNew);
                                    }
                                } else {
                                    showNoti('error', 'Stake Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });
    }

    const onHarvetReward = async () => {

        setIsLoading(true);

        await _harvetReward(poolContract, true)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        showNoti('success', 'Hartvet Successfully');
                                        setIsLoading(false);
                                        setTotalReward(0);
                                    }
                                } else {
                                    showNoti('error', 'Hartvet Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });


    }
    const onUnstake = async () => {

        setIsLoading(true);

        await _unstaking(poolContract, false)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        showNoti('success', 'Unlock Successfully');
                                        setIsLoading(false);
                                        setTotalReward(0);
                                        setTotalStaked(0);
                                        setTotalStakedUser(0);
                                    }
                                } else {
                                    showNoti('error', 'Unlock Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('error', error.message);
                    } else if (error.reason) {
                        showNoti('error', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('error', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });


    }

    const onSetMax = (stakeTokenBalance: any) => {
        form.setFieldsValue(
            {
                amount: stakeTokenBalance
            }
        )
    }

    const getTokenInfo = async (stakeTokenAddress, poolContractAddress) => {
        setIsLoadingInfo(true);
        let allowanceSC = await getTokenAllowance(tokenInfoAbi, stakeTokenAddress, account, poolContractAddress)
        const tokenInfoSC = await getTokenInformation(tokenInfoAbi, stakeTokenAddress);
        setDecimal(tokenInfoSC.decimals);
        setIsLoadingInfo(false);
        setAllowance(parseFloat(ethers.utils.formatUnits(allowanceSC, parseInt(tokenInfoSC.decimals))));
    }

    const getRewardTokenInterval = () => {
        intervalReward = setInterval(() => {
            (async () => {
                try {
                    _totalReward(poolContract, earnTokenAddress, account).then(
                        (res: any) => {
                            let totalReward: any = ethers.utils.formatUnits(res, 'ether');
                            setTotalReward(totalReward);
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            })();
        }, 5000);
    };

    // get total staked by account

    useEffect(() => {
        if (account) {
            _totalStakedUser(poolContract, account).then(
                (res: any) => {
                    let totalStakedUser: any = ethers.utils.formatUnits(res.amount, 'ether');
                    setTotalStakedUser(totalStakedUser);

                }
            );
            _totalStaked(poolContract).then(
                (res: any) => {
                    let totalStaked: any = ethers.utils.formatUnits(res, 'ether');
                    setTotalStaked(totalStaked);
                }
            );

            _totalReward(poolContract, earnTokenAddress, account).then(
                (res: any) => {
                    let totalReward: any = ethers.utils.formatUnits(res, 'ether');
                    setTotalReward(totalReward);
                }
            );

            getRewardTokenInterval();

        }

    }, [account])

    useEffect(() => {
        if (account && stakeTokenAddress) {
            getStakeTokenBalance();
        }
    }, [account, stakeTokenAddress]);

    useEffect(() => {
        if (stakeTokenAddress && poolContractAddress && account) {

            getTokenInfo(stakeTokenAddress, poolContractAddress)
        }
    }, [account, stakeTokenAddress, poolContractAddress]);



    return (
        <>

            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8} key={item.id}>
                <div className='staking-item'>
                    <div className='staking-item-header'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={18} xl={16} md={16} sm={16}>
                                <div className='staking-title'> {item.title}
                                    <Tooltip placement="top" title={'Any WaveSignal tokens you stake in this pool will be automatically harvested and compounded for you every 5 minutes.'}>
                                        <QuestionCircleOutlined className='icon-title' />
                                    </Tooltip>
                                </div>
                                <div className='staking-description'>Stake {item.stakeTokenName} - Earn {item.earnTokenName}</div>
                                <div className='staking-status'>

                                    {item.maxAmountStake === 0 || item.maxAmountStake === null ? (
                                        <>Unlimited</>
                                    ) : (
                                        <>max {item.maxAmountStake}</>
                                    )}
                                </div>
                            </Col>
                            <Col className="gutter-row text-right" xs={6} xl={8} md={8} sm={8}>
                                <div className='stake-logo'>
                                    {/* <img src={item.stakeTokenLogo} /> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='staking-item-content'>
                        <div className='apr-content'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={12}>
                                    <div className='apr-content-caculator'>
                                        APY
                                        <span><CalculatorFilled onClick={() => onShowRoi()} /></span>
                                        <Tooltip placement="top" title={item.aprTip}>
                                            <QuestionCircleOutlined className='icon-title' />
                                        </Tooltip>
                                    </div>
                                </Col>
                                <Col className="gutter-row text-right" xs={12}>
                                    <div className='apr-content-percent'>{item.apr}%</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='apr-button'>
                            {account ? (
                                <>
                                    {isLoadingInfo ? (
                                        <>
                                            <Space align='center'><Spin size="small" /></Space>
                                        </>
                                    ) : (
                                        <>
                                            {allowance > 0 ? (
                                                <>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                        <Col className="gutter-row" xs={12}>
                                                            <div className='total-staking'>
                                                                <p className='mb-0'><small>Staked {item.stakeTokenName}</small></p>
                                                                <p className='mb-0 total'>{totalStakedUsers.toLocaleString()} {item.stakeTokenName}</p>
                                                                <p className='mb-0'><small>$0.0000</small></p>
                                                            </div>
                                                        </Col>
                                                        <Col className="gutter-row text-right" xs={12}>
                                                            <Button htmlType='button' style={{ marginTop: '10px' }} className='btn-all' onClick={onShowAddAmount}><PlusOutlined /></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                        <div className='btn-group'>
                                                            {totalReward > 0 && (
                                                                <button className="btn-all" onClick={onHarvetReward}>Harvet {isLoading && <Spin className="ml-10" size="small" />}</button>
                                                            )}
                                                            {totalStakedUsers > 0 && (
                                                                <button className="btn-default" onClick={onUnstake}>Unstake {isLoading && <Spin className="ml-10" size="small" />}</button>
                                                            )}
                                                        </div>
                                                    </Row>
                                                </>
                                            ) : (
                                                <Button htmlType='button' onClick={handleApproveToken} className='btn-all'>Approve WAVE</Button>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <ConnectWallet />
                            )}
                        </div>
                        {account && (
                            <div className='apr-description'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p>Recent {item.stakeTokenName} profit</p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        {allowance > 0 && (
                                            <p><span className='text-info'>{totalReward > 0 ? totalReward.toLocaleString() : 0}</span></p>
                                        )}
                                    </Col>
                                </Row>

                                <p className='mb-0'>
                                    <strong className='text-info'>{item.unStakeFee}% unstaking fee until</strong>
                                    <Tooltip placement="top" title={`Only applies within ${item.unStakeFeeTime} days and 2 hours of staking. Unstaking after ${item.unStakeFeeTime} days and 2 hours will not include a fee. Timer resets every time you stake new WaveSignal in the pool.`}>
                                        <QuestionCircleOutlined className='icon-title' />
                                    </Tooltip>
                                </p>
                            </div>
                        )}
                        {isShowDetail && (
                            <div className='staking-detail-content'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p>Total stake</p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        <p><span className='text-info'>{totalStaked.toLocaleString()} </span></p>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p>
                                            Performance Fee
                                            <Tooltip placement="top" title={'Subtracted automatically from each yield harvest and burned once a month.'}>
                                                <QuestionCircleOutlined className='icon-title' />
                                            </Tooltip>
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        <p><span className='text-info'>1.99%</span></p>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p className='mb-0'>
                                            <Link to="">View Contract <RiseOutlined className='icon-title' /></Link>
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        <p className='mb-0'>
                                            <Link to="">Learn about WaveSignal <RiseOutlined className='icon-title' /></Link>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )}

                    </div>
                    <div className='staking-item-detail'>
                        <Button htmlType='button' onClick={() => onShowDetail('1')} className='btn-detail'>Detail {isShowDetail ? (<UpOutlined />) : (<DownOutlined />)}</Button>
                    </div>
                </div>
            </Col>

            <Modal
                title={<div className="text-md custom-modal-title">ROI</div>}
                visible={showRoi}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p>Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</p>

                    <ApyCalculatorModal
                        tokenPrice={tokenEarnPrice}
                        apr={item.apr}
                        earningTokenSymbol={item.earnTokenName}
                        performanceFee={item.unStakeFee}
                    />

                    <div className="modal-btn-group mt-15">
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>

            <Modal
                title={<div className="text-md custom-modal-title">Stake {item.stakeTokenName} Tokens</div>}
                visible={showAmountPopup}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">

                    <Form
                        form={form}
                        name="basic"
                        autoComplete="off"
                        onFinish={onConfirmAmount}
                    >

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={12}>
                                <p className='mb-0' style={{ paddingTop: '7px' }}>
                                    <span className='text-info'>Max stake</span>: {item.maxAmountStake > 0 ? (
                                        <span className='text-info'>{item.maxAmountStake.toLocaleString()}</span>
                                    ) : (
                                        <>Unlimit</>
                                    )}
                                </p>
                            </Col>
                            <Col className="gutter-row" xs={12}>
                                <p className='text-right text-info'>
                                    <strong className='text-danger'>{stakeTokenBalance.toLocaleString()}</strong> {item.stakeTokenName} Available
                                    <Button htmlType='button' onClick={() => onSetMax(stakeTokenBalance)} className='btn-max btn-all'>Max</Button>
                                </p>
                            </Col>
                        </Row>
                        <Form.Item name="amount" label="Amount"
                            rules={[
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {

                                        if (!value || parseFloat(value) <= 0) {
                                            cb('Invalid value')
                                        } else if (item.maxAmountStake > 0 && parseFloat(value) > item.maxAmountStake) {
                                            cb('Invalid limit')
                                        } else if (parseFloat(value) > stakeTokenBalance) {
                                            cb("Insufficient Balance");
                                        } else {
                                            cb();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" placeholder="Ex: 1000" />

                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" type="submit" disabled={isLoading}>Confirm {isLoading && <Spin className="ml-10" size="small" />}</button>
                            <button className="btn-default" onClick={closeBox} disabled={isLoading}>Close {isLoading && <Spin className="ml-10" size="small" />}</button>
                        </Form.Item>
                    </Form>

                </div>

            </Modal>

            {isLoading && (
                <LoadingFull />
            )}

        </>
    )
}

export default StakingItem
