
import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import ApplyToLaunch from '../apply-to-launch';

const { TextArea } = Input;
const { Content } = Layout;

const IdoUpdate = () => {
    const location = useLocation();

    const [form] = Form.useForm<{ name: string; age: number }>();
    let { id }: { id: string } = useParams();
    const onSubmitUpdate = (values: any) => {
        console.log('Finish:', values);
    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div>
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-update">
                            <div className="back-history">
                                <Link to={`/ico/detail/${id}`}><LeftCircleOutlined /> Cancel the Edit IDO</Link>
                            </div>
                            <div className="update-form">
                                <div className="update-form-title">
                                    Add Additional Info
                                </div>
                                <Form form={form} layout="vertical" autoComplete="off" onFinish={onSubmitUpdate}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item name="amount" label="Logo URL">
                                                <Input className="amount-buy" placeholder="http://wavecoin.finance/static/media/wavec.png" />
                                                <small className="warning-form">URL must end with a supported image extension png, jpg, jpeg or gif</small>
                                            </Form.Item>
                                            <Form.Item name="facebook" label="Facebook">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                            <Form.Item name="github" label="Github">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                            <Form.Item name="instagram" label="Instagram">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                            <Form.Item name="reddit" label="Reddit">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item name="amount" label="Website">
                                                <Input className="amount-buy" placeholder="http://wavecoin.finance/" />
                                                <small className="text-des-form">URL must end with a supported image extension png, jpg, jpeg or gif</small>
                                            </Form.Item>
                                            <Form.Item name="twitter" label="Twitter">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                            <Form.Item name="telegram" label="Telegram">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                            <Form.Item name="discord" label="Discord">
                                                <Input className="amount-buy" placeholder="Enter a link" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item name="description" label="Description">
                                        <TextArea rows={4} className="amount-buy" placeholder="Enter description" />
                                    </Form.Item>
                                    <hr className="hr-cus"></hr>
                                    <Form.Item className="gr-button">
                                        <Button className="btn-buy" htmlType="button">Cancel</Button>
                                        <Button type="primary" className="btn-buy" htmlType="submit">Buy</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                        <ApplyToLaunch />
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default IdoUpdate
