
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Link, useParams } from 'react-router-dom';
import { Layout, Row, Col, Button, Spin, Space, Tabs, Alert, Card, Table } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormOutlined, CopyOutlined, RightOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import {
    getLockById,
    getLP,
    getTokenInformation,
    cumulativeLockInfo, getWithdrawableTokens, approveToken, renounceLockOwnership, unLock
} from '../../utils';
import web3 from 'web3';
import { ethers } from 'ethers';

import { useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import pairAbi from '../../../config/abi/pairAbi.json';
import { convertDateTime } from 'utils/formatDatetime';

declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { TabPane } = Tabs;

const LockRecord = () => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();
    let { id }: { id: string } = useParams();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingVesting, setLoadingVesting] = useState(false);
    const [loadingKey, setLoadingKey] = useState(0);
    const [tokenAddress, setTokenAddress] = useState('');
    const [errorCallSC, setErrorCallSC] = useState(false);
    const [tokenSymbol, setTokenSymbol] = useState();
    const [tokenName, setTokenName] = useState();
    const [tokenDecimal, setTokenDecimal] = useState<any>(18);
    const [isLP, setIsLP] = useState<boolean>(false);
    const [pairName, setPairName] = useState<any>('');
    const [dex, setDex] = useState<any>('');
    const [description, setDescription] = useState<any>('');
    const [amountLocked, setAmountLocked] = useState<any>(0);
    const [valueLocked, setValueLocked] = useState<any>(0);
    const [owner, setOwner] = useState<any>('');
    const [cycle, setCycle] = useState<any>(0);
    const [cycleBps, setCycleBps] = useState<any>(0);
    const [lockDate, setLockDate] = useState<any>(Math.round(Date.now()));
    const [tgeBps, setTgeBps] = useState<any>(0);
    const [tgeDate, setTgeDate] = useState<any>(Math.round(Date.now()));
    const [unlockedAmount, setUnlockedAmount] = useState<any>(0);
    const [token0Name, setToken0Name] = useState<any>('');
    const [token1Name, setToken1Name] = useState<any>('');
    const [claimableTokens, setClaimableTokens] = useState<any>('');
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [vestingInfo, setVestingInfo] = useState<boolean>(false);
    const [dataVesting, setDataVesting] = useState<any>();



    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');
    const waveLockContract = useWaveLockContract(waveLockAddress)

    const getLockRecordDetail = async () => {
        try {
            if (account) {
                const lock = await getLockById(waveLockContract, id);
                const info = await cumulativeLockInfo(waveLockContract, lock.token);


                if (info.factory.toLowerCase() !== ethers.constants.AddressZero) {
                    const lpInfo = await getLP(info.token, pairAbi, library, account);
                    setIsLP(true);
                    setPairName(`${lpInfo.token0Info.symbol}/${lpInfo.token1Info.symbol}`)
                    setToken0Name(lpInfo.token0Info.name)
                    setToken1Name(lpInfo.token1Info.name)
                    setDex(lpInfo.name)
                    setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, 18)))
                    setUnlockedAmount(parseFloat(ethers.utils.formatUnits(lock.unlockedAmount, 18)));
                    if (lock.owner.toLowerCase() === account.toLowerCase()) {
                        setIsOwner(true);
                        if (Math.round(Date.now()) > tgeDate) {
                            const claimable = await getWithdrawableTokens(waveLockContract, id);
                            setClaimableTokens(parseFloat(ethers.utils.formatUnits(claimable, 18)));
                        }
                    }
                } else {
                    const tokenInfo = await getTokenInformation(pairAbi, info.token);
                    setTokenName(tokenInfo.name);
                    setTokenSymbol(tokenInfo.symbol);
                    setTokenDecimal(tokenInfo.decimals);
                    setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, tokenInfo.decimals)))
                    setUnlockedAmount(parseFloat(ethers.utils.formatUnits(lock.unlockedAmount, tokenInfo.decimals)));
                    if (lock.owner.toLowerCase() === account.toLowerCase()) {
                        setIsOwner(true);
                        if (Math.round(Date.now()) > tgeDate) {
                            const claimable = await getWithdrawableTokens(waveLockContract, id);
                            setClaimableTokens(parseFloat(ethers.utils.formatUnits(claimable, tokenInfo.decimals)));
                        }
                    }
                }

                setDescription(lock.description)
                setOwner(lock.owner)
                setCycle(parseInt(lock.cycle) / 60);
                setCycleBps(parseInt(lock.cycleBps) / 100);
                setLockDate(parseInt(lock.lockDate) * 1000);
                setTgeBps(parseInt(lock.tgeBps) / 100);
                setTgeDate(parseInt(lock.tgeDate) * 1000);
                setTokenAddress(info.token);

            }
        } catch (e) {
            console.log(e);
        }
    }

    const renounceLockOwnerShip = async () => {
        try {
            if (loading || !account) {
                return;
            }
            setLoading(true);
            setLoadingKey(1);
            await renounceLockOwnership(waveLockContract, id)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Renounce Lock Ownership Successfully`);
                                        countNoti++;
                                        setIsOwner(false);
                                        setOwner(ethers.constants.AddressZero)
                                    } else {
                                        showNoti('error', `Renounce Lock Ownership Failed`);
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            setLoading(false);
            console.log(error);
        }
    };

    const unlock = async () => {
        try {
            if ((loading) || !account) {
                return;
            }
            setLoading(true);
            setLoadingKey(2);
            await unLock(waveLockContract, id)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Unlock Successfully`);
                                        countNoti++;
                                        if (tgeBps === 0) {
                                            setUnlockedAmount(amountLocked);
                                        } else {
                                            setUnlockedAmount(claimableTokens);
                                        }
                                        setClaimableTokens(0)
                                    } else {
                                        showNoti('error', `UnLock Failed`);
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            setLoading(false);
            console.log(error);
        }
    };


    useEffect(() => {
        getLockRecordDetail();
    }, [account]);

    const onCopy = () => {
        showNoti('success', 'Copied');
    };

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // window.location.reload();
            // return;
        }
        return (
            <div className="process-tc process-lock">
                <div className="timer-count">
                    {days} <span>:</span><small>day</small>
                </div>
                <div className="timer-count">
                    {hours} <span>:</span><small>hours</small>
                </div>
                <div className="timer-count">
                    {minutes} <span>:</span><small>mins</small>
                </div>
                <div className="timer-count">
                    {seconds} <small>sec</small>
                </div>
            </div>
        );
    };
    const getProgressTime = useCallback((startTime) => {

        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);

    const showVestingInfo = () => {
        setVestingInfo(!vestingInfo);
        setLoadingVesting(true);
        let listVesting: any[] = [];

        let firstTokenUnlock: any = amountLocked * (tgeBps / 100);
        let cycleTokenRelease: any = amountLocked * (cycleBps / 100);
        let numberLoopClaim: any = (amountLocked - firstTokenUnlock) / cycleTokenRelease;

        listVesting.push({
            id: 1,
            time: tgeDate,
            unlocked_tokens: firstTokenUnlock,
            percent: tgeBps,
        });

        if (tgeBps < 100) {
            let time: any;
            for (let i = 1; i <= numberLoopClaim; i++) {
                time = tgeDate + (i * (cycle * 60 * 1000));

                listVesting.push({
                    id: i + 1,
                    time: time,
                    unlocked_tokens: cycleTokenRelease,
                    percent: cycleBps,
                });
            }
        }

        console.log('listVesting', listVesting);
        setDataVesting(listVesting);
        setLoadingVesting(false);
    }

    const columns = [
        {
            title: 'Unlock #',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        {record.id}
                    </Space>
                )
            }
        },
        {
            title: 'Time (UTC)',
            dataIndex: 'time',
            key: 'time',
            render: (text: any, record: any) => (
                <>
                    {convertDateTime(new Date(record.time))}
                </>
            )
        },
        {
            title: 'Unlocked tokens',
            dataIndex: 'unlocked_tokens',
            key: 'amounlocked_tokensunt',
            render: (text: any, record: any) => (
                <>
                    {record.unlocked_tokens} ({record.percent}%)
                </>
            )
        }
    ];

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="lock-content-detail">
                                <Card title="" extra={''} style={{ width: '100%' }} className="mb-15">
                                    <p style={{ textAlign: 'center' }}><strong>Unlock In</strong></p>
                                    <p style={{ textAlign: 'center' }}>
                                        <Countdown
                                            date={getProgressTime(Date.now() > tgeDate ? Date.now() : tgeDate)}
                                            renderer={rendererCountDown}
                                        />
                                    </p>
                                </Card>
                                <Card title="Token Info" extra={''} style={{ width: '100%' }} className="mb-15">
                                    {!errorCallSC ? (
                                        loadingTokenInfo ? (
                                            <div className="loading-info">
                                                <Space size="middle">
                                                    <Spin size="large" />
                                                </Space>
                                            </div>
                                        ) : (
                                            isLP ? (
                                                <div className="token-info-anti">
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-name">Pair Address</div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-value" style={{ color: '#1890ff' }}>
                                                                <span className="mr-10">{tokenAddress}</span>
                                                                <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                                    <CopyOutlined />
                                                                </CopyToClipboard>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-name">Pair Name</div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-value">{pairName}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-name">Token</div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-value">{token0Name}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-name">Quote Token</div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-value">{token1Name}</div>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-name">Dex</div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                            <div className="token-value">{dex}</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) :
                                                (
                                                    <div className="token-info-anti">
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Token Address</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value" style={{ color: '#1890ff' }}>
                                                                    <span className="mr-10">{tokenAddress}</span>
                                                                    <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                                        <CopyOutlined />
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Name</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenName}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Symbol</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenSymbol}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-name">Decimals</div>
                                                            </Col>
                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                <div className="token-value">{tokenDecimal}</div>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                )
                                        )

                                    ) : (
                                        <>
                                            <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                                        </>
                                    )}
                                </Card>
                                <Card title="Lock Info" extra={''} style={{ width: '100%' }} className="mb-15">

                                    <div className="token-info-anti">
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-name">Title</div>
                                            </Col>
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-value">
                                                    {(tgeDate > Date.now()) ? <Link to={`/wave-lock/update/${id}/metadata`}>{description} <FormOutlined /></Link> : description}

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-name">Total Amount Locked</div>
                                            </Col>
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-value">{amountLocked} {tokenSymbol}</div>
                                            </Col>
                                        </Row>
                                        {/*<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">*/}
                                        {/*    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>*/}
                                        {/*        <div className="token-name">Total Values Locked</div>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>*/}
                                        {/*        <div className="token-value">${valueLocked}</div>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-name">Owner</div>
                                            </Col>
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-value" style={{ color: '#1890ff' }}>
                                                    <span className="mr-10">{owner}</span>
                                                    <CopyToClipboard onCopy={onCopy} text={owner}>
                                                        <CopyOutlined />
                                                    </CopyToClipboard>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-name">Lock Date</div>
                                            </Col>
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-value">{convertDateTime(new Date(lockDate))}</div>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-name">Unlock Date</div>
                                            </Col>
                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                <div className="token-value">{convertDateTime(new Date(tgeDate))}</div>
                                            </Col>
                                        </Row>
                                        {tgeBps > 0 && (
                                            <>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-name">TGE Percent	</div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-value">{tgeBps} %</div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-name">Cycle</div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-value">{cycle} (minutes)</div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-name">Cycle Release Percent</div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-value">{cycleBps} %</div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-name">Unlocked Amount</div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-value">{unlockedAmount.toLocaleString()} {tokenSymbol}</div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-name">Vesting Info</div>
                                                    </Col>
                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                        <div className="token-value">
                                                            <Button htmlType="button" type="dashed" className="" onClick={showVestingInfo} style={{ minWidth: '40px !important' }} ><RightOutlined /></Button>
                                                        </div>
                                                    </Col>
                                                    {vestingInfo && (
                                                        <Col className="gutter-row" xs={24}>
                                                            <Table className="table-mt" dataSource={dataVesting} columns={columns} pagination={false} />
                                                        </Col>
                                                    )}
                                                </Row>
                                            </>
                                        )}

                                    </div>
                                    {isOwner && (
                                        <div className="grp-button">
                                            <Button htmlType="button" className="btn-all" disabled={loading && loadingKey === 1} onClick={renounceLockOwnerShip}>Renounce Lock Ownership {(loading && loadingKey === 1) && <Spin size="small" />}</Button>
                                            {Math.round(Date.now()) < tgeDate && (
                                                <Link to={`/wave-lock/update/${id}/edit`}><Button htmlType="button" className="btn-all">Update</Button></Link>
                                            )}
                                            <Button onClick={unlock}
                                                htmlType='button'
                                                className='btn-all'
                                                disabled={(tgeDate > Date.now()) ||
                                                    (tgeBps > 0 && claimableTokens === 0) ||
                                                    (tgeBps === 0 && unlockedAmount > 0) ||
                                                    (loading && loadingKey === 2)}>
                                                Unlock {claimableTokens > 0 ? `(${claimableTokens} ${tokenSymbol ? tokenSymbol : ''})` : ''}  {(loading && loadingKey === 2) && <Spin size="small" />}
                                            </Button>

                                        </div>
                                    )}

                                </Card>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default LockRecord
