
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse, Button, Table } from 'antd';
import { CopyOutlined, DoubleRightOutlined, IssuesCloseOutlined, RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import { useActiveWeb3React } from "../../../../hooks";
import { useWrap } from "../../../../context/WrapperContext";
import CopyToClipboard from 'react-copy-to-clipboard';
const { Content } = Layout;

const ReferralBanner = () => {

    const { showNoti } = useWrap();
    const { chainId, account, library } = useActiveWeb3React();
    const [showWithdrawalHistory, setShowWithdrawalHistory] = useState<any>(false);
    const [withdrawal, setWithdrawal] = useState<any>([]);

    const onCopy = () => {
        showNoti("success", "Copied");
    };
    const onWithdrawalHistory = (type: any) => {
        console.log('type', type);
        // call api get list withdraw by type
        setShowWithdrawalHistory(true);
    }

    const closeBox = () => {
        setShowWithdrawalHistory(false);
    };
    let linkRef: any = `${window.location.protocol + '//' + window.location.hostname}?ref=${account}`;
    let linkRefCut: any = `${window.location.protocol + '//' + window.location.hostname}?ref=${account?.substring(0, 6)}...${account?.substring(account.length - 6)}`;

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Hash',
            dataIndex: 'hash',
            key: 'hash',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ];

    return (
        <>
            <div className="referral-banner">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                        <div className="referral-banner-content">
                            <div className="referral-banner-apply-content">
                                <div className="referral-banner-investor-name">
                                    Invite friends. Earn coin together
                                </div>
                            </div>
                            <div className='referral-reward-block'>
                                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                                    <Col className="gutter-row border-right" xs={24} xl={12} md={12} sm={12}>
                                        <div className="ref-block-rw-item">
                                            <div className='ref-title'>
                                                Farms Referral
                                                <Button htmlType='button' className='ref-btn-his' onClick={() => onWithdrawalHistory("farms")}>
                                                    <IssuesCloseOutlined classID='icon-cus' />
                                                    History
                                                </Button>
                                            </div>
                                            <div className='ref-balance'>
                                                0.0000 <span>W86</span>
                                            </div>
                                            <div className='ref-withdraw'>
                                                <Button htmlType='button' className='btn-all'>Withdraw</Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                        <div className="ref-block-rw-item">
                                            <div className='ref-title'>
                                                Launchpools Referral
                                                <Button htmlType='button' className='ref-btn-his' onClick={() => onWithdrawalHistory("launchpools")}>
                                                    <IssuesCloseOutlined classID='icon-cus' />
                                                    History
                                                </Button>
                                            </div>
                                            <div className='ref-balance'>
                                                0.0000 <span>W86</span>
                                            </div>
                                            <div className='ref-withdraw'>
                                                <Button htmlType='button' className='btn-all'>Withdraw</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </Col>
                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                        <div className='referral-right'>
                            <div className='ref-link'>
                                {linkRefCut}
                                <CopyToClipboard onCopy={onCopy} text={linkRef}>
                                    <Button className="btn-all btn-copy-ref">
                                        <CopyOutlined size={30} />
                                    </Button>
                                </CopyToClipboard>
                            </div>
                            <div className='ref-reward'>
                                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                                    <Col className="gutter-row" xs={24} xl={15} md={15} sm={15}>
                                        <div className="ref-reward-my">
                                            <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                                                <Col className="gutter-row border-right" xs={24} xl={10} md={10} sm={10}>
                                                    <div className="ref-block">
                                                        <div className='ref-title'>
                                                            You will get
                                                        </div>
                                                        <div className='ref-main-percent'>
                                                            100%
                                                        </div>
                                                        <DoubleRightOutlined className='ref-arrow' />
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                                    <div className="ref-block">
                                                        <p><span>Farms</span> 5%</p>
                                                        <p><span>Launchpools</span> 5%</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={9} md={9} sm={9}>
                                        <div className="ref-reward-friend">
                                            <div className="ref-block">
                                                <div className='ref-title'>
                                                    Friends will get
                                                </div>
                                                <div className='ref-main-percent'>
                                                    0%
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <Modal
                title={<div className="text-md custom-modal-title">Withdrawal history</div>}
                visible={showWithdrawalHistory}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Table dataSource={withdrawal} columns={columns} />
                    <div className="modal-btn-group mt-15">
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default ReferralBanner
