import { instance } from '../../apiBase/instance';
let tokenID = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY1MTk0MDI4MX0.OGkSOjRJjH5519O4K94Oiw7Qavs-FpYFryb8O_5JOoKbJxmw6-Ho3Zo5oHj_HidbZ42oNl3hdNMlzk6qvGBp0g';
export const IdoLaunchpad = {

	getChainNetworkList(params) {
		return instance.get<any>(`chain-lists/client`, { params });
	},
	getSettings() {
		return instance.get<any>(`settings`, {});
	},
	getSettingsByChain(chainId: any) {
		return instance.get<any>(`settings/chain-id/${chainId}`, {});
	},
	getCexList(params) {
		return instance.get<any>(`cex-lists/client`, { params });
	},
	getLaunchpads(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post<any>(`list-launchpad`, data, { params });
	},
	getRoundIdoList(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post<any>(`round-ido/list`, data, { params });
	},
	getRoundIdoCalendar(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post<any>(`round-ido/calendar`, data, { params });
	},
	getLaunchpadDetailByHandle(handle: any, address: any) {
		return instance.get<any>(`launchpad-ido/${handle}/details?ownerAddress=${address ? address : ''}`);
	},
	getLaunchpadByAddress(account, params) {
		return instance.get<any>(`launchpad/${account}/address`, { params });
	},
	getWhitelistIdoByAddress(idoId: any, account: any) {
		return instance.get<any>(`round-ido/${idoId}/address/${account}/white-list-ido`);
	},
	getAllocationByAddress(idoId: any, account: any) {
		return instance.get<any>(`round-ido/${idoId}/claim-allocation/${account}`);
	},
	
	genLaunchpadById(id) {
		return instance.get<any>(`launchpad/${id}`);
	},
	checkWhiteListById(roundIdoId, address) {
		return instance.get<any>(`/round-ido/${roundIdoId}/my-address/${address}`);
	},
	getWhitelistByRound(roundId, params) {
		return instance.get<any>(`round/${roundId}/white-lists`);
	},
	createIdoLaunchpad(data) {
		return instance.post<any>(`create-launchpad`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateStatusIdoPool(roundId, data) {
		return instance.put<any>(`rounds/${roundId}/action`, data, {
			headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateHashAfterJoinPool(tokenID, data) {
		return instance.put<any>(`white-lists-ido/update-wl/client`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateIdoWhitelist(roundId, data) {
		return instance.put<any>(`rounds/${roundId}/enable-whitelist`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	addWhiteList(data) {
		return instance.post<any>(`white-lists/add`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	removeWhiteList(data) {
		return instance.delete<any>(`white-lists/remove`, { data });
	},
	verifyWhitelist(roundId, account) {
		return instance.get<any>(`round/${roundId}/address/${account}/verify-white-list`);
	},
	// private api

	createPrivate(data: any) {
		console.log(data);
		return instance.post<any>(`private-sale`, data, {});
	},
};
