
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Steps } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
const { Content } = Layout;
const { Step } = Steps;
const HowToJoinIdo = () => {
    return (
        <>
            <div className="project-how-ido-launchpad">
                <div className='container'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                            <div className="how-to-join-title">
                                How to join ICOs on WaveSignal?
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                            <div className="join-step">
                                <Steps
                                    type="navigation"
                                    size="small"
                                    className="site-navigation-steps custom-step"
                                >
                                    <Step
                                        title="Connect your wallet to WaveSignal "
                                        // subTitle="00:00:05"
                                        status="wait"
                                        description="Choose the chain and wallet, then connect to WaveSignal."
                                    />
                                    <Step
                                        title="Deposit fund"
                                        // subTitle="00:01:02"
                                        status="wait"
                                        description="Go to ICO Launchpad section, pick your favorite project. Deposit fund into project ICO pool within applicable period."
                                    />
                                    <Step
                                        title="Claim your token "
                                        // subTitle="waiting for longlong time"
                                        status="wait"
                                        description="Go to WaveSignal to claim IDO token to your wallet after TGE."
                                    />
                                </Steps>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default HowToJoinIdo
