
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Button, Form, Input, Steps, Spin, Space, Modal, InputNumber, Alert, Checkbox } from 'antd';
import {
    createToken,
    getSymbolToken,
    getTotalSupply,
    getTokenName,
    getTokenDecimal,
    getTokenAllowance, checkAntiBot
} from '../../../utils';
import standardTokenAbi from 'config/abi/standardTokenAbi.json';
import tokenInfoAbi from 'config/abi/standardTokenAbi.json';
import antiBotStandardTokenAbi from 'config/abi/antiBotStandardTokenAbi.json';
import { ANTI_BOT_TOKEN_BYTECODE, STANDARD_TOKEN_BYTECODE } from 'components/bytecodes/bytecodes';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useTokenInfoContract } from '../../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';

declare const window: Window & typeof globalThis & { ethereum: any };

const AntiBotVerifyToken = (props) => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const { next } = props;

    const token = form.getFieldValue('token_address')

    let storageAntiTokenInfo = window.localStorage.getItem('antiTokenInfo');
    let antiTokenInfo;
    if (storageAntiTokenInfo) {
        antiTokenInfo = JSON.parse(storageAntiTokenInfo);
    }

    const fundAddress = process.env.REACT_APP_FUND_ADDRESS;
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');



    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [tokenAddress, setTokenAddress] = useState(antiTokenInfo?.token_address);

    const [tokenSymbol, setTokenSymbol] = useState(antiTokenInfo?.token_symbol);
    const [tokenName, setTokenName] = useState(antiTokenInfo?.token_name);
    const [tokenDecimal, setTokenDecimal] = useState(antiTokenInfo?.token_decimal);
    const [antiBot, setAntiBot] = useState(antiTokenInfo?.enableAntiBot);

    const [feeList, setFeeList] = useState<any>([]);

    const [errorCallSC, setErrorCallSC] = useState(false);
    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState<boolean>(false);
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);
    const [createTokenForm] = Form.useForm();

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [initTokenFee, setInitTokenFee] = useState(0);



    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettings();
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => "INIT_TOKEN_FEE" === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                    }
                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => "INIT_LAUNCHPAD_FEE" === itemSetting.settingKey);

                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getSettings();
    }, []);



    const onChangeTokenAddress = async (values: any) => {
        setErrorCallSC(false);
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);
        try {
            let [token_symbol,
                token_name,
                token_decimal
            ] = await Promise.all([
                getSymbolToken(tokenInfoAbi, tokenAddressVal),
                getTokenName(tokenInfoAbi, tokenAddressVal),
                getTokenDecimal(tokenInfoAbi, tokenAddressVal),

            ]);
            let checkImplementAntiBot = await checkAntiBot(antiBotStandardTokenAbi, tokenAddressVal);

            if (token_symbol && token_name && token_decimal) {

                setTokenSymbol(token_symbol);
                setTokenName(token_name);
                setTokenDecimal(token_decimal);
                setAntiBot(checkImplementAntiBot);

                form.setFieldsValue({
                    token_address: tokenAddressVal,
                    token_name: token_name,
                    token_symbol: token_symbol,
                    token_decimal: token_decimal,
                    enableAntiBot: checkImplementAntiBot
                });
                setLoadingTokenInfo(false);

            } else {
                setLoadingTokenInfo(false);
                setErrorCallSC(true);
            }
        } catch (e: any) {
            setLoadingTokenInfo(false);
            setErrorCallSC(true);
        }
    };
    const onSubmitCreateTokenForm = async (values: any) => {
        setLoading(true);
        setLoadingTokenInfo(true);
        try {
            console.log(values.implement_anti_bot);
            await createToken(values.implement_anti_bot ? antiBotStandardTokenAbi : standardTokenAbi,
                values.implement_anti_bot ? `0x${ANTI_BOT_TOKEN_BYTECODE}` : `0x${STANDARD_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                fundAddress,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
                antiBotAddress,
                values.implement_anti_bot)
                // ethers.utils.parseUnits(`${fundAmount}`, 'ether').toHexString())
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setLoading(false);
                                            closeBox();
                                            setTokenAddress(res.contractAddress);
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setAntiBot(values.implement_anti_bot);
                                            form.setFieldsValue({
                                                token_address: res.contractAddress,
                                                token_name: values.name,
                                                token_symbol: values.symbol,
                                                token_decimal: values.decimals,
                                                enableAntiBot: values.implement_anti_bot
                                            });
                                            // let tokenInfoNew = {
                                            //     token_address: res.contractAddress,
                                            //     token_name: values.name,
                                            //     token_symbol: values.symbol,
                                            //     token_decimal: values.decimals,
                                            //     total_supply: values.total_supply,
                                            // };
                                            // window.localStorage.setItem('tokenApproval', JSON.stringify(tokenInfoNew));
                                            showNoti('success', 'Create Token Successfully!');
                                        }
                                    } else {

                                    }
                                    setLoading(false);
                                    setLoadingTokenInfo(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        } else {
                            if (error.data && error.data.message) {
                            }
                        }
                    }
                });

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };
    const showCreateTokenModal = () => {
        createTokenForm.resetFields()
        setIsCreateTokenVisible(true);
    }

    const closeBox = () => {
        setIsCreateTokenVisible(false);
    };

    const onConfirmAntiBotToken = (values: any) => {
        console.log(JSON.stringify(values));
        if (values.token_address && values.enableAntiBot) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('antiTokenInfo', data);
            next(1)
        }
    }
    useEffect(() => {
        form.setFieldsValue({
            token_address: tokenAddress,
            token_name: tokenName,
            token_symbol: tokenSymbol,
            token_decimal: tokenDecimal,
            enableAntiBot: antiBot

        });
    }, [tokenAddress]);
    return (
        <>
            <div className="sub-title-steps">
                Token Infomation
                {/*<button type="button" className="btn-create-token" disabled={account ? false : true} onClick={showCreateTokenModal}>Create Token</button>*/}
            </div>
            <div className="inputs-steps">

                <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onConfirmAntiBotToken}
                >
                    <Form.Item name="token_name" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="token_symbol" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="token_decimal" hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="enableAntiBot" valuePropName="checked" initialValue={false} hidden={true}>
                        <Checkbox>Implement WaveSignal Anti-Bot System?</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Token Address"
                        name="token_address"
                        rules={[{ required: true, message: 'Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4' }]}
                        help=''
                    >
                        <Input disabled={account ? false : true} className="amount-buy" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4" onBlur={onChangeTokenAddress} />

                    </Form.Item>
                    {!errorCallSC ? (
                        loadingTokenInfo ? (
                            <div className="loading-info">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        ) : (
                            tokenAddress && tokenSymbol && tokenName && tokenDecimal && (
                                <div className="token-info-anti">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Name</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenName}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Symbol</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenSymbol}</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-name">Decimals</div>
                                        </Col>
                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                            <div className="token-value">{tokenDecimal}</div>
                                        </Col>
                                    </Row>
                                    {!antiBot && <Alert message="This token is not implement AntiBot contract" description="Please try with another token" type="error" />}
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24}>
                                            <Form.Item className="btn-content">
                                                <button className="btn-all" disabled={loading || !antiBot} type="submit">Next {loading && <Spin size="small" />}</button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        )

                    ) : (
                        <>
                            <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                        </>
                    )}

                </Form>

            </div>

            <Modal
                title={<div className="text-md custom-modal-title">Create Token</div>}
                visible={isCreateTokenVisible}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Form
                        form={createTokenForm}
                        name="basic"
                        initialValues={{ price: 0 }}
                        autoComplete="off"
                        onFinish={onSubmitCreateTokenForm}
                        layout="vertical"
                    >
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input token name!' }]}>
                            <Input className="amount-buy" placeholder="Ex: Ethereum" />
                        </Form.Item>
                        <Form.Item name="symbol" label="Symbol" rules={[{ required: true, message: 'Please input token Symbol!' }]}>
                            <Input className="amount-buy" placeholder="Ex: ETH" />
                        </Form.Item>
                        <Form.Item name="decimals" label="Decimals" rules={[{ required: true, message: 'Please input Decimals!' }]}>
                            <InputNumber className="amount-buy" placeholder="Ex: 18" />
                        </Form.Item>
                        <Form.Item name="total_supply" label="Total supply" rules={[{ required: true, message: 'Please input Total supply!' }]}>
                            <InputNumber className="amount-buy" placeholder="Ex: 1000000" />
                        </Form.Item>
                        <Form.Item name="implement_anti_bot" valuePropName="checked" initialValue={false}>
                            <Checkbox>Implement WaveSignal Anti-Bot System?</Checkbox>
                        </Form.Item>
                        <Form.Item className="modal-btn-group">
                            <button className="btn-all" disabled={loading} type="submit">Create Token {loading && <Spin size="small" />}</button>
                            <button className="btn-default" disabled={loading} onClick={closeBox}>Close</button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
            {loadingFull && (
                <LoadingFull />
            )}
        </>
    )
}

export default AntiBotVerifyToken
