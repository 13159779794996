import { BinanceWallet, Metamask, SafePalWallet, TrustWallet, WalletConnect } from '../components/icons';

export const NetworkContextName = 'NETWORK';
export const API_KDG = 'https://hub.hubecom.com/api';

export const ADDRESS_USD: any = {
	56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
	97: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
	1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
	137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	250: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f'
}
// wallet list
export const WALLET_LIST = [
	{ icon: Metamask, title: 'Metamask', connectorId: 'injected' },
	{ icon: WalletConnect, title: 'Wallet Connect', connectorId: 'walletconnect' },
	{ icon: TrustWallet, title: 'Trust Wallet', connectorId: 'injected' }
];
export const ADDRESS_BUSD_ADDRESS = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const ADDRESS_RECEVIE_BUSD = '0x9a5D956C9FBc8Bd51a08DFC31441A2cCff2584aE';
export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;

export const MAINNET_BSC_URL = 'https://bsc-dataseed.binance.org';
export const MAINNET_CHAIN_ID = 56;

export const marketContractAddress = '0x7c76E1344A79fFe41Dd43239e30D60C57981dbF2';

export const bscTokens = [
	// stake pool
	{
		id: 'wbnb',
		symbol: 'wbnb',
		contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
	},
	{
		id: 'binance-usd',
		symbol: 'busd',
		contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
	},
	{
		id: 'tether',
		symbol: 'USDT',
		contract: '0x55d398326f99059fF775485246999027B3197955'
	},
	{
		id: 'ethereum',
		symbol: 'ETH',
		contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
	},
	{
		id: 'dai',
		symbol: 'DAI',
		contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3'
	},
	{
		id: 'polis',
		symbol: 'POLIS',
		contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd'
	},
	{
		id: 'tether',
		symbol: 'USDT',
		contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A'
	},
	{
		id: 'usd-coin',
		symbol: 'USDC',
		contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
	}
];
