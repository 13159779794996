
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
const { Content } = Layout;
const { Panel } = Collapse;
const FAQ = () => {
    return (
        <>
            <div className="faq container">
                <div className="faq-title">FAQs</div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <div className="faq-content">
                            <Collapse accordion className="faq-cus">
                                <Panel header="What is an ICO?" key="1">
                                    <p>An initial coin offering, or ICO, is a type of funding using cryptocurrencies. ICO gains popularity in 2017 and later was shaded because of various scam projects. Here, projects who launch ICO on WaveSignal must strictly comply to our safety regulations to protect users’ right.</p>
                                </Panel>
                                <Panel header="How to join an ICO on WaveSignal?" key="2">
                                    <p>Firstly, you connect wallet to WaveSignal, don’t forget to choose the right chain. Secondly, click on ICO Launchpad section, pick your favorite project and deposit fund within applicable period. Finally, claim token to your wallet at TGE. Make sure the token sit in your wallet and you’re good to go!</p>
                                </Panel>
                                <Panel header="When do I receive my token?" key="3">
                                    <p>At TGE, "Claim" portal on WaveSignal will open for you to claim token to your wallet.</p>
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <div className="faq-content">
                            <Collapse accordion className="faq-cus">
                                <Panel header="How much is the participation fee?" key="4">
                                    <p>We don't charge users for joining ICO (yessss)! Everyone can join an ICO pool without paying any fee to us.</p>
                                </Panel>
                                <Panel header="Which token should I buy?" key="5">
                                    <p>It's up to you to make the investment decision. Always DYOR first and never risk the money you can not afford to lose!</p>
                                </Panel>
                                <Panel header="How much can I invest?" key="6">
                                    <p>The total investment amount varies from project to project, depending on the amount raised and the allocation amount you have. Please check our docs for more details on tier system.</p>
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default FAQ
