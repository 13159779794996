export const INIT_AIRDROP_FEE = 'INIT_AIRDROP_FEE'
export const INIT_PRIVATE_SALE_FEE = 'INIT_PRIVATE_SALE_FEE'
export const INIT_TOKEN_FEE = 'INIT_TOKEN_FEE'
export const INIT_TOKEN_W86_FEE = 'INIT_TOKEN_W86_FEE'
export const INIT_LAUNCHPAD_FEE = 'INIT_LAUNCHPAD_FEE'
export const LIST_LAUNCHPAD_RAISED_FEE_ONLY = 'LIST_LAUNCHPAD_RAISED_FEE_ONLY'
export const LIST_LAUNCHPAD_RAISED_FEE = 'LIST_LAUNCHPAD_RAISED_FEE'
export const LIST_LAUNCHPAD_TOKEN_RAISED_FEE = 'LIST_LAUNCHPAD_TOKEN_RAISED_FEE'
export const LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1 = 'LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_1'
export const LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2 = 'LIST_LAUNCHPAD_TOKEN_W86_FEE_OPTION_2'
export const LAUNCHPAD_TOKEN_RAISED_FEE_ONLY = 'LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY'
export const LAUNCHPAD_TOKEN_RAISED_FEE = 'LIST_LAUNCHPAD_TOKEN_RAISED_FEE'
export const LAUNCHPAD_AMOUNT_RAISED_FEE = 'LIST_LAUNCHPAD_AMOUNT_RAISED_FEE'
export const INIT_TOKEN_FEE_WALLET = 'INIT_TOKEN_FEE_WALLET'
export const INIT_LIQUIDITY_TOKEN_FEE_WALLET = 'INIT_LIQUIDITY_TOKEN_FEE_WALLET'
export const INIT_PRIVATE_SALE_TOKEN_FEE = 'INIT_PRIVATE_SALE_TOKEN_FEE'
export const INIT_PRIVATE_SALE_W86_FEE = 'INIT_PRIVATE_SALE_W86_FEE'

