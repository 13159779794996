import web3 from 'web3';
import { ethers } from 'ethers';
import { getNormalSigner, getProviderOrSigner, getContract, getContractFromSigner } from '../utils';
import { getDexName } from './launchpad/factoryAddresses';
import tokenInfoAbi from '../config/abi/standardTokenAbi.json';

export const createToken = async (abi, bytecode, library, deployer, name, symbol, decimal, totalSupply, fundAddress, fundAmount, antiBotAddress, isAntiBotToken) => {
	const factory = new ethers.ContractFactory(abi, bytecode, getNormalSigner(library, deployer));
	if (isAntiBotToken) {
		const contract = await factory.deploy(name, symbol, decimal, totalSupply, antiBotAddress, fundAddress, fundAmount, { value: fundAmount });
		return contract.deployTransaction;
	} else {
		const contract = await factory.deploy(name, symbol, decimal, totalSupply, fundAddress, fundAmount, { value: fundAmount });
		return contract.deployTransaction;
	}
};

export const createStandardToken = async (abi, bytecode, library, deployer, name, symbol, decimal, totalSupply, fundAddress, fundAmount) => {
	const factory = new ethers.ContractFactory(abi, bytecode, getNormalSigner(library, deployer));
	const contract = await factory.deploy(name, symbol, decimal, totalSupply, fundAddress, fundAmount, { value: fundAmount });
	return contract.deployTransaction;
};

export const createLPGeneratorToken = async (abi, bytecode, library, deployer,
	name, symbol, totalSupply, router, charityAddress, taxFeeBps,
	liquidityFeeBps, charityFeeBps, serviceFeeReceiver,
	serviceFee) => {
	const factory = new ethers.ContractFactory(abi, bytecode, getNormalSigner(library, deployer));
	const contract = await factory.deploy(name, symbol, totalSupply, router, charityAddress, taxFeeBps, liquidityFeeBps, charityFeeBps, serviceFeeReceiver, serviceFee, { value: serviceFee });
	return contract.deployTransaction;
};

export const createAntiBotLPGeneratorToken = async (abi, bytecode, library, deployer,
	name, symbol, totalSupply, antiBotAddress, router, charityAddress, taxFeeBps,
	liquidityFeeBps, charityFeeBps, serviceFeeReceiver,
	serviceFee) => {

	const factory = new ethers.ContractFactory(abi, bytecode, getNormalSigner(library, deployer));
	const contract = await factory.deploy(name, symbol, totalSupply, antiBotAddress, router, charityAddress, taxFeeBps, liquidityFeeBps, charityFeeBps, serviceFeeReceiver, serviceFee, { value: serviceFee });
	return contract.deployTransaction;
};



export const createAntiBotStandardToken = async (abi, bytecode, library, deployer, name, symbol, decimal, totalSupply, fundAddress, fundAmount, antiBotAddress) => {
	const factory = new ethers.ContractFactory(abi, bytecode, getNormalSigner(library, deployer));
	const contract = await factory.deploy(name, symbol, decimal, totalSupply, antiBotAddress, fundAddress, fundAmount, { value: fundAmount });
	return contract.deployTransaction;

};

export const getSymbolToken = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const symbol = await contract.symbol();
		return symbol;
	}
};
export const getTotalSupply = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const totalSupply = await contract.totalSupply();
		return totalSupply;
	}
};
export const getBalance = async (tokenSCAbi, tokenAddressVal, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const balanceOf = await contract.balanceOf(account);
		return balanceOf;
	}
};
export const getTokenName = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const name = await contract.name();
		return name;
	}
};
export const getTokenDecimal = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const decimal = await contract.decimals();
		return decimal;
	}
};

export const getTokenInformation = async (tokenSCAbi, tokenAddressVal) => {
	try {
		if (typeof window.ethereum !== 'undefined') {
			let result;
			let chainId = window.ethereum.chainId
			let tokenInfo = window.localStorage.getItem(`${chainId}/${tokenAddressVal.toLowerCase()}`);
			if (!tokenInfo) {
				let [token_symbol,
					token_name,
					token_decimal,
					total_supply,
				] = await Promise.all([
					getSymbolToken(tokenInfoAbi, tokenAddressVal),
					getTokenName(tokenInfoAbi, tokenAddressVal),
					getTokenDecimal(tokenInfoAbi, tokenAddressVal),
					getTotalSupply(tokenInfoAbi, tokenAddressVal),
				]);

				result = {
					name: token_name,
					symbol: token_symbol,
					decimals: token_decimal,
					supply: parseFloat(ethers.utils.formatUnits(total_supply, token_decimal))
				};
				window.localStorage.setItem(`${chainId}/${tokenAddressVal.toLowerCase()}`, JSON.stringify(result));
			} else {
				result = JSON.parse(tokenInfo);
			}
			return result;

		}
	} catch (e) {
		console.log(e);

	}
}

export const checkAntiBot = async (tokenSCAbi, tokenAddressVal) => {
	try {
		if (typeof window.ethereum !== 'undefined') {
			const provider = new ethers.providers.Web3Provider(window.ethereum);
			const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
			const check = await contract.enableAntiBot();
			return check;
		}
	} catch (e) {
		return false;
	}

};

export const deployLaunchpad = async (deployLaunchpadContract, launchpadInfoSC, claimInfo, dexInfo, feeInfo, deployFee) => {
	const transaction = await deployLaunchpadContract.deployLaunchpad(launchpadInfoSC, claimInfo, dexInfo, feeInfo, { value: ethers.utils.parseUnits(`${deployFee}`, 18) });
	return transaction;
};

export const approveToken = async (tokenSCAbi, tokenAddressVal, library, account, spender) => {
	const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const approveExactAmountToken = async (tokenSCAbi, tokenAddressVal, library, account, spender, amount) => {
	const args = [spender, amount];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const getTokenAllowance = async (tokenSCAbi, tokenAddressVal, account, spender) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const allowance = await contract.allowance(account, spender);
		return allowance;
	}
};


export const contributeLaunchpad = async (launchpadAbi, launchpadAddress, library, account, sig, amount, decimals) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const feeToken = await contract.feeToken();
		let options = { value: 0 }
		if (!parseInt(feeToken)) {
			options['value'] = ethers.utils.parseUnits(`${amount}`, decimals)
		}
		const transaction = await contract.contribute(ethers.utils.parseUnits(`${amount}`, decimals), sig, options);
		return transaction;
	}
};

export const cancelLaunchpad = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.cancelLaunchpad();
		return transaction;
	}
};


export const finalizeLaunchPad = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.finalizeLaunchpad();
		return transaction;
	}
};

export const setWhitelistLaunchpad = async (launchpadAbi, launchpadAddress, library, account, whitelist, holdingToken, amount) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.setWhitelistPool(whitelist, holdingToken, amount);
		return transaction;
	}
};

export const withdrawContribute = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.withdrawContribute();
		return transaction;
	}
};

export const emergencyWithdrawContribute = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.emergencyWithdrawContribute();
		return transaction;
	}
};

export const claimToken = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.claimTokens();
		return transaction;
	}
};

//claimCanceledTokens
export const claimCanceledTokens = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.claimCanceledTokens();
		return transaction;
	}
};

export const setClaimTimeICO = async (launchpadAbi, launchpadAddress, library, account, listingTime) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const transaction = await contract.setClaimTime(listingTime);
		return transaction;
	}
};


export const getClaimable = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.getUserClaimAble(account);
		return result;
	}
};


export const getUserJoinInfo = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.joinInfos(account);
		return result;
	}
};


export const getLaunchpadInfo = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.getLaunchpadInfo();
		return result;
	}
};

export const getLaunchpadInfoByChainId = async (launchpadAbi, launchpadAddress, chainId) => {
	const contract = getContractFromSigner(launchpadAddress, launchpadAbi, chainId);
	const result = await contract.getLaunchpadInfo();
	return result;
};

export const getMaxLP = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.maxLiquidity();
		return result;
	}
};

export const getMaxInvest = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.maxInvest();
		return result;
	}
};

export const getListingTime = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.listingTime();
		return result;
	}
};

export const getOwnerZoneInfo = async (launchpadAbi, launchpadAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(launchpadAddress, launchpadAbi, library, account);
		const result = await contract.getOwnerZoneInfo(account);
		return result;
	}
};

export const configAntiBot = async (antiBotContract, token, pairToken, routerExchange, factoryExchange, amountLimitPerTrade, amountAddedPerLock, timeLimitPerTrade, totalLockBlocks) => {
	const transaction = await antiBotContract.configAntiBot(token, pairToken, routerExchange, factoryExchange, amountLimitPerTrade, amountAddedPerLock, timeLimitPerTrade, totalLockBlocks);
	return transaction;
};

export const enableAntiBot = async (antiBotContract, token, status, amount) => {
	const transaction = await antiBotContract.enableAntiBot(token, status, { value: amount });
	return transaction;
};

export const whitelistUsers = async (antiBotContract, token, status, users) => {
	const transaction = await antiBotContract.whitelistUsers(token, status, users);
	return transaction;
};

export const blacklistUsers = async (antiBotContract, token, status, users) => {
	const transaction = await antiBotContract.blacklistUsers(token, status, users);
	return transaction;
};

export const getTokenStatus = async (antiBotContract, token) => {
	const transaction = await antiBotContract.getTokenStatus(token);
	return transaction;
};

export const getAntiBotInfo = async (antiBotContract, token) => {
	const transaction = await antiBotContract.antiBotInfos(token);
	return transaction;
};

export const getBlackList = async (antiBotContract, token) => {
	const transaction = await antiBotContract.getBlackList(token);
	return transaction;
};

export const getWhiteList = async (antiBotContract, token) => {
	const transaction = await antiBotContract.getWhiteList(token);
	return transaction;
};

export const getWhiteListPaging = async (antiBotContract, token, start, end) => {
	const transaction = await antiBotContract.getWhiteListPaging(token, start, end);
	return transaction;
};

export const getBlackListPaging = async (antiBotContract, token, start, end) => {
	const transaction = await antiBotContract.getBlackListPaging(token, start, end);
	return transaction;
};

export const totalWhiteList = async (antiBotContract, token) => {
	const transaction = await antiBotContract.totalWhiteList(token);
	return transaction;
};

export const totalBlackList = async (antiBotContract, token) => {
	const transaction = await antiBotContract.totalBlackList(token);
	return transaction;
};

export const getSignature = async (account, library, message) => {
	const provider = getProviderOrSigner(library, account);
	const signature = await provider.signMessage(message);
	return signature;
};

//TODO: Create lp or normal token lock
export const createLock = async (waveLockContract, owner, token, isLpToken, amount, unlockDate, description) => {
	const transaction = await waveLockContract.lock(owner, token, isLpToken, amount, unlockDate, description);
	return transaction;
};

export const createVestingLock = async (waveLockContract, owner, token, isLpToken, amount, tgeDate, tgeBps, cycle, cycleBps, description) => {
	const transaction = await waveLockContract.vestingLock(owner, token, isLpToken, amount, tgeDate, tgeBps, cycle, cycleBps, description);
	return transaction;
};


export const unLock = async (waveLockContract, lockId) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await waveLockContract.unlock(lockId);
		return transaction;
	}
};

export const getWithdrawableTokens = async (waveLockContract, lockId) => {
	const transaction = await waveLockContract.withdrawableTokens(lockId);
	return transaction;
};

export const editLock = async (waveLockContract, lockId, newAmount, newUnlockDate) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await waveLockContract.editLock(lockId, newAmount, newUnlockDate);
		return transaction;
	}
};

export const editLockDescription = async (waveLockContract, lockId, description) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await waveLockContract.editLockDescription(lockId, description);
		return transaction;
	}
};

export const renounceLockOwnership = async (waveLockContract, lockId) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await waveLockContract.renounceLockOwnership(lockId);
		return transaction;
	}
};


//TODO: List Of LP Token
export const getNumberOfLPLock = async (waveLockContract) => {
	const result = await waveLockContract.allLpTokenLockedCount();
	return result;
};

export const getCumulativeLpTokenLockInfo = async (waveLockContract, start, end) => {
	const result = await waveLockContract.getCumulativeLpTokenLockInfo(start, end);
	return result;
};



//TODO: view LP detail
export const getCumulativeLpTokenLockInfoAt = async (waveLockContract, index) => {
	const result = await waveLockContract.getCumulativeLpTokenLockInfoAt(index);
	return result;
};



//TODO: List Of Normal Token
export const getNumberOfNormalLock = async (waveLockContract) => {
	if (typeof window.ethereum !== 'undefined') {
		const result = await waveLockContract.allNormalTokenLockedCount();
		return result;
	}

};

export const getCumulativeNormalTokenLockInfo = async (waveLockContract, start, end) => {
	const result = await waveLockContract.getCumulativeNormalTokenLockInfo(start, end);
	return result;
};

//TODO: view Normal detail
export const getCumulativeNormalTokenLockInfoAt = async (waveLockContract, index) => {
	const result = await waveLockContract.getCumulativeNormalTokenLockInfoAt(index);
	return result;
};


//TODO: Get my lock LP Token
export const lpLockCountForUser = async (waveLockContract, user) => {
	const result = await waveLockContract.lpLockCountForUser(user);
	return result;
};

export const lpLocksForUser = async (waveLockContract, user) => {
	const result = await waveLockContract.lpLocksForUser(user);
	return result;
};

export const lpLockForUserAtIndex = async (waveLockContract, user, index) => {
	const result = await waveLockContract.lpLockForUserAtIndex(user, index);
	return result;
};



//TODO: Get my lock Normal Token
export const normalLockCountForUser = async (waveLockContract, user) => {
	const result = await waveLockContract.normalLockCountForUser(user);
	return result;
};

export const normalLocksForUser = async (waveLockContract, user) => {
	const result = await waveLockContract.normalLocksForUser(user);
	return result;
};

export const normalLockForUserAtIndex = async (waveLockContract, user, index) => {
	const result = await waveLockContract.normalLockForUserAtIndex(user, index);
	return result;
};



// TODO: Get locks for token in detail lock
export const totalLockCountForToken = async (waveLockContract, token) => {
	if (typeof window.ethereum !== 'undefined') {
		const result = await waveLockContract.totalLockCountForToken(token);
		return result;
	}
};

export const getLocksForToken = async (waveLockContract, token, start, end) => {
	if (typeof window.ethereum !== 'undefined') {
		const result = await waveLockContract.getLocksForToken(token, start, end);
		return result;
	}
};

export const cumulativeLockInfo = async (waveLockContract, token) => {
	if (typeof window.ethereum !== 'undefined') {
		const result = await waveLockContract.cumulativeLockInfo(token);
		return result;
	}
};

export const getLockById = async (waveLockContract, lockId) => {
	if (typeof window.ethereum !== 'undefined') {
		const result = await waveLockContract.getLockById(lockId);
		return result;
	}
};

//TODO: get factory
export const getLPInformation = async (tokenAddress, pairAbi, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		try {
			const contract = getContract(tokenAddress, pairAbi, library, account);
			const factory = await contract.factory();
			const token0 = await contract.token0();
			const token1 = await contract.token1();
			const balance = await contract.balanceOf(account);
			const token0Name = await getTokenName(pairAbi, token0)
			const token0Symbol = await getSymbolToken(pairAbi, token0)
			const token1Name = await getTokenName(pairAbi, token1)
			const token1Symbol = await getSymbolToken(pairAbi, token1)


			return {
				factory: factory,
				fullPair: `${token0Name}/${token1Name}`,
				shortPair: `${token0Symbol}/${token1Symbol}`,
				dex: getDexName(factory),
				balance: parseFloat(ethers.utils.formatUnits(balance, 18))
			};
		} catch (e) {
			return {
				factory: null,
				fullPair: null,
				shortPair: null,
				dex: '',
				balance: 0
			};
		}

	}

};

export const getLP = async (tokenAddress, pairAbi, library, account) => {
	try {
		if (typeof window.ethereum !== 'undefined') {
			let chainId = window.ethereum.chainId
			let LPTokenInfo = window.localStorage.getItem(`LP/${chainId}/${tokenAddress.toLowerCase()}`);
			if (!LPTokenInfo) {
				const contract = getContract(tokenAddress, pairAbi, library, account);
				const token0 = await contract.token0();
				const token1 = await contract.token1();
				const name = await contract.name();
				const token0Info = await getTokenInformation(pairAbi, token0);
				const token1Info = await getTokenInformation(pairAbi, token1);
				window.localStorage.setItem(`LP/${chainId}/${tokenAddress.toLowerCase()}`, JSON.stringify({
					name,
					token0,
					token1
				}));
				return {
					name,
					token0Info,
					token1Info
				};

			} else {
				let { name, token0, token1 } = JSON.parse(LPTokenInfo);
				const token0Info = await getTokenInformation(pairAbi, token0);
				const token1Info = await getTokenInformation(pairAbi, token1);
				return {
					name,
					token0Info,
					token1Info
				};
			}

		} else {
			return {
				name: '',
				token0Info: {},
				token1Info: {}
			};
		}
	} catch (e) {
		return {
			name: '',
			token0Info: {},
			token1Info: {}
		};
	}
};
//TODO: Multi sender
export const multiSendToken = async (multiSendContract, token, ensureExactAmount, targets, amounts) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await multiSendContract.multisendToken(token, ensureExactAmount, targets, amounts);
		return transaction;
	}
};
export const multiSendEther = async (multiSendContract, targets, amounts, value) => {
	if (typeof window.ethereum !== 'undefined') {
		const transaction = await multiSendContract.multisendEther(targets, amounts, { value: value });
		return transaction;
	}
};

export const deployAirdrop = async (deployAirdropContract, token, fundPercent, amount) => {
	const transaction = await deployAirdropContract.deployAirDrop(token, fundPercent, amount, { value: amount });
	return transaction;
};


export const startAirdrop = async (airdropAbi, airdropAddress, library, account, startTime) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.startAirDrop(startTime);
		return transaction;
	}
};

export const cancelAirdrop = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cancelAirdrop();
		return transaction;
	}
};

export const setAirdropEnableExactAmount = async (airdropAbi, airdropAddress, library, account, status) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		console.log(contract);
		const transaction = await contract.setEnableExactAmount(status);
		return transaction;
	}
};

export const removeAllAirdropAllocations = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.removeAllAllocations();
		return transaction;
	}
};

export const setAirdropAllocations = async (airdropAbi, airdropAddress, library, account, users, amounts) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.setAllocations(users, amounts);
		return transaction;
	}
};


export const setAirdropVesting = async (airdropAbi, airdropAddress, library, account, tgeBps, cycle, cycleBps) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.setVesting(tgeBps, cycle, cycleBps);
		return transaction;
	}
};


export const claimAirdrop = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.claimAirdrop();
		return transaction;
	}
};

export const getAirdropClaimable = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.withdrawableTokens(account);
		return transaction;
	}
};

export const getAllAirdropAllocationCount = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.allAllocationCount();
		return transaction;
	}
};

export const getAirdropAllocations = async (airdropAbi, airdropAddress, library, account, start, end) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.getAllocations(start, end);
		return transaction;
	}
};
export const getEnsureExactAmount = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.ensureExactAmount();
		return transaction;
	}
};

export const getAirdropIsWLAdmin = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.wlAdmins(account);
		return transaction;
	}
};


export const getAirdropTGEDate = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.tgeDate();
		return transaction;
	}
};

export const getAirdropTGEBps = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.tgeBps();
		return transaction;
	}
};

export const getAirdropCycle = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cycle();
		return transaction;
	}
};

export const getAirdropCycleBps = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cycleBps();
		return transaction;
	}
};

export const getAirdropState = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.airDropState();
		return transaction;
	}
};

export const getAirdropFundPercent = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.fundPercent();
		return transaction;
	}
};

export const getAirdropTotalAllocationTokens = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.totalAllocationTokens();
		return transaction;
	}
};

export const getAirdropUserClaimedTokens = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.userClaimedTokens();
		return transaction;
	}
};

export const getAirdropUserAllocationInfos = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.allocationInfos(account);
		return transaction;
	}
};


export const getAirdropOwner = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.owner();
		return transaction;
	}
};
//private sale
export const deployPrivateSale = async (deployPrivateSaleContract, privateSaleInfo, vestingInfo, fee, fundPercent) => {
	const transaction = await deployPrivateSaleContract.deployPrivateSale(privateSaleInfo, vestingInfo, fee, fundPercent, { value: fee });
	return transaction;
};

export const privateSaleContribute = async (privateSaleAbi, privateSaleAddress, library, account, amount, sig) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.contribute(amount, sig, { value: amount });
		return transaction;
	}
};

export const privateSaleSetWhitelistPool = async (privateSaleAbi, privateSaleAddress, library, account, whitelist, holdingToken, amount) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.setWhitelistPool(whitelist, holdingToken, amount);
		return transaction;
	}
};

export const privateSaleFinalize = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.finalize();
		return transaction;
	}
};

export const privateSaleCancel = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.cancel();
		return transaction;
	}
};

export const privateSaleWithdrawContribute = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.withdrawContribute();
		return transaction;
	}
};

export const privateSaleEmWithdrawContribute = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.emergencyWithdrawContribute();
		return transaction;
	}
};


export const privateSaleWhitelistUsers = async (privateSaleAbi, privateSaleAddress, library, account, users) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.setWhitelistUsers(users);
		return transaction;
	}
};

export const privateSaleRMWhitelistUsers = async (privateSaleAbi, privateSaleAddress, library, account, users) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.removeWhitelistUsers(users);
		return transaction;
	}
};

export const privateSaleClaimFund = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.claimFund();
		return transaction;
	}
};

export const privateSaleTotalInvestors = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.allInvestorCount();
		return transaction;
	}
};

export const privateSaleTotalWLUsers = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.allWLUserCount();
		return transaction;
	}
};

export const privateSaleGetInvestors = async (privateSaleAbi, privateSaleAddress, library, account, start, end) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.getInvestors(start, end);
		return transaction;
	}
};

export const privateSaleGetWLUsers = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.getWLUsers();
		return transaction;
	}
};

export const privateSaleGetOwner = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.owner();
		return transaction;
	}
};

export const privateSaleGetTgeDate = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.tgeDate();
		return transaction;
	}
};

export const privateSaleGetTgePercent = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.tgeBps();
		return transaction;
	}
};

export const privateSaleGetCycle = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.cycle();
		return transaction;
	}
};

export const privateSaleGetCyclePercent = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.cycleBps();
		return transaction;
	}
};

export const privateSaleGetCurrency = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.currency();
		return transaction;
	}
};

export const privateSaleGetRaisedAmount = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.raisedAmount();
		return transaction;
	}
};
export const privateSaleGetRaisedAmountByChainId = async (privateSaleAbi, privateSaleAddress, chainId) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContractFromSigner(privateSaleAddress, privateSaleAbi, chainId);
		const transaction = await contract.raisedAmount();
		return transaction;
	}
};

export const privateSaleGetUnlockedAmount = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.unlockedAmount();
		return transaction;
	}
};

export const privateSaleGetInvestInfo = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.investInfos(account);
		return transaction;
	}
};

export const privateSaleGetPrivateSaleType = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.privateSaleType();
		return transaction;
	}
};

export const privateSaleGetHoldingToken = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.holdingToken();
		return transaction;
	}
};

export const privateSaleGetHoldingAmount = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.holdingAmount();
		return transaction;
	}
};

export const privateSaleGetSoftCap = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.softCap();
		return transaction;
	}
};


export const privateSaleGetHardCap = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.hardCap();
		return transaction;
	}
};

export const privateSaleGetMinInvest = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.minInvest();
		return transaction;
	}
};


export const privateSaleGetMaxInvest = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.maxInvest();
		return transaction;
	}
};

export const privateSaleGetStartTime = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.startTime();
		return transaction;
	}
};


export const privateSaleGetEndTime = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.endTime();
		return transaction;
	}
};

export const privateSaleGetState = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.privateSaleState();
		return transaction;
	}
};

export const privateSaleGetClaimAble = async (privateSaleAbi, privateSaleAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(privateSaleAddress, privateSaleAbi, library, account);
		const transaction = await contract.withdrawableTokens();
		return transaction;
	}
};

export const _approveBEP20 = async (contract, address, amount) => {
	const result = await contract.approve(address, "100000000000000000000000000000000")
	return result
}

export const _getBoughtBoxes = async (boxContract, userAddress, campaignId, boxId) => {

	try {
		return boxContract && boxContract.getBoughtBoxes(userAddress, campaignId, boxId)
	} catch (error) {
		return undefined
	}
}

export const _maxBuyBox = async (boxContract) => {

	try {
		return boxContract && boxContract.maxBuyBox()
	} catch (error) {
		return undefined
	}
}

export const _buyMysteryBox = async (boxContract, boxPrice, _campaignId, _mysteryBoxId, _quantity, sign, timelineBuyType, type) => {
	let sigTemp = '0x00'
	if (timelineBuyType === 2) {
		sigTemp = sign
	}
	const value = web3.utils.toWei((_quantity * boxPrice).toString());
	const args = [_campaignId, _mysteryBoxId, _quantity, sigTemp]
	try {
		// if currency is BNB
		if (type === 2) {
			const estimatedGas = await boxContract.estimateGas.buyMysteryBox(...args, { value })
			return boxContract.buyMysteryBox(...args, {
				gasLimit: estimatedGas,
				value
			})
		}
		// console.log('args>>',args)
		// if currency is token BEP20
		const estimatedGas = await boxContract.estimateGas.buyMysteryBox(...args)
		return boxContract.buyMysteryBox(...args, {
			gasLimit: estimatedGas
		})
	} catch (error) {
		return error
	}
}
export const _getCampaigns = async (boxContract, _campaignId) => {
	if (_campaignId === undefined) {
		return {}
	}
	try {
		const result = await boxContract && boxContract.campaigns(...[_campaignId])
		return result
	} catch (error) {
		return {}
	}
}
export const _myBoxInfo = async (boxContract, address, boxid) => {

	try {
		return boxContract && boxContract.balanceOf(address, boxid)
	} catch (error) {
		// console.log('error>>', error)
		return undefined
	}
}


export const getVestingTimes = (listingTime, lockAfterListing, lockAfterTGE, tgePercent, cycleTime, cyclePercent, totalUserTokens) => {
	let totalClaimedTokens = 0;
	let tgeToken = (totalUserTokens * tgePercent) / 100;
	let cycleToken = (totalUserTokens * cyclePercent) / 100;

	let result = [];
	if (!listingTime) {
		return result;
	}
	let tgeTime = listingTime + lockAfterListing;
	result.push({
		time: tgeTime,
		amount: tgeToken
	});
	totalClaimedTokens += tgeToken;
	if (totalClaimedTokens >= totalUserTokens) {
		return result;
	}

	let nextTime = tgeTime + lockAfterTGE;
	if (lockAfterTGE === 0) {
		nextTime += cycleTime;
	}
	let remainedTokens = totalUserTokens - totalClaimedTokens;
	let time = Math.floor((remainedTokens) / cycleToken)
	if ((remainedTokens % cycleToken) !== 0) {
		time += 1;
	}


	for (let i = 0; i < time; i++) {
		remainedTokens = totalUserTokens - totalClaimedTokens;
		nextTime += cycleTime;
		totalClaimedTokens += cycleToken;

		result.push({
			time: nextTime,
			amount: remainedTokens >= cycleToken ? cycleToken : remainedTokens
		});
	}
	return result;
};


export const convertTimeByMint = (numberOfMin) => {
	let timeText = '';
	if (numberOfMin > 60) {
		var numberOfHours = numberOfMin / 60;
		var days = Math.floor(numberOfHours / 24);
		var Remainder = numberOfHours % 24;
		var hours = Math.floor(Remainder);
		var minutes = Math.floor(60 * (Remainder - hours));

		if (days > 0) {
			timeText += days + " days"
		}
		if (hours > 0) {
			timeText += hours + " hours"
		}
		if (minutes > 0) {
			timeText += minutes + " minutes"
		}
		// timeText = "days " + days + "hours " + hours + "minutes " + minutes
	} else {
		timeText = minutes + " minutes";
	}
	return timeText;
};
