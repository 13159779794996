import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Alert, Button, Card, Col, Space, Form, Input, Radio, Row, Spin, Table } from 'antd';
import { DisconnectOutlined, FacebookFilled, FundOutlined, GithubFilled, IeOutlined, QuestionCircleOutlined, RedditCircleFilled, TwitterCircleFilled } from '@ant-design/icons';
import './style.css';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { useWrap } from 'context/WrapperContext';
import { useDeployAirdropContract } from 'hooks/useContract';

import { AirdropApi } from '../../../../config/api/airdropApi';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { deployAirdrop, deployLaunchpad, getEnsureExactAmount } from '../../../utils';
import { privatesSaleApi } from '../../../../config/api/privatesSaleApi';
import {
    INIT_AIRDROP_FEE,
    INIT_TOKEN_W86_FEE
} from 'components/keyConfig';

declare const window: Window & typeof globalThis & { ethereum: any };
const { TextArea } = Input;

const AirDropContent = (props) => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const history = useHistory();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initAirdropFee, setInitAirdropFee] = useState(0);
    const [initAirdropFeeOpj, setInitAirdropFee0pj] = useState<any>();
    const [feeList, setFeeList] = useState<any>([]);
    const [feeOptions, setFeeOptions] = useState<any>();

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let airdropTokenInfoStorage = window.localStorage.getItem('airdropTokenInfo');
    let tokenInfo;
    if (airdropTokenInfoStorage) {
        tokenInfo = JSON.parse(airdropTokenInfoStorage);
    }

    const deployAirdropAddress = getInformationByChain(chainId, 'REACT_APP_DEPLOY_AIRDROP');

    const deployAirdropContract = useDeployAirdropContract(deployAirdropAddress);

    const getSettingsByChain = async () => {
        try {
            let res = await privatesSaleApi.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setFeeList(res.data);
                    let initFee = res.data.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitAirdropFee(parseFloat(initFee[0].settingValue));
                        setInitAirdropFee0pj(initFee[0]);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        if (chainId) {
            getSettingsByChain();
        }
    }, [chainId]);

    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };

    const onConfirmCreate = async (values: any) => {
        try {
            if (tokenInfo.chainId != chainId) {
                showNoti('warning', 'Invalid Network. Please Connect Correct Network!');
                return;
            }
            setLoadingFull(true);


            let feeIds: any = [];

            let airdropFee: any;
            let isW86fee: any = false;

            let airdropFeeObj: any;
            let airdropFeeW86Obj: any;

            JSON.parse(values.fee_options).forEach(element => {

                let initFeeAirdrop = feeList.filter((itemSetting) => element === itemSetting.settingKey);
                if (initFeeAirdrop.length > 0) {
                    feeIds.push(initFeeAirdrop[0].id);
                }

                // set init airdrop fee
                if (element == INIT_AIRDROP_FEE) {
                    let initFeeAirdropKey = feeList.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFeeAirdropKey.length > 0) {
                        airdropFeeObj = initFeeAirdropKey[0]
                    }
                }
                if (element == INIT_TOKEN_W86_FEE) {
                    let initFeeAirdropW86Key = feeList.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFeeAirdropW86Key.length > 0) {
                        airdropFeeW86Obj = initFeeAirdropW86Key[0]
                    }
                }
            });
            if (JSON.parse(values.fee_options).length === 1) {
                if (airdropFeeObj) {
                    airdropFee = airdropFeeObj.settingValue
                }
            } else if (JSON.parse(values.fee_options).length === 2) {
                if (airdropFeeObj && airdropFeeW86Obj) {
                    airdropFee = airdropFeeObj.settingValue - (airdropFeeObj.settingValue * (airdropFeeW86Obj.settingValue / 100));
                    isW86fee = true;
                }
            }

            if (!feeIds || !airdropFee) {
                showNoti('error', 'Invalid Fee!');
                return;
            }

            const fundPercent = 100;
            const feeAmount = ethers.utils.parseUnits(`${airdropFee}`, 18)

            await deployAirdrop(deployAirdropContract, tokenInfo.token_address, fundPercent, feeAmount).then((txn) => {
                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {

                                    if (!countNoti) {
                                        countNoti++;
                                        let newSCAddress;

                                        for (let i = 0; i < res.logs.length; i++) {
                                            if (res.logs[i].topics && res.logs[i].topics.length == 2 && res.logs[i].topics[0] === '0xb5f54381f1b62853b1376e6d5833ba01af971a761c4efef72944cb6e64d591f1') {
                                                newSCAddress = res.logs[i].topics[1];
                                            }
                                        }
                                        console.log(newSCAddress);
                                        if (newSCAddress) {
                                            newSCAddress = w3.eth.abi.decodeParameter('address', newSCAddress)
                                            const payload = {
                                                chainId: tokenInfo.chainId,
                                                airDropAddress: newSCAddress,
                                                ownerAddress: account,
                                                airDropTitle: values.airdrop_title || '',
                                                description: values.description || '',
                                                logoUrl: values.logo_url || '',
                                                tokenAddress: tokenInfo.token_address,
                                                tokenName: tokenInfo.token_name,
                                                tokenSymbol: tokenInfo.token_symbol,
                                                website: values.website || '',
                                                facebook: values.facebook || '',
                                                twitter: values.twitter || '',
                                                github: values.github || '',
                                                telegram: values.telegram || '',
                                                instagram: values.instagram || '',
                                                discord: values.discord || '',
                                                reddit: values.reddit || '',
                                                airDropStatus: 'CREATE',
                                                onHome: false,
                                                virtualMachine: "EVM",
                                                taskLink: values.taskLink,
                                                settingIds: feeIds
                                            }


                                            // payload.rounds[0].roundAddress = w3.eth.abi.decodeParameter('address', newSCAddress);
                                            let res = await AirdropApi.createAirDrop(payload);
                                            window.localStorage.removeItem('airdropTokenInfo');
                                            if (res && res.data && res.data.handle) {
                                                history.push(`/airdrop/detail/${newSCAddress}/${res.data.handle}`);
                                            }
                                        }
                                    }
                                } else {
                                    showNoti('error', 'Create Airdrop Failed');
                                }
                                setLoadingFull(false);

                            }
                        })();
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                setLoadingFull(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('warning', error.message);
                    } else if (error.reason) {
                        showNoti('warning', error.reason);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('warning', error.data.message);
                        }
                    }
                }
            });
        } catch (error) {
            setLoadingFull(false)
            console.log('Error: ', error);
        }
    };
    return (
        <>
            <Col className="gutter-row" xs={24}>
                <Card title="New Airdrop" style={{ width: '100%' }} className="card-title">
                    <Form form={form} layout="vertical" autoComplete="off"
                        onFinish={onConfirmCreate}
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="airdrop_title" label="Airdrop Title" rules={[{ required: true, message: 'Airdrop Title cannot be blank!' }]}>
                                    <Input className="amount-buy" type="text" placeholder="Airdrop Title..." />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="logo_url"
                                    label={<>Logo URL (<span className='text-upload-image'>Click here upload your image and copy link:</span> <Link target="_blank" to="/upload-image"> Upload Image</Link>)</>}
                                    rules={[{ required: true, message: 'Logo URL cannot be blank!' }]}>
                                    <Input className="amount-buy" type="text" placeholder="Logo URL..." prefix={<FundOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="taskLink" label="Task Link" rules={[{ required: true, message: 'Task Link cannot be blank!' }]}>
                                    <Input className="amount-buy" type="text" placeholder="Task Link..." prefix={<QuestionCircleOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="website" label="Website">
                                    <Input className="amount-buy" type="text" placeholder="Website..." prefix={<IeOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="facebook" label="Facebook">
                                    <Input className="amount-buy" type="text" placeholder="Facebook..." prefix={<FacebookFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="twitter" label="Twitter">
                                    <Input className="amount-buy" type="text" placeholder="Twitter..." prefix={<TwitterCircleFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="github" label="Github">
                                    <Input className="amount-buy" type="text" placeholder="Github..." prefix={<GithubFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="telegram" label="Telegram">
                                    <Input className="amount-buy" type="text" placeholder="Telegram..." prefix={<GithubFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="instagram" label="Instagram">
                                    <Input className="amount-buy" type="text" placeholder="Instagram..." prefix={<GithubFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="discord" label="Discord">
                                    <Input className="amount-buy" type="text" placeholder="Discord..." prefix={<DisconnectOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="reddit" label="Reddit">
                                    <Input className="amount-buy" type="text" placeholder="Reddit..." prefix={<RedditCircleFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24}>
                                <Form.Item name="description" label="Description">
                                    <TextArea rows={4} className="amount-buy" placeholder="Enter description" />
                                </Form.Item>
                                {/* <p className='text-info'>Create Airdrop fee: {initAirdropFeeOpj?.settingValue} {initAirdropFeeOpj?.payToken}</p> */}
                            </Col>

                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                <Form.Item
                                    name="fee_options"
                                    label="Fee Options"
                                    initialValue={feeOptions}
                                    rules={[{ required: true, message: 'Please pick fee options!' }]}
                                >
                                    <Radio.Group onChange={onChangeFee}>
                                        <Space direction="vertical" className='ml-15'>
                                            {feeList.map((item, index) => {
                                                let valFeeMainToken: any;
                                                if (item.settingKey === INIT_TOKEN_W86_FEE && item.feeType === "PERCENT") {
                                                    valFeeMainToken = parseFloat(initAirdropFeeOpj?.settingValue) - (initAirdropFeeOpj?.settingValue * (item.settingValue / 100));
                                                }
                                                return (
                                                    <>
                                                        {item.settingKey === INIT_AIRDROP_FEE && ( // BNB fee
                                                            <Radio value={JSON.stringify(['INIT_AIRDROP_FEE'])} key={'fee-' + index}>
                                                                {item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} {initAirdropFeeOpj?.payToken}
                                                            </Radio>
                                                        )}
                                                        {item.settingKey === INIT_TOKEN_W86_FEE && ( // BNB only fee
                                                            <Radio disabled={true} value={JSON.stringify(['INIT_TOKEN_W86_FEE', 'INIT_AIRDROP_FEE'])} key={'fee-11' + index}>
                                                                Amount of <span className='text-info'>W86</span> equivalent to {valFeeMainToken} {initAirdropFeeOpj?.payToken} <span className='text-danger'>({item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} discount)</span>
                                                            </Radio>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>



                            <Col className="gutter-row" xs={24}>
                                <Form.Item className="btn-content btn-content-config">
                                    <button className="btn-all" disabled={!initAirdropFeeOpj ? true : false} type="submit">Create new airdrop {loading && <Spin size="small" />}</button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
            {loadingFull && (
                <LoadingFull />
            )}
        </>
    )
}

export default AirDropContent
