import web3 from "web3";
import BigNumber from 'bignumber.js'

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18)
    amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
  return amount
}

// get total staked by account

export const _userPeriodAmount = async (poolContract, account, period) => {
  try {
    return poolContract.userPeriodAmount(account, period)
  } catch (error) {
    console.log('error>>', error)
    return false
  }
}

export const _getExpiredTokens = async (stakingSC, account) => {
  try {
    return await stakingSC.getExpiredTokens(account)
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}
export const _getUnExpiredTokens = async (stakingSC, account) => {
  try {
    return await stakingSC.getUnexpiredTokens(account)
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}


// get total staked all
export const _totalStaked = async (poolContract) => {
  try {
    return await poolContract.ptotalStaked()
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}
// stake token
export const _stakeToken = async (stakingSC, amount, period) => {
  try {
    let amountWei = web3.utils.toWei(`${amount}`, 'ether');
    return await stakingSC.stake(amountWei, period);
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}
export const _harvestReward = async (stakingSC) => {
  try {
    return await stakingSC.harvest();
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}
export const _unstaking = async (stakingSC, stakeId) => {
  try {

    return await stakingSC.unstake(stakeId);
  } catch (error) {
    console.log('error >>', error)
    return false
  }
}
export const _stakePeriods = async (stakingSC, periods) => {
  try {
    return await stakingSC.stakePeriods(periods);
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}

export const _stakePeriodAPRs = async (stakingSC, period) => {
  try {
    return await stakingSC.stakePeriodAPRs(period);
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}

export const _userStakeIds = async (stakingSC, account, index) => {
  try {
    return stakingSC.userStakeIds(account, index)
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}

export const _userStakeIndex = async (poolContract, account) => {
  try {
    return await poolContract.userStakeIndex(account)
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}

export const _stakeDetails = async (poolContract, id) => {
  try {
    return poolContract.stakeDetails(id)
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}
export const _unstakingFees = async (poolContract, period) => {
  try {
    return poolContract.unstakingFees(period)
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}
export const _earning = async (poolContract, id) => {
  try {
    return poolContract.earning(id)
  } catch (error) {
    console.log('error >> ', error)
    return false
  }
}


