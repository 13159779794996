
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import {
    Layout,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Spin,
    Space,
    Modal,
    InputNumber,
    Alert,
    Checkbox,
    Card
} from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { CopyOutlined, FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useAntiBotContract, useTokenInfoContract, useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import tokenInfoAbi from 'config/abi/standardTokenAbi.json';
import pairAbi from 'config/abi/pairAbi.json';
import {
    getBalance,
    getTokenAllowance,
    approveToken,
    getLockById,
    cumulativeLockInfo,
    getLP,
    getTokenInformation,
    editLock, editLockDescription
} from '../../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';


declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { Option } = Select;

const UpdateLock = () => {
    const history = useHistory();
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();
    let { id, type }: { id: string, type: string } = useParams();
    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [cexList, setCexList] = useState<any>([]);
    const [tokenType, setTokenType] = useState('standard');

    const [feeList, setFeeList] = useState<any>([]);
    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState<boolean>(false);
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);
    const [createTokenForm] = Form.useForm();
    const [showVestingContributor, setShowVestingContributor] = useState(false);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [initTokenFee, setInitTokenFee] = useState(0);
    const [errorCallSC, setErrorCallSC] = useState(false);
    const [tokenAddress, setTokenAddress] = useState<any>();

    const [tokenSymbol, setTokenSymbol] = useState();
    const [tokenName, setTokenName] = useState();
    const [tokenDecimal, setTokenDecimal] = useState<any>();
    const [totalSupply, setTotalSupply] = useState<any>();
    const [balance, setBalance] = useState<any>(0);
    const [dex, setDex] = useState<any>('');
    const [pairName, setPairName] = useState<any>('');
    const [isLP, setIsLP] = useState<any>(false);
    const [tokenAllowance, setTokenAllowance] = useState<any>(0);
    const [token0Name, setToken0Name] = useState<any>('');
    const [token1Name, setToken1Name] = useState<any>('');
    const [description, setDescription] = useState<any>('');
    const [amountLocked, setAmountLocked] = useState<any>(0);
    const [owner, setOwner] = useState<any>('');
    const [cycle, setCycle] = useState<any>(0);
    const [cycleBps, setCycleBps] = useState<any>(0);
    const [lockDate, setLockDate] = useState<any>(Math.round(Date.now()));
    const [tgeBps, setTgeBps] = useState<any>(0);
    const [tgeDate, setTgeDate] = useState<any>(Math.round(Date.now()));
    const [unlockedAmount, setUnlockedAmount] = useState<any>(0);
    const [isOwner, setIsOwner] = useState<boolean>(false);


    const waveLockContract = useWaveLockContract(waveLockAddress)
    useEffect(() => {
        getLockRecordDetail();
    }, [account]);

    const getLockRecordDetail = async () => {
        try {
            if (account) {
                const lock = await getLockById(waveLockContract, id);
                const info = await cumulativeLockInfo(waveLockContract, lock.token);
                const balance = await getBalance(pairAbi, lock.token, account)
                let allowance = await getTokenAllowance(tokenInfoAbi, lock.token, account, waveLockAddress)

                if (info.factory.toLowerCase() !== ethers.constants.AddressZero) {
                    const lpInfo = await getLP(info.token, pairAbi, library, account);
                    setIsLP(true);
                    setPairName(`${lpInfo.token0Info.symbol}/${lpInfo.token1Info.symbol}`)
                    setToken0Name(lpInfo.token0Info.name)
                    setToken1Name(lpInfo.token1Info.name)
                    setDex(lpInfo.name)
                    setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, tokenDecimal)));
                    setBalance(parseFloat(ethers.utils.formatUnits(balance, tokenDecimal)));
                    setUnlockedAmount(ethers.utils.formatUnits(lock.unlockedAmount, tokenDecimal));
                    createTokenForm.setFieldsValue({
                        amount: parseFloat(ethers.utils.formatUnits(lock.amount, tokenDecimal)),
                    })
                } else {
                    const tokenInfo = await getTokenInformation(pairAbi, info.token);
                    setTokenName(tokenInfo.name);
                    setTokenSymbol(tokenInfo.symbol);
                    setTokenDecimal(tokenInfo.decimals);
                    setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, tokenInfo.decimals)));
                    setBalance(parseFloat(ethers.utils.formatUnits(balance, tokenInfo.decimals)));
                    setUnlockedAmount(ethers.utils.formatUnits(lock.unlockedAmount, tokenInfo.decimals));
                    createTokenForm.setFieldsValue({
                        amount: parseFloat(ethers.utils.formatUnits(lock.amount, tokenInfo.decimals)),
                    })
                }
                setDescription(lock.description);
                setOwner(lock.owner);
                setCycle(parseInt(lock.cycle) / 60);
                setCycleBps(parseInt(lock.cycleBps) / 100);
                setLockDate(parseInt(lock.lockDate) * 1000);
                setTgeBps(parseInt(lock.tgeBps) / 100);
                setTgeDate(parseInt(lock.tgeDate) * 1000);
                setTokenAddress(info.token);
                setTokenAllowance(parseInt(allowance));


                createTokenForm.setFieldsValue({
                    tge_date: (new Date(parseInt(lock.tgeDate) * 1000)).toISOString().slice(0, 16),
                    cycle: parseInt(lock.cycle) / 60,
                    cycle_release_percent: parseInt(lock.cycleBps) / 100,
                    tge_percent: parseInt(lock.cycleBps) / 100,
                    lock_until: (new Date(parseInt(lock.tgeDate) * 1000)).toISOString().slice(0, 16),
                    title: lock.description
                })

                if (lock.owner.toLowerCase() === account.toLowerCase()) {
                    setIsOwner(true);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    function onChangeVestingContributor(e) {
        setShowVestingContributor(e.target.checked);
    }

    const getSettings = async () => {

        try {
            let res = await IdoLaunchpad.getSettings();
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => "INIT_TOKEN_FEE" === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                    }
                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => "INIT_LAUNCHPAD_FEE" === itemSetting.settingKey);

                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };


    useEffect(() => {
        getSettings();
    }, []);


    const onUpdateLock = async (values: any) => {
        try {
            if (loading) {
                return;
            }
            setLoading(true);
            if (type === 'edit') {
                const newTgeDate = tgeBps > 0 ? values.tge_date : values.lock_until
                console.log(tgeDate);

                await editLock(waveLockContract,
                    id,
                    ethers.utils.parseUnits(`${values.amount}`, tokenDecimal),
                    new Date(newTgeDate).getTime() / 1000
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                showNoti('success', 'Update Lock Successfully');
                                                history.push({
                                                    pathname: `/wave-lock/record/${id}`,
                                                    search: ''
                                                });
                                            }
                                        } else {
                                            showNoti('warning', 'Update Lock Failed');
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                            } else {
                                if (error.data && error.data.message) {
                                }
                            }
                        }
                    });
            } else if (type === 'metadata') {
                await editLockDescription(waveLockContract,
                    id,
                    values.title
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                showNoti('success', 'Update Lock Successfully');
                                                history.push({
                                                    pathname: `/wave-lock/record/${id}`,
                                                    search: ''
                                                });
                                            }
                                        } else {
                                            showNoti('warning', 'Update Lock Failed');
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                            } else {
                                if (error.data && error.data.message) {
                                }
                            }
                        }
                    })
            }
        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const handleApproveToken = async (e) => {
        e.preventDefault();
        try {
            if (!tokenAddress) {
                return;
            }
            setLoadingFull(true);
            await approveToken(tokenInfoAbi, tokenAddress, library, account, waveLockAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve Successfully`);
                                        countNoti++;
                                        setTokenAllowance(Math.pow(2, 256));

                                    } else {
                                        showNoti('error', 'Approve Failed');
                                    }
                                    setLoadingFull(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingFull(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            setLoadingFull(false);
            console.log(error);
        }
    };


    const onCopy = () => {
        showNoti('success', 'Copied');
    };


    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="back-history">
                                <Link to={`/launchpad`}><LeftCircleOutlined /> Cancel Create Lock</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-step">
                                    <div className="create-step">
                                        <div className="anti-bot-block">
                                            <div className="steps-content-antibot">

                                                <div className="inputs-steps">
                                                    <Form
                                                        form={createTokenForm}
                                                        name="basic"
                                                        autoComplete="off"
                                                        onFinish={onUpdateLock}
                                                        layout="vertical"
                                                    >

                                                        {isLP ?
                                                            (<div className="token-info-anti" style={{ marginBottom: '15px' }}>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Pair Address</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value" style={{ color: '#1890ff' }}>
                                                                            <span className="mr-10">{tokenAddress}</span>
                                                                            <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                                                <CopyOutlined />
                                                                            </CopyToClipboard>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Pair</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{pairName}</div>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Dex</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{dex}</div>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Balance</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{balance.toLocaleString()}</div>
                                                                    </Col>
                                                                </Row>
                                                            </div>) :
                                                            (
                                                                <div className="token-info-anti" style={{ marginBottom: '15px' }}>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-name">Token Address</div>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-value" style={{ color: '#1890ff' }}>
                                                                                <span className="mr-10">{tokenAddress}</span>
                                                                                <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                                                    <CopyOutlined />
                                                                                </CopyToClipboard>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-name">Name</div>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-value">{tokenName}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-name">Symbol</div>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-value">{tokenSymbol}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-name">Decimals</div>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-value">{tokenDecimal}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-name">Balance</div>
                                                                        </Col>
                                                                        <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                            <div className="token-value">{balance.toLocaleString()}</div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        {type === 'metadata' &&
                                                            (<Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input Title!' }]}>
                                                                <Input className="amount-buy" placeholder="Ex: My lock" />
                                                            </Form.Item>)}


                                                        {type === 'edit' &&
                                                            (
                                                                <>
                                                                    <Form.Item name="amount" label="Amount" rules={[
                                                                        { required: true, message: 'Please input Amount!' },
                                                                        {
                                                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                !value ||
                                                                                    parseFloat(value) <= 0 ||
                                                                                    parseFloat(value) > balance ||
                                                                                    parseFloat(value) < amountLocked
                                                                                    ? cb(`Amount must be > 0 and <=  ${balance} ${tokenSymbol}`)
                                                                                    : cb();
                                                                            }
                                                                        }
                                                                    ]}>
                                                                        <InputNumber className="amount-buy" placeholder="Ex: 1000000" />
                                                                    </Form.Item>
                                                                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                                                        <Form.Item name="vesting_contributor">
                                                                            <Checkbox.Group>
                                                                                <Checkbox onChange={onChangeVestingContributor} value={true} style={{ lineHeight: '32px' }} disabled>Using Vesting?</Checkbox>
                                                                            </Checkbox.Group>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    {tgeBps > 0 ? (
                                                                        <Row>
                                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                                <Form.Item name="tge_date" label="TGE Date" rules={[
                                                                                    { required: true, message: 'Please input TGE Date!' },
                                                                                    {
                                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                            !value || new Date(value) <= new Date(Date.now())
                                                                                                ? cb("TGE Date needs to be after now")
                                                                                                : cb();
                                                                                        }
                                                                                    }
                                                                                ]}>
                                                                                    <Input className="amount-buy" type="datetime-local" placeholder="0" />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                                <Form.Item name="tge_percent" label="TGE Percent" rules={[{ required: true, message: 'Please input TGE Percent!' }]}>
                                                                                    <Input className="amount-buy" type="number" placeholder="5" disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                                <Form.Item name="cycle" label="Cycle (minutes)" rules={[{ required: true, message: 'Please input Cycle!' }]}>
                                                                                    <Input className="amount-buy" type="number" placeholder="10" disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                                <Form.Item name="cycle_release_percent" label="Cycle Release Percent" rules={[{ required: true, message: 'Please input Cycle Release Percent!' }]}>
                                                                                    <Input className="amount-buy" type="number" placeholder="10" disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : (<></>)}
                                                                    {tgeBps === 0 && (
                                                                        <Form.Item name="lock_until" label="Lock until"
                                                                            rules={[
                                                                                { required: true, message: 'Please input Lock until!' },
                                                                                {
                                                                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                        !value || new Date(value) <= new Date(Date.now())
                                                                                            ? cb("Lock until needs to be after now")
                                                                                            : cb();
                                                                                    }
                                                                                }
                                                                            ]}>
                                                                            <Input className="amount-buy" type="datetime-local" placeholder="0" />
                                                                        </Form.Item>
                                                                    )}

                                                                </>
                                                            )}
                                                        {isOwner && (
                                                            (tokenAddress && tokenAllowance <= 0 && type === 'edit') ?
                                                                (<button className='btn-all' disabled={loading}
                                                                    onClick={(e) => handleApproveToken(e)}>Approve {loading &&
                                                                        <Spin size='small' />}</button>) :
                                                                (
                                                                    <Form.Item className='modal-btn-group'>
                                                                        <button className='btn-all' disabled={loading}
                                                                            type='submit'>Update Your
                                                                            Lock {loading &&
                                                                                <Spin size='small' />}</button>
                                                                    </Form.Item>
                                                                )
                                                        )}
                                                    </Form>
                                                </div>
                                                {loadingFull && (
                                                    <LoadingFull />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default UpdateLock
