
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select } from 'antd';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';
import { convertDateTime } from 'utils/formatDatetime';

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

declare const window: Window & typeof globalThis & { ethereum: any };

const Finish = () => {
    let storagePrivateSaleInfo = window.localStorage.getItem('privateSaleInfo');
    let storageAdditionalInfo = window.localStorage.getItem('additionalInfo');

    let storageStep1 = window.localStorage.getItem('step1');

    let additionalInfo;
    let privateSaleInfo;
    let step1;
    if (storageStep1) {
        step1 = JSON.parse(storageStep1);
    }
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }
    if (storagePrivateSaleInfo) {
        privateSaleInfo = JSON.parse(storagePrivateSaleInfo);
    }
    return (
        <>

            <div className="private-create-form">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Title:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{step1 ? step1.title : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Sale Type:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.saleType : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Claim Type Token:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.claimTypeToken : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Softcap:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.softCap : ''} {step1 ? step1.currencyApply : ''}</p>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Hardcap:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.hardCap : ''} {step1 ? step1.currencyApply : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Minimum buy:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.minimum_buy : ''} {step1 ? step1.currencyApply : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Maximum buy:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.maximum_buy : ''} {step1 ? step1.currencyApply : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Fund TGE:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.first_fund_release : ''} %</p>
                    </Col>
                </Row>
                {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Fund Cycle:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.fund_vesting_period_each_cycle : ''} %</p>
                    </Col>
                </Row> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Fund Release Each Cycle:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? privateSaleInfo.fund_release_each_cycle : ''} %</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Start time:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? convertDateTime(new Date(privateSaleInfo.start_time)) : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>End time:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{privateSaleInfo ? convertDateTime(new Date(privateSaleInfo.end_time)) : ''}</p>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="info-row">
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus'>Website:</p>
                    </Col>
                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                        <p className='label-cus text-info'>{additionalInfo?.website ? additionalInfo.website : '___'}</p>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Finish
