import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Pagination, Collapse, Tabs, Progress, Button, Spin, message } from 'antd';
import NumericInput from 'react-numeric-input';
import { BrowserRouter, Route, useParams, useLocation, useHistory } from 'react-router-dom';
import ButtonLoading from '../../Button';
import HeaderTop from 'components/common/header-top';
import Footer from 'components/common/footer';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import { InoLaunchpad } from 'config/api/inoLaunchpad';
import { useActiveWeb3React } from 'hooks';
import { useContract, useBoxMarketContract, useMyBoxContract } from '../../../hooks/useContract';
import abiBEP20 from '../../../config/abi/abiBEP20.json';
import { _approveBEP20, _buyMysteryBox, _getBoughtBoxes, _myBoxInfo } from '../../utils';
import { PRICE_TYPE } from './index.d';
import { useHookIno } from '../Store-ino';

const { Content } = Layout;
const InoDetails = () => {
	const [state, actions] = useHookIno();
	const location = useLocation();
	const pathSymbol = location.search.split('/');
	const pathHash = pathSymbol[0].split('?');
	const pathLink = pathHash[1];
	const [numMaxBox, setMaxBox] = useState(0);
	const [quantity, setQuantity]: any = useState(1);
	const [boughtBox, setBoughtBox]: any = useState(0);
	const [isLoading, setLoading] = React.useState(false);
	const [isApproveJoin, setApproveJoin] = useState(false);
	const [data, setData] = useState();

	const { TabPane } = Tabs;

	const onChange = (key: string) => {
		console.log(key);
	};
	const history = useHistory()
	const goBack = () => {
		history.goBack()
	}
	const [detail, setDetail] = useState<any>();

	const getInoListDetail = async () => {
		try {
			let res = await InoLaunchpad.getInoListDetail(pathLink);
			if (res.status === 200) {
				setDetail(res.data);
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		getInoListDetail();
	}, []);

	const [itemBox, setItemBox]: any = useState({});
	const [newboxId, setNewBoxId]: any = useState();
	const [loadingSpin, setLoadingSpin] = useState(false);
	const { account } = useActiveWeb3React();
	const contractBox = useBoxMarketContract(detail?.marketContract);
	const contractBoxInfo = useMyBoxContract(detail?.boxContract);
	const [img, setImg] = useState('');

	useEffect(() => {
		setImg(itemBox?.imageLink);
	}, [itemBox?.imageLink]);

	useEffect(() => {
		if (contractBox && detail && itemBox?.id) {
			if (detail?.id && account) {
				_getBoughtBoxes(contractBox, account, detail?.id, itemBox?.id).then((data) => {
					setBoughtBox(data.toString());
				});
			}
		}
	}, [contractBox, itemBox?.id]);

	const ItemBoxInfo = ({
		itemBoxActive,
		setItemBox,
		setNewBoxId,
		setLoadingSpin,
		// inoID,
		account,
		campaignId,
		item
		// contractBox
	}) => {
		const param = {
			id: item.id
		};
		const clickType = (obj) => {
			param.id = obj.id;
			try {
				setLoadingSpin(true);
				setNewBoxId(obj.id);
				getInoListDetail();
				setItemBox(obj);
				setTimeout(() => {
					setLoadingSpin(false);
				}, 500);
			} catch (error) {
				setLoadingSpin(false);
			}
		};
		return (
			<div className="colum w-4">
				<button className="btn-type" type="button" onClick={() => clickType(item)}>
					<div className={itemBoxActive.id === item.id ? 'type-current add active' : 'type-current add'}>
						<span className="icon">
							{' '}
							<img src={item?.imageLink} alt="" />{' '}
						</span>
						<div className="t-top">
							{item?.name}
							<span className="t-bot">
								{item?.total}/{item?.total}
							</span>
						</div>
					</div>
				</button>
			</div>
		);
	};

	useEffect(() => {
		if (detail?.id) setNewBoxId(detail?.id);
	}, [detail?.id]);

	useEffect(() => {
		if (detail?.inoBoxes !== undefined) setItemBox(detail?.inoBoxes[0]);
	}, [detail?.id]);

	const bep20Contract = useContract(detail?.paymentContract || '0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D', abiBEP20);

	const price = detail?.inoBoxes[0]?.price;

	useEffect(() => {
		if (bep20Contract && account) {
			// bep20Contract.allowance(account, CONTRACT_BOX_MARKET).then((res) => {
			bep20Contract.allowance(account, detail?.marketContract).then((res) => {
				if (res.toString() / 1e18 >= quantity * price) {
					setApproveJoin(true);
				} else {
					setApproveJoin(false);
				}
			});
		}
	}, [account]);

	const _approveBpx = async () => {
		setLoading(true);
		// await _approveBEP20(bep20Contract, CONTRACT_BOX_MARKET, quantity * price)
		await _approveBEP20(bep20Contract, detail?.marketContract, quantity * price)
			.then((res) => {
				if (res.hash !== null) {
					setTimeout(() => {
						setLoading(false);
						setApproveJoin(true);
					}, 10000);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const getGenSign = async () => {
		try {
			let res = await InoLaunchpad.getGenSign(detail?.id, account, detail?.inoBoxes[0].id);
			if (res.status === 200) {
				return res;
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	const getAfterBuyBox = async (sign: any) => {
		let data = {
			ownerAddress: account,
			quantity: quantity,
			totalPrice: price,
			buyHash: sign,
			boxId: detail?.id
		};

		try {
			let res: any = await InoLaunchpad.getAfterBuyBox(data);
			if (res.status === 201) {
				return setData(res.data);
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	const _buyBox = () => {
		// TODO
		setLoading(true);
		getGenSign()
			.then((res: any) => {
				const sign = res.data.signBuyBox;
				// if (!res.data.data && state.inoItem.timelineBuyType === 2) {
				if (res.data.data) {
					message.warning(res.data.message);
					setLoading(false);
					return;
				}

				if (boughtBox === numMaxBox) {
					message.warning('The maximum limit!');
					setLoading(false);
					return;
				}
				try {
					_buyMysteryBox(contractBox, price, detail?.id, itemBox?.id, quantity, sign, 1, detail?.currency)
						.then((resBuy) => {
							if (resBuy.hash) {
								setLoading(false);
								// wait tx success then call api update
								setTimeout(() => {
									setBoughtBox(parseInt(boughtBox) + quantity);
									getAfterBuyBox(sign).then((res2) => {
										console.log('res2', res2);
									});
									setTimeout(() => {
										setLoading(false);
										window.location.reload();
									}, 3000);

									if (detail?.currency !== PRICE_TYPE.BNB) {
										setApproveJoin(false);
									}
								}, 10000);
							} else {
								setLoading(false);
								message.warning(resBuy.data.message);
							}
						})
						.catch((error) => {
							setLoading(false);
						});
				} catch (error) {
					setLoading(false);
					// console.log('error...', error)
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const contentRule = (
		<>
			<div className="content-rule">
				<ul className="list-content">
					<li>{detail?.inoRule?.content}</li>
				</ul>
			</div>
		</>
	);

	const contentBox = (
		<>
			<div className="table-box">
				<table>
					<tr className="tr-top">
						<th>Name</th>
						<th>Total Sale</th>
						<th>Remaining</th>
						<th>Description</th>
					</tr>
					{detail?.inoBoxes &&
						detail?.inoBoxes.map((item, i) => (
							<tr className="tr-bottom">
								<td>
									<span className="icon">
										{' '}
										<img src={item.imageLink} alt="" />{' '}
									</span>
									<span className="name-box">{item.name}</span>
								</td>

								<td>{item?.total}</td>
								<td>{item?.total}</td>
								<td>{item.description}</td>
							</tr>
						))}
				</table>
			</div>
		</>
	);

	const contentSeri = (
		<>
			<div className="table-box">
				<table>
					<tr className="tr-top">
						<th>Name</th>
						<th>Amount</th>
						<th>Chance</th>
						<th>Ratity</th>
					</tr>
					{detail?.inoSeriesContents &&
						detail?.inoSeriesContents.map((item, i) => (
							<tr className="tr-bottom">
								<td>
									<span className="icon">
										{' '}
										<img src={item.imageLink} alt="" />{' '}
									</span>
									<span className="name-box">{item.name}</span>
								</td>
								<td>{item.amount}</td>
								<td>{item.chance}</td>
								<td>{item.rarity}</td>
							</tr>
						))}
				</table>
			</div>
		</>
	);

	const ContentCollect = () => {
		const ItemQuantity = ({ item, contractBoxInfo, account }) => {
			console.log('item', item);
			const [amount, setAmount] = useState([0]);
			_myBoxInfo(contractBoxInfo, account, item.id).then((resData) => {
				setAmount(resData.toNumber());
			});
			return <div className="pro-table">{amount}</div>;
		};
		useEffect(() => {
			if (contractBoxInfo && account && detail?.id) {
				_myBoxInfo(contractBoxInfo, account, detail?.id).then((resData) => {
					setData(resData.toNumber());
				});
			}
		}, [contractBoxInfo, account, detail?.id]);

		return (
			<>
				<div className="table-box">
					<table>
						<tr className="tr-top">
							<th>No.</th>
							<th>Name</th>
							<th>Quantity</th>
						</tr>
						{detail?.inoBoxes &&
							detail?.inoBoxes.map((item, i) => (
								<tr className="tr-bottom">
									<td>{i++}</td>
									<td>
										<span className="icon">
											{' '}
											<img src={item.imageLink} alt="" />{' '}
										</span>
										<span className="name-box">{item.name}</span>
									</td>
									<td>
										<ItemQuantity item={item} contractBoxInfo={contractBoxInfo} account={account} />
									</td>
								</tr>
							))}
					</table>
				</div>
			</>
		);
	};

	const contentTimeline = (
		<>
			<div className="content-timeline">
				<div className="arrow-line">
					<img src="../images/arrow-line.png" alt="" />
				</div>
				<ul className="list-time-line">
					{detail?.inoTimelines &&
						detail?.inoTimelines.map((item, i) => (
							<li>
								<div className="number">{item.step}</div>
								<div className="title">{item.title}</div>
								<div className="desc">{item.content}</div>
							</li>
						))}
				</ul>
			</div>
		</>
	);

	return (
		<>
			<HeaderMobile />
			<Content className="page-container">
				<div className="sider-bar">
					<div className="menu-sidebar">
						<MenuSidebar />
					</div>
				</div>
				<div className="page-main">
					<HeaderTop pageType={'color'} />
					<div className="page-content">
						<div className="main-ino-detail">
							<div className="eclipse-top">
								<img src="../images/ellipse-6.png" alt="" />
							</div>
							<div className="eclipse-bottom">
								<img src="../images/ellipse-5.png" alt="" />
							</div>
							<div className="container-fl">
								<div className="content-ino-detail">
									<button type="button" className="click-back" onClick={goBack}>
										<div className="breadcrumlist">
											<img src="../images/br-l.png" alt="" /> Back to NFT Launchpad List
										</div>
									</button>
									<div className="top-ino-detail">
										<ul className="list-info-user">
											<li>
												<span className="txt-top">Registered Users</span>
												<span className="txt-bottom">{detail?.totalRegister}</span>
											</li>
											<li>
												<span className="txt-top">Ordered Boxes</span>
												<span className="txt-bottom">{boughtBox}</span>
											</li>
											<li>
												<span className="txt-top">Your Ordered</span>
												<span className="txt-bottom">{boughtBox}</span>
											</li>
											<li>
												<div className="txt-finish">
													<span className="icon">
														<img src="../images/co.png" alt="" />
													</span>{' '}
													Finished
												</div>
											</li>
										</ul>
									</div>
									<div className="tabs-drop-info">
										<div className="columns">
											<div className="colum w-5">
												<div className="box-img">
													{loadingSpin ? (
														<Spin size="large" />
													) : (
														<img src={img} alt="" className="animation-css-scale" />
													)}
												</div>
											</div>
											<div className="colum w-7">
												<div className="content-info-box">
													<div className="name-pr">{detail?.title}</div>
													<div className="bracnh-pr">
														<span className="icon">
															{' '}
															<img src={detail?.logoLink} alt="" />{' '}
														</span>{' '}
														{detail?.title}
													</div>
													<div className="price-buy">
														<span className="icon">
															{' '}
															<img
																src="https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
																alt=""
															/>{' '}
														</span>{' '}
														{itemBox?.price} {itemBox?.currency}/BOX
													</div>
													<ul className="list-total-sale">
														<li>
															<span className="txt-top">Total Sale</span>
															<span className="txt-bottom">{itemBox?.total}</span>
														</li>
														<li>
															<span className="txt-top">Remaining</span>
															<span className="txt-bottom">{itemBox?.total - boughtBox}</span>
														</li>
														<li>
															<span className="txt-top">Supported</span>
															<span className="txt-bottom">
																<span className="icon">
																	{' '}
																	<img src="../images/logo-wave.png" alt="" /> WAVEC{' '}
																</span>
															</span>
														</li>
														<li>
															<span className="txt-top">Min Rank</span>
															<span className="txt-bottom">
																<span className="icon">
																	{' '}
																	<img src="../images/meta.png" alt="" /> Rookie{' '}
																</span>
															</span>
														</li>
													</ul>
													<div className="type-sc">
														<div className="title">CURRENCY</div>
														<div className="type-current logo">
															<span className="icon">
																{' '}
																<img
																	src="https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
																	alt=""
																/>{' '}
															</span>{' '}
															<span>{itemBox?.currency}</span>
														</div>
													</div>
													<div className="type-sc">
														<div className="title">Type</div>
														<div className="box-type">
															{detail?.inoBoxes &&
																detail?.inoBoxes.map((item, i) => (
																	<ItemBoxInfo
																		itemBoxActive={itemBox}
																		setItemBox={setItemBox}
																		setNewBoxId={setNewBoxId}
																		setLoadingSpin={setLoadingSpin}
																		account={account}
																		campaignId={detail?.id}
																		// contractBox={contractBox}
																		item={item}
																	/>
																))}
														</div>
													</div>
													<hr className="ino-line" />

													<div className="ino-amount">
														<div className="amount-left">
															<div className="amount-title">Amount</div>
															<NumericInput
																min={0}
																max={detail?.maxBuy - boughtBox}
																// max={quantity <= boughtBox || boughtBox >= numMaxBox}
																strict
																value={quantity}
																onChange={(e) => {
																	setQuantity(e);
																}}
																mobile
																className="form-control"
															/>
														</div>
														<div className="amount-right">
															<div className="bought-title">Bought / Max</div>
															<div className="bought-num">
																{boughtBox} / {itemBox?.total}
																{/* {state.inoItem.whitelist !== null ? state.inoItem.whitelist.maxOrder : '0'} */}
															</div>
														</div>
														<div className="ino-progress">
															<Progress percent={boughtBox} />
														</div>
														<div className="stt">
															<div className="stt-start">
																{((boughtBox / detail?.totalBoxSell) * 100).toFixed(2)}%
															</div>
															<div className="stt-end">
																{/* {totalOrder}/{totalAllBox} Boxes */} {detail?.totalBoxSell} Boxes
															</div>
														</div>
													</div>
													<div className="ino-button">
														{!isApproveJoin && detail?.currency !== PRICE_TYPE.BNB ? (
															<ButtonLoading
																click={() => _approveBpx()}
																disabled={isLoading}
																type="button"
																className="btn-mystery-box"
																text="Approve"
																primary
																right={
																	isLoading && (
																		<i className="fa fa-spinner fa-spin btn-pending text-white" />
																	)
																}
															/>
														) : (
															<ButtonLoading
																click={() => _buyBox()}
																type="button"
																className="btn-mystery-box"
																disabled={quantity <= 0 || account === undefined}
																text="Buy now"
																primary
																right={
																	isLoading && (
																		<i className="fa fa-spinner fa-spin btn-pending text-white" />
																	)
																}
															/>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="bottom-ino-detail">
										<div className="tabs-content-ino">
											<Tabs defaultActiveKey="1" onChange={onChange}>
												<TabPane tab="Rule Introduction" key="1">
													{contentRule}
												</TabPane>
												<TabPane tab="Box Information" key="2">
													{contentBox}
												</TabPane>
												<TabPane tab="Series Content" key="3">
													{contentSeri}
												</TabPane>
												<TabPane tab="Timeline" key="4">
													{contentTimeline}
												</TabPane>
												<TabPane tab="Collection (2)" key="5">
													<ContentCollect />
												</TabPane>
											</Tabs>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Content>
			<div className="page-main">
				<div className="page-content">
					<Footer />
				</div>
			</div>
		</>
	);
};
export default InoDetails;
