
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Input, Spin, Space, Table, Tabs, Pagination, Row, Col, Menu } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RightCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import web3 from 'web3';
import { ethers } from 'ethers';
import { useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import {
    getCumulativeNormalTokenLockInfo,
    getNumberOfNormalLock,
    getTokenInformation,
    normalLockCountForUser, normalLocksForUser
} from '../../utils';
import pairAbi from 'config/abi/pairAbi.json';
import LockPageBanner from '../page-banner';
import NoneData from 'components/element/NoneData';

declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { TabPane } = Tabs;

const TokenLock = () => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const [activeTab, setActiveTab] = useState('all');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');
    const waveLockContract = useWaveLockContract(waveLockAddress);
    const [dataLocks, setDataLocks] = useState<any>([]);
    const [dataMyLocks, setDataMyLocks] = useState<any>();
    const [totalLocks, setTotalLocks] = useState<any>(0);
    const [totalMyLocks, setTotalMyLocks] = useState<any>(0);
    const [loading, setLoading] = useState<any>(false);
    const [searchAddress, setSearchAddress] = useState<any>('');


    const [param, setParam] = useState(
        {
            page: 0,
            size: 20,
            sort: 'id,desc'
        }
    );

    useEffect(() => {
        getLocks();
        if (activeTab === 'my-lock') {
            getMyLocks();
        }
    }, [account, param, searchAddress, activeTab]);

    const onCopy = () => {
        showNoti('success', 'Copied');
    };
    const getLocks = async () => {
        setLoading(true);
        try {
            let result: any[] = []
            const total = await getNumberOfNormalLock(waveLockContract);

            if (parseInt(total) > 0) {
                setTotalLocks(parseInt(total.toString()));
                const startIndex = param.page * param.size;
                let endIndex = startIndex + param.size;
                if (endIndex > parseInt(total)) {
                    endIndex = parseInt(total);
                }

                const allNormalLocks = await getCumulativeNormalTokenLockInfo(waveLockContract, 0, parseInt(total));

                const rvAllNormalLocks = [...allNormalLocks].reverse();
                let pagingLocks: any = [];
                if (searchAddress) {
                    pagingLocks = rvAllNormalLocks.filter(e => {
                        return e.token.toLowerCase() === searchAddress.toLowerCase();
                    })
                } else {
                    pagingLocks = [...rvAllNormalLocks].slice(startIndex, endIndex);
                }
                if (pagingLocks) {
                    for (let i = 0; i < param.size; i++) {
                        if (pagingLocks[i]) {
                            const tokenInfo = await getTokenInformation(pairAbi, pagingLocks[i].token);
                            result.push({
                                id: i + 1,
                                name: tokenInfo.name,
                                symbol: tokenInfo.symbol,
                                token: pagingLocks[i].token,
                                amount: parseFloat(ethers.utils.formatUnits(pagingLocks[i].amount, tokenInfo.decimals))
                            });
                        }
                    }
                }
                setDataLocks(result);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    const getMyLocks = async () => {
        setLoading(true);
        try {
            let result: any[] = []
            const total = await normalLockCountForUser(waveLockContract, account);
            if (parseInt(total) > 0) {
                setTotalMyLocks(parseInt(total.toString()));
                const myLocks = await normalLocksForUser(waveLockContract, account);
                if (myLocks) {
                    for (let i = 0; i < total; i++) {
                        if (myLocks[i]) {
                            const tokenInfo = await getTokenInformation(pairAbi, myLocks[i].token);
                            result.push({
                                id: myLocks[i].id,
                                name: myLocks[i].description,
                                symbol: tokenInfo.symbol,
                                token: myLocks[i].token,
                                amount: parseFloat(ethers.utils.formatUnits(myLocks[i].amount, tokenInfo.decimals))
                            });
                        }

                    }
                }
                // console.log(result);
                setDataMyLocks(result);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    let totalRow = activeTab == 'my-lock' ? totalMyLocks : totalLocks;


    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };

    const LockContent = (props) => {
        const { type } = props;

        let data: any = searchAddress ? dataLocks : (type == 'my-lock' ? dataMyLocks : dataLocks);

        return (
            <>
                {loading ? (
                    <>
                        <div className="loading-center">
                            <Space size="middle"><Spin size="large" /></Space>
                        </div>
                    </>
                ) : (
                    <>
                        <Row gutter={30}>
                            {
                                data && data.length > 0 ? (
                                    data.map((item, index) => {
                                        let linkPath: any = type === 'my-lock' ? 'record' : 'detail';
                                        let linkId: any = type === 'my-lock' ? item.id : item.token;
                                        let linkDetail: any = '/wave-lock/' + linkPath + '/' + linkId;
                                        return (
                                            <Col xs={24} sm={6} xl={6} md={6} key={'round-' + index}>
                                                <div className='lock-item'>
                                                    <div className='lock-title'> <Link to={linkDetail}>{item.name}</Link> </div>
                                                    <div className='lock-amount'>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                <p className='label-cus mb-0'>Address</p>
                                                            </Col>
                                                            <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                                <label className='label-cus text-danger'>
                                                                    <>
                                                                        <span className="mr-10">
                                                                            {item.token?.substring(0, 8)}...{item.token?.substring(item.token.length - 6)}
                                                                        </span>
                                                                        <CopyToClipboard onCopy={onCopy} text={item.token}>
                                                                            <CopyOutlined />
                                                                        </CopyToClipboard>
                                                                    </>
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='lock-amount'>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                <p className='label-cus mb-0'>Amount</p>
                                                            </Col>
                                                            <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                                <label className='label-cus text-danger'>
                                                                    <span className="mr-10">
                                                                        {item.amount} {item.symbol}
                                                                    </span>
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='lock-view-detail'> <Link to={linkDetail}>View detail <RightCircleOutlined /></Link> </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                ) : (
                                    <NoneData text={'No data'} />
                                )
                            }

                        </Row>

                        {totalRow > param.size && (
                            <Pagination
                                // style={{ padding: 15, float: 'right' }}
                                current={param.page + 1}
                                defaultCurrent={1}
                                total={totalRow}
                                pageSize={param.size}
                                onChange={onChange_Pagi}
                                showSizeChanger={false}
                                className="pagiation-custom"
                            />
                        )}
                    </>
                )}

            </>
        );
    };

    const returnContentTab = () => {
        switch (activeTab) {
            case 'all':
                return <LockContent type="all" />;
                break;
            case 'my-lock':
                return <LockContent type="my-lock" />;
                break;
            default:
                break;
        }
    };
    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const address = e.target.value;
        if (ethers.utils.isAddress(address) || !address.trim()) {
            setSearchAddress(address);
        }

    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">

                        <div className="project-block-create">
                            <div className="lock-content-block">
                                <h3>WaveLock</h3>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className='gutter-row' xs={16}>
                                        <Menu mode="horizontal" defaultSelectedKeys={['token']} className="lock-menu">
                                            <Menu.Item key="token">
                                                <Link to="/wave-lock/token">
                                                    Token
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key="Liquidity">
                                                <Link to="/wave-lock/liquidity">
                                                    Liquidity
                                                </Link>
                                            </Menu.Item>
                                        </Menu>
                                    </Col>
                                    <Col className='gutter-row text-right text-danger' xs={8}>
                                        <div className="lock-search">
                                            <Input className="amount-buy" placeholder="Search by token address ..." onChange={onChangeSearch} />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="lock-token-list">
                                    <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="tab-lock-cus">
                                        <TabPane tab="All Lock" key="all">
                                            {returnContentTab()}
                                        </TabPane>
                                        <TabPane tab="My Lock" key="my-lock">
                                            {returnContentTab()}
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default TokenLock
