import React from 'react';
import './nonedata.css';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';

const NoneData = (props: any) => {
	const { text = '', link = '', linkText = '' } = props;
	return (
		<div className="none-data">
			<div className='logo-main mb-15'>
				<img width={50} src='../../images/logo-title.png' />
			</div>
			{/* <Empty description={text} className="mb-15" /> */}
			{link && (
				<a className="link-button link-hover m-t-15" target="_blank" href={link}>{linkText.length > 0 ? linkText : 'Create now'}</a>
			)}

		</div>
	);
};

export default NoneData;
