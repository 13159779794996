import { Contract } from '@ethersproject/contracts';

import { useMemo } from 'react';

import { getContract } from 'utils';
import tokenInfoAbi from 'config/abi/tokenInfo.json';
import deployLaunchpadAbi from 'config/abi/deployLaunchpadAbi.json';
import deployAirdropAbi from 'config/abi/deployAirdropAbi.json';
import deployPrivateSaleAbi from 'config/abi/deployPrivateSaleAbi.json';
import antiBotAbi from 'config/abi/antiBotAbi.json';
import waveLockAbi from 'config/abi/waveLockAbi.json';
import abiBoxMarket from 'config/abi/abiBoxMarket.json'
import abiMyBox from 'config/abi/abiMyBox.json'
import pairAbi from 'config/abi/pairAbi.json';
import multiSenderAbi from 'config/abi/multiSenderAbi.json';

import { useActiveWeb3React } from './index';
// returns null on errors
export function useContract(address, ABI, withSignerIfPossible = true) {
	const { library, account } = useActiveWeb3React();

	return useMemo(() => {
		if (!address || !ABI || !library) return null;
		try {
			return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
		} catch (error) {
			console.error('Failed to get contract', error);
			return null;
		}
	}, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenInfoContract(scAddr) {
	return useContract(scAddr, tokenInfoAbi);
}
export function useIdoTokenClaimContract(scAddr, claimDailyAbi) {
	return useContract(scAddr, claimDailyAbi);
}

export function useDeployLaunchpadContract(scAddr) {
	return useContract(scAddr, deployLaunchpadAbi);
}

export function useAntiBotContract(scAddr) {
	return useContract(scAddr, antiBotAbi);
}

export function useWaveLockContract(scAddr) {
	return useContract(scAddr, waveLockAbi);
}


export function usePairContract(scAddr) {
	return useContract(scAddr, pairAbi);
}

export function useMultiSenderContract(scAddr) {
	return useContract(scAddr, multiSenderAbi);
}


export function useDeployAirdropContract(scAddr) {
	return useContract(scAddr, deployAirdropAbi);
}


export function useDeployPrivateSaleContract(scAddr) {
	return useContract(scAddr, deployPrivateSaleAbi);
}
export function useBoxMarketContract(address): any {
	return useContract(address, abiBoxMarket, true)
}
export function useMyBoxContract(address): any {
	return useContract(address, abiMyBox, true)
}
