
import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
// import './style.css';


const FarmBanner = () => {
    return (
        <>
            {/* <div className="farm-banner-apply-launch">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                        <div className="farm-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={16} xl={13} md={13} sm={13}>
                                    <div className="farm-banner-apply-content">
                                        <div className="farm-banner-investor-name">
                                            Farming
                                        </div>
                                        <div className="farm-banner-investor-des">
                                            Don't let tokens stay idle in your wallet. Use tokens to earn more tokens without any fee.
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={8} xl={11} md={11} sm={11}>
                                    <div className="farm-banner-faq-content">
                                        <div className="farm-banner-apply-img animation-css-scale">
                                            <img src="../../images/staking.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                        <TokenBalance />
                    </Col>
                </Row>

            </div> */}

            <div className="private-banner-apply-launch bg-private-sale">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <div className="private-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="private-banner-apply-content text-center">
                                        <div className="private-banner-investor-name">
                                            Farming
                                        </div>
                                        <div className="private-banner-investor-des">
                                            <p>Don't let tokens stay idle in your wallet.</p>
                                            <p>Use tokens to earn more tokens without any fee.</p>
                                        </div>
                                        <div className="mt-20 banner-button">
                                            <a className="link-button link-hover" target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeImXqte1_xMaNqLVuLBxGAgEpx0OUeAxj7HQhMWX-OniNawg/viewform'>Open farm pool</a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={11} md={11} sm={11}>
                                    <div className="private-banner-faq-content text-left">
                                        <div className="private-banner-apply-img text-left mt-30">
                                            {/* <img src="../../images/img-stake/farm-bg.png" /> */}
                                            <video src="../../images/earn/farm.mp4" loop autoPlay={true} muted />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default FarmBanner
