import { instance } from '../../apiBase/instance';
let tokenID = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY1MTk0MDI4MX0.OGkSOjRJjH5519O4K94Oiw7Qavs-FpYFryb8O_5JOoKbJxmw6-Ho3Zo5oHj_HidbZ42oNl3hdNMlzk6qvGBp0g';
export const AirdropApi = {

	createAirDrop(data) {
		return instance.post<any>(`air-drops/client`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	getAirdrops(params) {
		return instance.get<any>(`air-drops/client`, { params }, );
	},
	getAirdropsHome(params) {
		return instance.get<any>(`air-drops/home-page`, { params }, );
	},

	getOwnerAirdrops(params, account) {
		return instance.get<any>(`air-drops/${account}/client`, { params }, );
	},


	getAirdropDetail(handle) {
		return instance.get<any>(`air-drops/${handle}/details` , {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},

	updateAirDrop(id, data) {
		return instance.put<any>(`air-drops/${id}/status`,  data , {

		});
	}
};
