
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Alert, Card, Modal, Tag } from 'antd';
import { FormOutlined, GlobalOutlined, CopyOutlined, FacebookFilled, GithubOutlined, InstagramOutlined, InstagramFilled, MediumCircleFilled, TwitterCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useWrap } from 'context/WrapperContext';
import '../style.css';
import { convertDateTime } from 'utils/formatDatetime';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import MetrictChart from './MetrictChart';
import { convertTimeByMint } from 'components/utils';

declare const window: Window & typeof globalThis & { ethereum: any };

const DetailInfo = (props) => {
    const { detailInfo, idoId, status, chainNetworkListTest, chainNetworkListMain, maxLP, dex } = props;
    const { showNoti } = useWrap();
    let nowDate = new Date(Date.now());
    let startDate = new Date(detailInfo?.rounds[0]?.startTime);
    let endDate = new Date(detailInfo?.rounds[0]?.endTime);

    let isManualListing: any = detailInfo?.rounds[0]?.doManualListing;;

    const [isSwitchChainModal, setIsSwitchChainModal] = useState(false);
    const [textVestingTime, setTextVestingTime] = useState<any>('');


    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status: any = '';
        let color: any = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#87d068';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag color={color}>{status}</Tag>;
    }
    const closeBox = () => {
        setIsSwitchChainModal(false);
    };
    const ChainNetworkRender = (chainId) => {

        let logoNetwork: any = '';
        let title: any = '';
        let chain: any;
        chain = chainNetworkListMain.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
        if (chain == undefined || chain.length == 0) {
            chain = chainNetworkListTest.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
        }

        if (chain[0]) {
            logoNetwork = chain[0].logoLink;
            title = chain[0].name;
        }
        return (
            <>
                <img title={title} src={logoNetwork} /> <span>{title}</span>
            </>
        );
    }

    // const getConvertTimeByMint = (vestingCycle) => {
    //     try {

    //         let listVestingCaculator: any = convertTimeByMint(vestingCycle);
    //         if (listVestingCaculator) {
    //             setTextVestingTime(listVestingCaculator);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // useEffect(() => {
    //     if (detailInfo && detailInfo?.vestingContributor.vestingCycle > 0) {

    //         getConvertTimeByMint(detailInfo?.vestingContributor.vestingCycle);

    //     }
    // }, [detailInfo]);

    const onCopy = () => {
        showNoti('success', 'Copied');
    };
    return (
        <>
            <div className="detail-right">
                <div className="detail-title">
                    {detailInfo?.name} - {detailInfo?.rounds[0]?.name}
                    <div className="network-right">
                        {/* <img src="../../images/polygon-network.png" /> */}
                        {ChainNetworkRender(detailInfo?.rounds[0]?.chainId)}
                    </div>
                </div>
                <div className="detail-status">
                    {StatusRender(nowDate, startDate, endDate, detailInfo?.rounds[0]?.roundStatus)}
                    <span className="project-token-main">${detailInfo?.tokenInfo?.symbol}</span>
                    {detailInfo?.website && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.website}><GlobalOutlined className="icon" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.facebook && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.facebook}><FacebookFilled className="icon" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.github && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.github}><GithubOutlined className="icon" /></a></span>
                    )}
                    {/* {detailInfo?.socialNetwork?.discord && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.discord}><FontAwesomeIcon icon={'fa-discord' as IconProp} /></a></span>
                    )} */}
                    {detailInfo?.socialNetwork?.instagram && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.instagram}><InstagramOutlined className="icon" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.medium && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.medium}><MediumCircleFilled className="icon" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.telegram && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.telegram}><FontAwesomeIcon icon={faPaperPlane as IconProp} /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.twitter && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.twitter}><TwitterCircleFilled className="icon" /></a></span>
                    )}


                </div>
                <div className="detail-description">
                    {detailInfo?.description}
                </div>

                <Card title="Verify Token" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    {isManualListing && (
                        <div className="detail-text">
                            <div className="left">Manual Listing</div>
                            <div className="right">
                                <span className='text-info'>Liquidity will not be automatically added!</span>
                            </div>
                        </div>
                    )}
                    <div className="detail-text">
                        <div className="left">Presale Address</div>
                        <div className="right">
                            <span>{detailInfo?.rounds[0].roundAddress} </span>
                            <CopyToClipboard onCopy={onCopy} text={detailInfo?.rounds[0].roundAddress}>
                                <CopyOutlined />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="detail-text">
                        <div className="left">Token Address</div>
                        <div className="right">
                            <span>{detailInfo?.tokenInfo?.tokenAddress} </span>
                            <CopyToClipboard onCopy={onCopy} text={detailInfo?.tokenInfo?.tokenAddress}>
                                <CopyOutlined />
                            </CopyToClipboard>
                            <p className='mb-0 text-danger'>(Do not send {detailInfo?.rounds[0]?.currencyPay} to the token address)</p>
                        </div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Name</div><div className="right text-info">{detailInfo?.name}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Symbol</div><div className="right text-info">{detailInfo?.tokenInfo?.symbol}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Decimals</div><div className="right text-info">{detailInfo?.tokenInfo?.decimals}</div>
                    </div>
                </Card>
                <Card title="Defi Launchpad Info" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    <div className="detail-text">
                        <div className="left">Total Supply</div><div className="right text-info">{detailInfo?.tokenInfo.totalSupply.toLocaleString()} {detailInfo?.tokenInfo.symbol}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Tokens For Presale</div><div className="right text-info">{(detailInfo?.hardCap * detailInfo?.rounds[0]?.swapRate).toLocaleString()} {detailInfo?.tokenInfo.symbol}</div>
                    </div>

                    <div className="detail-text">
                        <div className="left">Presale rate</div><div className="right text-info">1 {detailInfo?.rounds[0]?.currencyPay} = {detailInfo?.rounds[0].swapRate ? detailInfo?.rounds[0].swapRate.toLocaleString() : 0} {detailInfo?.tokenInfo.symbol}</div>
                    </div>
                    {!isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Tokens For Liquidity</div><div className="right text-info">{maxLP.toLocaleString()} {detailInfo?.tokenInfo.symbol}</div>
                            </div>
                            <div className="detail-text">
                                <div className="left">Listing Rate</div><div className="right text-info">1 {detailInfo?.rounds[0]?.currencyPay} = {detailInfo?.rounds[0].listingRate ? detailInfo?.rounds[0].listingRate.toLocaleString() : 0} {detailInfo?.tokenInfo.symbol}</div>
                            </div>
                        </>
                    )}

                    {detailInfo?.vestingContributor.firstRelease > 0 && (
                        <div className="detail-text">
                            <div className="left">First Release For Presale</div><div className="right text-info">{detailInfo?.vestingContributor.firstRelease ? detailInfo?.vestingContributor.firstRelease : 100}%</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.tgeLock > 0 && (
                        <div className="detail-text">
                            <div className="left">Claim after listing</div><div className="right text-info">{detailInfo?.vestingContributor.tgeLock} minutes</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.lockAfterTGE > 0 && (
                        <div className="detail-text">
                            <div className="left">Cliff after TGE</div><div className="right text-info">{detailInfo?.vestingContributor.lockAfterTGE} days</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.tokenReleaseCycle > 0 && (
                        <div className="detail-text">
                            <div className="left">Vesting For Presale</div>
                            <div className="right text-info">
                                {detailInfo?.vestingContributor.tokenReleaseCycle}% each {detailInfo?.vestingContributor.vestingCycle} days
                                {/* {detailInfo?.vestingContributor.tokenReleaseCycle}% each {convertTimeByMint(detailInfo?.vestingContributor.vestingCycle)} */}
                            </div>
                        </div>
                    )}


                    <div className="detail-text">
                        <div className="left">Soft Cap</div><div className="right text-info">{detailInfo?.softCap.toLocaleString()} {detailInfo?.rounds[0]?.currencyPay}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Hard Cap</div><div className="right text-info">{detailInfo?.hardCap.toLocaleString()} {detailInfo?.rounds[0]?.currencyPay}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Unsold Tokens</div><div className="right text-info">{detailInfo?.rounds[0]?.refundType}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Presale Start Time</div><div className="right text-info">{detailInfo?.rounds[0]?.startTime ? convertDateTime(startDate) : ''}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Presale End Time</div><div className="right text-info">{detailInfo?.rounds[0]?.endTime ? convertDateTime(endDate) : ''}</div>
                    </div>

                    {!isManualListing && dex && (
                        <div className="detail-text">
                            <div className="left">Listing On</div><div className="right text-info">{dex.name}</div>
                        </div>
                    )}
                    {!isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Liquidity Percent</div><div className="right text-info">{detailInfo?.rounds[0]?.liquidityPercent}%</div>
                            </div>
                            {parseInt(detailInfo?.liquidityLockup) > 0 && (
                                <div className="detail-text">
                                    <div className="left">Liquidity Lockup Time</div><div className="right text-info">{convertTimeByMint(parseInt(detailInfo?.liquidityLockup) * (24 * 60))} after pool ends</div>
                                </div>
                            )}
                        </>
                    )}


                    {isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Liquidity Percent</div>
                                <div className="right text-info">(Manual listing)</div>
                            </div>

                        </>
                    )}



                </Card>
                <Card title="Token Metrics" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    <MetrictChart isManualListing={isManualListing} detailInfo={detailInfo} maxLP={maxLP} />
                </Card>


            </div>
            <Modal
                title={<div className="text-md custom-modal-title">Confirm Cancel Pool</div>}
                visible={isSwitchChainModal}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p><Alert message="Confirm Cancel This Pool" type="warning" showIcon /></p>
                    <div className="modal-btn-group">
                        <button className="btn-all" type="button" onClick={() => { }}>Confirm </button>
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>


                </div>
            </Modal>
        </>
    )
}

export default DetailInfo
