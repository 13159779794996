import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Row, Col, Button, Space, Spin, Tag } from 'antd';
import { DoubleRightOutlined, RightCircleOutlined } from '@ant-design/icons';
import '../earn-wave/style.css';
import NoneData from 'components/element/NoneData';
import { AirdropApi } from '../../../config/api/airdropApi';
import { convertDateTime } from 'utils/formatDatetime';

const AirdropHomepage = () => {

    const [loadingData, setLoadingData] = useState(false);
    const [dataAirDrops, setDataAirDrops] = useState<any>([]);
    const [param, setParam] = useState(
        {
            page: 0,
            size: 3,
            sort: 'id,desc'
        }
    );

    const getAirDrops = async () => {
        setLoadingData(true);
        const dataFilter = {
            ...param
        };
        try {
            let res = await AirdropApi.getAirdropsHome(dataFilter);
            if (res.status === 200) {
                setDataAirDrops(res.data);

            } else {
                setDataAirDrops([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        getAirDrops();
    }, [param]);

    const StatusRender = (nowDate, startDate, roundStatus) => {
        let status: any = '';
        let color: any = '';
        if (startDate > nowDate && roundStatus !== 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && roundStatus !== 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (roundStatus === 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag className='tag-status' color={color}>{status}</Tag>;
    }

    return (
        <>
            <div className="block-service">
                <div className="block-content container">
                    <div className="block-title">
                        Airdrop
                    </div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {!loadingData ? (
                            <>
                                {dataAirDrops?.length > 0 ? (
                                    dataAirDrops.map((item, index) => {
                                        let nowDate = new Date(Date.now());
                                        let startDate = new Date(item?.startTime);

                                        return (
                                            <Col xs={24} sm={8} xl={8} md={8} key={'item' + index}>
                                                <div className="project-item airdrop-item">
                                                    <div className="airdrop-image">
                                                        <Link to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}><img src={item?.logoUrl?.length > 0 ? item?.logoUrl : "../images/bg-default.webp"} alt={item.airDropTitle} /></Link>
                                                    </div>
                                                    <div className="project-item-content">
                                                        <div className="airdrop-name"><Link to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}>{item.airDropTitle}</Link></div>

                                                        <div className="project-total-raise">
                                                            <span>Start time</span>
                                                            <span className='text-orange'>{item?.startTime ? convertDateTime(startDate) : '---'}</span>
                                                        </div>
                                                        <div className="project-total-raise">
                                                            <span>Token reward</span>
                                                            <span><span className='text-info'>{item.totalTokenReward}</span> {item.tokenSymbol}</span>
                                                        </div>
                                                        <div className="project-total-raise">
                                                            <span>Status</span>
                                                            <span>{StatusRender(nowDate, startDate, item.airDropStatus)}</span>
                                                        </div>
                                                        <div className="airdrop-btn">
                                                            <div className="ido-btn-grid">
                                                                {item.taskLink.length > 0 && (
                                                                    <Button htmlType="button" className="btn-all mr-10">
                                                                        <a target="_blank" href={item.taskLink}>Join Airdrop <DoubleRightOutlined /></a>
                                                                    </Button>
                                                                )}
                                                                <Button htmlType="button" className={item.taskLink.length > 0 ? 'btn-default' : 'btn-all'}>
                                                                    <Link to={`/airdrop/detail/${item.airDropAddress}/${item.handle}`}>
                                                                        {item.taskLink.length > 0 ? (
                                                                            <>View detail <DoubleRightOutlined /></>
                                                                        ) : (
                                                                            <>Claim your reward <DoubleRightOutlined /></>
                                                                        )}

                                                                    </Link>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                ) : (
                                    <>
                                        <NoneData text={'No Data'} />
                                    </>
                                )}
                            </>
                        ) : (<>
                            <div className="loading-data">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        </>)}

                    </Row>
                    <div className="see-more-link">
                        <Link to="/airdrop">See More <RightCircleOutlined className="icon-link" /></Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AirdropHomepage
