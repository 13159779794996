import { instance } from '../../apiBase/instance';
let tokenID = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY1MTk0MDI4MX0.OGkSOjRJjH5519O4K94Oiw7Qavs-FpYFryb8O_5JOoKbJxmw6-Ho3Zo5oHj_HidbZ42oNl3hdNMlzk6qvGBp0g';
export const InoLaunchpad = {
	getInoList(data) {
		let params = {
			page: 0,
			size: 20
		}
		return instance.post<any>(`ino-round/filter`, data , { params });	
	},
	getInoListDetail(handle:any) {
		return instance.get<any>(`ino-round/${handle}`);	
	},
	getGenSign(inoRoundId:any,account:any,boxId:any) {
		return instance.get<any>(`ino-round/gen-sign-buy-box?inoRoundId=${inoRoundId}&ownerAddress=${account}&boxId=${boxId}` ,)
	},
	getAfterBuyBox(data) {
		return instance.post<any>(`ino/after-buying`, { data });	
	},
	getInoListOpening(data) {
		return instance.post<any>(`ino-round/opening`, data );	
	},
}; 