import React, { useState, useEffect } from "react";
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Button } from 'antd';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Banner from './banner';
import LaunchPool from './launchpool';
import Reviews from './review';
import Partners from './partners';
import Footer from 'components/common/footer';
import AirdropHomepage from './airdrop';


const { Content } = Layout;

const Home = () => {
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop color={true} />
                    <div className="page-content">
                        <Banner />
                        {/* <ServicesSlide /> */}
                        {/* <EarnWave /> */}
                        <AirdropHomepage />
                        <LaunchPool />
                        {/* <OurBenefits /> */}
                        <Reviews />
                        <Partners />
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default Home
