import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Alert, Progress, Button, Col, Modal, Table, Space } from 'antd';
import {
    useContract,
    useIdoTokenClaimContract
} from "../../../../hooks/useContract";
import { ADDRESS_USD } from "../../../../constants";
import Countdown from 'react-countdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IdoLaunchpad } from '../../../../config/api/idoLaunchpad';
import { useActiveWeb3React } from 'hooks';
import { useWrap } from 'context/WrapperContext';
import web3 from 'web3';
import { convertDateTime } from 'utils/formatDatetime';
import { ethers } from 'ethers';
import { getInformationByChain } from 'config/network/multichainAddresses';
import abiBUSD from "../../../../config/abi/abiBUSD.json";
import abiJoinPool from "../../../../config/abi/abiJoinPoolNew.json";
import abiClaim from "../../../../config/abi/abiClaimOneButton.json";
import claimDailyAbi from "../../../../config/abi/claimDailyAbi.json";
import {
    _isJoined,
    _approveBUSD,
    getProgressTime,
    rendererCountDown,
    _isRefundedRC,
    _refund,
    _claimTokens,
    _showClaimBtn,
    _isClaimed,
    _currentClaimRound,
    _getClaimAble,
    _totalJoined,
    _joinPoolNew,
    _refundChange,
    _isClaimChange,
    _totalClaimed,
    totalDailyClaimed,
    _dailyClaimAble,
    _totalTokenNeed,
    _dailyClaimTokens,
} from "../../utilsIDO";
import LoadingFull from 'components/loading-full';

const { Content } = Layout;

declare const window: Window & typeof globalThis & { ethereum: any };

const RoundItem = (props: any) => {

    const { detail, tokenInfoIdo, item } = props;
    const { showNoti } = useWrap();
    let tokenID = window.localStorage.getItem('tokenId');

    // khai báo trạng thái
    const [disClaim, setDisClaim] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const [isApproveJoin, setApproveJoin] = useState(false);
    const [disnableJoin, setDisnableJoin] = useState(false);
    const [isRefundedRC, setRefundedFromSC] = useState(false);
    const [isLoadingRefund, setLoadingRefund] = useState(false);
    const [isShowClaim, setIsShowClaim] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [isShowAllocationPopup, setIsShowAllocationPopup] = useState<boolean>(false);
    const [isLoadingClaimChange, setLoadingClaimChange] = useState(false);
    const [isClaimChangeRC, setClaimChangeFromSC] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState(0);
    const [totalJoined, setTotalJoined] = useState(0);
    const [claimblesSC, setClaimblesSC] = useState(0);
    const [currentRoundSC, setCurrentRoundSC] = useState(0);
    const [roundWhitelist, setRoundWhitelist] = useState<any>();
    const [allocationList, setAllocationList] = useState<any>();

    // daily claim

    const [totalClaimedDaily, setTotalClaimedDaily] = useState(0);
    const [dailyAmount, setDailyAmount] = useState(0);
    const [dailyClaimAble, setDailyClaimAble] = useState(0);

    let nowDate = new Date(Date.now());
    let startJoinPool = new Date(item.startJoinPool);
    let endJoinPool = new Date(item.endJoinPool);
    let startClaim: any = new Date(item?.startClaim);
    let refundTime: any = new Date(item?.refundTime);

    let allocation: any = item?.allocationDetails;
    console.log('allocation', allocation);

    let claimType: any = item?.claimType;

    const enableClaim = allocation && allocation.claimSign !== null && claimblesSC > 0;

    let amountJoin: any = roundWhitelist?.commitFund ? roundWhitelist?.commitFund : 0;

    const fundNeed = roundWhitelist?.fundNeed ? roundWhitelist?.fundNeed : 0;
    const joinSignBusd = roundWhitelist?.signatureJoin ? roundWhitelist?.signatureJoin : '';
    // const is_commit_fund = item.allocation && item.allocation.claimChange.refundContract !== null;
    const is_allow_joinpool = roundWhitelist && roundWhitelist?.signatureJoin !== null
    const joinStatus = roundWhitelist && roundWhitelist.joinedPool;
    // const signRefund = roundWhitelist?.signatureRefund;
    const signatureClaimChange = roundWhitelist?.signatureClaimChange ?? '';
    const claimChangeBusd = roundWhitelist?.claimChangeAmount;
    const claimbles = item?.allocationDetails && item?.allocationDetails.totalClaimable;

    const scJoinAddress = item?.addressReceiver ? item?.addressReceiver : '';
    const scClaimChangeAddress = item?.claimChangeAddress ? item?.claimChangeAddress : '';
    const scClaimDailyAddress = item?.claimContractOnChain ? item?.claimContractOnChain : '';
    const scClaimAddress = allocation?.claimContract ?? item?.claimContractOffChain;
    const screfundAddress = item?.refundAddress ?? '';

    const { chainId, account, library } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    let chain_id: any = chainId ? chainId : process.env.REACT_APP_CHAIN_ID;

    const busdContract = useContract(ADDRESS_USD[chain_id], abiBUSD);
    const joinPoolContract = useContract(scJoinAddress, abiJoinPool);
    const claimChangeContract = useContract(scClaimChangeAddress, abiClaim);
    const refundContract = useContract(screfundAddress, abiClaim);
    const claimContract = useContract(scClaimAddress, abiClaim);
    const claimDailyContract = useIdoTokenClaimContract(scClaimDailyAddress, claimDailyAbi);

    let _claimedTokens: any = totalClaimed + totalClaimedDaily;

    let totalAllocation: any = roundWhitelist?.totalAllocation;

    let processClaim =
        item.allocation !== null
            ? Number(((_claimedTokens / totalAllocation) * 100).toFixed(2))
            : 0;

    if (isRefundedRC) processClaim = 100;


    const getWhitelistIdoByAddress = async (account: any, id: any) => {
        try {
            let res = await IdoLaunchpad.getWhitelistIdoByAddress(id, account.toLowerCase());
            if (res.status === 200) {
                console.log('roundWhitelist', res.data);
                setRoundWhitelist(res.data);
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    const getAllocationByAddress = async (account: any, id: any) => {
        try {
            let res = await IdoLaunchpad.getAllocationByAddress(id, account.toLowerCase());
            if (res.status === 200) {
                console.log('AllocationByAddress', res.data);
                setAllocationList(res.data);
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    // handle approve trước khi join
    const _approveJoinPool = async () => {
        console.log("aspprove: ", busdContract, scJoinAddress, amountJoin);
        setIsLoading(true);
        await _approveBUSD(busdContract, scJoinAddress, amountJoin)
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;

                                        setIsLoading(false);
                                        setApproveJoin(true);
                                        if (!is_allow_joinpool) {
                                            setDisnableJoin(true);
                                        }
                                        showNoti('success', "Approved successfully!!! ");
                                        getWhitelistIdoByAddress(account, item.id);
                                    }
                                } else {
                                    setIsLoading(false);
                                    setApproveJoin(false);
                                    showNoti('error', "Error!!! ");
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                showNoti('error', error?.data?.message);
                setIsLoading(false);
            });
    };

    // HANDLE CLICK JOIN POOL
    const _handleJoinPool = async (campaignId: any, roundId: any) => {
        setIsLoading(true);

        if (!busdContract) {
            return;
        }
        if (!joinSignBusd) {
            showNoti('error', "Join sign is empty!");
            setIsLoading(false);
            return;
        }
        // console.log("aspprove: ", joinPoolContract, amountJoin, roundId, joinSignBusd);
        const balance = await busdContract.balanceOf(account);

        if (balance.toString() / 1e18 < amountJoin) {
            showNoti('error', "Not enough balance!");
            setIsLoading(false);
            return;
        }
        // if (network === NETWORK.BSC && chainId !== 56) {
        //     message.info("Please select network BSC");
        //     setIsLoading(false);
        //     return;
        // }
        // if (network === NETWORK.Ethereum && chainId !== 1) {
        //     message.info("Please select network Ethereum");
        //     setIsLoading(false);
        //     return;
        // }
        // if (network === NETWORK.Poly && chainId !== 137) {
        //     message.info("Please select network Polygon");
        //     setIsLoading(false);
        //     return;
        // }

        await _joinPoolNew(
            joinPoolContract,
            amountJoin,
            campaignId,
            roundId,
            joinSignBusd,
            chainId
        )
            .then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;

                                        const payload = {
                                            roundId: roundId,
                                            ownerAddress: account,
                                            hash: txn.hash,
                                            wlTypeEnum: 'JOIN'
                                        };
                                        let res = await IdoLaunchpad.updateHashAfterJoinPool(tokenID, payload);
                                        if (res.status === 200) {
                                            if (res.data) {
                                                showNoti('success', 'Join Pool Successfully');
                                            }
                                        }

                                        setIsJoined(true);
                                        setIsLoading(false);
                                        getWhitelistIdoByAddress(account, item.id);
                                    }
                                } else {
                                    showNoti('warning', 'Join Pool Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                showNoti('error', error?.data?.message);
                setIsLoading(false);
            });
    };

    // handle claim change
    const _handleClaimChange = async (roundId: any) => {
        if (signatureClaimChange === '') {
            showNoti('error', "Claim change sign is empty!");
            return;
        }
        setIsLoading(true);
        await _refundChange(claimChangeContract, claimChangeBusd, signatureClaimChange)
            .then((txn: any) => {
                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;

                                        const payload = {
                                            roundId: roundId,
                                            ownerAddress: account,
                                            hash: txn.hash,
                                            wlTypeEnum: 'CLAIM_CHANGE'
                                        };
                                        let res = await IdoLaunchpad.updateHashAfterJoinPool(tokenID, payload);
                                        if (res.status === 200) {
                                            if (res.data) {
                                                showNoti('success', 'Claim Change Successfully');
                                            }
                                        }

                                        setClaimChangeFromSC(true);
                                        showNoti('success', 'Claim Change Successfully');
                                        getWhitelistIdoByAddress(account, item.id);
                                    }
                                } else {
                                    showNoti('warning', 'Claim Change Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('warning', error.message);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('warning', error?.data?.message);
                        }
                    }
                }
                setIsLoading(false);
            });
    };

    // handle RF
    const _handleRefund = async (roundId: any) => {
        setIsLoading(true);
        await _refund(refundContract, fundNeed, roundWhitelist.signatureRefund).then((txn: any) => {

            if (txn && txn.hash) {
                let countNoti = 0;
                const interval = setInterval(function () {
                    (async () => {
                        const res = await w3.eth.getTransactionReceipt(txn.hash);
                        if (res) {
                            clearInterval(interval);
                            if (res.status && res.blockNumber) {
                                if (!countNoti) {
                                    countNoti++;

                                    const payload = {
                                        roundId: roundId,
                                        ownerAddress: account,
                                        hash: txn.hash,
                                        wlTypeEnum: 'REFUND'
                                    };
                                    let res = await IdoLaunchpad.updateHashAfterJoinPool(tokenID, payload);
                                    if (res.status === 200) {
                                        if (res.data) {
                                            showNoti('success', 'Refund Successfully');
                                        }
                                    }
                                    // showNoti('success', 'Refund Successfully');
                                    setIsLoading(false);
                                    setLoadingRefund(false);
                                    setRefundedFromSC(true);
                                    getWhitelistIdoByAddress(account, item.id);
                                }
                            } else {
                                showNoti('warning', 'Refund Failed');
                            }
                            setIsLoading(false);
                        }
                    })();
                }, 1000);
            }
        }).catch((error: any) => {
            showNoti('error', error?.data?.message);
            setIsLoading(false);
        });

        return true;

    };

    // handle Claim
    const _handleClaim = async (roundId: any) => {
        // if (showPopupClaim) {
        //     actions.changeOpenModalClaim(item.id);
        //     return;
        // }

        console.log('allocation', allocation);

        if (!allocation?.claimSign) {
            showNoti('error', "Claim sign is empty!");
            return;
        }

        setIsLoading(true);
        if (allocation && allocation.totalClaimable > 0) {
            await _claimTokens(
                claimContract,
                allocation.totalClaimable,
                detail.tokenInfoIdo.decimals,
                allocation?.claimSign
            ).then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;

                                        // const payload = {
                                        //     roundId: roundId,
                                        //     ownerAddress: account,
                                        //     hash: txn.hash,
                                        //     wlTypeEnum: 'CLAIM'
                                        // };
                                        // let res = await IdoLaunchpad.updateHashAfterJoinPool(tokenID, payload);
                                        // if (res.status === 200) {
                                        //     if (res.data) {
                                        //         showNoti('success', 'Claim Successfully');
                                        //     }
                                        // }

                                        showNoti('success', 'Claim Successfully');
                                        setIsLoading(false);
                                        // setIsShowClaim(false);
                                        setIsClaimed(true);
                                        setDisClaim(true);

                                        getWhitelistIdoByAddress(account, item.id);
                                    }
                                } else {
                                    showNoti('warning', 'Claim Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            }).catch((error: any) => {
                console.log('error', error);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        showNoti('warning', error.message);
                    } else {
                        if (error.data && error.data.message) {
                            showNoti('warning', error.data.message);
                        }
                    }
                }
                setIsLoading(false);
            });


        } else {
            showNoti('warning', 'Allocation is empty');
        }
    };
    const _handleDailyClaim = async (roundId: any) => {
        setIsLoading(true);
        if (dailyClaimAble > 0) {
            await _dailyClaimTokens(
                claimDailyContract,
            ).then((txn: any) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;

                                        // const payload = {
                                        //     roundId: roundId,
                                        //     ownerAddress: account,
                                        //     hash: txn.hash,
                                        //     wlTypeEnum: 'CLAIM'
                                        // };
                                        // let res = await IdoLaunchpad.updateHashAfterJoinPool(tokenID, payload);
                                        // if (res.status === 200) {
                                        //     if (res.data) {
                                        //         showNoti('success', 'Claim Successfully');
                                        //     }
                                        // }

                                        showNoti('success', 'Claim Successfully');
                                        setIsLoading(false);
                                        // setIsShowClaim(false);
                                        setIsClaimed(true);
                                        getWhitelistIdoByAddress(account, item.id);
                                    }
                                } else {
                                    showNoti('warning', 'Claim Failed');
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            }).catch((error: any) => {
                showNoti('error', error?.data?.message);
                setIsLoading(false);
            });


        } else {
            showNoti('warning', 'Allocation is empty');
        }
    }

    useEffect(() => {
        getWhitelistIdoByAddress(account, item.id);
        if (claimType === "OFF_CHAIN") {
            getAllocationByAddress(account, item.id);
        }
    }, [account, item.id]);

    useEffect(() => {
        if (account) {
            if (joinStatus) {
                setIsJoined(true);
            } else if (joinPoolContract !== null) {
                _isJoined(joinPoolContract, account, detail.id, item.id).then(
                    (res: any) => {
                        setIsJoined(res);
                    }
                );
            }
            if (claimChangeContract !== null) {
                _isClaimChange(claimChangeContract, account).then((res: any) => {
                    setClaimChangeFromSC(res);
                });
            }
            if (refundContract !== null) {
                _isRefundedRC(refundContract, account).then((res: any) => {
                    setRefundedFromSC(res);
                });
            }
            if (claimContract !== null) {
                _isClaimed(claimContract, account).then((res: any) => {
                    setIsClaimed(res);
                });
                _totalClaimed(claimContract, account, detail.tokenInfoIdo.decimals).then((res: any) => {
                    setTotalClaimed(res);
                });
            }
            _totalJoined(joinPoolContract).then(
                (res: any) => {
                    if (res > 0) {
                        let totalJoin: any = web3.utils.fromWei(res.toString(), 'ether');
                        setTotalJoined(totalJoin);
                    }
                }
            );

        }
    }, [
        account,
        joinStatus,
        joinPoolContract,
        refundContract
    ]);

    // check approve when reload page
    useEffect(() => {
        if (joinStatus || isJoined) {
            setApproveJoin(true);
        } else if (busdContract && scJoinAddress) {
            busdContract.allowance(account, scJoinAddress).then((res: any) => {
                if (res.toString() / 1e18 > 0) {
                    setApproveJoin(true);
                } else {
                    setApproveJoin(false);
                }
            });
        }
    }, [busdContract, account, scJoinAddress, joinStatus, isJoined]);

    useEffect(() => {
        if (claimContract && claimType === "OFF_CHAIN") {
            _getClaimAble(claimContract, account, allocation.totalClaimable, detail.tokenInfoIdo.decimals).then((res) => {
                console.log('getClaimAble', res);
                console.log('allocation.totalClaimable', allocation.totalClaimable);
                setClaimblesSC(res);
            });
            if (account && allocation.totalClaimable > 0) {
                _showClaimBtn(
                    claimContract,
                    account,
                    allocation.totalClaimable,
                    detail.tokenInfoIdo.decimals
                ).then((res) => {
                    setIsShowClaim(res);
                });
            }

            _currentClaimRound(claimContract).then(
                (res: any) => {
                    if (res > 0) {
                        let roundSC: any = web3.utils.fromWei(res.toString(), 'ether');
                        setCurrentRoundSC(roundSC);
                    }
                }
            );
        }

    }, [account, claimContract, allocation]);

    useEffect(() => {

        if (claimDailyContract !== null) {
            totalDailyClaimed(claimDailyContract, account).then((res) => {
                let dailyAmountSC: any = web3.utils.fromWei(res.amount.toString(), 'ether');
                let totalClaimedDailySC: any = web3.utils.fromWei(res.tokensClaimed.toString(), 'ether');
                setTotalClaimedDaily(totalClaimedDailySC);
                setDailyAmount(dailyAmountSC);
                console.log('setTotalClaimDaily', dailyAmountSC);
            });
            if (claimType === "ON_CHAIN") {
                _dailyClaimAble(claimDailyContract, account).then((res) => {
                    let claimAbleDL: any = web3.utils.fromWei(res.toString(), 'ether');
                    console.log('_dailyClaimAble', claimAbleDL);
                    setDailyClaimAble(claimAbleDL);
                });

                _totalTokenNeed(claimDailyContract).then((res) => {
                    let claimAbleDL: any = web3.utils.fromWei(res.toString(), 'ether');
                    console.log('_totalTokenNeed', res.toString());

                });
            }
        }

    }, [account, claimDailyContract]);

    let currentClaim: any = currentRoundSC;
    let nextRoundClaim: any;
    if (totalClaimed > 0 && allocationList) {
        currentClaim = currentRoundSC + 1;
        nextRoundClaim = allocationList[currentClaim];

    }
    let nextTimeClaim: any;
    if (nextRoundClaim) {
        let nextClaim = new Date(nextRoundClaim?.claimTime);
        nextTimeClaim = convertDateTime(nextClaim)
    }

    const BtnRender = (item: any) => {
        let nowDate = new Date(Date.now());
        let startJoinPool = new Date(item.startJoinPool);
        let endJoinPool = new Date(item.endJoinPool);
        let startClaim = new Date(item.startClaim);
        let isClaimChange: boolean = false;
        if (roundWhitelist?.claimChangeAmount !== null && roundWhitelist?.claimChangeAmount > 0 && roundWhitelist?.signatureClaimChange !== null) {
            isClaimChange = true;
        }
        let btn: any;

        if (!roundWhitelist) {
            btn = <Alert message="You are not whitelisted" type="warning" />
        } else {
            if (startJoinPool > nowDate) { // Pool comming soon
                btn = <Button htmlType="button" disabled className="btn-all">Join pool</Button>
            }
            if (startJoinPool <= nowDate && endJoinPool > nowDate) { // pool start
                if (!isJoined) {
                    if (!isApproveJoin) { // approve before commit fund
                        btn = <Button htmlType="button" disabled={isLoading} onClick={_approveJoinPool} className="btn-all">Approve</Button>
                    } else {
                        btn = <Button htmlType="button" disabled={isLoading} onClick={() => _handleJoinPool(detail.id, item.id)} className="btn-all">
                            {isLoading ? (
                                <>
                                    Waiting
                                    <i className="fa fa-spinner fa-spin" />
                                </>
                            ) : "Commit fund"}
                        </Button>
                    }
                } else {
                    btn = <Button htmlType="button" disabled className="btn-all">Claim change</Button>
                }
            }
            if (startJoinPool <= nowDate && endJoinPool < nowDate) { // pool end
                if (isJoined) {
                    btn = <>
                        {claimType === "OFF_CHAIN" ? (
                            <>
                                {!isRefundedRC && (
                                    <>
                                        {startClaim < nowDate && !isClaimed && totalAllocation !== totalClaimed && (
                                            <div className='btn-claim-grp'>
                                                <Button htmlType="button" disabled={!enableClaim || disClaim} onClick={() => _handleClaim(item.id)} className="btn-all">Claim</Button>
                                                {roundWhitelist?.signatureRefund !== null && !isClaimed && item.allowRefund && nowDate < refundTime && (
                                                    <Button htmlType="button" disabled={!enableClaim} onClick={() => _handleRefund(item.id)} className="btn-default">Refund</Button>
                                                )}
                                            </div>
                                        )}
                                        {startClaim > nowDate && allocation?.totalClaimable > 0 && totalAllocation !== totalClaimed && (
                                            <div className='btn-claim-grp'>
                                                <Button htmlType="button" disabled className="btn-all">Claim</Button>
                                            </div>
                                        )}
                                        {isClaimed && totalAllocation !== totalClaimed && (
                                            <div className='btn-claim-grp'>
                                                <Button htmlType="button" disabled={!enableClaim || disClaim} onClick={() => _handleClaim(item.id)} className="btn-all">Claim</Button>
                                            </div>
                                        )}
                                    </>
                                )}

                            </>
                        ) : (
                            <>
                                {!isRefundedRC && (
                                    <div className='btn-claim-grp'>
                                        <Button htmlType="button" disabled={dailyClaimAble == 0 ? true : false} onClick={() => _handleDailyClaim(item.id)} className="btn-all">Claim ({dailyClaimAble})</Button>
                                        {roundWhitelist?.signatureRefund !== null && item.allowRefund && nowDate < refundTime && (
                                            <Button htmlType="button" disabled={!enableClaim} onClick={() => _handleRefund(item.id)} className="btn-default">Refund</Button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        {isRefundedRC && (
                            <div className='btn-claim-grp'>
                                <Button htmlType="button" disabled className="btn-all">Refunded</Button>
                            </div>
                        )}

                        {!isClaimChangeRC && (
                            <Button htmlType="button" onClick={() => _handleClaimChange(item.id)} disabled={!isClaimChange} className={startClaim <= nowDate ? "btn-default" : "btn-all"}>Claim change</Button>
                        )}
                    </>
                }
            }

            if (endJoinPool <= nowDate && !isJoined) {
                btn = <Button htmlType="button" disabled className="btn-all">You have't joined pool</Button>
            }
        }
        return btn;
    }

    let classBg: any = 'ido-guaranteed';
    if (item.roundType === "GUARANTEED") {
        classBg = 'ido-guaranteed'
    }
    if (item.roundType === "PRIVATE") {
        classBg = 'ido-private'
    }
    if (item.roundType === "COMMUNITY") {
        classBg = 'ido-community'
    }
    if (item.roundType === "FCFS") {
        classBg = 'ido-community'
    }

    let percentProcess: any = Number((totalJoined / item.totalRaise) * 100).toFixed(0);

    const showAllocationPopup = () => {
        setIsShowAllocationPopup(true);
    };
    const closeBox = (e) => {
        e.preventDefault()
        setIsShowAllocationPopup(false);
    };
    const columnsAllocations = [
        {
            title: 'No.',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Token Allocation',
            dataIndex: 'tokenAmount',
            key: 'tokenAmount',
            render: (text: any, record: any) => {
                return (
                    <Space align='center' key={'tokenAmount-' + record.id}>{record.tokenAmount}</Space>
                )
            }
        },
        {
            title: 'Percentage (%)',
            dataIndex: 'percentage',
            key: 'percentage',
        },
        {
            title: 'Start claim',
            dataIndex: 'claimTime',
            key: 'claimTime',
            render: (text: any, record: any) => {
                let claimTime: any = new Date(record?.claimTime);
                return (
                    <span key={'claimTime-' + record.id}>{convertDateTime(claimTime)}</span>
                )
            }
        }
    ];

    return (
        <>
            <div className="project-item project-item-close">
                <div className="ido-item">
                    <div className="ido-item-name">{item.title}</div>
                    <div className="ido-item-currency">
                        <img src={detail?.tokenInfoIdo?.logoURL} /> <span>{detail?.tokenInfoIdo?.symbol}/ {detail?.currencyPay}</span>
                    </div>
                    <div className={`ido-item-info ${classBg}`}>
                        <div className="ido-text">
                            <span className="left">Total Raise:</span>
                            <span className="right">${item.totalRaise.toLocaleString()}</span>
                        </div>
                        <div className="ido-text">
                            <span className="left">Swap Rate:</span>
                            <span className="right">1 {detail?.tokenInfoIdo?.symbol} = {(1 / item.conversionRate).toFixed(6)} {detail?.currencyPay}</span>
                        </div>
                        <div className="ido-text">
                            <span className="left">Start Pool:</span>
                            <span className="right">{convertDateTime(startJoinPool)}</span>
                        </div>
                        <div className="ido-text">
                            <span className="left">End Pool:</span>
                            <span className="right">{convertDateTime(endJoinPool)}</span>
                        </div>
                        <div className="ido-text">
                            <span className="left">Vesting:</span>
                            <span className="right"><ExclamationCircleOutlined onClick={showAllocationPopup} /></span>
                        </div>
                    </div>
                </div>
                <div className="project-item-content">
                    {startJoinPool > nowDate && ( // pool upcoming
                        <>
                            <div className="ido-time-description">Join pool starts in : </div>
                            <div className="ido-time-countdown">
                                <Countdown date={getProgressTime(startJoinPool)} renderer={rendererCountDown} />
                            </div>
                        </>
                    )}
                    {startJoinPool <= nowDate && endJoinPool > nowDate && ( // pool start
                        <>

                            <div className="rounds-join-pool">Commit fund end in</div>

                            <div className="ido-time-countdown">
                                <Countdown date={getProgressTime(endJoinPool)} renderer={rendererCountDown} />
                            </div>
                        </>
                    )}
                    {startJoinPool <= nowDate && endJoinPool <= nowDate && !isJoined && ( // pool end - user not join
                        <>
                            <div className="ido-time-description">Pool Ended </div>
                            <div className="ido-time-countdown">

                            </div>
                        </>
                    )}
                    {startJoinPool <= nowDate && endJoinPool <= nowDate && isJoined && startClaim > nowDate && ( // pool end - user join - start claim
                        <>
                            <div className="ido-time-description">Claim start in </div>
                            <div className="ido-time-countdown">
                                <Countdown date={getProgressTime(startClaim)} renderer={rendererCountDown} />
                            </div>
                        </>
                    )}

                    {startClaim < nowDate && totalClaimed > 0 || totalClaimedDaily > 0 ? (
                        <>
                            <div className="ido-swap-process">CLAIM PROGRESS</div>
                            <Progress percent={processClaim} className="project-progress-percent" />
                        </>
                    ) : (
                        <>
                            <div className="ido-swap-process">SWAP PROGRESS</div>
                            <Progress percent={percentProcess} className="project-progress-percent" />
                        </>
                    )}

                    <div className="ido-btn mt-15">
                        {BtnRender(item)}
                    </div>

                    <div className="mt-15">

                        {!isJoined && (
                            <>
                                {/* {startJoinPool < nowDate && ( // pool start
                                            <> */}
                                {roundWhitelist && (
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <p className='label-cus'>Commit Funds: </p>
                                        </Col>
                                        <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                            <label className='label-cus text-danger'>{Number(amountJoin).toFixed(0)} {detail?.currencyPay}</label>
                                        </Col>
                                    </Row>
                                )}
                                {/* </>
                                        )} */}
                            </>
                        )}
                        {isJoined && (
                            <>
                                {startJoinPool > nowDate && ( // pool upcoming
                                    <>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                <p className='label-cus'>Commit Funds:</p>
                                            </Col>
                                            <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                <label className='label-cus text-danger'>{Number(amountJoin).toFixed(0)} {detail?.currencyPay}</label>
                                            </Col>
                                        </Row>

                                    </>
                                )}
                                {startJoinPool <= nowDate && endJoinPool > nowDate && ( // pool start
                                    <>
                                        {/* {isJoined && claimType == "OFF_CHAIN" && (
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                    <p className='label-cus'>Commit Funds :</p>
                                                </Col>
                                                <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                    <label className='label-cus text-danger'>{Number(amountJoin).toFixed(0)} {detail?.currencyPay}</label>
                                                </Col>
                                            </Row>
                                        )} */}
                                        {isJoined && claimType == "OFF_CHAIN" && (
                                            <>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                        <p className='label-cus'>Commited:</p>
                                                    </Col>
                                                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                        <label className='label-cus text-danger'>{Number(amountJoin).toFixed(0)} {detail?.currencyPay}</label>
                                                    </Col>
                                                </Row>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                        <p className='label-cus'>Allocation:</p>
                                                    </Col>
                                                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                        {!isJoined ? (
                                                            <label className='label-cus'>- - -</label>
                                                        ) : (
                                                            <label className='label-cus text-warning'>Caculating</label>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                )}

                                {startJoinPool <= nowDate && endJoinPool <= nowDate && roundWhitelist?.joinedPool && ( // pool end
                                    <>
                                        {!isClaimChangeRC && (
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                    <p className='label-cus'>Change:</p>
                                                </Col>
                                                <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                    <label className='label-cus text-danger'>{roundWhitelist?.claimChangeAmount ? Number(roundWhitelist?.claimChangeAmount).toFixed(0) + ' ' + detail?.currencyPay : 'TBA'}</label>
                                                </Col>
                                            </Row>
                                        )}

                                        {/* {claimType === "OFF_CHAIN" && ( */}
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                <p className='label-cus'>Allocation:</p>
                                            </Col>
                                            <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                <label className='label-cus text-info'>{totalAllocation ? totalAllocation + ' ' + detail?.tokenInfoIdo?.symbol : 'Caculating'}</label>
                                            </Col>
                                        </Row>
                                        {/* )} */}

                                        {roundWhitelist?.fundNeed !== null && roundWhitelist?.fundNeed > 0 && (
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                    <p className='label-cus'>Investment:</p>
                                                </Col>
                                                <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                    <label className='label-cus text-danger'>{Number(roundWhitelist?.fundNeed).toFixed(0)} {detail?.currencyPay}</label>
                                                </Col>
                                            </Row>
                                        )}
                                        {_claimedTokens > 0 && (
                                            <>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                        <p className='label-cus'>Claimed:</p>
                                                    </Col>
                                                    <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                        {claimType === "OFF_CHAIN" ? (
                                                            <>
                                                                <label className='label-cus text-danger'> {totalClaimed > 0 ? totalClaimed : '- - -'} {detail?.tokenInfoIdo?.symbol}</label>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label className='label-cus text-danger'> {totalClaimedDaily > 0 ? totalClaimedDaily : '- - -'} {detail?.tokenInfoIdo?.symbol}</label>
                                                            </>
                                                        )}

                                                    </Col>
                                                </Row>
                                                {nextRoundClaim && (
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                            <p className='label-cus'>Next time claim</p>
                                                        </Col>
                                                        <Col className="gutter-row text-right" xs={24} xl={12} md={12} sm={12}>
                                                            <label className='label-cus text-danger'> {nextTimeClaim}</label>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </>
                        )}

                    </div>
                </div>
            </div>
            <Modal
                title={<div className="text-md custom-modal-title">Allocation</div>}
                visible={isShowAllocationPopup}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <Table pagination={false} dataSource={allocationList} columns={columnsAllocations} />
                    <div className="modal-btn-group mt-15">
                        <button className="btn-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>

            {isLoading && (
                <LoadingFull />
            )}
        </>
    )
}

export default RoundItem
