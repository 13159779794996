import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Switch, Spin, Tabs, Pagination, Button, Space } from 'antd';
import { TwitterOutlined, CodeSandboxOutlined, GlobalOutlined, CopyOutlined, DoubleRightOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useActiveWeb3React } from 'hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NoneData from 'components/element/NoneData';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import './style.css';
import { useWrap } from 'context/WrapperContext';
import { chainApi } from 'config/api/chainApi';

const { TabPane } = Tabs;

const IdoProject = () => {

    const { showNoti } = useWrap();

    const [gridCalendar, setGridCalendar] = useState(false);
    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState<any>([]);
    const [roundIdos, setRoundIdos] = useState<any>([]);
    const [idoCalendar, setIdoCalendar] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);
    const { chainId, account, library } = useActiveWeb3React();
    const [totalRow, setTotalRow] = useState(0);
    const [activeTab, setActiveTab] = useState('upcoming');

    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            filterEnum: 'ALL',
            chainId: 0
        }
    );

    const getIdoList = async (type, chainId) => {
        setLoadingData(true);
        let dataFilter: any;

        if (type === "JONED") {
            dataFilter = {
                ...param,
                filterEnum: "ALL",
                ownerAddress: account,
                chainId: param.chainId ? param.chainId : chainId ? chainId : 0
            };
        } else {
            dataFilter = {
                ...param,
                filterEnum: type,
                chainId: param.chainId ? param.chainId : chainId ? chainId : 0
            };
        }
        try {

            let res: any = await IdoLaunchpad.getRoundIdoList(dataFilter);

            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setLoadingData(false);
                setRoundIdos(res.data);
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    };
    const getIdoCalendar = async (type) => {
        setLoadingData(true);
        let dataFilter: any = {
            ...param,
            filterEnum: type
        };
        try {
            let res: any = await IdoLaunchpad.getRoundIdoCalendar(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setLoadingData(false);

                let idoCalendar: any = [];

                if (res.data.events) {
                    res.data.events.forEach((item, index) => {
                        let itemNew: any = {
                            title: item.title,
                            date: item.date,
                            url: `/launchpad-ido/${item?.handle}`,
                            imageurl: item.imageUrl,
                        }
                        idoCalendar.push({ ...itemNew });
                    });
                }
                setIdoCalendar(idoCalendar);
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    };
    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 20, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                    if (isTestnet === true) {
                        setChainNetworkListTest(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList(false);
        getChainNetworkList(true);
    }, []);

    useEffect(() => {
        if (activeTab) {
            switch (activeTab) {
                case 'upcoming':
                    getIdoList('ALL', chainId);
                    break;
                case 'active':
                    getIdoList('IN_PROGRESS', chainId);
                    break;
                case 'ended':
                    getIdoList('ENDED', chainId);
                    break;
                case 'joined':
                    getIdoList('JONED', chainId);
                    break;
                default:
                    break;
            }
        }
    }, [activeTab, param, chainId]);

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };


    const onCopy = () => {
        showNoti('success', 'Copied');
    };

    const onChangeSwitchCalendar = (checked: boolean) => {

        getIdoCalendar('UP_COMING');
        setGridCalendar(checked);

    };

    function renderEventContent(eventInfo: any, arr: any) {
        return (
            <>
                <div className='calendar-title'>
                    <img width={35} src={eventInfo.event.extendedProps.imageurl} />
                    {eventInfo.event.title}
                </div>
            </>
        )
    }

    const IdoContent = () => {

        return (
            <>
                {!gridCalendar ? (
                    !loadingData ? (
                        <Row gutter={30}>
                            {roundIdos?.length > 0 ? (
                                roundIdos.map((item, index) => {

                                    let logoNetwork: any = '';
                                    let titleNetwork: any = '';
                                    let chain: any;
                                    chain = chainNetworkListMain.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    if (chain == undefined || chain.length == 0) {
                                        chain = chainNetworkListTest.filter(itemChain => parseInt(item?.chainId) === parseInt(itemChain.chainId));
                                    }
                                    if (chain[0]) {
                                        logoNetwork = chain[0].logoLink;
                                        titleNetwork = chain[0].name;
                                    }


                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'round-item-' + index}>
                                            <div className="project-item ido-item-grid">
                                                <div className="project-image">
                                                    <img src={item?.bannerURL?.length > 0 ? item?.bannerURL : "../images/bg-default.webp"} />
                                                    <div className="project-chain">
                                                        <div className="project-token-main">{item?.campaignIdo?.tokenInfoIdo?.symbol}</div>
                                                        {titleNetwork && logoNetwork && (
                                                            <div className="project-token-chain">
                                                                <img title={titleNetwork} src={logoNetwork} />
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="project-item-content">
                                                    <div className="project-name ido-item-name"><Link to={`/ido-launchpad/${item?.campaignIdo?.handle}`}>{item?.campaignIdo?.title}</Link> <span className="ido-currency-pay">{item?.title}</span></div>
                                                    <div className="ido-description">
                                                        {item?.campaignIdo?.description}
                                                    </div>
                                                    <div className={`ido-item-info ido-${item?.roundType.toLowerCase()}`}>
                                                        <div className="ido-text">
                                                            <span className="left">Swap Rate:</span>
                                                            <span className="right">1 {item?.campaignIdo?.currencyPay} = {item?.conversionRate} {item?.campaignIdo?.tokenInfoIdo?.symbol}</span>
                                                        </div>
                                                        <div className="ido-text">
                                                            <span className="left">IDO Supply:</span>
                                                            <span className="right">{item?.totalRaise} {item?.campaignIdo?.currencyPay}</span>
                                                        </div>
                                                        <div className="ido-text">
                                                            <span className="left">Total Supply:</span>
                                                            <span className="right">{item?.campaignIdo?.tokenInfoIdo?.totalSupply} {item?.campaignIdo?.tokenInfoIdo?.symbol}</span>
                                                        </div>
                                                    </div>
                                                    <div className="ido-text mt-0">
                                                        <span className="left">Community:</span>
                                                        <span className="right" style={{ paddingRight: '0px !important' }}>
                                                            <div className="social">
                                                                <Link to={item?.campaignIdo?.socialNetworkIdo?.twitter ?? '#'}><TwitterOutlined className="icon" /></Link>
                                                                <Link to={item?.campaignIdo?.socialNetworkIdo?.twitter ?? '#'}><GlobalOutlined className="icon" /></Link>
                                                                <Link className="last-icon" to="#"><CodeSandboxOutlined className="icon" /></Link>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="ido-text">
                                                        <span className="left">Contract:</span>
                                                        <span className="right text-primary" style={{ paddingRight: '0px !important' }}>
                                                            <i style={{ marginRight: '10px' }}>0x94949...98349348</i>
                                                            {/* {record.token?.substring(0, 8)}...{record.token?.substring(record.token.length - 6)} */}
                                                            <CopyToClipboard onCopy={onCopy} text={'asjkdhkjashdkasd'}>
                                                                <CopyOutlined />
                                                            </CopyToClipboard>
                                                        </span>
                                                    </div>
                                                    <div className="ido-btn-grid">
                                                        <Button htmlType="button" onClick={(event) => (window.location.href = `/ido-launchpad/${item?.campaignIdo?.handle}`)} className="btn-all mt-15">View Pool</Button>
                                                    </div>
                                                    <div className="ido-link-grid">
                                                        <Link to="#">Full Research Project <DoubleRightOutlined /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <NoneData text={'No Data'} />
                                </>
                            )}
                        </Row>
                    ) : (
                        <>
                            <div className="loading-data">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        </>
                    )
                ) : (
                    <Row gutter={30}>
                        <Col xs={24}>
                            <div className="calendar-grid">
                                <FullCalendar
                                    plugins={[dayGridPlugin]}
                                    initialView="dayGridMonth"
                                    events={idoCalendar}
                                    selectable={false}
                                    navLinks={false}
                                    eventContent={renderEventContent}
                                />
                            </div>
                        </Col>
                    </Row>
                )
                }

            </>
        );
    };
    const onChangeTabs = (key: string) => {
        setActiveTab(key);
        setGridCalendar(false);
        setRoundIdos([]);
    };

    return (
        <>
            <div className="projects-grid">
                <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="filter-block"
                    tabBarExtraContent={
                        <div className='filter-block-form'>
                            View Calendar <Switch defaultChecked={false} onChange={onChangeSwitchCalendar} />
                        </div>
                    }
                >
                    <TabPane tab="Upcoming" key="upcoming">
                        <IdoContent />
                    </TabPane>
                    <TabPane tab="Active" key="active">
                        <IdoContent />
                    </TabPane>
                    <TabPane tab="Ended" key="ended">
                        <IdoContent />
                    </TabPane>
                    <TabPane tab="Joined" key="joined">
                        <IdoContent />
                    </TabPane>
                </Tabs>

                {totalRow > 9 && !gridCalendar && (
                    <Pagination
                        current={param.page + 1}
                        defaultCurrent={1}
                        total={totalRow}
                        pageSize={param.size}
                        onChange={onChange_Pagi}
                        showSizeChanger={false}
                        className="pagiation-custom"
                    />
                )}
            </div>
        </>
    )
}

export default IdoProject

