
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { RadarChartOutlined, LeftCircleOutlined, InsertRowBelowOutlined, MergeCellsOutlined } from '@ant-design/icons';
import './style.css';

const { Content } = Layout;

const LaunchpadCreate = () => {
    const history = useHistory();

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-create">
                            <div className="back-history l-30">
                                <Link to={`/ido`}><LeftCircleOutlined /> Cancel create launchpad</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Create Launchpad</div>
                                <div className="create-step">
                                    <div className="create-launchpad-block">
                                        <button type="button" onClick={(event) => (window.location.href = `/ico/create`)} className="btn-launchpad-create">
                                            <RadarChartOutlined style={{ fontSize: '30px !important' }} />
                                            <p>Pre Sale</p>
                                        </button>
                                        <button type="button" onClick={(event) => (window.location.href = `/air-drop/create`)} className="btn-launchpad-create">
                                            <InsertRowBelowOutlined />
                                            <p>Air Drop</p>
                                        </button>
                                        <button type="button" onClick={(event) => (window.location.href = `/ido/create`)} className="btn-launchpad-create">
                                            <MergeCellsOutlined />
                                            <p>IDO</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>

            </Content>
        </>
    )
}

export default LaunchpadCreate
