/* eslint-disable import/prefer-default-export */
export enum STATUS {
    IN_COMMING=2,
    OPEN = 3,
    CLOSE = 4
  }
  
  export enum PRICE_TYPE {
    BUSD = 1,
    BNB=2
  }
  
  export enum TIMELINE_STT {
    PENDING = 0,
    PROCESS = 1,
    COMPLETED = 2
  }
  
  