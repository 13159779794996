
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Button } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import CominSoon from 'components/cominsoon';
const { Content } = Layout;

const Services = () => {
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div>
                <div className='page-main'>
                    <HeaderTop pageType={'color'} />
                    <div className="page-content">

                        <CominSoon />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default Services
