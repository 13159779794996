
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Form, Input, Steps, Table, Col, Spin } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import './style.css';
import VerifyToken from './verify-token';
import Finish from './finish';
import { useWrap } from 'context/WrapperContext';
import LoadingFull from 'components/loading-full';
import { Helmet } from "react-helmet"
import { useActiveWeb3React } from 'hooks';

import web3 from 'web3';
import { getInformationByChain } from 'config/network/multichainAddresses';
import { ethers } from 'ethers';
import { multiSendToken, multiSendEther, createVestingLock, createLock } from 'components/utils';
import { useMultiSenderContract } from 'hooks/useContract';

const { Step } = Steps;
const { Content } = Layout;
declare const window: Window & typeof globalThis & { ethereum: any };

const MultiSender = () => {
    const { showNoti } = useWrap();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [canNext, setCanNext] = useState(false);
    const [transactions, setTransactions] = useState<any>([]);
    const [done, setDone] = useState<any>(false);
    const { chainId, account } = useActiveWeb3React();



    const [firstStepInfo, setFirstStepInfo] = useState<any>({});

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const multiSenderAddress = getInformationByChain(chainId, 'REACT_APP_MULTI_SENDER');
    const multiSenderContract = useMultiSenderContract(multiSenderAddress);

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    useEffect(() => {
        const index = transactions.findIndex(element => {
            return element.status === 'pending';
        });
        console.log(index);
        if (index !== -1) {
            setDone(true);
        }
    }, [transactions]);

    const steps = [
        {
            title: 'Add Your Allocation',
            content: <VerifyToken form={form} setCanNext={setCanNext} />,
            description: 'Enter your token to be send with allocations'
        },
        {
            title: 'Confirmation',
            content: <Finish form={form} />,
            description: 'Let review your information'
        },
    ];

    const columns = [
        {
            title: 'Transaction Hash',
            dataIndex: 'hash',
            key: 'hash',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];



    const [current, setCurrent] = React.useState<any>(0);


    const prev = () => {
        setCurrent(0);
    };


    const onNextStep = async (values: any) => {
        setCurrent(1);
        if (!values.token_address) {
            let balance = await w3.eth.getBalance(account as string);
            values.balances = parseFloat(ethers.utils.formatUnits(balance, values.decimals));
            form.setFieldsValue({ 'balances': parseFloat(ethers.utils.formatUnits(balance, values.decimals)) })
        }
        setFirstStepInfo({ info: firstStepInfo, ...values });
    };

    const addTXN = obj => {
        setTransactions(current => [...current, obj]);
    };

    const updateTXNStt = (hash, status) => {
        setTransactions(current =>
            current.map(obj => {
                if (obj.hash.toLowerCase() === hash.toLowerCase()) {
                    return { ...obj, status: status };
                }

                return obj;
            }),
        );
    };


    const onConfirmDistribute = async (values: any) => {
        if (firstStepInfo.tokenToSend > firstStepInfo.balances) {
            showNoti('warning', 'Insufficient Balance');
            return;
        }
        setLoading(true);
        try {
            if (!firstStepInfo.token_address) {
                await multiSendEther(
                    multiSenderContract,
                    JSON.parse(firstStepInfo.addressList),
                    JSON.parse(firstStepInfo.amountList),
                    ethers.utils.parseUnits(`${firstStepInfo.tokenToSend}`, firstStepInfo.decimals).toHexString()
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            addTXN({ hash: txn.hash, status: 'pending' })
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                updateTXNStt(txn.hash, 'success')
                                                countNoti++;

                                            }
                                        } else {
                                            updateTXNStt(txn.hash, 'failed')
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                showNoti('warning', error.message);
                            } else {
                                if (error.data && error.data.message) {
                                    showNoti('warning', error.data.message);
                                }
                            }
                        }
                    });
            } else {
                await multiSendToken(multiSenderContract,
                    firstStepInfo.token_address,
                    values.send_options === 'safe_mode',
                    JSON.parse(firstStepInfo.addressList),
                    JSON.parse(firstStepInfo.amountList)
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            addTXN({ hash: txn.hash, status: 'pending' })
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                updateTXNStt(txn.hash, 'success')
                                            }
                                        } else {
                                            updateTXNStt(txn.hash, 'failed')
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                showNoti('warning', error.message);
                            } else {
                                if (error.data && error.data.message) {
                                    showNoti('warning', error.data.message);
                                }
                            }
                        }
                    })
            }

        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    return (
        <>
            <Helmet>
                <title>WaveSignal | MultiSender</title>
            </Helmet>
            <HeaderMobile />
            <Content className="page-container">
                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-create container">
                            <div className="back-history">
                                <Link to={`/ido`}><LeftCircleOutlined /> Cancel create Multi-Sender</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Create Multi-Sender</div>
                                <div className="create-step">
                                    <>
                                        <Steps current={current} className="create-step-cus">
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} description={item.description} />
                                            ))}
                                        </Steps>
                                        <div className="multi-sender-block">
                                            <Form form={form} layout="vertical" autoComplete="off"
                                                onFinish={current != steps.length - 1 ? onNextStep : onConfirmDistribute}
                                            >
                                                <div className="steps-content">{steps[current].content}</div>
                                                <div className="steps-action">
                                                    {(current === 1 && !done) && (
                                                        <button className="btn-default" type="button" style={{ margin: '0 8px' }} onClick={() => prev()} disabled={loading}>
                                                            Back
                                                        </button>
                                                    )}
                                                    {current === 0 && (
                                                        <>
                                                            {current == 0 && (
                                                                <button disabled={!account || !canNext} className="btn-all" type="submit">
                                                                    {account ? 'Next' : 'Connect Wallet'}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {(current === 1 && !done) && (
                                                        <button type="submit" disabled={loading} className="btn-all">
                                                            {account ? 'Confirm' : 'Connect Wallet'} {loading && <Spin className="ml-10" size="small" />}
                                                        </button>
                                                    )}
                                                </div>
                                                {(current === 1 && transactions.length > 0) && (
                                                    <Table style={{ marginTop: '15px' }} dataSource={transactions} columns={columns} />
                                                )
                                                }
                                            </Form>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
                {/*{loading && (*/}
                {/*    <>*/}
                {/*        <LoadingFull />*/}
                {/*    </>*/}
                {/*)}*/}
            </Content>
        </>
    )
}

export default MultiSender
