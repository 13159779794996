import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Menu } from 'antd';
import { FacebookOutlined, GlobalOutlined, LinkOutlined, MediumCircleFilled, MediumOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './footer.css';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <div className="footer">
            <div className='container'>
                <Row gutter={{ xs: 8, sm: 20, md: 20, lg: 20 }}>
                    <Col className="gutter-row footer-line" xs={24} xl={8} md={8} sm={8}>
                        <div className="logo-footer">
                            <a href='/'><img width={40} src="../../../../images/w-logo.png" /></a>
                        </div>
                        <Row gutter={{ xs: 8, sm: 15, md: 15, lg: 15 }}>
                            {/* <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                <div className="token-name">W86</div>
                                <div className="token-price">-</div>
                                <div className="mt-20">
                                    <Link className="link-button" to="">Buy W86</Link>
                                </div>
                            </Col> */}
                            <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                                <div className="token-info-content">
                                    <p className="token-info">
                                        <span>Max supply</span>
                                        {/* <span>680,000,000</span> */}
                                        <span><small>Coming soon</small></span>
                                    </p>
                                    <p className="token-info">
                                        <span>Total supply</span>
                                        <span><small>Coming soon</small></span>
                                    </p>
                                    <p className="token-info">
                                        <span>Circulating supply</span>
                                        <span><small>Coming soon</small></span>
                                    </p>
                                    <p className="token-info">
                                        <span>Total Burned</span>
                                        <span><small>Coming soon</small></span>
                                    </p>
                                    <p className="token-info">
                                        <span>Market Cap</span>
                                        <span><small>Coming soon</small></span>
                                    </p>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                    <Col className="gutter-row footer-line" xs={24} xl={12} md={12} sm={12}>

                        <Row gutter={{ xs: 8, sm: 15, md: 15, lg: 15 }}>
                            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                <div className="menu-footer-content">
                                    <div className="footer-title">About</div>
                                    <Menu className="footer-menu">
                                        <Menu.Item key="footer-docs">
                                            <a href='https://docs.wavesignal.finance' target={'_blank'}>
                                                <span>Docs</span>
                                            </a>
                                        </Menu.Item>

                                        <Menu.Item key="footer-teams"><a target="_blank" href="https://medium.com/@wavesignal-official/hello-b73ad89c90cb">Teams</a></Menu.Item>
                                        <Menu.Item key="footer-github"><a target="_blank" href="https://github.com/wavesignal86">Github</a></Menu.Item>
                                    </Menu>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                <div className="menu-footer-content">
                                    <div className="footer-title">Products</div>
                                    <Menu className="footer-menu">
                                        <Menu.Item key="footer-liquidity"><Link to="">Liquidity</Link></Menu.Item>
                                        <Menu.Item key="footer-farms"><Link to="/farm">Farms</Link></Menu.Item>
                                        <Menu.Item key="footer-launchpools"><Link to="">Launchpools</Link></Menu.Item>
                                        <Menu.Item key="footer-fixed-staking"><Link to="/fixed-staking">Fixed Staking</Link></Menu.Item>

                                    </Menu>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                <div className="menu-footer-content">
                                    <div className="footer-title">Service</div>
                                    <Menu className="footer-menu">
                                        <Menu.Item key="footer-referral"><Link to="">Referral program</Link></Menu.Item>
                                        {/* <Menu.Item key="footer-wavec"><Link to="">W86 Token</Link></Menu.Item> */}
                                        {/* <Menu.Item key="apply-to-launch"><a target="_blank" href="https://docs.google.com/forms/d/1_82HuQLqK9BSd_7jnu3hiQk8BvbkXffgVFI9KhEYQRg/edit">Apply to Launch</a></Menu.Item> */}
                                    </Menu>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={4} md={4} sm={4}>
                        <div className="menu-footer-content">
                            <div className="footer-title p-l0">Community</div>
                            <span className="icon-social"><a title='Website' target="_blank" href="https://www.wavesignal.finance/"><GlobalOutlined size={30} /></a></span>
                            <span className="icon-social"><a title='Medium' target="_blank" href="https://medium.com/@wavesignal-official"><MediumOutlined size={30} /></a></span>
                            <span className="icon-social"><a title='Link3' target="_blank" href="https://link3.to/wavesignal"><img style={{ marginTop: '-4px' }} width={16} src='../../../images/Link3_Logo_White.png' /></a></span>
                            <span className="icon-social"><a title='Telegram' target="_blank" href="https://t.me/WaveSignalAnnouncements"><FontAwesomeIcon icon={faPaperPlane as IconProp} /></a></span>
                            <span className="icon-social"><a title='Twitter' target="_blank" href="https://twitter.com/wave_signal"><TwitterOutlined size={30} /></a></span>
                            {/* <span className="icon-social"><Link to=""><YoutubeOutlined size={30} /></Link></span> */}
                        </div>
                        <div className='footer-audit'>
                            <a target="_blank" href='https://github.com/solidproof/projects/tree/main/WaveSignal'><img src='../../../images/audit.jpeg' /></a>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default Footer
