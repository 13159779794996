
import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, } from 'antd';
import HeaderTop from 'components/common/header-top';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import './style.css';
import ProjectMenu from './menu-project';
import HowToJoinIdo from './how-to-join';
import FAQ from './faq';
import ApplyToLaunch from './apply-to-launch';
import IdoProject from './IdoProject';
import IdoBanner from './ido-banner';

const { Content } = Layout;

const IdoLaunchpad = () => {
    const location = useLocation();


    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content mt-100">

                        <div className="project-block container">
                            <IdoBanner />
                            {location.pathname !== "/ido-launchpad" && (
                                <>
                                    <div className="project-title">
                                        Project List
                                    </div>
                                    <ProjectMenu />
                                </>
                            )}

                            <div className="project-content">
                                <IdoProject />
                            </div>
                        </div>
                        <HowToJoinIdo />
                        <FAQ />
                        <ApplyToLaunch />
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default IdoLaunchpad
