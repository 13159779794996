import './App.css';
import Header from './components/common/header';
import {BrowserRouter as Router} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core';
import { getLibrary, Web3ProviderNetwork } from './context/web3provider';
import { RefreshContextProvider } from './context/RefreshContext';
import { WrapperProvider } from './context/WrapperContext';

function App() {
	return (
		<>
		 <Router>
			<Web3ReactProvider getLibrary={getLibrary}>
				<Web3ProviderNetwork getLibrary={getLibrary}>
					<RefreshContextProvider>
						<WrapperProvider>
							<ReactNotification />
							<Header />
						</WrapperProvider>
					</RefreshContextProvider>
				</Web3ProviderNetwork>
			</Web3ReactProvider>
			</Router>
		</>
	);
}

export default App;
