import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Button } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import { chainApi } from 'config/api/chainApi';
import './style.css';


const { Content } = Layout;

const Reviews = () => {
    const [chainNetworkListMain, setChainNetworkListMain] = useState<any>([]);
    const getChainNetworkList = async (isTestnet: any) => {
        let dataFilter: any = { page: 0, size: 10, sort: 'id,desc', vm: 'EVM' };
        if (isTestnet !== null) {
            dataFilter = { ...dataFilter, isTestnet: isTestnet }
        }
        try {
            let res = await chainApi.getChainNetworkList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (isTestnet === false) {
                        setChainNetworkListMain(res.data);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        getChainNetworkList(false);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="review-crypto container">
                <div className="review-crypto-content ">
                    <div className="review-crypto-title">
                        Suite of Tools for Token Launch
                    </div>
                    <div className="review-crypto-description">
                        Create your own tokens and launchpads in (some) clicks. Fast, simple, low fee. No prior code knowledge required and 100% decentralized!
                    </div>

                    <div className="slide-reviews">
                        <Slider {...settings} className="customer-slide">
                            <div className="p-lr-15" key={1}>
                                <div className="review-item">
                                    <div className="review-img">
                                        <p><Link to="/ico/create"><img src="../images/icon-service/ico.png" /></Link></p>
                                        <Link to="/ico/create">ICO Launchpad</Link>
                                    </div>
                                    <div className="review-des">
                                        The ICO help you easy create and raise fund from the community.
                                    </div>

                                </div>
                            </div>
                            <div className="p-lr-15" key={2}>
                                <div className="review-item">
                                    <div className="review-img">
                                        <p><Link to="/ido-launchpad"><img src="../images/icon-service/stellar.png" /></Link></p>
                                        <Link to="/ido-launchpad">IDO Launchpad </Link><small className='text-gray text-small'>(Coming soon)</small>
                                    </div>
                                    <div className="review-des">WaveSignal help you easy and quickly sell your token with multiple rounds</div>
                                </div>
                            </div>
                            <div className="p-lr-15" key={3}>
                                <div className="review-item">
                                    <div className="review-img">
                                        <p><Link to="#"><img src="../images/icon-service/bitcoin-craft.png" /></Link></p>
                                        <Link to="#">INO Launchpad </Link><small className='text-gray text-small'>(Coming soon)</small>
                                    </div>
                                    <div className="review-des">Where you can collect and earn from latest, most valuable NFT collections.</div>
                                </div>
                            </div>
                            <div className="p-lr-15" key={4}>
                                <div className="review-item">
                                    <div className="review-img">
                                        <p><Link to="/wave-lock/token"><img src="../images/icon-service/encrypted.png" /></Link></p>
                                        <Link to="/wave-lock/token">Locking</Link>
                                    </div>
                                    <div className="review-des">Lock your liquidity to WaveSignal, PancakeSwap after presale.</div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="slide-reviews-mobile">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                                <div className="review-item" key={5}>
                                    <div className="review-img">
                                        <p><Link to="/ico/create"><img src="../images/icon-service/ico.png" /></Link></p>
                                        <Link to="/ico/create">ICO Launchpad</Link>
                                    </div>
                                    <div className="review-des">
                                        The ICO help you easy create and raise fund from the community.
                                    </div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                                <div className="review-item" key={6}>
                                    <div className="review-img">
                                        <p><Link to="#"><img src="../images/icon-service/stellar.png" /></Link></p>
                                        <Link to="#">IDO Launchpad </Link><small className='text-gray text-small'>(Coming soon)</small>
                                    </div>
                                    <div className="review-des">WaveSignal help you easy and quickly sell your token with multiple rounds</div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                                <div className="review-item" key={7}>
                                    <div className="review-img">
                                        <p><Link to="#"><img src="../images/icon-service/bitcoin-craft.png" /></Link></p>
                                        <Link to="#">INO Launchpad </Link><small className='text-gray text-small'>(Coming soon)</small>
                                    </div>
                                    <div className="review-des">Where you can collect and earn from latest, most valuable NFT collections.</div>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                                <div className="review-item" key={8}>
                                    <div className="review-img">
                                        <p><Link to="/wave-lock/token"><img src="../images/icon-service/encrypted.png" /></Link></p>
                                        <Link to="/wave-lock/token">Locking</Link>
                                    </div>
                                    <div className="review-des">Lock your liquidity to WaveSignal, PancakeSwap after presale.</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
            <div className='chain-support-footer bg-white'>
                <div className='container'>
                    <div className='chain-support-content'>
                        <div className="review-crypto-title">
                            Network supported
                        </div>
                        <div className='chain-support-list'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={9} md={9} sm={9}>
                                    <p className='mb-0 font-16'>
                                        We love innovative and sustainable chains! Here are some of the top networks integrated and there are more coming as we wish to diversify choices for our users.
                                    </p>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={11} md={11} sm={11}>
                                    <div className='chain-support-block'>
                                        {chainNetworkListMain && chainNetworkListMain?.length > 0 && (
                                            <>
                                                {chainNetworkListMain?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div className='chain-sp-item' key={'chain-sp-' + index}>
                                                                <img key={'chain-sp-img' + index} src={item.logoLink} title={item.name} alt={item.name} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={4} md={4} sm={4}>
                                    <div className='chain-support-btn'>
                                        <Button htmlType="button" className="btn-all">
                                            <a target="_blank" href="https://docs.wavesignal.finance/wave_signal/contact-us">Partner with us <DoubleRightOutlined /></a>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Reviews
