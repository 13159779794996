
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, Spin, Space, Table, Tabs, Alert, Card } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormOutlined, CopyOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { useActiveWeb3React } from 'hooks';
import '../style.css';
import {
    getSymbolToken,
    getTokenName,
    getTokenDecimal,
    totalLockCountForToken, getLocksForToken, cumulativeLockInfo, getLP, getTokenInformation
} from '../../utils';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useTokenInfoContract, useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import tokenInfoAbi from 'config/abi/standardTokenAbi.json';
import pairAbi from '../../../config/abi/pairAbi.json';
import { convertDateTime } from 'utils/formatDatetime';

declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { TabPane } = Tabs;

const LockDetail = () => {
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();
    let { address }: { address: string } = useParams();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const [activeTab, setActiveTab] = useState('all');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
    const [tokenAddress, setTokenAddress] = useState('0xA5C277D1d2Eb5B02Be17cAFFE65D2994B8c890D7');
    const [errorCallSC, setErrorCallSC] = useState(false);
    const [tokenSymbol, setTokenSymbol] = useState();
    const [tokenName, setTokenName] = useState();
    const [tokenDecimal, setTokenDecimal] = useState<any>(18);
    const [isLP, setIsLP] = useState<boolean>(false);
    const [amountLocked, setAmountLocked] = useState<any>(0);
    const [valueLocked, setValueLocked] = useState<any>(0);
    const [pairName, setPairName] = useState<any>('');
    const [dex, setDex] = useState<any>('');
    const [records, setRecords] = useState<any>([]);


    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');
    const waveLockContract = useWaveLockContract(waveLockAddress)

    const getLockRecords = async () => {
        try {
            if (account) {
                const total = await totalLockCountForToken(waveLockContract, address);
                if (parseInt(total) > 0) {
                    const lockRecords = await getLocksForToken(waveLockContract, address, 0, parseInt(total));
                    setRecords(lockRecords)
                    console.log(records);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getCumulativeLockInfo = async () => {
        try {
            if (account) {
                const info = await cumulativeLockInfo(waveLockContract, address);
                if (info.factory.toLowerCase() !== ethers.constants.AddressZero) {
                    const lpInfo = await getLP(info.token, pairAbi, library, account);
                    setIsLP(true);
                    setPairName(`${lpInfo.token0Info.symbol}/${lpInfo.token1Info.symbol}`)
                    setDex(lpInfo.name)
                } else {
                    const tokenInfo = await getTokenInformation(pairAbi, info.token);
                    setTokenName(tokenInfo.name);
                    setTokenSymbol(tokenInfo.symbol);
                    setTokenDecimal(tokenInfo.decimals);
                }
                setAmountLocked(ethers.utils.formatUnits(info.amount, tokenDecimal))
            }
        } catch (e) {
            console.log(e);
        }
    };



    useEffect(() => {
        getLockRecords()
        getCumulativeLockInfo()
    }, [account]);

    const onCopy = () => {
        showNoti('success', 'Copied');
    };



    const columns = [
        {
            title: 'Wallet',
            dataIndex: 'owner',
            key: 'owner',
            render: (text, record) => {

                return (
                    <Space key={record.id} size="middle">
                        <Link to={`https://testnet.bscscan.com/address/${record.owner}`}>{record.owner}</Link>
                    </Space>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <div>{parseFloat(ethers.utils.formatUnits(record.amount, tokenDecimal))}</div>
                    </Space>
                )
            }
        },
        {
            title: 'Cycle(m)',
            dataIndex: 'cycle',
            key: 'cycle',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <div>{parseInt(record.cycle) / 60}</div>
                    </Space>
                )
            }
        },
        {
            title: 'Cycle Release(%)',
            dataIndex: 'cycleBps',
            key: 'cycleBps',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <div>{parseInt(record.cycleBps) / 100}</div>
                    </Space>
                )
            }
        },
        {
            title: 'TGE(%)',
            dataIndex: 'tgeBps',
            key: 'tgeBps',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <div>{parseInt(record.tgeBps) / 100}</div>
                    </Space>
                )
            }
        },
        {
            title: 'Unlock time(UTC)',
            dataIndex: 'tgeDate',
            key: 'tgeDate',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <div>{convertDateTime((new Date(parseInt(record.tgeDate) * 1000)))}</div>
                    </Space>
                )
            }
        },
        {
            title: '',
            dataIndex: 'view',
            key: 'view',
            render: (text, record) => {
                return (
                    <Space key={record.id} size="middle">
                        <Link to={`/wave-lock/record/${record.id}`}>View</Link>
                    </Space>
                )
            }
        },
    ];

    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content container">
                        <div className="project-block-create">
                            <div className="lock-content-detail">


                                {!isLP ? (
                                    <Card title="Lock Info" extra={''} style={{ width: '100%' }} className="mb-15">
                                        <div className="token-info-anti">
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Total Amount Locked</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">
                                                        {amountLocked} {tokenSymbol}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Total Values Locked</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">$0</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Token Address</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value" style={{ color: '#1890ff' }}>
                                                        <span className="mr-10">{address}</span>
                                                        <CopyToClipboard onCopy={onCopy} text={address}>
                                                            <CopyOutlined />
                                                        </CopyToClipboard>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Token Name</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenName}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Token Symbol</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenSymbol}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Token Decimals</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenDecimal}</div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Card>
                                ) : (
                                    <Card title="Lock Info" extra={''} style={{ width: '100%' }} className="mb-15">

                                        <div className="token-info-anti">
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Total Amount Locked</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">
                                                        {amountLocked}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Total Values Locked</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">$0</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Liquidity Address</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value" style={{ color: '#1890ff' }}>
                                                        <span className="mr-10">{address}</span>
                                                        <CopyToClipboard onCopy={onCopy} text={address}>
                                                            <CopyOutlined />
                                                        </CopyToClipboard>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Pair Name</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{pairName}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Dex</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{dex}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                )}

                                <Card title="Lock records" extra={''} style={{ width: '100%' }} className="mb-15">

                                    <Table dataSource={records} columns={columns} pagination={false} />
                                </Card>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </Content>
        </>
    )
}

export default LockDetail
