
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, Spin, Space, Modal, InputNumber, Alert, Checkbox } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top';
import Footer from 'components/common/footer';
import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from 'hooks';
import web3 from 'web3';
import { ethers } from 'ethers';
import LoadingFull from 'components/loading-full';
import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import { useAntiBotContract, useTokenInfoContract, useWaveLockContract } from '../../../hooks/useContract';
import { useWrap } from 'context/WrapperContext';
import { getInformationByChain } from 'config/network/multichainAddresses';
import tokenInfoAbi from 'config/abi/standardTokenAbi.json';
import pairAbi from 'config/abi/pairAbi.json';
import {
    getSymbolToken,
    getBalance,
    getTotalSupply,
    getTokenName,
    getTokenDecimal,
    getTokenAllowance, checkAntiBot,
    createLock,
    createVestingLock, getLPInformation, approveToken
} from '../../utils';


declare const window: Window & typeof globalThis & { ethereum: any };
const { Content } = Layout;
const { Option } = Select;

const CreateLock = () => {
    const history = useHistory();
    const { showNoti } = useWrap();
    const { account, library, chainId } = useActiveWeb3React();
    const waveLockAddress = getInformationByChain(chainId, 'REACT_APP_WAVE_LOCK');



    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);

    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState<boolean>(false);
    const [createTokenForm] = Form.useForm();
    const [showVestingContributor, setShowVestingContributor] = useState(false);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [errorCallSC, setErrorCallSC] = useState(false);
    const [tokenAddress, setTokenAddress] = useState();

    const [tokenSymbol, setTokenSymbol] = useState();
    const [tokenName, setTokenName] = useState();
    const [tokenDecimal, setTokenDecimal] = useState<any>();
    const [totalSupply, setTotalSupply] = useState<any>();
    const [balance, setBalance] = useState<any>(0);
    const [dex, setDex] = useState<any>('');
    const [pairName, setPairName] = useState<any>('');
    const [isLP, setIsLP] = useState<any>(false);
    const [tokenAllowance, setTokenAllowance] = useState<any>(0);

    const waveLockContract = useWaveLockContract(waveLockAddress)


    const onChangeTokenAddress = async (values: any) => {
        setErrorCallSC(false);
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);
        try {
            setIsLP(false);
            setDex('');
            setBalance(0);

            const info = await getLPInformation(tokenAddressVal, pairAbi, library, account)
            if (info && info.factory && info.shortPair) {
                setIsLP(true);
                setDex(info.dex);
                setPairName(info.shortPair)
                setTokenDecimal(18);
            } else {
                let [token_symbol,
                    token_name,
                    token_decimal,
                    total_supply,
                    balance_,
                ] = await Promise.all([
                    getSymbolToken(tokenInfoAbi, tokenAddressVal),
                    getTokenName(tokenInfoAbi, tokenAddressVal),
                    getTokenDecimal(tokenInfoAbi, tokenAddressVal),
                    getTotalSupply(tokenInfoAbi, tokenAddressVal),
                    getBalance(tokenInfoAbi, tokenAddressVal, account)
                ]);
                if (token_symbol && token_name && token_decimal && total_supply && balance_) {
                    let totalSupply: any = parseFloat(ethers.utils.formatUnits(total_supply.toString(), token_decimal));
                    setTokenSymbol(token_symbol);
                    setTokenName(token_name);
                    setTokenDecimal(token_decimal);
                    setTotalSupply(totalSupply);
                    setBalance(parseFloat(ethers.utils.formatUnits(balance_, token_decimal)));

                } else {
                    setErrorCallSC(true);
                }
            }
            const allow = await getTokenAllowance(tokenInfoAbi, tokenAddressVal, account, waveLockAddress)
            setTokenAllowance(parseFloat(ethers.utils.formatUnits(allow, tokenDecimal)))
            setLoadingTokenInfo(false);

        } catch (e: any) {
            setLoadingTokenInfo(false);
            setErrorCallSC(true);
        }
    };
    function onChangeVestingContributor(e) {
        setShowVestingContributor(e.target.checked);
    }


    const getLockId = (res) => {
        if (res.logs && res.logs.length > 0) {
            let log;
            for (let i = 0; i < res.logs.length; i++) {
                if (res.logs[i].topics && res.logs[i].topics[0].toLowerCase() === '0x694af1cc8727cdd0afbdd53d9b87b69248bd490224e9dd090e788546506e076f') {
                    log = res.logs[i];
                }
            }
            if (log) {
                const data = log.data
                const topic = log.topics
                const decodedData = w3.eth.abi.decodeLog([
                    {
                        "indexed": true,
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "unlockDate",
                        "type": "uint256"
                    }
                ], data, topic.slice(1, 5))

                return decodedData.id || 0;
            }
        }
    }

    const onSubmitCreateTokenForm = async (values: any) => {
        setLoading(true);

        try {
            if (values.vesting_contributor) {
                await createVestingLock(waveLockContract,
                    values.owner,
                    values.token_address,
                    isLP,
                    ethers.utils.parseUnits(`${values.amount}`, tokenDecimal),
                    new Date(values.tge_date).getTime() / 1000,
                    values.tge_percent * 100,
                    values.cycle * 60,
                    values.cycle_release_percent * 100,
                    values.title
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                showNoti('success', 'Create Vesting Lock Successfully');
                                                const lockId = getLockId(res);
                                                history.push({
                                                    pathname: `/wave-lock/record/${lockId}`,
                                                    search: ''
                                                });
                                            }
                                        } else {
                                            showNoti('warning', 'Create Vesting Lock Failed');
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                            } else {
                                if (error.data && error.data.message) {
                                }
                            }
                        }
                    });
            } else {
                await createLock(waveLockContract,
                    values.owner,
                    values.token_address,
                    isLP,
                    ethers.utils.parseUnits(`${values.amount}`, tokenDecimal),
                    new Date(values.lock_until).getTime() / 1000,
                    values.title
                )
                    .then((txn) => {
                        if (txn && txn.hash) {
                            let countNoti = 0;
                            const interval = setInterval(function () {
                                (async () => {
                                    const res = await w3.eth.getTransactionReceipt(txn.hash);
                                    if (res) {
                                        clearInterval(interval);
                                        if (res.status && res.blockNumber) {
                                            if (!countNoti) {
                                                countNoti++;
                                                showNoti('success', 'Create Lock Successfully');
                                                const lockId = getLockId(res);
                                                history.push({
                                                    pathname: `/wave-lock/record/${lockId}`,
                                                    search: ''
                                                });
                                            }
                                        } else {
                                            showNoti('warning', 'Create Lock Failed');
                                        }
                                        setLoading(false);
                                    }
                                })();
                            }, 1000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                            } else {
                                if (error.data && error.data.message) {
                                }
                            }
                        }
                    })
            }
        } catch (e: any) {
            setLoading(false);
            console.log(e);
        }
    };

    const handleApproveToken = async (e) => {
        console.log('calll');
        e.preventDefault();
        try {
            if (!tokenAddress) {
                return;
            }
            setLoadingFull(true);
            await approveToken(tokenInfoAbi, tokenAddress, library, account, waveLockAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && showNoti('success', `Approve Successfully`);
                                        countNoti++;
                                        setTokenAllowance(Math.pow(2, 256));

                                    } else {
                                        showNoti('error', 'Approve Failed');
                                    }
                                    setLoadingFull(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingFull(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            showNoti('warning', error.message);
                        } else {
                            if (error.data && error.data.message) {
                                showNoti('warning', error.data.message);
                            }
                        }
                    }
                });
        } catch (error: any) {
            setLoadingFull(false);
            console.log(error);
        }
    };

    const closeBox = () => {
        setIsCreateTokenVisible(false);
    };

    let show_vesting_contributor = false;

    if (showVestingContributor) {
        show_vesting_contributor = true;
    }



    return (
        <>
            <HeaderMobile />
            <Content className="page-container">

                {/* <div className='sider-bar'>
                    <div className="menu-sidebar">
                        <MenuSidebar />
                    </div>
                </div> */}
                <div className='page-main'>
                    <HeaderTop pageType={'color'} chainId={''} />
                    <div className="page-content">
                        <div className="project-block-create container">
                            <div className="back-history">
                                <Link to={`/launchpad`}><LeftCircleOutlined /> Cancel Create Lock</Link>
                            </div>
                            <div className="create-form">
                                <div className="create-title">Lock Create</div>
                                <div className="create-step">
                                    <div className="create-step">
                                        <div className="anti-bot-block">
                                            <div className="steps-content-antibot">

                                                <div className="inputs-steps">
                                                    <Form
                                                        form={createTokenForm}
                                                        name="basic"
                                                        autoComplete="off"
                                                        onFinish={onSubmitCreateTokenForm}
                                                        layout="vertical"
                                                    >

                                                        <Form.Item name="token_address" label="Token or LP Token address" rules={[{ required: true, message: 'Please input Token address!' }]}>
                                                            <Input className="amount-buy" placeholder="Ex: Ox..." onBlur={onChangeTokenAddress} />
                                                        </Form.Item>

                                                        {!errorCallSC ? (
                                                            loadingTokenInfo ? (
                                                                <div className="loading-info">
                                                                    <Space size="middle">
                                                                        <Spin size="large" />
                                                                    </Space>
                                                                </div>
                                                            ) : (isLP ? (<div className="token-info-anti" style={{ marginBottom: '15px' }}>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Pair</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{pairName}</div>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Dex</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{dex}</div>
                                                                    </Col>
                                                                </Row>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-name">Balance</div>
                                                                    </Col>
                                                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                        <div className="token-value">{balance.toLocaleString()}</div>
                                                                    </Col>
                                                                </Row>
                                                            </div>) :
                                                                (tokenAddress && tokenSymbol && tokenName && tokenDecimal && (
                                                                    <div className="token-info-anti" style={{ marginBottom: '15px' }}>
                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-name">Name</div>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-value">{tokenName}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-name">Symbol</div>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-value">{tokenSymbol}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-name">Decimals</div>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-value">{tokenDecimal}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-name">Balance</div>
                                                                            </Col>
                                                                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                                                <div className="token-value">{balance.toLocaleString()}</div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )
                                                                )
                                                            )

                                                        ) : (
                                                            <>
                                                                <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                                                            </>
                                                        )}

                                                        <Form.Item name="owner" label="Owner" rules={[{ required: true, message: 'Please input Owner!' }]}
                                                            help="If you are input owner address. Unlocked token will send to owner address"

                                                        >
                                                            <Input className="amount-buy" placeholder="Ex: Ox..." />
                                                        </Form.Item>

                                                        <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input Title!' }]}>
                                                            <Input className="amount-buy" placeholder="Ex: My lock" />
                                                        </Form.Item>


                                                        <Form.Item name="amount" label="Amount" rules={[
                                                            { required: true, message: 'Please input Amount!' },
                                                            {
                                                                validator: (rule, value, cb: (msg?: string) => void) => {
                                                                    !value || parseFloat(value) <= 0 || parseFloat(value) > balance
                                                                        ? cb(`Amount must be > 0 and <=  ${balance}`)
                                                                        : cb();
                                                                }
                                                            }
                                                        ]}>
                                                            <InputNumber className="amount-buy" placeholder="Ex: 1000000" />
                                                        </Form.Item>
                                                        <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                                            <Form.Item name="vesting_contributor">
                                                                <Checkbox.Group>
                                                                    <Checkbox onChange={onChangeVestingContributor} value={true} style={{ lineHeight: '32px' }}>Using Vesting?</Checkbox>
                                                                </Checkbox.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        {show_vesting_contributor ? (
                                                            <Row>
                                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                    <Form.Item name="tge_date" label="TGE Date" rules={[
                                                                        { required: true, message: 'Please input TGE Date!' },
                                                                        {
                                                                            validator: (rule, value, cb: (msg?: string) => void) => {
                                                                                !value || new Date(value) <= new Date(Date.now())
                                                                                    ? cb("TGE Date needs to be after now")
                                                                                    : cb();
                                                                            }
                                                                        }
                                                                    ]}>
                                                                        <Input className="amount-buy" type="datetime-local" placeholder="0" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                    <Form.Item name="tge_percent" label="TGE Percent" rules={[{ required: true, message: 'Please input TGE Percent!' }]}>
                                                                        <Input className="amount-buy" type="number" placeholder="5" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                    <Form.Item name="cycle" label="Cycle (minutes)" rules={[{ required: true, message: 'Please input Cycle!' }]}>
                                                                        <Input className="amount-buy" type="number" placeholder="10" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                                    <Form.Item name="cycle_release_percent" label="Cycle Release Percent" rules={[{ required: true, message: 'Please input Cycle Release Percent!' }]}>
                                                                        <Input className="amount-buy" type="number" placeholder="10" />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Form.Item name="lock_until" label="Lock until"
                                                                rules={[
                                                                    { required: true, message: 'Please input Lock until!' },
                                                                    {
                                                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                                                            !value || new Date(value) <= new Date(Date.now())
                                                                                ? cb("Lock until needs to be after now")
                                                                                : cb();
                                                                        }
                                                                    }
                                                                ]}>
                                                                <Input className="amount-buy" type="datetime-local" placeholder="0" />
                                                            </Form.Item>
                                                        )}

                                                        {tokenAddress && tokenAllowance <= 0 ?
                                                            (<button className="btn-all" disabled={loadingFull} onClick={(e) => handleApproveToken(e)}>Approve {loading && <Spin size="small" />}</button>) : (
                                                                <Form.Item className="modal-btn-group">
                                                                    <button className="btn-all" disabled={loading} type="submit">Create Lock {loading && <Spin size="small" />}</button>
                                                                </Form.Item>
                                                            )}


                                                    </Form>

                                                </div>


                                                {loadingFull && (
                                                    <LoadingFull />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Content>
        </>
    )
}

export default CreateLock
