
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
import TokenBalance from 'components/balance';
const { Content } = Layout;

const IcoBanner = () => {
    return (
        <>
            <div className="ico-banner-apply-launch">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                        <div className="ico-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={13} md={13} sm={13}>
                                    <div className="ico-banner-apply-content">
                                        <div className="ico-banner-investor-name ico-mobile-title">
                                            ICO Launchpad
                                        </div>
                                        <div className="ico-banner-investor-des">
                                            Fast, safe, cost-saving. Let's introduce your token to WaveSignal community!
                                        </div>
                                        <div className="mt-20 banner-button">
                                            <Link className="link-button" to="/ico/create">Create ICO</Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={11} md={11} sm={11}>
                                    <div className="ico-banner-canvas-content">
                                        {/* <div className="ico-banner-apply-img animation-css-scale">
                                            <img src="../../images/ico-banner.png" />
                                        </div> */}
                                        <div className="radar-type1"> <span className="cir-zero"></span> <span className="cir-one"></span> <span className="cir-two"></span> <span className="cir-three"></span></div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                        <TokenBalance />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default IcoBanner
