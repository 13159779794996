import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Select
} from 'antd';
import { currencies, getCurrencyAddress, getCurrencyDecimals } from 'config/network/config';
import { useActiveWeb3React } from 'hooks';



declare const window: Window & typeof globalThis & { ethereum: any };
const { Option } = Select;

const VerifyToken = (props) => {

    const { account, library, chainId } = useActiveWeb3React();

    const { form } = props;
    let storageStep1 = window.localStorage.getItem('step1');

    let step1;
    if (storageStep1) {
        step1 = JSON.parse(storageStep1);
    }


    let activeCurrencyPay = 'BNB';

    const [currencyPay, setCurrencyPay] = useState(activeCurrencyPay);
    const [createPoolFee, setCreatePoolFee] = useState<any>([]);

    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [baseCurrencies, setBaseCurrencies] = useState<any>([]);


    useEffect(() => {
        if (chainId) {
            setBaseCurrencies(Object.keys(currencies[chainId]))
            if (step1 && step1.currencyApply) {
                if (Object.keys(currencies[chainId]).includes(step1.currencyApply)) {
                    activeCurrencyPay = step1.currencyApply;
                } else {
                    activeCurrencyPay = Object.keys(currencies[chainId])[0];
                }
            } else {
                activeCurrencyPay = Object.keys(currencies[chainId])[0];
            }
            form.setFieldsValue({
                currencyApply: activeCurrencyPay,
                currency_pay_address: getCurrencyAddress(chainId, activeCurrencyPay),
                currency_pay_decimals: getCurrencyDecimals(chainId, activeCurrencyPay)
            });

        } else {
            setBaseCurrencies([])
        }
    }, [chainId])

    useEffect(() => {
        if (!checkUpdate  && chainId) {
            if ( step1 && Object.keys(currencies[chainId]).includes(step1.currencyApply)) {
                form.setFieldsValue({
                    init_launchpad_fee: createPoolFee?.settingValue,
                    title: step1 ? step1.title : '',
                    currencyApply: step1 ? step1.currencyApply : activeCurrencyPay,
                    currency_pay_address: step1 ? step1.currency_pay_address : getCurrencyAddress(chainId, activeCurrencyPay),
                    currency_pay_decimals: step1 ? step1.currency_pay_decimals :  getCurrencyDecimals(chainId, activeCurrencyPay),
                })
            } else {
                form.setFieldsValue({
                    init_launchpad_fee: createPoolFee?.settingValue,
                    title: '',
                    currencyApply:  activeCurrencyPay,
                    currency_pay_address: getCurrencyAddress(chainId, activeCurrencyPay),
                    currency_pay_decimals: getCurrencyDecimals(chainId, activeCurrencyPay),
                })
            }

            console.log(form.getFieldValue('currencyApply'));
            setCheckUpdate(true);
        }
    }, [step1, account, chainId]);


    const onChangeCurrencyPay = e => {
        setCurrencyPay(e.target.value);
        let address = getCurrencyAddress(chainId, e.target.value)
        let decimals = getCurrencyDecimals(chainId, e.target.value)
        console.log(address);
        form.setFieldsValue({
            currencyApply: e.target.value,
            currency_pay_address: address,
            currency_pay_decimals: decimals
        })
        console.log(form.getFieldValue('currency_pay_address'));
    };

    return (
        <>
            <div className="inputs-steps">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item name="step_verify_token" initialValue={1} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="currency_pay_decimals" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="currency_pay_address" hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>

                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Private sale can not be null' }]}
                        >
                            <Input className="amount-buy" placeholder="Ex: This is my private sale" />

                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="currencyApply"
                            label="Currency Pay"
                            initialValue={activeCurrencyPay}
                            rules={[{ required: true, message: 'Please pick Currency Pay!' }]}
                            help={<><span className="warning-form blue-text">Users will pay with: {currencyPay} for your token</span></>}
                        >

                            <Radio.Group style={{ width: '80%' }} onChange={onChangeCurrencyPay}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {baseCurrencies.map((item, index) => (
                                        <Col className='gutter-row' xs={24} xl={6} md={6} sm={6} key={'currencies' + index}>
                                            <Radio value={item} key={index}>{item}</Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                </Row>

            </div>
        </>
    )
}

export default VerifyToken
