
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select } from 'antd';
import { convertDateTime } from 'utils/formatDatetime';

declare const window: Window & typeof globalThis & { ethereum: any };

const Finish = () => {
    let storageLaunchpadInfo = window.localStorage.getItem('launchpadInfo');
    let storageAdditionalInfo = window.localStorage.getItem('additionalInfo');
    let storageTokenInfo = window.localStorage.getItem('tokenInfo');

    let additionalInfo;
    let launchpadInfo;
    let tokenInfo;
    if (storageTokenInfo) {
        tokenInfo = JSON.parse(storageTokenInfo);
    }
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }
    if (storageLaunchpadInfo) {
        launchpadInfo = JSON.parse(storageLaunchpadInfo);
    }
    return (
        <>
            <div className="title-steps">Finish</div>
            <div className="launchpad-info-form">
                <div className="sub-title-steps">Verify Token</div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Network</p>
                            <p>{tokenInfo ? tokenInfo.token_network_chain : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Currency Pay</p>
                            <p>{tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Total Token</p>
                            <p>{tokenInfo ? tokenInfo.total_supply.toLocaleString() : ''} {tokenInfo ? tokenInfo.token_symbol : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Token Name</p>
                            <p>{tokenInfo ? tokenInfo.token_name : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Token Symbol</p>
                            <p>{tokenInfo ? tokenInfo.token_symbol : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Total decimals</p>
                            <p>{tokenInfo ? tokenInfo.token_decimal : ''}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="hr-cus-cre"></hr>
                <div className="sub-title-steps">Defi Launchpad Info</div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Presale rate</p>
                            <p>{launchpadInfo ? launchpadInfo.presale_rate.toLocaleString() : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Listing rate</p>
                            <p>{launchpadInfo ? launchpadInfo.listing_rate : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Sale method</p>
                            <p>{launchpadInfo && launchpadInfo.whitelist == 'disable' ? 'Public' : 'Private'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Softcap</p>
                            <p>{launchpadInfo ? launchpadInfo.softcap : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>

                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Hardcap</p>
                            <p>{launchpadInfo ? launchpadInfo.hardCap : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Unsold Tokens</p>
                            <p>{launchpadInfo ? launchpadInfo.refund_type.toUpperCase() : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Minimum buy</p>
                            <p>{launchpadInfo ? launchpadInfo.minimum_buy : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Maximum buy</p>
                            <p>{launchpadInfo ? launchpadInfo.maximum_buy : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Liquidity</p>
                            <p>{launchpadInfo ? launchpadInfo.liquidity : ''}%</p>
                        </div>
                    </Col>

                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Start time</p>
                            <p>{launchpadInfo ? convertDateTime(new Date(launchpadInfo.start_time)) : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>End time</p>
                            <p>{launchpadInfo ? convertDateTime(new Date(launchpadInfo.end_time)) : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Liquidity lockup time</p>
                            <p>{launchpadInfo ? launchpadInfo.liquidity_lockup : ''} days</p>
                        </div>
                    </Col>
                </Row>
                <hr className="hr-cus-cre"></hr>
                <div className="sub-title-steps">Add Additional Info</div>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Website</p>
                            <p>{additionalInfo?.website ? additionalInfo.website : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Facebook</p>
                            <p>{additionalInfo?.facebook ? additionalInfo.facebook : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Twitter</p>
                            <p>{additionalInfo?.twitter ? additionalInfo.twitter : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Telegram</p>
                            <p>{additionalInfo?.telegram ? additionalInfo.telegram : '___'}</p>
                        </div>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Github</p>
                            <p>{additionalInfo?.github ? additionalInfo.github : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Instagram</p>
                            <p>{additionalInfo?.instagram ? additionalInfo.instagram : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Discord</p>
                            <p>{additionalInfo?.discord ? additionalInfo.discord : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Reddit</p>
                            <p>{additionalInfo?.reddit ? additionalInfo.reddit : '___'}</p>
                        </div>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Description</p>
                            <p>{additionalInfo?.description ? additionalInfo.description : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Using Anti-Rug system (Team Vesting System)</p>
                            <p>{launchpadInfo && launchpadInfo.team_vesting_system && launchpadInfo?.team_vesting_system[0] ? 'Yes' : 'No'}</p>
                        </div>
                    </Col>

                </Row>
            </div>
        </>
    )
}

export default Finish
