
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin } from 'antd';
import HeaderTop from 'components/common/header-top';
import MenuSidebar from 'components/common/menu-sidebar';
import HeaderMobile from 'components/common/menu-sidebar-top'
import Footer from 'components/common/footer'
import { stakingApi } from 'config/api/stakingApi';
import '../style.css';
import NoneData from 'components/element/NoneData';
import StakingFixedItem from './staking-item';
import FixBanner from './banner';
import { Helmet } from "react-helmet"

const { Content } = Layout;
const { Option } = Select;
const StakingFixed = () => {

    const [totalRow, setTotalRow] = useState(0);
    const [classTab, setClassTabs] = useState('');
    const history = useHistory();
    const typeProject = history.location.search.replace('?', '');
    const [form] = Form.useForm();
    const [dataLaunchpad, setDataLaunchpad] = useState<any>([]);
    const [loadingData, setLoadingData] = useState(false);

    let typeList: any = 'STAKE';

    const displayTabs = (action: string) => {
        history.push({
            pathname: '',
            search: action
        });
    };
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            typeEnum: 'STAKE_FIXED_APR',
            keyword: '',
            w86: false,
        }
    );

    const getPrivateList = async (status) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            active: status
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setLoadingData(false);
                    setDataLaunchpad(res.data);
                } else {
                    setLoadingData(false);
                    setDataLaunchpad([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        if (classTab) {
            switch (classTab) {
                case 'active':
                    getPrivateList(true);
                    break;
                case 'inactive':
                    getPrivateList(false);
                    break;
                default:
                    break;
            }
        }
    }, [classTab]);


    useEffect(() => {
        if (typeProject) {
            setClassTabs(typeProject);
        } else {
            setClassTabs('active');
        }
    }, [typeProject]);

    const StakeItemContent = (props) => {

        const { type } = props;

        return (
            <>

                {!loadingData ? (
                    <>
                        {dataLaunchpad?.length > 0 ? (
                            dataLaunchpad.map((item, index) => {
                                return (
                                    <StakingFixedItem item={item} index={index} />
                                )
                            })
                        ) : (
                            <>
                                <NoneData text={'No Data'} link="https://docs.google.com/forms/d/e/1FAIpQLSeImXqte1_xMaNqLVuLBxGAgEpx0OUeAxj7HQhMWX-OniNawg/viewform" linkText="Apply for Staking" />
                            </>
                        )}
                    </>
                ) : (<>
                    <div className="loading-data">
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    </div>
                </>)}


            </>
        );
    };

    const returnContentProjects = () => {
        switch (classTab) {
            case 'active':
                return <StakeItemContent type="active" />;
                break;
            case 'inactive':
                return <StakeItemContent type="inactive" />;
                break;
            default:
                break;
        }
    };
    return (
        <>
            <HeaderMobile />
            <Content className="page-container">
                <HeaderTop pageType={'color'} chainId={''} />
                <div className='page-main bg-white-ps'>
                    <FixBanner />
                    <div className="page-content container ">

                        <div className="project-block">
                            <div className="projects-top">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                        <div className='filter-staking-status'>
                                            <div className="projects-btn-status">
                                                <button type="button" className={classTab == 'active' ? 'active' : ''} onClick={() => displayTabs('active')}>
                                                    Active
                                                </button>
                                                <button type="button" className={classTab == 'inactive' ? 'active' : ''} onClick={() => displayTabs('inactive')}>
                                                    InActive
                                                </button>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="project-content">
                                {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}> */}
                                <div className='farm-list'>
                                    {returnContentProjects()}
                                </div>
                                {/* </Row> */}

                            </div>
                        </div>


                    </div>
                    <Footer />
                </div>

            </Content>
        </>
    )
}

export default StakingFixed
