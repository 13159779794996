
import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Modal, Collapse } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import './style.css';
import { Link } from 'react-router-dom';
import TokenBalance from 'components/balance';
const { Content } = Layout;

const IdoBanner = () => {
    return (
        <>
            <div className="ido-banner-apply-launch">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={16} md={16} sm={16}>
                        <div className="ido-banner-apply-launch-content">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={16} xl={13} md={13} sm={13}>
                                    <div className="ido-banner-apply-content">
                                        <div className="ido-banner-investor-name">
                                            IDO Launchpad
                                        </div>
                                        <div className="ido-banner-investor-des">
                                            Enjoy profitable yield farming and exchanging with the lowest fees in DeFi space!
                                        </div>
                                        <div className="mt-20 banner-button">
                                            <a className="link-button" target="_blank" href="https://docs.google.com/forms/d/1_82HuQLqK9BSd_7jnu3hiQk8BvbkXffgVFI9KhEYQRg/edit">Apply to Launch</a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={8} xl={11} md={11} sm={11}>
                                    <div className="ido-p-banner-image">
                                        <div className="ido-banner-1">
                                            <img src="../images/p-banner-1.png" />
                                        </div>
                                        <div className="ido-img-banner-main animation-css-scale">
                                            <img src="../images/p-banner-main.png" />
                                        </div>
                                        <div className="ido-banner-2">
                                            <img src="../images/p-banner-2.png" />
                                        </div>
                                        <div className="ido-banner-3">
                                            <img src="../images/p-banner-3.png" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                        <TokenBalance />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default IdoBanner
