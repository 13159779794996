import React, { memo } from "react";

import { Img, Link, Logo, Wrapper, Content, OpenMenu, ImgOpenMenu } from './styled'

import { useHookShowMenuMobile } from '../menu-sidebar/actionsSidebar'

const SidebarTopApp = () => {

    const [, actions] = useHookShowMenuMobile()

    return (
        <>

        </>
    )
}

export default memo(SidebarTopApp)
